export enum Requirement {
    Enabled = 0,
    Disabled = 1 << 0,
    UserIsMember = 1 << 1,
    InRoomAsNormal = 1 << 2,
    RoomModeIsGroupOrPrivate = 1 << 3,
    RoomCategoryIsCouple = 1 << 4,
    HasSmartToy = 1 << 5,
    RoomModeIsPrivate = 1 << 6,
    RoomModeIsNotGroupOrPrivate = 1 << 7,
    InSxRoom = 1 << 8
}
