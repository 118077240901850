import {makeStyles} from "@material-ui/styles";
import React from "react";
import {Clickable} from "./contract/Clickable";

const useStyles = makeStyles({
    root: {
        color: "#009CDE",
        font: "inherit"
    }
});

interface Props extends Clickable {
    title: string
}

export const LinkInline = React.memo<Props>(({onClick, title}) => {
    const classes = useStyles();

    return <span className={classes.root} onClick={onClick}>{title}</span>;
});
