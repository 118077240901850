import React from "react";
import {priceUri} from "../price.helper";
import {createStyles, WithStyles, withStyles} from "@material-ui/core";
import {MouseClickEvent} from "../domain/MouseClickEvent";
import {Price} from "../domain/Price";

const styles = createStyles({
    root: {
        flex: 1,
        overflowY: "scroll"
    },
    container: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    item: {
        position: "relative",
        width: "42px",
        height: "42px",
        margin: "10px"
    },
    img: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "42px",
        height: "42px"
    }
});

const indexAttribute = "data-index";

const onClick = (images, onSelect) => event => {
    const {target} = event as MouseClickEvent;
    const element = target && target.closest && target.closest(`[${indexAttribute}]`);

    if (element) {
        const index = element.getAttribute(indexAttribute);

        onSelect(images[index]);
    }
};

interface Image {
    key: string
    src: string
    alt: string
    price?: Price
}

interface Props extends WithStyles<typeof styles> {
    images: Array<any>
    transformer: (item: any, index: number) => Image
    onSelect: (item: any) => void
}

export const ImagePicker = withStyles(styles)(React.memo<Props>(({images, transformer, onSelect, classes}) => (
    <div className={classes.root} onClick={onClick(images, onSelect)}>
        <div className={classes.container}>
            {
                images
                    .map(transformer)
                    .map((image: Image, index) => (
                        <div className={classes.item} key={image.key} {...{[indexAttribute]: index}}>
                            <img className={classes.img} alt={image.alt} src={image.src}/>

                            {
                                image.price &&
                                <img className={classes.img} alt="price" src={priceUri(image.price)}/>
                            }
                        </div>
                    ))
            }
        </div>
    </div>
)));
