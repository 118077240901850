import {WebApiService} from "./webapi.service";

export class KnockoutService {
    constructor(private webapiService: WebApiService) {}

    public getKnockoutOffer() {
        return this.webapiService.get("member/knockOutPromo/getCurrent");
    }

    public setKnockoutOffered() {
        return this.webapiService.post("member/knockOutPromo/setOffered");
    }
}
