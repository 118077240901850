import {RoomListActionType} from "../action/roomlist.action";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";
import {isDevelopment} from "../environment";

export interface RoomListState {
    rooms: Array<RoomDto>
    upcoming: RoomDto
}

const initialState: RoomListState = {
    rooms: [],
    upcoming: null
};

const testRooms: Array<RoomDto> = isDevelopment ? [{
    roomid: "4885",
    roomMode: RoomMode.Free,
    screenName: "Test Performer",
    publicData: {
        profilePic: "/images/test-performer.jpg"
    },
    pom: false
}] : [];

export function roomListReducer(state: RoomListState = initialState, action): RoomListState {
    const {type} = action;

    if (type === RoomListActionType.Updated) {
        const {rooms} = action;

        state = {
            rooms: [...testRooms, ...rooms],
            upcoming: rooms.find((room: RoomDto) => room.roomMode === RoomMode.Free) || null
        };
    }

    return state;
}
