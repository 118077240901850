import {createStyles, Paper, Tab, Tabs, WithStyles, withStyles} from "@material-ui/core";
import React, {PureComponent} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {Subject} from "rxjs";
import {sendSticker} from "../../action/chat.action";
import {Feature} from "../../domain/feature/Feature";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {featureAttribute, isSxRoom} from "../../middleware/helper";
import {Closable} from "../contract/Closable";
import {ImagePicker} from "../ImagePicker";
import {Header} from "../layout/Header";

interface Props extends WithStyles<typeof styles>, Closable {
    roomId: RoomId
    onStickerClick: (roomId: RoomId, stickerId) => void
    emojiSubject: Subject<string>
    girlStickerDisabled: boolean
    coupleStickerDisabled: boolean
}

interface State {
    tab: number
}

const styles = createStyles({
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    }
});

const emojis = ["\u{1f642}", "\u{1f641}", "\u{1f600}", "\u{1f644}", "\u{1f910}", "\u{1f912}", "\u{1f913}", "\u{1f914}", "\u{1f601}", "\u{1f602}", "\u{1f603}", "\u{1f604}", "\u{1f605}", "\u{1f606}", "\u{1f609}", "\u{1f60a}", "\u{1f60b}", "\u{1f60c}", "\u{1f60d}", "\u{1f60f}", "\u{1f612}", "\u{1f613}", "\u{1f614}", "\u{1f616}", "\u{1f618}", "\u{1f61a}", "\u{1f61c}", "\u{1f61d}", "\u{1f61e}", "\u{1f620}", "\u{1f621}", "\u{1f622}", "\u{1f623}", "\u{1f624}", "\u{1f625}", "\u{1f628}", "\u{1f629}", "\u{1f62a}", "\u{1f62b}", "\u{1f62d}", "\u{1f630}", "\u{1f631}", "\u{1f632}", "\u{1f635}", "\u{1f637}", "\u{1f638}", "\u{1f639}", "\u{1f63a}", "\u{1f63b}", "\u{1f63c}", "\u{1f63d}", "\u{1f63e}", "\u{1f63f}", "\u{1f640}", "\u{1f648}", "\u{1f649}", "\u{1f64a}", "\u{2764}", "\u{1f494}", "\u{1f607}", "\u{1f608}", "\u{1f60e}", "\u{1f610}", "\u{1f611}", "\u{1f615}", "\u{1f617}", "\u{1f619}", "\u{1f61b}", "\u{1f61f}", "\u{1f626}", "\u{1f627}", "\u{1f62c}", "\u{1f62e}", "\u{1f62f}", "\u{1f634}", "\u{1f636}"];
const stickers = {
    girls: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
    couples: [38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
};

const mapStateToProps = ({feature}) => ({
    girlStickerDisabled: feature.girlSticker === false,
    coupleStickerDisabled: feature.coupleSticker === false
});

const mapDispatchToProps = dispatch => ({
    onStickerClick: (roomId: RoomId, stickerId) => dispatch(sendSticker(roomId, stickerId))
});

export const EmojiPanel = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props, State> {

    state = {
        tab: 0
    };

    private readonly emojiIcon;
    private readonly girlIcon;
    private readonly coupleIcon;

    constructor(props) {
        super(props);

        this.handleClickEmoji = this.handleClickEmoji.bind(this);
        this.handleClickSticker = this.handleClickSticker.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);

        this.emojiIcon =
            <img src="//img.a.apn2.com/asset/emoji/chat_sendertool_pack/1f604.svg" alt="emojis" width={24} height={24}/>;
        this.girlIcon = <img src="//img.a.apn2.com/asset/stickers/01.svg" alt="girl stickers" width={24} height={24}/>;
        this.coupleIcon =
            <img src="//img.a.apn2.com/asset/stickers/38.svg" alt="couple stickers" width={24} height={24}/>;
    }

    private handleTabChange(event, tab) {
        this.setState({tab});
    }

    private handleClickSticker(stickerId) {
        const {roomId, onStickerClick, onClose} = this.props;

        onStickerClick(roomId, stickerId);
        onClose();
    }

    private handleClickEmoji(emoji) {
        this.props.emojiSubject.next(emoji);
    }

    private transformEmoji(emoji, index) {
        return {
            key: index,
            src: "//img.a.apn2.com/asset/emoji/chat_sendertool_pack/" + emoji.codePointAt(0).toString(16) + ".svg",
            alt: emoji
        };
    }

    private transformSticker(stickerId, index) {
        return {
            key: index,
            src: "//img.a.apn2.com/asset/stickers/" + String(stickerId).padStart(2, "0") + ".svg",
            alt: stickerId
        };
    }

    render() {
        const {tab} = this.state;
        const {classes, girlStickerDisabled = false, coupleStickerDisabled = false, onClose, roomId}: Props = this.props;

        return (
            <Paper classes={{root: classes.root}}>
                <Header onClose={onClose} title={intl.get("emoji.title")}/>

                {
                    tab === 0 &&
                    <ImagePicker images={emojis}
                                 onSelect={this.handleClickEmoji}
                                 transformer={this.transformEmoji}/>
                }

                {
                    tab === 1 && girlStickerDisabled === false &&
                    <ImagePicker images={stickers.girls}
                                 onSelect={this.handleClickSticker}
                                 transformer={this.transformSticker}/>
                }

                {
                    tab === 2 && coupleStickerDisabled === false &&
                    <ImagePicker images={stickers.couples}
                                 onSelect={this.handleClickSticker}
                                 transformer={this.transformSticker}/>
                }

                {
                    // TODO use feature state instead
                    isSxRoom({roomid: roomId} as RoomDto) &&
                    <Tabs variant="fullWidth" value={tab} onChange={this.handleTabChange}>
                        <Tab icon={this.emojiIcon}
                             buttonRef={featureAttribute(Feature.Emoji)}/>
                        <Tab disabled={girlStickerDisabled}
                             icon={this.girlIcon}
                             buttonRef={featureAttribute(Feature.GirlSticker)}/>
                        <Tab disabled={coupleStickerDisabled}
                             icon={this.coupleIcon}
                             buttonRef={featureAttribute(Feature.CoupleSticker)}/>
                    </Tabs>
                }
            </Paper>
        );
    }
}));
