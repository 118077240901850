import {CircularProgress, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import {Viewport} from "./layout/Viewport";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        justifyContent: "center"
    },
    text: {
        marginTop: "25px"
    }
});

interface ProgressScreenProps {
    text?: string
}

export const ProgressScreen = React.memo<ProgressScreenProps>(({text}) => {
    const classes = useStyles();

    return (
        <Viewport className={classes.root}>
            <CircularProgress/>

            {
                text && <Typography className={classes.text} children={text}/>
            }
        </Viewport>
    );
});
