import React, {PureComponent, ReactNode} from "react";
import "rmc-picker/assets/index.css";
import Picker from "rmc-picker";
import {Credit} from "../../domain/topup/Credit";
import intl from "react-intl-universal";
import {createStyles, withStyles, WithStyles} from "@material-ui/core";
import {Disableable} from "../contract/Disableable";

const styles = createStyles({
    root: {
        width: "200px",
        height: "102px",
        margin: "20px auto",
        flex: "unset",
        "& .rmc-picker-mask": {
            backgroundImage: "linear-gradient(180deg,hsla(0, 0%, 0%, 0.95),rgba(0, 0, 0, 0.6)),linear-gradient(0deg,rgba(0, 0, 0, 0.95),rgba(0, 0, 0, 0.6))"
        }
    },
    item: {
        color: "#fff",
        "& > span.original": {
            textDecoration: "line-through"
        },
        "& > span.with-extra": {
            color: "#FFC022"
        }
    },
    indicator: {
        borderWidth: "3px",
        borderColor: "#21ACE5"
    },
    indicatorHappyHour: {
        borderWidth: "3px",
        borderColor: "#FFC022"
    }
});

interface Props extends WithStyles<typeof styles>, Disableable {
    options: Array<Credit>
    selectedValue: Credit
    onSelect: (credit: Credit) => void
}

class CreditPicker extends PureComponent<Props> {
    constructor(props) {
        super(props);

        this.selectValue = this.selectValue.bind(this);
    }

    private selectValue(index: number) {
        this.props.onSelect(this.props.options[index]);
    }

    private renderItem(credit: Credit, index: number): ReactNode {
        const {classes} = this.props;

        if (credit.promotionType) {
            return <Picker.Item
                key={index}
                className={classes.item}
                value={index}><span className="original">{credit.amount}</span>&nbsp;<span className="with-extra">{intl.getHTML("topup.credit", {amount: credit.totalAmount})}</span></Picker.Item>;
        } else {
            return <Picker.Item
                key={index}
                className={classes.item}
                value={index}>{intl.getHTML("topup.credit", credit)}</Picker.Item>;
        }
    }

    render(): ReactNode {
        const {disabled, classes, options, selectedValue} = this.props;
        const isHappyHour = options.some(credit => Boolean(credit.extraCredit));

        return (
            <Picker
                disabled={disabled}
                indicatorClassName={isHappyHour ? classes.indicatorHappyHour : classes.indicator}
                className={classes.root}
                selectedValue={options.indexOf(selectedValue)}
                onValueChange={this.selectValue}>{options.map(this.renderItem, this)}</Picker>
        );
    }
}

const component = withStyles(styles)(CreditPicker);

export {
    component as CreditPicker
};
