import {LOCATION_CHANGE} from "connected-react-router";
import {ChatActionType} from "../action/chat.action";
import {IncentiveActionType} from "../action/incentive.action";
import {RoomDto} from "../domain/RoomDto";

export interface IncentiveState {
    open: boolean
    closable: boolean
    room: RoomDto
}

const initialState: IncentiveState = {
    open: false,
    closable: false,
    room: null
};

export function incentiveReducer(state: IncentiveState = initialState, action): IncentiveState {
    const {type, closable, room} = action;

    switch (type) {
        case IncentiveActionType.ShowDialog: {
            return {
                open: true,
                closable,
                room
            };
        }

        case ChatActionType.LeaveRoom:
        case ChatActionType.RemovedFromRoom:
        case LOCATION_CHANGE:
        case IncentiveActionType.CloseDialog: {
            return {
                ...state,
                open: false
            };
        }
    }

    return state;
}
