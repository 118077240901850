import {Button, TextField, Typography} from "@material-ui/core";
import React, {FormEvent, Fragment, useRef, useState} from "react";
import intl from "react-intl-universal";
import {useApplicationContext} from "../../application/ApplicationContext";
import {I18n} from "../../domain/I18n";
import {routeToRoomList} from "../../helper";
import {redirectToRoomList, redirectToSignIn, redirectToSignUp} from "../../redirect";
import {Body} from "../layout/Body";
import {Header} from "../layout/Header";
import {Section} from "../layout/Section";
import {Toolbar} from "../layout/Toolbar";
import {Viewport} from "../layout/Viewport";
import {LinkInline} from "../LinkInline";
import {ProgressButton} from "../ProgressButton";

const redirectToSignInThenRooms = () => redirectToSignIn(routeToRoomList());

export const ForgottenPasswordScreen = React.memo(() => {
    const [progress, setProgress] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);
    const inputRef = useRef(null);
    const {authService} = useApplicationContext();
    const startProgress = () => setProgress(true);
    const stopProgress = () => setProgress(false);
    const retry = () => setError(null);
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const value = inputRef?.current?.value;

        if (value) {
            startProgress();
            authService.sendPasswordReminder(value)
                .then(() => {
                    setEmail(value);
                    setError(false);
                }, setError)
                .then(stopProgress);
        }
    };

    return (
        <Viewport color={"secondary"}>
            <Header onClose={redirectToRoomList} title={intl.get(I18n.ForgottenPasswordTitle)}/>
            <Body>

                {
                    error === false &&
                    <Fragment>
                        <Section>
                            <Typography style={{color: "black"}}>{intl.get(I18n.ForgottenPasswordSucceededText, {email})}</Typography>
                        </Section>
                        <Section textAlign="right">
                            <Button onClick={redirectToSignInThenRooms}>{intl.get(I18n.ForgottenPasswordSucceededButton)}</Button>
                        </Section>
                    </Fragment>
                }

                {
                    error &&
                    <Fragment>
                        <Section>
                            <Typography color="error">{intl.get(I18n.ForgottenPasswordFailedText)}</Typography>
                        </Section>
                        <Section textAlign="right">
                            <Button onClick={retry}>{intl.get(I18n.ForgottenPasswordFailedButton)}</Button>
                        </Section>
                    </Fragment>
                }

                {
                    error === null &&
                    <Fragment>
                        <Section>
                            <Typography color={"secondary"}>{intl.get(I18n.ForgottenPasswordText)}</Typography>
                        </Section>
                        <form onSubmit={handleSubmit}>
                            <Section>
                                <TextField disabled={progress}
                                           fullWidth
                                           required
                                           name="email"
                                           autoComplete="on"
                                           type="email"
                                           label={intl.get(I18n.ForgottenPasswordFieldLabel)}
                                           inputRef={inputRef}/>
                            </Section>
                            <Section textAlign="right">
                                <ProgressButton progress={progress} label={intl.get(I18n.ForgottenPasswordButtonLabel)} type="submit"/>
                            </Section>
                        </form>
                    </Fragment>
                }
            </Body>
            <Toolbar>
                <Typography style={{
                    flex: 1,
                    textAlign: "center",
                    color: "#fff",
                    margin: "8px 0"
                }} component="div" variant="body2"><LinkInline onClick={redirectToSignInThenRooms} title={intl.get(I18n.ForgottenPasswordBackToSignIn)}/><br/>
                    <span>{intl.get(I18n.SignInNotSignedUp)}</span>&nbsp;
                    <LinkInline onClick={redirectToSignUp} title={intl.get(I18n.SignInSignUpLink)}/></Typography>
            </Toolbar>
        </Viewport>
    );
});
