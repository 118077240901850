import {Publisher} from "../domain/c2c/Publisher";
import {RoomId} from "../domain/RoomId";
import {noop} from "../helper";
import {SxRoomService} from "../service/SxRoomService";
import {WebApiService} from "../service/webapi.service";
import {RoomServiceSignalingChannel} from "./RoomServiceSignalingChannel";
import {SnapshotUpstream} from "./SnapshotUpstream";
import {VideoCapturer} from "./VideoCapturer";
import {WebRTCConnection} from "./WebRTCConnection";

interface AuthData {
    username: string
    password: string
}

export class SxPublisher implements Publisher {
    private connection: WebRTCConnection;
    private snapshot: SnapshotUpstream;

    constructor(
        private webApi: WebApiService,
        private roomService: SxRoomService,
        private roomId: RoomId
    ) {
    }

    public publish(mediaStream: MediaStream, video: HTMLVideoElement): Promise<void> {
        return this.getServer()
            .then(server => new WebRTCConnection(server, new RoomServiceSignalingChannel(this.roomService, this.roomId), mediaStream))
            .then(connection => {
                connection.publish();

                this.connection = connection;
            })
            .then(() => {
                this.snapshot = new SnapshotUpstream(this.roomService, new VideoCapturer(video));
                this.snapshot.start();
            })
            .then(() => this.roomService.startCamToCam());
    }

    public unpublish(): Promise<void> {
        return this.roomService.stopCamToCam()
            .catch(noop)
            .finally(() => {
                this.connection?.unpublish();
                this.connection = null;

                this.snapshot?.stop();
                this.snapshot = null;
            });
    }

    public destroy() {
        this.connection?.destroy();
    }

    private getServer(): Promise<RTCIceServer> {
        return this.webApi.get<AuthData>("turnserver/getAuthData", {unType: "extended"})
            .then(data => ({
                urls: "turn:cam2cam.apn2.com:3478",
                username: data.username,
                credential: data.password
            }));
    }
}
