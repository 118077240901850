import {makeStyles} from "@material-ui/styles";
import React, {ReactElement} from "react";

const useStyles = makeStyles({
    "@keyframes first": {
        "0%": {
            opacity: 1,
        },
        "20%": {
            opacity: 1,
        },
        "30%": {
            opacity: 0,
        },
        "70%": {
            opacity: 0,
        },
        "80%": {
            opacity: 1,
        },
        "100%": {
            opacity: 1,
        },
    },
    "@keyframes second": {
        "0%": {
            opacity: 0,
        },
        "20%": {
            opacity: 0,
        },
        "30%": {
            opacity: 1,
        },
        "70%": {
            opacity: 1,
        },
        "80%": {
            opacity: 0,
        },
        "100%": {
            opacity: 0,
        },
    },
    firstFade: {
        "animation-name": "$first",
        "animation-fill-mode": "both",
        "animation-iteration-count": "infinite",
        "animation-duration": "20s",
        "animation-direction": "alternate",
    },
    secondFade: {
        "animation-name": "$second",
        "animation-fill-mode": "both",
        "animation-iteration-count": "infinite",
        "animation-duration": "20s",
        "animation-direction": "alternate",
    },
});

interface Props {
    firstElement: ReactElement
    secondElement: ReactElement
}

export const AlternateTwoElements = React.memo<Props>(({firstElement, secondElement}) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.firstFade}>
                {firstElement}
            </div>
            <div className={classes.secondFade}>
                {secondElement}
            </div>
        </>
    );
});
