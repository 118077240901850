import {CameraActionType} from "../../action/camera.action";
import {ChatActionType} from "../../action/chat.action";
import {UpdateActionType} from "../../action/update.action";
import {Feature} from "./Feature";
import {Requirement} from "./Requirement";

export const Matrix = {
    [Feature.SignUp]: Requirement.Enabled,
    [Feature.Tip]: Requirement.UserIsMember | Requirement.InSxRoom,
    [Feature.Emoji]: Requirement.UserIsMember | Requirement.InRoomAsNormal,
    [Feature.TopUp]: Requirement.UserIsMember,
    [Feature.Chat]: Requirement.UserIsMember | Requirement.InRoomAsNormal,

    [Feature.ZzzShow]: Requirement.HasSmartToy | Requirement.InSxRoom,
    [Feature.Zzz]: Requirement.UserIsMember | Requirement.InRoomAsNormal | Requirement.InSxRoom,

    [Feature.GiftShow]: Requirement.InSxRoom,
    [Feature.Gift]: Requirement.UserIsMember | Requirement.InRoomAsNormal | Requirement.InSxRoom,

    [Feature.StickerShow]: Requirement.InSxRoom,
    [Feature.GirlSticker]: Requirement.UserIsMember | Requirement.InRoomAsNormal | Requirement.RoomModeIsGroupOrPrivate,
    [Feature.CoupleSticker]: Requirement.UserIsMember | Requirement.InRoomAsNormal | Requirement.RoomModeIsGroupOrPrivate | Requirement.RoomCategoryIsCouple,
    [Feature.SmartToy]: Requirement.HasSmartToy,

    [Feature.Camera]: Requirement.UserIsMember | Requirement.RoomModeIsGroupOrPrivate,

    [Feature.MicrophoneShow]: Requirement.InSxRoom,
    [Feature.Microphone]: Requirement.RoomModeIsPrivate,

    [Feature.Knockout]: Requirement.UserIsMember | Requirement.InRoomAsNormal | Requirement.HasSmartToy | Requirement.InSxRoom,
    [Feature.Update]: Requirement.RoomModeIsNotGroupOrPrivate
};

export const ActionToFeature = {
    [CameraActionType.TurnOn]: [Feature.Camera],
    [CameraActionType.Unmute]: [Feature.Microphone],
    [ChatActionType.CheckKnockout]: [Feature.Knockout, true],
    [ChatActionType.OfferKnockout]: [Feature.Knockout, true],
    [UpdateActionType.RequestReload]: [Feature.Update, true],
};
