import {Observable, Subject} from "rxjs";
import {Chat, SourceSet} from "vchat-core";
import {Publisher} from "../../domain/c2c/Publisher";
import {ChatMessageEvent} from "../../domain/ChatMessageEvent";
import {JoinRoomFailed, JoinRoomSucceeded, RequestChatModeFailed} from "../../domain/events";
import {JoinRoomFailedReason} from "../../domain/JoinRoomFailedReason";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {RoomMode} from "../../domain/RoomMode";
import {noop} from "../../helper";
import {Logger} from "../../logger/Logger";
import {VxPublisher} from "../../publisher/visitx/VxPublisher";
import {AuthenticationService} from "../AuthenticationService";
import {VxRoomListService} from "../VxRoomListService";
import {WebApiService} from "../webapi.service";
import {CanStartChatResponseDto} from "./CanStartChatResponseDto";
import {StartChatResponseDto} from "./StartChatResponseDto";
import {VxChatHandler} from "./VxChatHandler";

export class VxRoomService {

    private subject: Subject<any> = new Subject<any>();
    private chat: Chat;
    private handler: VxChatHandler;

    constructor(
        private readonly web: WebApiService,
        private readonly logger: Logger,
        private readonly authService: AuthenticationService,
        private readonly vxRoomListService: VxRoomListService
    ) {
    }

    public get events(): Observable<any> {
        return this.subject.asObservable();
    }

    public getPublisher(): Publisher {
        return new VxPublisher(this.chat);
    }

    private canStartChat(roomId: RoomId): Promise<RoomDto> {
        return Promise.all([
                this.vxRoomListService.getRoomList()
                    .then(rooms => rooms.find(room => room.roomid === roomId))
                    .then(room => room ? room : Promise.reject()),

                this.web.get("member/visitx/canStartChat", {
                    roomID: roomId
                }).then((response: CanStartChatResponseDto) => response.online && response.success ? Promise.resolve() : Promise.reject()) as Promise<void>
            ])
            .then(([room]) => room);
    }

    private startChat(room: RoomDto) {
        const {roomid: roomId} = room;

        return this.web.post("member/visitx/startChat", {
                roomID: roomId,
                lang: "en"
            })
            .then((response: StartChatResponseDto) => {
                if (response?.success) {
                    return response;
                }

                switch (response?.reason) {
                    case "ooc":
                        return Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.NotEnoughCredits, room));

                    case "offline":
                        return Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.RoomNotFound, room));

                    default:
                        return Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.Other, room));
                }
            })
            .then((response: StartChatResponseDto) => {
                const handler = this.handler = new VxChatHandler(
                    this.logger,
                    room,
                    this.subject
                );

                const chat = this.chat = new Chat({
                    clientId: response.clientID,
                    host: response.server,
                    version: "sxc"
                }, handler);

                return chat.init()
                    .then(() => Promise.all([
                        chat.startText(),
                        chat.startStream()
                    ]))
                    .then(([ignored, stream]) => stream as SourceSet)
                    .then(source => new JoinRoomSucceeded({
                        ...room,
                        modeSpecific: {
                            main: {
                                visitx: source
                            }
                        }
                    } as RoomDto))
                    .catch(() => this.close().then(() => Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.Other, room))));
            });
    }

    private initChat(room: RoomDto, roomMode: RoomMode): Promise<JoinRoomSucceeded> {
        switch (roomMode) {
            case RoomMode.Group: {

                // TODO this checking may be prior to join
                // TODO only members allowed to join with a minimum credit for a minute
                if (this.authService.getUser()?.isMember) {
                    return this.startChat(room);
                }

                return Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.GuestNotAllowed, room));
            }

            case RoomMode.Free:
            default:
                return Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.DifferentMode, room));
        }
    }

    public join(roomId: RoomId, roomMode: RoomMode): Promise<JoinRoomSucceeded> {
        return this.canStartChat(roomId).then(
            room => this.initChat(room, roomMode),
            () => Promise.reject(new JoinRoomFailed(JoinRoomFailedReason.RoomNotFound))
        );
    }

    public requestChatMode(roomId: RoomId, mode: RoomMode): Promise<JoinRoomSucceeded> {

        // TODO check the event if correct (JoinRoomFailed)
        return Promise.reject(new RequestChatModeFailed(JoinRoomFailedReason.RoomNotFound));
    }

    public sendMessage(roomId: RoomId, message: string) {
        this.chat.sendMessage(message).then(() => {
            this.subject.next(new ChatMessageEvent({

                // TODO fill fields
                from: this.authService.getUser()?.auth?.userName,
                type: "member",
                message: {
                    text: message,
                    type: "chat"
                }
            }));
        }, noop);
    }

    private close(): Promise<void> {
        this.handler?.close();
        this.handler = null;

        const chat = this.chat;

        if (chat) {
            this.chat = null;

            return chat.close().catch(noop);
        }

        return Promise.resolve();
    }

    public leave(roomId: RoomId): Promise<void> {
        return this.close();
    }
}
