import {RoomId} from "./RoomId";
import {RoomMode} from "./RoomMode";

export class Invitation {
    constructor(
        public readonly roomId: RoomId,
        public readonly roomMode: RoomMode,
        public readonly message: string
    ) {
    }
}
