import {Box, Checkbox, FormControlLabel, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useFormik} from "formik";
import React, {Fragment, useState} from "react";
import intl from "react-intl-universal";
import {useApplicationContext} from "../../application/ApplicationContext";
import {I18n} from "../../domain/I18n";
import {Section} from "../layout/Section";
import {PasswordField} from "../PasswordField";
import {ProgressButton} from "../ProgressButton";

interface Props {
    classes?: any
}

interface FormValues {
    username: string
    email: string
    password: string
    passwordAgain: string
    accepted: boolean
}

const createError = (field, key) => ({
    field,
    key
});

const getErrorKey = error => {
    switch (error) {
        case "join_err_missingdata":
            return I18n.SignUpErrorMissingData;
        case "join_err_usernametaken":
            return createError("username", I18n.SignUpErrorUsernameTaken);
        case "join_err_usernameinvalid":
        case "join_err_userid_character_not_allowed":
            return createError("username", I18n.SignUpErrorUsernameInvalid);
        case "join_err_emailinvalid":
            return createError("email", I18n.SignUpErrorEmailInvalid);
        case "join_err_emailused":
            return createError("email", I18n.SignUpErrorEmailUsed);
        case "join_err_passwdmismatch":
            return createError("passwordAgain", I18n.SignUpErrorPasswordMismatch);
        case "join_err_passwdinvalid":
            return createError("password", I18n.SignUpErrorPasswordInvalid);
        case "join_err_iptempban":
            return I18n.SignUpErrorTemporaryBan;
        case "join_err_disabled":
            return I18n.SignUpErrorDisabled;
        case "join_err_technical":
        default:
            return I18n.SignUpErrorTechnical;
    }
};

const initialValues: FormValues = {
    username: "",
    email: "",
    password: "",
    passwordAgain: "",
    accepted: false
};

const useStyles = makeStyles({
    bbar: {
        textAlign: "right"
    },
    submitButton: {}
});

export const SignUpForm = React.memo<Props>(props => {
    const classes = useStyles(props);
    const {authService} = useApplicationContext();
    const [success, setSuccess] = useState<boolean>(null);
    const [error, setError] = useState<string>(null);
    const formik = useFormik<FormValues>({
        initialValues,
        onSubmit: values => {
            const {username, email, password, passwordAgain} = values;

            setSuccess(null);
            setError(null);

            authService.signUp(username, email, password, passwordAgain)
                .finally(() => formik.setSubmitting(false))
                .then(() => setSuccess(true))
                .catch(error => {
                    setSuccess(false);

                    const key = getErrorKey(error);

                    if (typeof key === "string") {
                        setError(key);
                    } else {
                        formik.setFieldError(key.field, intl.get(key.key));
                    }
                });
        }
    });

    return (
        <Fragment>
            {
                success &&
                <Section>
                    <Typography color="inherit" variant="h5">{intl.get(I18n.SignUpSuccessTitle)}</Typography>
                    <Typography color="inherit">{intl.get(I18n.SignUpSuccessText)}</Typography>
                </Section>
            }

            {
                error &&
                <Section>
                    <Box textAlign="center">
                        <Typography color="error">{intl.get(error)}</Typography>
                    </Box>
                </Section>
            }

            {
                (success === false || success === null) &&
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Section>
                        <TextField fullWidth
                                   required
                                   autoComplete="new-password"
                                   name="username"
                                   type="text"
                                   error={Boolean(formik.errors.username)}
                                   helperText={formik.errors.username}
                                   label={intl.get(I18n.SignUpUsernameLabel)}
                                   onChange={formik.handleChange}
                                   disabled={formik.isSubmitting}
                                   value={formik.values.username}/>
                        <TextField fullWidth
                                   required
                                   autoComplete="new-password"
                                   name="email"
                                   type="email"
                                   error={Boolean(formik.errors.email)}
                                   helperText={formik.errors.email}
                                   label={intl.get(I18n.SignUpEmailLabel)}
                                   onChange={formik.handleChange}
                                   disabled={formik.isSubmitting}
                                   value={formik.values.email}/>
                        <PasswordField fullWidth
                                       required
                                       autoComplete="new-password"
                                       name="password"
                                       error={Boolean(formik.errors.password)}
                                       helperText={formik.errors.password}
                                       label={intl.get(I18n.SignUpPasswordLabel)}
                                       onChange={formik.handleChange}
                                       disabled={formik.isSubmitting}
                                       value={formik.values.password}/>
                        <PasswordField fullWidth
                                       required
                                       name="passwordAgain"
                                       autoComplete="new-password"
                                       error={Boolean(formik.errors.passwordAgain)}
                                       helperText={formik.errors.passwordAgain}
                                       label={intl.get(I18n.SignUpPasswordAgainLabel)}
                                       onChange={formik.handleChange}
                                       disabled={formik.isSubmitting}
                                       value={formik.values.passwordAgain}/>
                        <FormControlLabel control={
                            <Checkbox name="accepted"
                                      disabled={formik.isSubmitting}
                                      checked={formik.values.accepted}
                                      onChange={formik.handleChange}
                                      color="primary"/>}
                                          labelPlacement="end"
                                          label={
                                              <span dangerouslySetInnerHTML={{__html: intl.get(I18n.SignUpTerms)}}/>}/>
                    </Section>
                    <Section className={classes.bbar}>
                        <ProgressButton className={classes.submitButton}
                                        disabled={formik.values.accepted === false}
                                        progress={formik.isSubmitting}
                                        label={intl.get(I18n.SignUpSubmitButton)}
                                        type="submit"/>
                    </Section>
                </form>
            }
        </Fragment>
    );
});
