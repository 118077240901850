import {Observable} from "rxjs";
import {StreamData} from "./StreamData";

export enum PlayerState {
    Stopped,
    Loading,
    Playing,
    Error
}

export const PLAYER_TIMEOUT = 30000;

export interface Player {

    muteState(): Observable<boolean>

    playState(): Observable<PlayerState>

    destroy(): void

    isPlaying(stream: StreamData): boolean

    play(stream: StreamData, muted: boolean): Promise<void>

    stop(): void

    mute(): void

    unmute(): void

    getElement(): HTMLElement
}
