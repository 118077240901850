import {Subscription} from "rxjs";
import {SignalingChannel} from "../domain/c2c/SignalingChannel";
import {Cam2CamSignalingEvent, Cam2CamSignalingEventType} from "../domain/Cam2CamSignalingEvent";

export class WebRTCConnection {
    private peerConnection: RTCPeerConnection;
    private subscription: Subscription;

    constructor(
        private server: RTCIceServer,
        private signaling: SignalingChannel,
        private mediaStream: MediaStream
    ) {
    }

    public destroy() {
        this.mediaStream = null;
        this.signaling = null;
        this.server = null;
        this.unpublish();
    }

    public publish() {
        this.subscription = this.signaling.events.subscribe(event => this.handleSignalingEvent(event));
        this.initPeerConnection();
    }

    public unpublish() {
        this.subscription?.unsubscribe();
        this.subscription = null;

        this.peerConnection?.getSenders().map(this.peerConnection?.removeTrack, this.peerConnection);
        this.peerConnection?.close();
        this.peerConnection = null;
    }

    private handleSignalingEvent(event: Cam2CamSignalingEvent) {
        const {type, candidate, description} = event;
        const {peerConnection} = this;

        switch (type) {
            case Cam2CamSignalingEventType.NewIceCandidate: {
                peerConnection.addIceCandidate(candidate)
                    .catch((error: DOMException) => {
                        switch (error.name) {
                            case "TypeError":
                            case "InvalidStateError":
                            case "OperationError":
                            default:
                                console.error("peerConnection.addIceCandidate", error);
                            // TODO cam2cam: handle peerConnection.addIceCandidate error
                        }
                    });

                break;
            }

            case Cam2CamSignalingEventType.PerformerReady: {
                peerConnection.createOffer()
                    .then(description => Promise.all([
                        peerConnection.setLocalDescription(description),
                        this.signaling.signalOffer(description)
                    ]))
                    .catch((reason: string) => {
                        switch (reason) {
                            case "InvalidStateError": // RTCPeerConnection is closed.
                            case "NotReadableError": // No cert
                            case "OperationError": // Resource check failed
                            default:
                                console.error("peerConnection.createOffer", reason);
                            // TODO cam2cam: handle peerConnection.createOffer error
                        }
                    });

                break;
            }

            case Cam2CamSignalingEventType.Answer: {
                peerConnection.setRemoteDescription(description)
                    .catch(error => {
                        console.error("peerConnection.setRemoteDescription", error);
                        // TODO cam2cam: handle peerConnection.setRemoteDescription error
                    });
            }
        }
    }

    private initPeerConnection() {
        const peerConnection = new RTCPeerConnection({
            iceServers: [this.server],
            iceTransportPolicy: "relay"
        });

        peerConnection.onnegotiationneeded = () => {
            console.debug("initPeerConnection.onnegotiationneeded");
        };

        peerConnection.onicecandidateerror = (event: RTCPeerConnectionIceErrorEvent) => {
            console.error("initPeerConnection.onicecandidateerror", event);
        };

        peerConnection.onicegatheringstatechange = (event: Event) => {
            console.debug("initPeerConnection.onicegatheringstatechange", event);
        };

        peerConnection.onicecandidate = ({candidate}: RTCPeerConnectionIceEvent) => {
            this.signaling.signalCandidate(candidate);
        };

        this.mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, this.mediaStream));
        this.peerConnection = peerConnection;
    }
}
