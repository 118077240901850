import {TopUpProvider} from "../../domain/topup/TopUpProvider";
import {Credit} from "../../domain/topup/Credit";
import {config} from "../../config/securionpay";
import {Http} from "../http";
import {PromoType} from "../../domain/PromoType";

function unexpectedError() {
    return {
        success: false,
        message: "Technical Error"
    };
}

export class SecurionQuickbuyProvider implements TopUpProvider {
    constructor(private http: Http, private credits) {
    }

    public charge(credit: Credit): Promise<void> {
        const body = [
            `amount=${credit.amount}`
        ];

        if (credit.promotionType === PromoType.HappyHour) {
            body.push(
                "promoType=extraCredit",
                "promoName=echh",
                `promoAmount=${credit.extraCredit}`
            );
        }
        if (credit.promotionType === PromoType.PushPromo) {
            body.push(
                `ecp=${credit.extraCredit}`,
            );
        }

        const requestInit: RequestInit = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            method: "POST",
            body: body.join("&")
        };

        return this.http.fetch(config.quickbuy, requestInit)
            .catch(unexpectedError)
            .then(response => {
                if (response.success) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response);
                }
            });
    }

    getCreditOptions(): Array<Credit> {
        return this.credits;
    }
}
