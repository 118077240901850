import {makeStyles} from "@material-ui/styles";
import React, {ReactElement, useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActivityInitiator} from "../../domain/ActivityInitiator";
import {ActivityState} from "../../domain/ActivityState";
import {Orientation} from "../../domain/Orientation";
import {clsx} from "../../helper";
import {RootState} from "../../reducer";
import {Screen} from "../../reducer/screen.reducer";
import {RoomHolder} from "../contract/RoomHolder";
import {FavoriteButton} from "../favorite/FavoriteButton";
import {CameraButton} from "./camera/CameraButton";
import {ExitShowButton} from "./ExitShowButton";
import {FullscreenButton} from "./FullscreenButton";
import {MuteButton} from "./MuteButton";
import {RoomModeButtons} from "./RoomModeButtons";
import {TopUpButton} from "./TopUpButton";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        overflow: "hidden",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },
    btnMute: {
        top: "50px",
        right: "5px",
        position: "absolute"
    },
    btnTopUp: {
        top: "50px",
        left: "5px",
        position: "absolute"
    },
    btnCam2Cam: {
        top: "95px",
        left: "5px",
        position: "absolute"
    },
    lblPerformer: {
        width: "unset",
        top: "5px",
        left: "5px",
        position: "absolute"
    },
    lblPerformerLabel: {
        margin: "auto 8px",
        textTransform: "none"
    },
    btnFullscreen: {
        bottom: "5px",
        left: "5px",
        position: "absolute"
    }
});

interface Props extends RoomHolder {
    children?: ReactElement
    screen: Screen
}

export const ControlOverlay = React.memo<Props>(({children, room, screen}) => {
    const dispatch = useDispatch();
    const onActivity = useCallback(() => dispatch({
        type: "idle/activity",
        initiator: ActivityInitiator.USER
    }), [dispatch]);
    const idle = useSelector<RootState, ActivityState>(root => root.idle);
    const classes = useStyles();
    const className = clsx(classes.root, idle.idle ? "idle" : "active", idle.lastActivityBy === ActivityInitiator.USER ? "user" : "system");
    const screenNameClasses = useMemo<any>(() => ({
        root: classes.lblPerformer,
        label: classes.lblPerformerLabel
    }), [classes]);

    return (
        <div className={className} onClick={onActivity}>
            {children}

            <ExitShowButton roomId={room.roomid} className="right-on-idle delayed"/>

            <MuteButton className={clsx("right-on-idle", classes.btnMute)}/>

            {
                screen.orientation === Orientation.Landscape &&
                <RoomModeButtons room={room} labelClass={screenNameClasses.label}/>
            }

            <FavoriteButton className={clsx("left-on-idle", classes.lblPerformer)}/>

            <TopUpButton className={clsx("left-on-idle", classes.btnTopUp)}/>

            <CameraButton className={clsx("left-on-idle", classes.btnCam2Cam)}/>

            <FullscreenButton className={clsx("left-on-idle", classes.btnFullscreen)}/>
        </div>
    );
});
