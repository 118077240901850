import {RoomMode} from "../domain/RoomMode";
import {baseUrl} from "./application";

export const config = {
    roomList: baseUrl.concat("/ajax/api/roomList"),
    chatServer: "wss://chatserver.apn2.com",
    tipAfterRoomModes: [RoomMode.Group, RoomMode.Private, RoomMode.Exclusive] as Array<RoomMode>,
    minimumCreditBeforeTip: 200,
    minimumMsInRoomBeforeTip: 2 * 60 * 1000, // 2 minutes
    beforeMsRunningOutOfCredit: 3 * 60 * 1000 // 3 minute
};
