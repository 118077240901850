import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Player, PlayerState} from "../domain/Player";
import {StreamData} from "../domain/StreamData";

export class NullPlayer implements Player {
    private element: HTMLDivElement = document.createElement("div");
    private playerStateSubject: Subject<PlayerState> = new BehaviorSubject(PlayerState.Error);
    private muteStateSubject: Subject<boolean> = new BehaviorSubject(true);

    public destroy(): void {
        this.element.remove();
        this.element = null;
    }

    public play(): Promise<void> {
        return Promise.resolve();
    }

    public stop() {
    }

    public mute() {
        this.muteStateSubject.next(true);
    }

    public unmute() {
        this.muteStateSubject.next(false);
    }

    public playState(): Observable<PlayerState> {
        return this.playerStateSubject;
    }

    public muteState(): Observable<boolean> {
        return this.muteStateSubject;
    }

    public isPlaying(stream: StreamData): boolean {
        return false;
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}
