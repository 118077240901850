import React from "react";
import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {styles} from "./styles";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";
import {redirectToRoomList, redirectToTopUp} from "../../../redirect";
import {CountdownButton} from "../../CountdownButton";
import {LinkButton} from "../../LinkButton";
import {ExitToRoomListButton} from "../ExitToRoomListButton";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const NotEnoughCreditLobby = withStyles(styles)(React.memo<Props>(({classes, lobby}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic} icon="/images/lowbattery.svg"/>
            <Typography className={classes.message}>{intl.get("lobby.not-enough-credit", {screenName: lobby.screenName})}</Typography>
            <LinkButton title={intl.get("common.button.back-to-rooms")} onClick={redirectToRoomList}/>
        </Box>
        <CountdownButton onClick={redirectToTopUp} label={intl.get("lobby.button.topup")}/>
        <ExitToRoomListButton/>
    </Viewport>
)));
