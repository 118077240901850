import {BehaviorSubject, noop, Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";
import {AuthenticationService} from "./AuthenticationService";
import {WebApiService} from "./webapi.service";

interface Response {
    data: Array<RoomDto>
}

export class VxRoomListService {
    private subject: Subject<Array<RoomDto>> = new BehaviorSubject([]);
    private timer;

    public get rooms(): Observable<Array<RoomDto>> {
        return this.subject.asObservable();
    }

    constructor(
        private readonly webApiService: WebApiService,
        private readonly authenticationService: AuthenticationService
    ) {

        // FIXME possible race condition with WebApi,
        //  may be tie to that service instead
        this.authenticationService.user.pipe(
            filter(user => Boolean(user))
        ).subscribe(() => this.startFetch());
    }

    public getRoomList(): Promise<Array<RoomDto>> {
        return this.webApiService.get("member/visitx/getRoomList")
            .then((response: Response) => response.data)
            .then(rooms => rooms.filter(room => room.roomMode === RoomMode.Group))
            .then(rooms => {
                this.subject.next(rooms);

                return rooms;
            });
    }

    private startFetch() {
        this.stopFetch();
        this.getRoomList().catch(noop);
        this.timer = setInterval(() => this.getRoomList().catch(noop), 2 * 60 * 1000);
    }

    private stopFetch() {
        clearInterval(this.timer);
    }

    public update() {
        this.startFetch();
    }
}
