import {ChatActionType} from "../action/chat.action";
import {Invitation} from "../domain/Invitation";

export interface InvitationState {
    active: boolean
    invitation: Invitation
}

const initialState: InvitationState = {
    active: false,
    invitation: null
};

export function invitationReducer(state: InvitationState = initialState, action): InvitationState {
    const {type, event} = action;

    switch (type) {
        case ChatActionType.InvitationReceived: {
            return {
                active: true,
                invitation: event as Invitation
            };
        }

        case ChatActionType.RoomPaused:
        case ChatActionType.RemovedFromRoom:
        case ChatActionType.JoinRoom:
        case ChatActionType.RequestChatMode:
        case ChatActionType.RejectInvitation: {
            return {
                ...state,
                active: false
            };
        }

        default:
    }

    return state;
}
