import {Box, createStyles, Dialog, IconButton, withStyles, WithStyles} from "@material-ui/core";
import React, {PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {Subject, Subscription} from "rxjs";
import {filter, first} from "rxjs/operators";
import {giftPanelClosed, joinRoom, leaveRoom} from "../../action/chat.action";
import ApplicationContext from "../../application/ApplicationContext";
import {ChatMessageEvent} from "../../domain/ChatMessageEvent";
import {Feature} from "../../domain/feature/Feature";
import {Orientation} from "../../domain/Orientation";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {RoomMode} from "../../domain/RoomMode";
import {routeToRoom} from "../../helper";
import {isSxRoom} from "../../middleware/helper";
import {Screen} from "../../reducer/screen.reducer";
import {SignInButton} from "../auth/SignInButton";
import {FadeTransition} from "../FadeTransition";
import {GiftPanel} from "../GiftPanel";
import {ProgressScreen} from "../ProgressScreen";
import {CameraStream} from "./camera/CameraStream";
import {ChatInput} from "./ChatInput";
import {ChatMessages} from "./ChatMessages";
import {ControlOverlay} from "./ControlOverlay";
import {EmojiPanel} from "./EmojiPanel";
import {GiftOverlay} from "./GiftOverlay";
import {InvitationDialog} from "./InvitationDialog";
import {Lobby} from "./lobby/Lobby";
import {ModeBar} from "./ModeBar";
import {scenes} from "./scene";
import {ChatScene} from "./scene/ChatScene";
import {GiftScene} from "./scene/GiftScene";
import {Scene} from "./scene/Scene";
import {Stream} from "./Stream";
import {ZzzFab} from "./ZzzFab";
import {ZzzPanel} from "./ZzzPanel";

const styles = createStyles({
    root: {
        height: "100%",
        backgroundColor: "black",
        ".portrait &": {
            display: "flex",
            flexDirection: "column",
            overflow: "scroll"
        }
    },
    portraitChat: {
        flex: 1
    },
    landscapeChat: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: "linear-gradient(to right, rgba(0,0,0,0.5) 25%, rgba(0,0,0,0) 50%)"
    },
    btnGift: {
        backgroundColor: "#1A94C5",
        color: "white",
        width: 44,
        flex: "unset !important"
    },
    btnZzz: {
        width: 43,
        height: 43,
        color: "#fff",
        backgroundColor: "#21ACE5",
        border: "2px solid #fff",
        position: "absolute",
        right: 20,
        bottom: 29,
        ".landscape &": {
            display: "none"
        }
    },
    modeBar: {
        display: "flex",
        height: "44px",
        minHeight: "44px",
        backgroundColor: "#fff",
        "& > a": {
            display: "flex",
            flex: 1,
            marginRight: "1px",
            textDecoration: "none"
        },
        "& > a > button": {
            flex: 1
        },
        "& > button": {
            flex: 1,
            marginRight: "1px",
            borderRadius: 0
        },
        "& > button:last-child": {
            marginRight: 0
        },
        ".landscape &": {
            display: "none"
        }
    },
    chatInput: {
        ".landscape &": {
            display: "none"
        }
    },
    emojiPanel: {
        flex: 1,
        ".landscape &": {
            display: "none"
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    hasSmartToy: boolean,
    changeInProgress: boolean
    screen: Screen
    lobby: any
    room: any
    messages: Array<ChatMessageEvent>
    match: any
    join: (roomId: RoomId, roomMode: RoomMode) => void
    leave: (roomId: RoomId) => void
    giftPanelClosed: () => void
    showGiftPanel: boolean,
    giftPanelSelectedGiftId?: string
}

interface State {
    scene: Scene
}

const mapStateToProps = ({chat, screen, feature}) => ({
    hasSmartToy: feature.hasSmartToy,
    changeInProgress: chat.roomModeChangeInProgress,
    screen: screen,
    messages: chat.messages,
    room: chat.room,
    lobby: chat.lobby,
    showGiftPanel: chat.showGiftPanel,
    giftPanelSelectedGiftId: chat.giftPanelSelectedGiftId
});

const mapDispatchToProps = dispatch => ({
    join: (roomId: RoomId, roomMode: RoomMode) => dispatch(joinRoom(roomId, roomMode)),
    leave: (roomId: RoomId) => dispatch(leaveRoom(roomId)),
    giftPanelClosed: () => dispatch(giftPanelClosed())
});

export const Room = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props, State> {
    static contextType = ApplicationContext;

    private roomId: RoomId = null;

    state = {
        scene: ChatScene
    };

    private subscription: Subscription;
    private emojiSubject: Subject<string>;

    constructor(props) {
        super(props);

        this.triggerGift = this.triggerGift.bind(this);
        this.triggerChat = this.triggerChat.bind(this);
        this.triggerEmoji = this.triggerEmoji.bind(this);
        this.triggerZzz = this.triggerZzz.bind(this);
        this.emojiSubject = new Subject<string>();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {showGiftPanel} = nextProps;
        if (showGiftPanel) {
            return {
                ...prevState,
                scene: GiftScene
            };
        }
        return prevState;
    }

    componentDidMount(): void {
        const {match}: Props = this.props;

        // TODO refactor this mechanism
        this.subscription = this.context.roomService.onReady
            .pipe(filter<boolean>(ready => ready === true), first())
            .subscribe(() => this.handleJoin(match.params.roomId));
    }

    private triggerGift() {
        this.trigger(Feature.Gift);
        this.resetGiftPanelStore();
    }

    private triggerChat() {
        this.trigger(Feature.Chat);
    }

    private triggerEmoji() {
        this.trigger(Feature.Emoji);
    }

    private triggerZzz() {
        this.trigger(Feature.Zzz);
    }

    private handleJoin(roomId: RoomId) {
        if (roomId === this.roomId) {
            return;
        }

        this.roomId = null;

        if (roomId) {
            this.roomId = roomId;
            this.props.join(roomId, RoomMode.Free);
        }
    }

    componentWillUnmount(): void {
        this.subscription.unsubscribe();
        this.props.leave(this.roomId);
        this.resetGiftPanelStore();
    }

    private resetGiftPanelStore() {
        const {gift} = this.state.scene;
        if (gift) {
            this.props.giftPanelClosed();
        }
    }

    private trigger(feature: Feature) {
        let scene = scenes[feature] || ChatScene;

        if (scene === this.state.scene) {
            scene = ChatScene;
        }

        this.setState({scene});
    }

    render(): ReactNode {
        const {room, lobby} = this.props;

        if (room) {
            return this.renderRoom(room);
        }

        if (lobby) {
            return this.renderLobby(lobby);
        }

        return <ProgressScreen/>;
    }

    private renderRoom(room: RoomDto): ReactNode {
        const {classes, messages, screen, changeInProgress, hasSmartToy}: Props = this.props;
        const {scene}: State = this.state;
        const height = screen.orientation === Orientation.Portrait ? "56.25vw" : screen.height;

        return (
            <div className={classes.root}>
                <Box width={screen.width} height={height}>
                    <Stream overlayA={<GiftOverlay/>}
                            overlayB={
                                screen.orientation === Orientation.Portrait &&
                                <ControlOverlay screen={screen} room={room}/>
                            }/>
                </Box>

                {
                    scene.modeBar &&
                    <ModeBar room={room} className={classes.modeBar}>

                        {
                            // TODO use feature state instead
                            isSxRoom(room) &&
                            <IconButton color="primary" className={classes.btnGift} onClick={this.triggerGift}>
                                <img src="/images/gift.svg" alt="gift"/>
                            </IconButton>
                        }

                    </ModeBar>
                }

                {
                    scene.input &&
                    <ChatInput
                        roomId={room.roomid}
                        classes={{root: classes.chatInput}}
                        onFocus={this.triggerChat}
                        observable={this.emojiSubject}>
                        <IconButton color="primary" onClick={this.triggerEmoji}>
                            <img src="//img.a.apn2.com/asset/emoji/chat_sendertool_pack/1f604.svg" alt="\u{1f604}"
                                 width={24} height={24}/>
                        </IconButton>
                    </ChatInput>
                }

                {
                    scene.messages && screen.orientation === Orientation.Portrait &&
                    <ChatMessages classes={{root: classes.portraitChat}} messages={messages}/>
                }

                {
                    scene.emoji &&
                    <EmojiPanel
                        roomId={room.roomid}
                        classes={{root: classes.emojiPanel}}
                        onClose={this.triggerEmoji}
                        emojiSubject={this.emojiSubject}/>
                }

                {
                    scene.btnZzz && hasSmartToy &&
                    <ZzzFab classes={{root: classes.btnZzz}} onClick={this.triggerZzz}/>
                }

                {
                    scene.zzz &&
                    <ZzzPanel roomId={room.roomid} onClose={this.triggerZzz}/>
                }

                {
                    scene.gift &&
                    <GiftPanel roomId={room.roomid} onClose={this.triggerGift}
                               selected={this.props.giftPanelSelectedGiftId}/>
                }

                {
                    screen.orientation === Orientation.Landscape &&
                    <ControlOverlay screen={screen} room={room}>
                        <ChatMessages classes={{root: classes.landscapeChat}} messages={messages}/>
                    </ControlOverlay>
                }

                {
                    scene.btnSignIn &&
                    <SignInButton next={routeToRoom(room.roomid)}/>
                }

                <CameraStream/>

                <Dialog open={changeInProgress} TransitionComponent={FadeTransition}>
                    <ProgressScreen text={intl.get("chat.mode-change-in-progress")}/>
                </Dialog>

                <InvitationDialog/>
            </div>
        );
    }

    private renderLobby(lobby): ReactNode {
        return <Lobby lobby={lobby} join={this.props.join}/>;
    }
}));
