import * as Sentry from "@sentry/react";
import {Provider} from "vchat-core";
import {DataURI} from "../../domain/DataURI";
import {VideoCapturer} from "../VideoCapturer";

export class SnapshotProvider implements Provider {

    constructor(
        private capturer: VideoCapturer,
        private quality: number,
        private width: number,
        private height: number
    ) {
    }

    public getSnapshot(): Promise<DataURI> {
        const {capturer, width, height, quality} = this;

        return capturer.capture(quality, width, height);
    }

    public onError(error: Error): void {
        Sentry.captureException(error);
    }
}
