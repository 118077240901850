import {Logger} from "../Logger";

export class ConsoleLogger implements Logger {
    public debug(...args) {
        console.debug(...args);
    }

    public error(...args) {
        console.error(...args);
    }

    public info(...args) {
        console.info(...args);
    }

    public warn(...args) {
        console.warn(...args);
    }
}
