import {RoomId} from "./RoomId";

export enum Cam2CamSignalingEventType {
    NewIceCandidate = "newIceCandidate",
    PerformerReady = "performerReady",
    Answer = "answer"
}

export class Cam2CamSignalingEvent {
    constructor(
        public readonly roomId: RoomId,
        public readonly type: Cam2CamSignalingEventType,
        public readonly candidate: RTCIceCandidate,
        public readonly description: RTCSessionDescriptionInit
    ) {
    }
}
