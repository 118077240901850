import {Box, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import {FormikErrors, useFormik} from "formik";
import React, {ChangeEvent, useCallback, useEffect, useImperativeHandle, useRef} from "react";
import intl from "react-intl-universal";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {accountDeleted} from "../../../action/auth.action";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {useMessage} from "../../../hooks/message.hook";
import {useProgress} from "../../../hooks/progress.hook";
import {Captcha, CaptchaRef} from "../../Captcha";
import {Loadable} from "../../contract/Loadable";
import {SettingsModule} from "../../contract/SettingsModule";
import {Message} from "../../Message";
import {PasswordField} from "../../PasswordField";
import {ProgressButton} from "../../ProgressButton";

interface FormValues {
    password: string
    captcha: string
}

const initialValues: FormValues = {
    password: "",
    captcha: ""
};

const useStyles = makeStyles({
    root: {
        textAlign: "justify",
        padding: "8px 16px 16px"
    },
    description: {
        marginBottom: 16,
        textAlign: "justify"
    },
    warning: {
        backgroundColor: "red",
        padding: 8,
        marginBottom: 8
    },
    button: {
        marginBottom: 16
    }
});

interface Props extends Loadable {
}

export const DeleteAccount = React.forwardRef<SettingsModule, Props>(({onLoad}, ref) => {
    const classes = useStyles();
    const captchaRef = useRef<CaptchaRef>();
    const [inProgress, trackProgress] = useProgress();
    const {message, resetMessage, setErrorOnSave} = useMessage();
    const {settingsService} = useApplicationContext();
    const dispatch = useDispatch();

    const handleSubmit = trackProgress(values => {
        return settingsService.deleteAccount(values).then(values => {
            const {result} = values;

            if (result.success) {
                dispatch(accountDeleted(result.message));
            } else {
                Object.keys(result.fields).length > 0 && formik.setErrors(result.fields as FormikErrors<FormValues>);
                result.message && setErrorOnSave(result.message);
                captchaRef.current?.refresh();
            }
        }).catch(() => {
            setErrorOnSave();
            captchaRef.current?.refresh();
        });
    });

    const formik = useFormik<FormValues>({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            password: Yup.string().required(intl.get("settings.delete-account.error.required")),
            captcha: Yup.string().required(intl.get("settings.delete-account.error.required"))
        }),
        onSubmit: handleSubmit
    });

    const handleFormChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (message) {
            resetMessage();
        }

        formik.handleChange(event);
    }, [message, resetMessage, formik]);

    useImperativeHandle(ref, (): SettingsModule => ({
        isDirty: formik.dirty,
        inProgress: inProgress
    }));

    useEffect(onLoad, []);

    return (
        <Box className={classes.root}>
            <section className={classes.description}>
                {intl.get("settings.delete-account.description")}
            </section>

            <Alert variant={"filled"} severity={"warning"}>{intl.get("settings.delete-account.warning")}</Alert>

            {
                message &&
                <Message inProgress={inProgress} message={message}/>
            }

            <Box mt={2}>
                <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
                    <Captcha ref={captchaRef}/>

                    <TextField
                        autoComplete="new-password"
                        disabled={inProgress}
                        error={Boolean(formik.errors.captcha)}
                        helperText={formik.errors.captcha}
                        fullWidth
                        name="captcha"
                        label={intl.get("settings.delete-account.captcha.placeholder")}
                        value={formik.values.captcha}
                        onChange={handleFormChange}
                    />

                    <PasswordField
                        autoComplete="new-password"
                        disabled={inProgress}
                        error={Boolean(formik.errors.password)}
                        helperText={formik.errors.password}
                        fullWidth
                        name="password"
                        label={intl.get("settings.delete-account.password.placeholder")}
                        value={formik.values.password}
                        onChange={handleFormChange}
                    />

                    <Box textAlign="right">
                        <ProgressButton
                            progress={inProgress}
                            label={intl.get("settings.delete-account.button")}
                            type="submit"
                        />
                    </Box>
                </form>
            </Box>
        </Box>
    );
});
