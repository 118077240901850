import React from "react";
import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import {CircleProfilePicture} from "../CircleProfilePicture";
import intl from "react-intl-universal";
import {Viewport} from "../../layout/Viewport";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {styles} from "./styles";
import {CountdownButton} from "../../CountdownButton";
import {redirectToRoomList} from "../../../redirect";
import {ExitToRoomListButton} from "../ExitToRoomListButton";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const RoomRejoinedLobby = withStyles(styles)(React.memo<Props>(({classes, lobby}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic}/>
            <Typography className={classes.message}>{intl.get("lobby.rejoined")}</Typography>
        </Box>
        <CountdownButton onClick={redirectToRoomList} label={intl.get("lobby.button.roomlist")}/>
        <ExitToRoomListButton/>
    </Viewport>
)));
