import React from "react";
import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {styles} from "./styles";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {ExitShowButton} from "../ExitShowButton";
import {Viewport} from "../../layout/Viewport";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const PauseLobby = withStyles(styles)(React.memo<Props>(({classes, lobby}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic} icon="/images/pause.svg"/>
            <Typography className={classes.message}>{intl.get("chat.paused", {screenName: lobby.screenName})}</Typography>
        </Box>
        <ExitShowButton roomId={lobby.roomid}/>
    </Viewport>
)));
