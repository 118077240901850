import React, {PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {Header} from "../layout/Header";
import Box from "@material-ui/core/Box";
import {createStyles, Slider, WithStyles, withStyles} from "@material-ui/core";
import {Closable} from "../contract/Closable";
import {RoomId} from "../../domain/RoomId";
import {zzzSteps} from "../../domain/zzzSteps";
import {Price} from "../../domain/Price";
import {sendZzz} from "../../action/chat.action";
import {connect} from "react-redux";
import {noop} from "../../helper";

const styles = createStyles({
    root: {
        overflow: "hidden", // FIXME this required otherwise sliding cause scroll
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#000"
    },
    box: {
        padding: "20px"
    },
    slider: {
        flex: 1,
        padding: "30px 0",
        backgroundRepeat: "repeat-x",
        backgroundPositionY: "-1px",
        backgroundImage: "url(/images/zzz-sinus.svg)"
    },
    thumb: {
        touchAction: "none",
        marginTop: "-9px",
        marginLeft: "-9px",
        width: "20px",
        height: "20px"
    },
    submit: {
        marginLeft: "20px",
        width: "43px",
        height: "43px",
        backgroundColor: "#21ACE5",
        borderRadius: "50%",
        border: "2px solid #fff",
        backgroundRepeat: "no-repeat",
        backgroundSize: "18px 18px",
        backgroundPosition: "11px 50%",
        backgroundOrigin: "border-box",
        backgroundImage: "url(/images/paperplane.svg)"
    }
});

interface Props extends WithStyles<typeof styles>, Closable {
    step: number,
    inProgress: boolean,
    roomId: RoomId
    sendZzz: (roomId: RoomId, price: Price) => void
}

interface State {
    value: number
}

const mapStateToProps = ({gift}) => ({
    step: gift.step,
    inProgress: gift.inProgress
});

const mapDispatchToProps = dispatch => ({
    sendZzz: (roomId: RoomId, price: Price) => dispatch(sendZzz(roomId, price))
});

export const ZzzPanel = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props, State> {
    state = {
        value: 0
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.step
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {step} = this.props;

        if (prevProps.step !== step) {
            this.setValue(null, step);
        }
    }

    private setValue(event, value) {
        this.setState({value});
    }

    private handleSubmit() {
        const {sendZzz, roomId} = this.props;
        const {value} = this.state;

        sendZzz(roomId, zzzSteps[value]);
    }

    render(): ReactNode {
        const {classes, onClose, inProgress} = this.props;
        const {value} = this.state;

        return (
            <div className={classes.root}>
                <Header onClose={onClose} title={intl.get("zzz.title", {value: zzzSteps[value]})}/>
                <Box display="flex" flexDirection="row" alignItems="center" className={classes.box}>
                    <Slider
                        classes={{
                            root: classes.slider,
                            thumb: classes.thumb
                        }}
                        value={value}
                        min={0}
                        max={zzzSteps.length - 1}
                        step={1}
                        onChange={this.setValue}
                    />
                    <div className={classes.submit} onClick={inProgress ? noop : this.handleSubmit}/>
                </Box>
            </div>
        );
    }
}));
