import {Store} from "redux";
import {asyncScheduler} from "rxjs";
import {filter, observeOn} from "rxjs/operators";
import {updateFavorite, updateFavorites} from "../action/favorite.action";
import {FavoriteListUpdated, FavoriteUpdated} from "../domain/events";
import {RoomService} from "../service/RoomService";

export const favoriteMiddleware = (roomService: RoomService) => (store: Store) => {

    roomService.events
        .pipe(
            filter<FavoriteListUpdated | FavoriteUpdated>(
                event => event instanceof FavoriteListUpdated || event instanceof FavoriteUpdated
            ),
            observeOn(asyncScheduler)
        )
        .subscribe((event: FavoriteListUpdated | FavoriteUpdated) => {
            if (event instanceof FavoriteUpdated) {
                return store.dispatch(updateFavorite(event.roomId, event.status));
            }

            store.dispatch(updateFavorites(event.roomIds));
        });

    return next => action => next(action);
};
