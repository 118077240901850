import {makeStyles} from "@material-ui/styles";
import React, {PropsWithChildren} from "react";

const useStyles = makeStyles({
    root: {
        flex: 1,
        overflowY: "auto",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 24,
        paddingRight: 24
    }
});

interface Props {
    classes?: any
}

export const Body = React.memo<PropsWithChildren<Props>>(({children, ...props}) => {
    const classes = useStyles(props);

    return <div className={classes.root} children={children}/>;
});

Body["whyDidYouRender"] = false;
