import {User} from "../domain/authentication/User";

export enum AuthActionType {
    SignIn = "auth/sign-in",
    SignInFailed = "auth/sign-in-failed",

    SignOut = "auth/sign-out",

    UserSet = "auth/set-user",

    AccountDeleted = "auth/account-deleted"
}

export const signIn = (username: string, password: string, rememberMe: boolean, next: string) => ({
    type: AuthActionType.SignIn,
    username,
    password,
    rememberMe,
    next
});

export const signOut = () => ({
    type: AuthActionType.SignOut
});

export const userSet = (user: User | null) => ({
    type: AuthActionType.UserSet,
    user
});

export const signInFailed = error => ({
    type: AuthActionType.SignInFailed,
    error
});

export const accountDeleted = (message: string) => ({
    type: AuthActionType.AccountDeleted,
    message
});
