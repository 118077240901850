import {Price} from "./domain/Price";

const template = "<svg viewBox=\"0 0 300 300\" width=\"300\" height=\"300\" xmlns=\"http://www.w3.org/2000/svg\" " +
    " font-size=\"86px\" font-family=\"Helvetica\" font-weight=\"bold\" text-anchor=\"middle\">" +
    "<text x=\"50%\" y=\"58%\" style=\"transform:translateY(8px)\" fill=\"#784521\">{price}</text>" +
    "<text x=\"50%\" y=\"58%\" fill=\"#ffd04d\">{price}</text></svg>";

const cache = {};

export const priceUri = (price: Price) => {
    if (cache[price]) {
        return cache[price];
    }

    return cache[price] = "data:image/svg+xml;base64,".concat(window.btoa(template.replace(/{price}/g, String(price))));
};
