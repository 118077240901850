import {TextField, Typography} from "@material-ui/core";
import React, {PureComponent} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {signIn} from "../../action/auth.action";
import {I18n} from "../../domain/I18n";
import {parseNext} from "../../helper";
import {redirectToForgottenPassword, redirectToRoomList, redirectToSignUp} from "../../redirect";
import {AuthState} from "../../reducer/auth.reducer";
import {Body} from "../layout/Body";
import {Header} from "../layout/Header";
import {Section} from "../layout/Section";
import {Toolbar} from "../layout/Toolbar";
import {Viewport} from "../layout/Viewport";
import {LinkInline} from "../LinkInline";
import {PasswordField} from "../PasswordField";
import {ProgressButton} from "../ProgressButton";

interface Props {
    auth: AuthState
    location: any
    signIn: Function
}

const mapStateToProps = ({auth}) => ({
    auth
});

const mapDispatchToProps = dispatch => ({
    signIn: (username: string, password: string, rememberMe: boolean, next: string) => dispatch(signIn(username, password, rememberMe, next))
});

export const SignInScreen = connect(mapStateToProps, mapDispatchToProps)(class extends PureComponent<Props> {
    state = {
        rememberMe: false
    };

    private usernameRef: HTMLInputElement;
    private passwordRef: HTMLInputElement;

    private handleSubmit(event) {
        event.preventDefault();
        const {signIn, location} = this.props as Props;

        signIn(
            this.usernameRef.value,
            this.passwordRef.value,
            this.state.rememberMe,
            parseNext(location.search)
        );
    }

    private getErrorKey(error) {
        switch (error) {
            case "bad-credentials":
                return I18n.SignInErrorCredentials;
            default:
                return I18n.SignInErrorTechnical;
        }
    }

    render(): React.ReactNode {
        const {auth} = this.props;

        return (
            <Viewport color={"secondary"}>
                <Header onClose={redirectToRoomList} title={intl.get(I18n.SignInTitle)}/>
                <Body>

                    {
                        auth.error &&
                        <Section>
                            <Typography color="error" style={{textAlign: "center"}}>{intl.get(this.getErrorKey(auth.error.reason))}</Typography>
                        </Section>
                    }

                    <form onSubmit={event => this.handleSubmit(event)}>
                        <Section>
                            <TextField disabled={auth.inProgress}
                                       fullWidth
                                       required
                                       name="username"
                                       autoComplete="on"
                                       type="text"
                                       label={intl.get(I18n.SignInUsernameLabel)}
                                       inputRef={ref => this.usernameRef = ref}/>
                            <PasswordField disabled={auth.inProgress}
                                           fullWidth
                                           required
                                           name="password"
                                           autoComplete="on"
                                           label={intl.get(I18n.SignInPasswordLabel)}
                                           inputRef={ref => this.passwordRef = ref}/>
                        </Section>
                        <Section textAlign="right">
                            <ProgressButton progress={auth.inProgress} label={intl.get(I18n.SignInButton)} type="submit"/>
                        </Section>
                    </form>
                </Body>
                <Toolbar>
                    <Typography style={{
                        flex: 1,
                        textAlign: "center",
                        color: "#fff",
                        margin: "8px 0"
                    }} component="div" variant="body2"><LinkInline onClick={redirectToForgottenPassword} title={intl.get(I18n.SignInForgottenPasswordLink)}/><br/>
                        <span>{intl.get(I18n.SignInNotSignedUp)}</span>&nbsp;
                        <LinkInline onClick={redirectToSignUp} title={intl.get(I18n.SignInSignUpLink)}/></Typography>
                </Toolbar>
            </Viewport>
        );
    }
});
