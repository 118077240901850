import {AuthenticationDetails} from "./AuthenticationDetails";

export interface User {
    isMember: boolean
    auth: AuthenticationDetails
}

export class GuestUser implements User {
    get isMember() {
        return false;
    }

    constructor(public readonly auth: AuthenticationDetails) {
    }
}

export class MemberUser implements User {
    get isMember() {
        return true;
    }

    constructor(public readonly auth: AuthenticationDetails) {
    }
}
