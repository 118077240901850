import {HappyHourStartedEvent} from "../domain/HappyHourStartedEvent";
import {ExtraCredit} from "../domain/ExtraCredit";
import {PushPromo} from "../domain/PushPromo";

export enum TopUpActionType {
    HappyHourStarted = "topup/happy-hour-started",
    HappyHourEnded = "topup/happy-hour-ended",
    PushPromoStarted = "topup/push-promo-started",
    PushPromoEnded = "topup/push-promo-ended",
    ShowTopUp = "topup/show",
    HideTopUp = "topup/hide"
}

export const showTopUp = () => ({
    type: TopUpActionType.ShowTopUp
});

export const hideTopUp = () => ({
    type: TopUpActionType.HideTopUp
});

export const startHappyHour = (extraCredit: ExtraCredit) => ({
    type: TopUpActionType.HappyHourStarted,
    event: new HappyHourStartedEvent(extraCredit)
});

export const endHappyHour = () => ({
    type: TopUpActionType.HappyHourEnded
});

export const startPushPromo = (pushPromo: PushPromo) => ({
    type: TopUpActionType.PushPromoStarted,
    pushPromo
});

export const endPushPromo = () => ({
    type: TopUpActionType.PushPromoEnded
});
