import {
    Box,
    Button,
    CircularProgress,
    createStyles,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import React, {PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {sendGift} from "../action/chat.action";
import ApplicationContext from "../application/ApplicationContext";
import {Feature} from "../domain/feature/Feature";
import {Gift} from "../domain/Gift";
import {GiftCategory} from "../domain/GiftCategory";
import {GiftId} from "../domain/GiftId";
import {Price} from "../domain/Price";
import {RoomId} from "../domain/RoomId";
import {TipType} from "../domain/TipType";
import {featureProp} from "../middleware/helper";
import {priceUri} from "../price.helper";
import {Closable} from "./contract/Closable";
import {Disableable} from "./contract/Disableable";
import {ImagePicker} from "./ImagePicker";
import {Header} from "./layout/Header";
import {ProgressButton} from "./ProgressButton";

interface Props extends WithStyles<typeof styles>, Closable, Disableable {
    roomId: RoomId
    inProgress: boolean
    onSend: (roomId: RoomId, giftId: GiftId, message: string, price: Price) => void
    hasSmartToy: boolean,
    selected?: string,
}

interface State {
    isLoading: boolean
    categories: Array<GiftCategory>
    tab: number
    selected: Gift,
}

const styles = createStyles({
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: "170px"
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain"
    }
});

const mapStateToProps = ({gift, feature}) => ({
    inProgress: gift.inProgress,
    disabled: feature.gift === false,
    hasSmartToy: feature.hasSmartToy
});

const mapDispatchToProps = dispatch => ({
    onSend: (roomId: RoomId, giftId: GiftId, message: string, price: Price) => dispatch(sendGift(roomId, giftId, message, price))
});

const transformer = (gift: Gift, index) => {
    return {
        key: gift.id.concat(index),
        src: gift.png,
        alt: gift.name,
        price: gift.id === TipType.Tip ? gift.price : null,
        cls: gift.id === TipType.Tip ? "tip" : null
    };
};

const createTab = (category: GiftCategory) => {
    return <Tab key={category.id} icon={<img src={category.svg} alt={category.name} width={24} height={24}/>}/>;
};

export const GiftPanel = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props, State> {
    static contextType = ApplicationContext;

    state = {
        isLoading: true,
        tab: 0,
        categories: [],
        selected: null,
    };

    private inputRef: HTMLTextAreaElement;

    constructor(props) {
        super(props);

        this.selectGift = this.selectGift.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.cancelSelection = this.cancelSelection.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(): void {
        this.fetchGifts();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.hasSmartToy !== this.props.hasSmartToy || prevProps.selected !== this.props.selected) {
            this.fetchGifts();
        }
    }

    private fetchGifts() {
        const {hasSmartToy}: Props = this.props;

        this.context.giftService.getGifts(hasSmartToy)
            .then(categories => {
                let selected = null;
                if (this.props.selected) {
                    selected = this.context.giftService.getById(this.props.selected);
                }
                this.setState({
                    isLoading: false,
                    categories,
                    selected,
                })
            });
    }

    private onTabChange(event, tab) {
        this.setState({tab});
    }

    private selectGift(selected: Gift) {
        this.setState({selected});
    }

    private cancelSelection() {
        this.setState({selected: null});
    }

    private handleSubmit() {
        const {roomId, onSend, onClose}: Props = this.props;
        const gift: Gift = this.state.selected;

        if (gift) {
            onSend(roomId, gift.id, this.inputRef.value.trim(), gift.price);
            onClose();
        }
    }

    render(): ReactNode {
        const {isLoading}: State = this.state;
        const {classes}: Props = this.props;

        if (isLoading) {
            return <Paper className={classes.root} style={{
                alignItems: "center",
                justifyContent: "center"
            }}><CircularProgress/></Paper>;
        }

        return this.renderView();
    }

    private renderView(): ReactNode {
        const {classes, onClose, disabled, inProgress}: Props = this.props;
        const {categories, tab, selected}: State = this.state;
        const category: GiftCategory = categories[tab];

        return (
            <Paper className={classes.root}>
                {
                    selected &&
                    <Box flex={1} display="flex" flexDirection="column" overflow={"hidden"}>
                        <Header disabled={inProgress} onBack={!this.props.selected && this.cancelSelection} onClose={onClose}
                                title={intl.get("gift.selected.title", {gift: selected.name})}/>
                        <Box flex={1} display="flex" flexDirection="column" padding={2} overflow={"scroll"}>
                            <Box flex={1} textAlign="center" overflow={"hidden"} position="relative">
                                <img src={selected.svg || selected.png} alt={selected.name} className={classes.image}/>

                                {
                                    selected.id === TipType.Tip &&
                                    <img alt="price" src={priceUri(selected.price)} className={classes.image}/>
                                }
                            </Box>

                            <Box textAlign={"right"}>
                                <Typography>{intl.get("gift.price", {price: selected.price})}</Typography>
                            </Box>

                            <Box flex={1} color={"white"}>
                                <TextField
                                    disabled={disabled || inProgress}
                                    inputRef={inputRef => this.inputRef = inputRef}
                                    inputProps={featureProp(Feature.Gift)}
                                    fullWidth
                                    multiline
                                    rowsMax={2}
                                    placeholder={intl.get("gift.message.description")}
                                />
                            </Box>
                            <Box textAlign="right" marginTop={2}>
                                {!this.props.selected && <Button color="secondary" disabled={inProgress}
                                                                 onClick={this.cancelSelection}>{intl.get("gift.btn.back")}</Button>
                                }
                                <ProgressButton progress={inProgress} onClick={this.handleSubmit}
                                                label={intl.get("gift.btn.send")}/>
                            </Box>
                        </Box>
                    </Box>
                }

                {
                    selected === null && category &&
                    <Box flex={1} display="flex" flexDirection="column" overflow={"hidden"}>
                        <Header onClose={onClose} title={intl.get("gift.title")}/>
                        <ImagePicker key={category.id}
                                     images={category.items}
                                     onSelect={this.selectGift}
                                     transformer={transformer}/>
                        <Tabs variant="scrollable" scrollButtons="on" value={tab} onChange={this.onTabChange}>
                            {categories.map(createTab)}
                        </Tabs>
                    </Box>
                }
            </Paper>
        );
    }
}));
