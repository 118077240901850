import {createStyles, Fab, withStyles, WithStyles} from "@material-ui/core";
import React from "react";
import {Feature} from "../../domain/feature/Feature";
import {featureAttribute} from "../../middleware/helper";
import {Clickable} from "../contract/Clickable";
import {Disableable} from "../contract/Disableable";

const styles = createStyles({
    root: {
        borderRadius: "50%"
    }
});

interface Props extends WithStyles<typeof styles>, Clickable, Disableable {
}

export const ZzzFab = withStyles(styles)(React.memo<Props>(({classes, onClick, disabled = false}) => (
    <Fab classes={{root: classes.root}}
         disabled={disabled}
         onClick={onClick}
         buttonRef={featureAttribute(Feature.Zzz)}>
        <img src="/images/zZz.svg" width={24} height={24} alt="zzz"/>
    </Fab>
)));
