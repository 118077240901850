import {CircularProgress, createStyles, Typography, WithStyles, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, {Fragment, PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import ApplicationContext from "../../application/ApplicationContext";
import {PromoType} from "../../domain/PromoType";
import {Credit} from "../../domain/topup/Credit";
import {TopUpProvider} from "../../domain/topup/TopUpProvider";
import {TopUpType} from "../../domain/topup/TopUpType";
import {PremiumRateCallProvider} from "../../service/topup/PremiumRateCallProvider";
import {PremiumRateTextProvider} from "../../service/topup/PremiumRateTextProvider";
import {SecurionQuickbuyProvider} from "../../service/topup/SecurionQuickbuyProvider";
import {SecurionTopUpProvider} from "../../service/topup/SecurionTopUpProvider";
import {Closable} from "../contract/Closable";
import {Viewport} from "../layout/Viewport";
import {BuyButton} from "./BuyButton";
import {CallTopUpScreen} from "./CallTopUpScreen";
import {CardTopUpScreen} from "./CardTopUpScreen";
import {CreditPicker} from "./CreditPicker";
import {SuccessfulTopUpScreen} from "./SuccessfulTopUpScreen";
import {TextTopUpScreen} from "./TextTopUpScreen";
import {TopUpTypeSelector} from "./TopUpTypeSelector";

const styles = createStyles({
    root: {
        overflowY: "scroll",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column"
    },
    inner: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        "@media (orientation: landscape)": {
            flexDirection: "row"
        }
    }
});

interface Props extends WithStyles<typeof styles>, Closable {
}

enum Phase {SELECT, BUY, SUCCESS}

interface State {
    error: any
    isLoading: boolean
    inProgress: boolean
    topUpType: TopUpType
    selectedCredit: Credit
    options: Array<Credit>
    phase: Phase
}

class TopUpScreen extends PureComponent<Props, State> {
    static contextType = ApplicationContext;

    state = {
        error: null,
        isLoading: true,
        inProgress: false,
        topUpType: null,
        selectedCredit: null,
        options: [],
        phase: Phase.SELECT
    };

    private providers: Map<TopUpType, TopUpProvider>;

    constructor(props) {
        super(props);

        this.showSelectPhase = this.showSelectPhase.bind(this);
        this.moveToBuyPhase = this.moveToBuyPhase.bind(this);
        this.showSuccessPhase = this.showSuccessPhase.bind(this);
        this.selectCredit = this.selectCredit.bind(this);
        this.selectTopUpType = this.selectTopUpType.bind(this);
    }

    private selectCredit(selectedCredit: Credit) {
        this.setState({selectedCredit});
    }

    private showSelectPhase() {
        this.setState({phase: Phase.SELECT});
    }

    private moveToBuyPhase() {
        const {topUpType, selectedCredit} = this.state;

        if (topUpType === TopUpType.QUICKBUY) {
            const provider = this.providers.get(TopUpType.QUICKBUY) as SecurionQuickbuyProvider;

            this.setState({inProgress: true, error: null});

            provider.charge(selectedCredit)
                .then(() => this.setState({inProgress: false, phase: Phase.SUCCESS}))
                .catch(error => this.setState({inProgress: false, error}));
        } else {
            this.setState({phase: Phase.BUY});
        }
    }

    private showSuccessPhase() {
        this.setState({phase: Phase.SUCCESS});
    }

    componentDidMount(): void {
        const {topupService} = this.context;

        topupService.getProviders()
            .then(providers => {
                this.providers = providers;
                this.selectTopUpType(Array.from(providers.keys())[0] as TopUpType);
            })
            .finally(() => this.setState({isLoading: false}));

        // TODO handle catch
    }

    private selectTopUpType(type: TopUpType) {
        const options = this.filterOptions(type);
        const promoIndex = Math.max(0, options.findIndex(credit => credit.promotionType));

        this.setState({
            phase: Phase.SELECT,
            topUpType: type,
            options,
            selectedCredit: options[promoIndex]
        });
    }

    private filterOptions(type: TopUpType): Array<Credit> {
        return this.providers.get(type).getCreditOptions();
    }

    render(): ReactNode {
        const {onClose} = this.props;
        const {phase, selectedCredit} = this.state;

        if (phase === Phase.SUCCESS) {
            return <SuccessfulTopUpScreen credit={selectedCredit} onClose={onClose}/>;
        }

        return phase === Phase.BUY ? this.renderBuyPhase() : this.renderSelectPhase();
    }

    private renderSelectPhase(): ReactNode {
        const providers = this.providers;
        const {selectedCredit, options, topUpType, error, inProgress, isLoading} = this.state;
        const {classes, onClose} = this.props;
        const isHappyHour = options.some((credit: Credit) => credit.promotionType === PromoType.HappyHour);
        const isPushPromo = options.some((credit: Credit) => credit.promotionType === PromoType.PushPromo);

        return (
            <Viewport className={classes.root}>
                <TopUpTypeSelector
                    onSelect={this.selectTopUpType}
                    onClose={onClose}
                    availableTypes={providers ? Array.from(providers.keys()) : []}
                    selection={topUpType}/>

                {
                    topUpType &&
                    <Typography style={{
                        textAlign: "center",
                        margin: "5px 0"
                    }}>
                        {
                            intl.get(`topup.${topUpType}.title`)
                        }

                        {
                            isHappyHour &&
                            <Fragment>
                                <br/><span style={{color: "#ffc022"}}>{intl.get("topup.extra-credit.title")}</span>
                            </Fragment>
                        }

                        {
                            isPushPromo &&
                            <Fragment>
                                <br/><span style={{color: "#ffc022"}}>{intl.get("topup.push-promo.title")}</span>
                            </Fragment>
                        }
                    </Typography>
                }

                {
                    error &&
                    <Typography color="error" style={{
                        textAlign: "center",
                        margin: "5px 0"
                    }}>{error.message}</Typography>
                }

                <Box className={classes.inner}>
                    {
                        isLoading &&
                        <Box margin="0 auto">
                            <CircularProgress/>
                        </Box>
                    }

                    {
                        isLoading === false && options.length > 0 &&
                        <CreditPicker selectedValue={selectedCredit} options={options} onSelect={this.selectCredit}/>
                    }

                    {
                        isLoading === false && options.length > 0 && selectedCredit &&
                        <BuyButton inProgress={inProgress} credit={selectedCredit} onClick={this.moveToBuyPhase}/>
                    }
                </Box>
            </Viewport>
        );
    }

    private renderBuyPhase(): ReactNode {
        const providers = this.providers;
        const {onClose} = this.props;
        const {selectedCredit, topUpType} = this.state;

        switch (topUpType) {
            case TopUpType.CARD:
                return <CardTopUpScreen
                    provider={providers.get(TopUpType.CARD) as SecurionTopUpProvider}
                    credit={selectedCredit}
                    onBack={this.showSelectPhase}
                    onClose={onClose}
                    onSuccess={this.showSuccessPhase}
                />;

            case TopUpType.CALL:
                return <CallTopUpScreen
                    provider={providers.get(TopUpType.CALL) as PremiumRateCallProvider}
                    credit={selectedCredit}
                    onBack={this.showSelectPhase}
                    onClose={onClose}
                />;

            case TopUpType.TEXT:
                return <TextTopUpScreen
                    provider={providers.get(TopUpType.TEXT) as PremiumRateTextProvider}
                    credit={selectedCredit}
                    onBack={this.showSelectPhase}
                    onClose={onClose}
                />;

            default:
                return null;
        }
    }
}

const component = withStyles(styles)(TopUpScreen);

export {
    component as TopUpScreen
};
