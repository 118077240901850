import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React from "react";
import intl from "react-intl-universal";
import {Viewport} from "./layout/Viewport";
import {CircleProfilePicture} from "./room/CircleProfilePicture";

const reload = () => window.location.reload(true);

export const UpdateScreen = React.memo(() => {
    return (
        <Viewport>
            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly">
                <CircleProfilePicture title={intl.get("update.title")} color={"base"}/>
                <Box margin={"0 3em"} textAlign={"center"}>
                    <Typography variant="body1">{intl.get("update.text")}</Typography>
                </Box>
                <Button onClick={reload}>{intl.get("update.button")}</Button>
            </Box>
        </Viewport>
    );
});
