import React from "react";
import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ModeButton} from "../ModeButton";
import {RoomMode} from "../../../domain/RoomMode";
import {styles} from "./styles";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
    action: () => void
}

export const PrivateLobby = withStyles(styles)(React.memo<Props>(({classes, lobby, action}) => {
    const canJoin = lobby.modeSpecific && lobby.modeSpecific.canJoin;

    return (
        <Viewport>
            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <CircleProfilePicture src={lobby?.publicData?.profilePic}/>

                {
                    canJoin &&
                    <Typography className={classes.message}>{intl.getHTML("lobby.private", {
                        screenName: lobby.screenName,
                        price: lobby.pricing.voyeur
                    })}</Typography>
                }
            </Box>

            {
                canJoin &&
                <ModeButton mode={RoomMode.Voyeur} price={lobby.pricing.voyeur} onClick={action}/>
            }

            <ExitToRoomListButton/>
        </Viewport>
    );
}));
