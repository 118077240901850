import {FormControl, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
    input: {
        color: "#000"
    }
});

interface Props {
    label: string
    value: string
    helperText?: string
    classes?: {
        input?: string
    }
}

export const DisplayField = React.memo<Props>(({helperText, label, value, ...props}) => {
    const classes = useStyles(props);

    return (
        <FormControl fullWidth>
            <TextField
                inputProps={{className: classes.input}}
                disabled
                fullWidth
                helperText={helperText}
                label={label}
                variant={"standard"}
                contentEditable={false}
                value={value}
            />
        </FormControl>
    );
});
