export class InteractionTracker {
    private interactionCount: number = 0;
    private lastInteractionAt: number = 0;

    constructor() {
        document.addEventListener("click", this.click);
    }

    public click = () => {
        this.interactionCount++;
        this.lastInteractionAt = Date.now();
    };

    public wasAny(): boolean {
        return this.interactionCount > 0;
    }
}
