import React from "react";
import intl from "react-intl-universal";
import {SubLabelButton} from "../SubLabelButton";
import {Clickable} from "../contract/Clickable";
import {Disableable} from "../contract/Disableable";
import {RoomMode} from "../../domain/RoomMode";
import {Price} from "../../domain/Price";

interface Props extends Clickable, Disableable {
    mode: RoomMode
    price: Price
}

export const ModeButton = React.memo<Props>(({disabled = false, onClick, mode, price}) => {
    return <SubLabelButton disabled={disabled}
                           onClick={onClick}
                           label={intl.get("chat.enter.".concat(mode))}
                           subLabel={intl.get("chat.price", {price})}/>;
});
