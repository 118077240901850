import {config} from "../config/webapi";

import {User} from "../domain/authentication/User";
import {AuthenticationService} from "./AuthenticationService";

interface IKeyValuePair {
    [key: string]: any
}

export class WebApiService {
    private user: User;

    constructor(private authService: AuthenticationService) {
        authService.user.subscribe((user: User) => {
            this.user = user;
        });
    }

    public get<T>(method: string, data?: IKeyValuePair): Promise<T> {
        return this.call<T>(method, data, "GET");
    }

    public post<T>(method: string, data?: IKeyValuePair): Promise<T> {
        return this.call<T>(method, data, "POST");
    }

    private call<T>(call: string, data: IKeyValuePair = null, method: "GET" | "POST"): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const {tokenID} = this.user?.auth;
            let body;

            if (!tokenID) {
                return reject("No tokenID");
            }

            const query = new URLSearchParams();

            query.set("tokenID", tokenID);

            if (method === "POST" && data) {
                body = Object.entries(data).reduce((formData: FormData, [name, value]) => {
                    formData.set(name, String(value));

                    return formData;
                }, new FormData());
            }

            if (method === "GET" && data) {
                Object.entries(data).forEach(([name, value]) => query.set(name, value));
            }

            const endpoint = `${config.apiUrl}/${call}?${query}`;
            const requestInit: RequestInit = {
                body,
                method,
                mode: "cors",
                headers: {
                    accept: "application/json",
                    "x-requested-with": "fetch"
                }
            };

            fetch(endpoint, requestInit)
                .then(response => response.ok ? response : Promise.reject())
                .then(response => response.json())
                .then(resolve).catch(reject);
        });
    }
}
