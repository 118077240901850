import {Box, Button, Dialog, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import intl from "react-intl-universal";
import {useSelector} from "react-redux";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {AggregateDeviceInfo} from "../../../domain/c2c/AggregateDeviceInfo";
import {Setup} from "../../../domain/c2c/Setup";
import {Feature} from "../../../domain/feature/Feature";
import {RootState} from "../../../reducer";
import {Closable} from "../../contract/Closable";
import {Selectable} from "../../contract/Selectable";
import {Body} from "../../layout/Body";
import {Header} from "../../layout/Header";
import {Section} from "../../layout/Section";
import {Viewport} from "../../layout/Viewport";
import {SlideTransition} from "../../SlideTransition";

export interface SettingsDialogProps extends Closable, Selectable<Setup> {
    open: boolean
}

const renderRadio = device => (
    <FormControlLabel key={device.id} value={device.id} control={<Radio/>} label={device.name}/>
);

export const SettingsDialog = React.memo<SettingsDialogProps>(({open, onClose, onSelect}) => {
    const {cameraService} = useApplicationContext();
    const [devices, setDevices] = useState<AggregateDeviceInfo>(null);
    const [setup, setSetup] = useState<Setup>(null);
    const [initialSetup, setInitialSetup] = useState<Setup>(null);
    const isAudioAvailable = useSelector<RootState, boolean>(({feature}) => feature[Feature.MicrophoneShow]);

    useEffect(() => {
        if (open) {
            Promise.all([
                cameraService.getDevices(),
                cameraService.getSetup()
            ]).then(([devices, setup]) => {
                setDevices(devices);
                setSetup(setup);
                setInitialSetup(setup);
            });
        }
    }, [cameraService, open]);

    const clear = useCallback(() => {
        setDevices(null);
        setSetup(null);
        setInitialSetup(null);
    }, []);

    const handleChange = useCallback((event) => {
        const {name, value} = event.target as HTMLInputElement;

        setSetup(setup => ({
            ...setup,
            [name]: value
        }));
    }, []);

    const handleSelect = useCallback(() => {
        onSelect(setup);
    }, [onSelect, setup]);

    const disabled = useMemo<boolean>(() => {
        if (initialSetup === null || setup === null) {
            return false;
        }

        return initialSetup.video === setup.video && initialSetup.audio === setup.audio;
    }, [initialSetup, setup]);

    return (
        <Dialog open={open} TransitionComponent={SlideTransition} onExited={clear}>
            <Viewport color={"secondary"}>
                <Header title={intl.get("camera.settings.title")} onClose={onClose}/>
                <Body>
                    {
                        setup && devices &&
                        <Fragment>

                            {
                                isAudioAvailable &&
                                devices.audio.length > 0 &&
                                <Section>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{intl.get("camera.settings.audio.title")}</FormLabel>

                                        {
                                            devices.audio.length > 1 &&
                                            <RadioGroup aria-label="audio" name="audio" value={setup.audio} onChange={handleChange}>
                                                {devices.audio.map(renderRadio)}
                                            </RadioGroup>
                                        }

                                        {
                                            devices.audio.length === 1 &&
                                            <Box mt={1}>
                                                <FormLabel>{devices.audio[0].name}</FormLabel>
                                            </Box>
                                        }

                                    </FormControl>
                                </Section>
                            }

                            {
                                devices.video.length > 0 &&
                                <Section>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{intl.get("camera.settings.video.title")}</FormLabel>

                                        {
                                            devices.video.length > 1 &&
                                            <RadioGroup aria-label="video" name="video" value={setup.video} onChange={handleChange}>
                                                {devices.video.map(renderRadio)}
                                            </RadioGroup>
                                        }

                                        {
                                            devices.video.length === 1 &&
                                            <Box mt={1}>
                                                <FormLabel>{devices.video[0].name}</FormLabel>
                                            </Box>
                                        }

                                    </FormControl>
                                </Section>
                            }

                            <Section textAlign={"right"}>
                                <Button disabled={disabled} onClick={handleSelect}>{intl.get("camera.settings.apply.button")}</Button>
                            </Section>
                        </Fragment>
                    }
                </Body>
            </Viewport>
        </Dialog>
    );
});
