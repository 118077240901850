import {Store} from "redux";
import {ApplicationActionType} from "../action/application.action";
import {AuthActionType} from "../action/auth.action";
import {FavoriteActionType} from "../action/favorite.action";
import {roomListUpdated} from "../action/roomlist.action";
import {RoomListService} from "../service/RoomListService";

export const roomListMiddleware = (service: RoomListService) => (store: Store) => {
    return next => action => {
        const result = next(action);
        const {type} = action;

        switch (type) {
            case ApplicationActionType.Ready: {
                service.rooms.subscribe(rooms => store.dispatch(roomListUpdated(rooms)));

                break;
            }

            case AuthActionType.UserSet:
            case FavoriteActionType.ListUpdated:
            case ApplicationActionType.BecameVisible: {
                service.update();

                break;
            }

            default:
        }

        return result;
    };
};
