import {AuthActionType} from "../action/auth.action";
import {FavoriteActionType} from "../action/favorite.action";
import {RoomId} from "../domain/RoomId";

export interface FavoriteState {
    roomIds: Array<RoomId>
    loaded: boolean
}

const initialState: FavoriteState = {
    roomIds: [],
    loaded: false
};

export function favoriteReducer(state: FavoriteState = initialState, action): FavoriteState {
    const {type, roomIds, roomId, status, user} = action;

    switch (type) {
        case AuthActionType.UserSet: {
            if (user?.isMember === false) {
                return initialState;
            }

            break;
        }

        case FavoriteActionType.ListUpdated: {
            return {
                roomIds,
                loaded: true
            };
        }

        case FavoriteActionType.RoomUpdated: {
            if (status === true) {
                return {
                    ...state,
                    roomIds: state.roomIds.concat(roomId)
                };
            }

            if (status === false) {
                return {
                    ...state,
                    roomIds: state.roomIds.filter(id => id !== roomId)
                };
            }
        }
    }

    return state;
}
