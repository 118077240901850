import {DataURI} from "../domain/DataURI";
import {noop} from "../helper";
import {SxRoomService} from "../service/SxRoomService";
import {VideoCapturer} from "./VideoCapturer";

export class SnapshotUpstream {
    private UPDATE_FREQ = 5000;
    private timer = null;

    constructor(
        private readonly roomService: SxRoomService,
        private readonly capturer: VideoCapturer
    ) {
    }

    public start() {
        if (this.timer) {
            return;
        }

        this.timer = setInterval(this.capture, this.UPDATE_FREQ);
        this.capture();
    }

    public stop() {
        clearInterval(this.timer);
        this.timer = null;
        this.clear();
    }

    private clear() {
        this.send(null).catch(noop);
    }

    private capture = () => {
        this.capturer.capture().then(this.send).catch(noop);
    };

    private send = (uri: DataURI): Promise<void> => {
        return this.roomService.sendSnapshot(uri);
    };
}
