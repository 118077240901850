import React, {useCallback} from "react";
import {connect} from "react-redux";
import {exitShow} from "../../action/chat.action";
import {RoomId} from "../../domain/RoomId";
import {ExitFab} from "./ExitFab";

interface Props {
    roomId: RoomId
    exitRoom: (roomId: RoomId) => void
    className?: string
}

const mapDispatchToProps = dispatch => ({
    exitRoom: (roomId: RoomId) => dispatch(exitShow(roomId))
});

export const ExitShowButton = connect(null, mapDispatchToProps)(
    React.memo<Props>(({exitRoom, roomId, className}) => {
        const onClick = useCallback(() => exitRoom(roomId), [exitRoom, roomId]);

        return <ExitFab onClick={onClick} className={className}/>;
    }));
