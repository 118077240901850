import {Box, IconButton} from "@material-ui/core";
import {Refresh} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback, useImperativeHandle, useState} from "react";
import {useSelector} from "react-redux";
import {baseUrl} from "../config/application";
import {RootState} from "../reducer";

const useStyles = makeStyles({
    button: {
        color: "black"
    }
});

export interface CaptchaRef {
    refresh: () => void
}

export const Captcha = React.forwardRef<CaptchaRef>((props, ref) => {
    const classes = useStyles();
    const [random, setRandom] = useState<number>(Date.now());
    const locale = useSelector<RootState, string>(state => state.i18n.code);
    const source = `${baseUrl}/lib/captcha/captcha.php?language=${locale}&random=${random}`;
    const refresh = useCallback(() => setRandom(Date.now()), []);

    useImperativeHandle(ref, () => ({refresh}));

    return (
        <Box display={"flex"} flexDirection={"row"}>
            <img alt={"captcha"} src={source} width={148} height={48}/>
            <IconButton onClick={refresh} className={classes.button}>
                <Refresh/>
            </IconButton>
        </Box>
    );
});
