import React from "react";
import {Emojione} from "react-emoji-render";

export interface TextWithEmojiProps {
    text: string
}

export const TextWithEmoji = React.memo<TextWithEmojiProps>(({text}) =>
    typeof text === "string" ? <Emojione svg={true} text={text}/> : null
);
