import {RoomDto} from "../domain/RoomDto";

export enum RoomListActionType {
    Updated = "roomlist/updated"
}

export const roomListUpdated = (rooms: Array<RoomDto>) => ({
    type: RoomListActionType.Updated,
    rooms
});
