import {Subject} from "rxjs";
import {Abilities, ChatHandler, ExitCode, MessageKey, Query, SourceSet} from "vchat-core";
import {ChatMessageEvent} from "../../domain/ChatMessageEvent";
import {RemovedFromRoom} from "../../domain/RemovedFromRoom";
import {RemovedFromRoomReason} from "../../domain/RemovedFromRoomReason";
import {RoomDto} from "../../domain/RoomDto";
import {Logger} from "../../logger/Logger";

export class VxChatHandler implements ChatHandler {

    private active: boolean = true;

    constructor(
        private logger: Logger,
        private room: RoomDto,
        private subject: Subject<any>
    ) {
    }

    close() {
        this.active = false;
        this.subject = null;
    }

    onChatPause() {
        this.logger.info("onChatPause", this.active);

        // TODO implement RoomPaused
    }

    onChatResume(sourceSet: SourceSet) {
        this.logger.info("onChatResume", this.active, sourceSet);

        // TODO implement this RoomResumed
    }

    onAbilityUpdate(name: keyof Abilities, value: boolean): void {
        // ignore
    }

    onAudioMuteUpdate(value: boolean): void {
        // ignore
    }

    onChatStop(code: ExitCode, exitMessage?: string): void {
        this.logger.info("onChatStop", this.active, code, ExitCode[code], exitMessage);

        // TODO handle exit codes
        if (this.active) {
            this.subject.next(new RemovedFromRoom(RemovedFromRoomReason.RoomTerminated, this.room.roomid));
        }
    }

    onMessage(text: string, from: string, key: keyof MessageKey, params: Record<string, unknown>): void {
        this.logger.info("onMessage", this.active, text, from, key, params);

        if (this.active === false) {
            return;
        }

        if (typeof from === "string" && from.toLowerCase() === "system") {
            return;
        }

        this.subject.next(new ChatMessageEvent({
            from: this.room.screenName,
            type: "performer",
            message: {
                text,
                type: "chat"
            }
        }));
    }

    onQuery(query: Query): void {
        // ignore
    }

    onSingleModeUpdate(value: boolean): void {
        // ignore
    }

    onTextMuteUpdate(value: boolean): void {
        // ignore
    }
}
