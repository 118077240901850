import {Box, BoxProps} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import {clsx} from "../../helper";

const useStyle = makeStyles({
    root: {
        marginBottom: 32
    }
});

export const Section = React.memo<BoxProps>(({className, ...rest}) => {
    const classes = useStyle();

    return <Box {...rest} className={clsx(classes.root, className)}/>;
});
