import {ConsentActionType} from "../action/consent.action";
import {Consent} from "../domain/Consent";
import {ConsentFailedReason} from "../service/ConsentService";

const initialState: ConsentState = {
    ageLimitAcknowledged: false,
    consent: null,
    error: null,
    inProgress: false
};

export interface ConsentState {
    ageLimitAcknowledged: boolean,
    consent: Consent,
    error: {
        type: ConsentFailedReason,
        consent?: Consent,
        message?: string
    },
    inProgress: boolean
}

export function consentReducer(state: ConsentState = initialState, action): ConsentState {
    switch (action.type) {
        case ConsentActionType.InitAgeLimitAck: {
            state = {
                ...state,
                ageLimitAcknowledged: action.acknowledged
            };

            break;
        }
        case ConsentActionType.AcknowledgeAgeLimit: {
            state = {
                ...state,
                ageLimitAcknowledged: true
            };

            break;
        }

        case ConsentActionType.GiveConsent: {
            state = {
                ...initialState,
                ageLimitAcknowledged: state.ageLimitAcknowledged,
                inProgress: true
            };

            break;
        }

        case ConsentActionType.ConsentSet:
        case ConsentActionType.GiveConsentSucceeded: {
            state = {
                ...initialState,
                ageLimitAcknowledged: state.ageLimitAcknowledged,
                consent: action.consent
            };

            break;
        }

        case ConsentActionType.GiveConsentFailed: {
            state = {
                ...initialState,
                ageLimitAcknowledged: state.ageLimitAcknowledged,
                error: action.error
            };

            if (action.error.type === ConsentFailedReason.NotSatisfied) {
                state.consent = action.error.consent;
            }

            break;
        }

        default:
    }

    return state;
}
