import React from "react";
import Fab from "@material-ui/core/Fab";
import {connect} from "react-redux";
import {toggleFullScreen} from "../../action/application.action";

interface Props {
    className?: string
    classes?: {
        root?: string
    }
    isFullscreenEnabled: boolean
    isFullscreen: boolean
    toggleFullscreen: () => void
}

const mapStateToProps = state => ({
    isFullscreen: state.app.isFullscreen,
    isFullscreenEnabled: state.app.isFullscreenEnabled
});

const mapDispatchToProps = dispatch => ({
    toggleFullscreen: () => dispatch(toggleFullScreen())
});

export const FullscreenButton = connect(mapStateToProps, mapDispatchToProps)(
    React.memo<Props>(({isFullscreenEnabled, isFullscreen, toggleFullscreen, classes, className}) => (
        isFullscreenEnabled &&
        <Fab color="secondary" className={className} classes={classes} onClick={toggleFullscreen}>
            {
                isFullscreen
                    ? <img src="/images/normalscreen.svg" alt="exit fullscreen" width={24} height={24}/>
                    : <img src="/images/fullscreen.svg" alt="enter fullscreen" width={24} height={24}/>
            }
        </Fab>
    )));
