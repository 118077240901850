export enum UpdateActionType {
    ReleaseReceived = "update/release-received",
    RequestReload = "update/request-reload"
}

export const releaseReceived = (release: string) => ({
    type: UpdateActionType.ReleaseReceived,
    release
});

export const requestReload = () => ({
    type: UpdateActionType.RequestReload
});
