import {Box, CircularProgress, createStyles, Typography, WithStyles, withStyles} from "@material-ui/core";
import React, {Fragment, PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import ApplicationContext from "../../application/ApplicationContext";
import {Context} from "../../application/Context";
import {I18n} from "../../domain/I18n";
import {redirectToRoom, redirectToRoomList} from "../../redirect";
import {CountdownButton} from "../CountdownButton";
import {Header} from "../layout/Header";
import {Viewport} from "../layout/Viewport";
import {CircleProfilePicture} from "../room/CircleProfilePicture";
import {RoomHolder} from "../contract/RoomHolder";

const styles = createStyles({
    text: {
        textAlign: "center",
        margin: "0 20px"
    }
});

interface Props extends WithStyles<typeof styles>, RoomHolder {
    hash: string
}

interface State {
    inProgress: boolean
    success: boolean
    message: string
}

const mapStateToProps = ({rooms}) => ({
    room: rooms.upcoming
});

const mapDispatchToProps = dispatch => ({});

export const EmailValidationScreen = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props, State> {
    static contextType = ApplicationContext;

    state = {
        inProgress: true,
        success: null,
        message: null
    };

    componentDidMount(): void {
        const {authService}: Context = this.context;
        const {hash} = this.props;

        authService.confirmEmail(hash)
            .then(message => this.setState({inProgress: false, success: true, message}))
            .catch(message => this.setState({inProgress: false, success: false, message}));
    }

    render(): ReactNode {
        const {classes, room}: Props = this.props;
        const {message, inProgress, success} = this.state;

        return (
            <Viewport color={"primary"}>
                <Header title={intl.get(I18n.EmailValidationTitle)} onClose={redirectToRoomList}/>
                <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    {
                        inProgress === false &&
                        <Fragment>
                            <Typography className={classes.text}>{message}</Typography>

                            {
                                success && room &&
                                <Fragment>
                                    <CircleProfilePicture src={room?.publicData?.profilePic}/>
                                    <Typography className={classes.text}>{intl.get(I18n.EmailValidationRoomComingUp, room)}</Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }

                    {
                        inProgress && <CircularProgress/>
                    }
                </Box>

                {
                    inProgress === false && success && room &&
                    <CountdownButton onClick={() => redirectToRoom(room.roomid)}
                                     label={intl.get(I18n.ToRoomButton, room)}/>
                }
            </Viewport>
        );
    }
}));
