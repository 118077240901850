import NumberFormat from "react-number-format";
import React from "react";

interface Props {
    inputRef: React.Ref<HTMLInputElement>
    onChange: any
}

export const MonthOrYearFormat = React.memo<Props>(({inputRef, onChange, ...other}) => {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            pattern="\d*"
            inputMode="numeric"
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            format="##"
        />
    );
});
