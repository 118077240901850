import {useCallback, useState} from "react";

type PromiseFactory = (...args) => Promise<any>

type Progress = [
    boolean,
    (fn: PromiseFactory) => PromiseFactory,
    () => void,
    () => void
]

export const useProgress = (): Progress => {
    const [count, setCount] = useState<number>(0);

    const trackProgress = useCallback((fn: PromiseFactory) => {
        return (...args) => {
            setCount(count => count + 1);

            return fn(...args).finally(() => setCount(count => count - 1));
        };
    }, []);

    return [
        count > 0,
        trackProgress,
        useCallback(() => setCount(count => count + 1), []),
        useCallback(() => setCount(count => count - 1), [])
    ];
};
