import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {AbortError} from "../domain/AbortError";
import {Player, PlayerState} from "../domain/Player";
import {PlayerError} from "../domain/PlayerError";
import {StreamData} from "../domain/StreamData";
import {TimeoutError} from "../domain/TimeoutError";
import {H5LivePlayer} from "./H5LivePlayer";
import {HlsJsPlayer} from "./HlsJsPlayer";
import {HlsNativePlayer} from "./HlsNativePlayer";
import {NullPlayer} from "./NullPlayer";
import {VisitXPlayer} from "./visitx/VisitXPlayer";
import {WebRTCPlayer} from "./webrtc/WebRTCPlayer";

export enum PlayerType {
    Auto,
    WebRTC,
    HLS,
    NULL
}

const handleIf = (errors: Array<Function>, fn: (reason: any) => any) => (reason: any) => {
    if (errors.some(error => reason instanceof error)) {
        return fn(reason);
    }

    return Promise.reject(reason);
};

const matcher = (use: PlayerType) => (type: PlayerType): boolean => use === PlayerType.Auto || use === type;

interface PlayerCandidate {
    disabled?: boolean
    name: string
    test: (stream: StreamData, match: (type: PlayerType) => boolean) => boolean
    factory: () => Player
    instance?: Player
}

export class PlayerFactory implements Player {
    private player: Player;
    private container: HTMLDivElement = document.createElement("div");
    private playerStateSubject: BehaviorSubject<PlayerState> = new BehaviorSubject(PlayerState.Stopped);
    private muteStateSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private playerStateSubscription: Subscription;
    private muteStateSubscription: Subscription;

    public destroy(): void {
        this.unsubscribe();
        this.player?.destroy();
    }

    public playState(): Observable<PlayerState> {
        return this.playerStateSubject;
    }

    public muteState(): Observable<boolean> {
        return this.muteStateSubject;
    }

    public mute(): void {
        this.muteStateSubject.next(true);
        this.player?.mute();
    }

    public stop(): void {
        this.playerStateSubject.next(PlayerState.Stopped);
        this.player?.stop();
    }

    public unmute(): void {
        this.muteStateSubject.next(false);
        this.player?.unmute();
    }

    private readonly providers: Array<PlayerCandidate> = [
        {
            name: "vx",
            test: stream => Boolean(stream.visitx),
            factory: () => new VisitXPlayer()
        },
        {
            name: "webrtc",
            test: (stream, is) => is(PlayerType.WebRTC) && stream.webrtc && WebRTCPlayer.isSupported(),
            factory: () => new WebRTCPlayer()
        },
        {
            name: "h5l",
            test: stream => stream.h5live && stream.rtmp && H5LivePlayer.isSupported(),
            factory: () => new H5LivePlayer()
        },
        {
            name: "hls-js",
            test: (stream, is) => is(PlayerType.HLS) && stream.hls && HlsJsPlayer.isSupported(),
            factory: () => new HlsJsPlayer()
        },
        {
            name: "hls-native",
            test: (stream, is) => is(PlayerType.HLS) && stream.hls && HlsNativePlayer.isSupported(),
            factory: () => new HlsNativePlayer()
        },
        {
            name: "void",
            test: () => true,
            factory: () => new NullPlayer()
        }
    ].filter((() => {
        let player = null;

        if ("URLSearchParams" in window) {
            const params = new URLSearchParams(window.location.search);

            if (params.has("player")) {
                player = params.get("player");
            }
        }

        return ({name}) => {
            if (name === "void") {
                return true;
            }

            if (player) {
                return player === name;
            }

            return true;
        };
    })());

    private addToContainer(player: Player) {
        if (player) {
            this.container.appendChild(player.getElement());
        }
    }

    public getElement(): HTMLElement {
        this.addToContainer(this.player);

        return this.container;
    }

    private getPlayer(stream: StreamData, prefer: PlayerType = PlayerType.NULL) {
        const oldPlayer = this.player;
        const candidate = this.providers
            .filter(provider => Boolean(provider.disabled) === false)
            .find(provider => provider.test(stream, matcher(prefer)));
        const newPlayer = candidate.instance || candidate.factory();

        this.player = candidate.instance = newPlayer;

        newPlayer[this.muteStateSubject.value ? "mute" : "unmute"]();

        if (oldPlayer !== newPlayer) {
            this.playerChanged(oldPlayer, newPlayer);
        }

        if (this.container.parentNode) {
            this.addToContainer(newPlayer);
        }

        return newPlayer;
    }

    private playerChanged(oldPlayer: Player, newPlayer: Player) {
        oldPlayer?.stop();
        this.clearContainer();
        this.unsubscribe();
        this.subscribe(newPlayer);
    }

    private clearContainer() {
        const ctr = this.container;

        while (ctr.firstChild) {
            ctr.removeChild(ctr.firstChild);
        }
    }

    private subscribe(player: Player) {
        this.playerStateSubscription = player.playState()
            .subscribe(this.playerStateSubject);

        this.muteStateSubscription = player.muteState()
            .subscribe(this.muteStateSubject);
    }

    private unsubscribe() {
        this.playerStateSubscription?.unsubscribe();
        this.muteStateSubscription?.unsubscribe();
    }

    public play(
        streamData: StreamData,
        muted: boolean,
        preferences: Array<PlayerType> = [PlayerType.Auto, PlayerType.HLS]
    ): Promise<void> {
        const type = preferences.shift();
        const player = this.getPlayer(streamData, type);

        this[muted ? "mute" : "unmute"]();

        return player.play(streamData, muted)
            .catch(handleIf(
                [AbortError],
                reason => {
                    player?.stop();

                    return Promise.reject(reason);
                }
            ))

            // fallback to the next preferred player on error
            .catch(handleIf(
                [PlayerError, TimeoutError],
                () => this.play(streamData, muted, preferences)
            ))

            .catch(handleIf(
                [PlayerError, TimeoutError],
                reason => {
                    this.playerStateSubject.next(PlayerState.Error);

                    return Promise.reject(reason);
                }
            ));
    }

    public isPlaying(stream: StreamData): boolean {
        return this.player?.isPlaying(stream) === true;
    }
}
