import {Dialog} from "@material-ui/core";
import {ConnectedRouter} from "connected-react-router";
import React, {Fragment, useMemo} from "react";
import {connect, useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router";
import {hideTopUp} from "../action/topup.action";
import {User} from "../domain/authentication/User";
import {Dialog as DialogType} from "../domain/Dialog";
import {Feature} from "../domain/feature/Feature";
import {Locale} from "../domain/Locale";
import {routeToRoom, routeToRoomList, routeToSignIn} from "../helper";
import {closeDialog} from "../redirect";
import {RootState} from "../reducer";
import {ConsentState} from "../reducer/consent.reducer";
import "./App.scss";
import {EmailValidationScreen} from "./auth/EmailValidationScreen";
import {ForgottenPasswordScreen} from "./auth/ForgottenPasswordScreen";
import {SignInScreen} from "./auth/SignInScreen";
import {SignUpScreen} from "./auth/SignUpScreen";
import {UserHolder} from "./contract/UserHolder";
import {IncentiveDialog} from "./IncentiveDialog";
import {PrivacyScreen} from "./PrivacyScreen";
import {ProgressScreen} from "./ProgressScreen";
import {Room} from "./room/Room";
import {RoomList} from "./room/RoomList";
import {AgeLimitConsentGuardCondition} from "./route/AgeLimitConsentGuardCondition";
import {ConsentGuardCondition} from "./route/ConsentGuardCondition";
import {Guard} from "./route/Guard";
import {SettingsScreen} from "./settings/SettingsScreen";
import {SlideTransition} from "./SlideTransition";
import {TermsScreen} from "./TermsScreen";
import {TopUpScreen} from "./topup/TopUpScreen";
import {UpdateScreen} from "./UpdateScreen";

interface Props extends UserHolder {
    isSignUpEnabled: boolean
    dialog: DialogType
    hideTopUp: () => void
    showTopUp: boolean
    consent: ConsentState
    ready: boolean
    i18n: Locale
    history: any,
    showReloadRequest: boolean
}

const redirectToRoomList = React.memo(() => <Redirect to={routeToRoomList()}/>);
const redirectToRoom = React.memo<any>(props => <Redirect to={routeToRoom(props.match.params.roomId)}/>);
const redirectToSignIn = React.memo(() => <Redirect to={routeToSignIn(routeToRoomList())}/>);
const roomRenderer = props => <Room key={props.match.params.roomId} {...props}/>;
const confirmationRenderer = props =>
    <EmailValidationScreen hash={props.match.params.hash} key={props.match.params.hash}/>;

const mapStateToProps = ({app, topup, auth, consent, i18n, feature, update}) => ({
    isSignUpEnabled: feature[Feature.SignUp],
    dialog: app.dialog,
    showTopUp: topup.showTopUp,
    user: auth.user,
    consent: consent,
    ready: app.ready,
    i18n: i18n,
    showReloadRequest: update.showReloadRequest
});

const mapDispatchToProps = dispatch => ({
    hideTopUp: () => dispatch(hideTopUp())
});

export const App = connect(mapStateToProps, mapDispatchToProps)(
    React.memo<Props>(({dialog, history, ready, consent, showTopUp, hideTopUp, isSignUpEnabled, showReloadRequest}) => {
        const ageLimitConsentGuardCondition = useMemo(() => new AgeLimitConsentGuardCondition(consent.ageLimitAcknowledged), [consent.ageLimitAcknowledged]);
        const consentGuardCondition = useMemo(() => new ConsentGuardCondition(consent.consent), [consent.consent]);
        const conditionA = useMemo(() => [ageLimitConsentGuardCondition], [ageLimitConsentGuardCondition]);
        const conditionB = useMemo(() => [ageLimitConsentGuardCondition, consentGuardCondition], [ageLimitConsentGuardCondition, consentGuardCondition]);
        const user = useSelector<RootState, User>(({auth}) => auth.user);

        if (ready) {
            return <Fragment>
                <ConnectedRouter history={history}>
                    <Switch>

                        {
                            user.isMember === false && isSignUpEnabled &&
                            <Guard conditions={conditionA} exact path="/signup" component={SignUpScreen}/>
                        }

                        {
                            user.isMember === false &&
                            <Guard conditions={conditionA} exact path="/signin" component={SignInScreen}/>
                        }

                        <Route exact path="/forgotten-password" component={ForgottenPasswordScreen}/>

                        <Guard conditions={conditionB} exact path="/rooms" component={RoomList}/>
                        <Guard conditions={conditionB} exact path="/rooms/:roomId" render={roomRenderer}/>

                        {
                            user.isMember &&
                            <Route exact path="/settings" component={SettingsScreen}/>
                        }

                        <Route exact path="/validate_email/:hash" render={confirmationRenderer}/>
                        <Route path="/mypage/:roomId" component={redirectToRoom}/>
                        <Route path="/login" component={redirectToSignIn}/>

                        <Route component={redirectToRoomList}/>
                    </Switch>
                </ConnectedRouter>

                <Dialog open={showTopUp} TransitionComponent={SlideTransition}>
                    <TopUpScreen onClose={hideTopUp}/>
                </Dialog>

                <IncentiveDialog/>

                <Dialog open={dialog === DialogType.TERMS} TransitionComponent={SlideTransition}>
                    <TermsScreen onClose={closeDialog}/>
                </Dialog>

                <Dialog open={dialog === DialogType.PRIVACY} TransitionComponent={SlideTransition}>
                    <PrivacyScreen onClose={closeDialog}/>
                </Dialog>

                <Dialog open={showReloadRequest} TransitionComponent={SlideTransition}>
                    <UpdateScreen/>
                </Dialog>
            </Fragment>;
        }

        return <ProgressScreen/>;
    })
);
