import {RouteProps} from "react-router";
import {AgeRestrictionScreen} from "../AgeRestrictionScreen";
import {GuardCondition} from "./GuardCondition";

export class AgeLimitConsentGuardCondition implements GuardCondition {
    constructor(private ageLimitAcknowledged: boolean) {

    }

    getRouteProps(props): RouteProps {
        return {...props, component: AgeRestrictionScreen};
    }

    test(): boolean {
        return this.ageLimitAcknowledged === false;
    }
}
