import {useCallback, useState} from "react";

export const useDataLoaded = (): {
    dataLoaded: boolean,
    setDataLoaded: () => void,
    setDataNotLoaded: () => void
} => {
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    return {
        dataLoaded,
        setDataLoaded: useCallback(() => setDataLoaded(true), []),
        setDataNotLoaded: useCallback(() => setDataLoaded(false), [])
    };
};
