import {Box, Dialog, Typography} from "@material-ui/core";
import React, {useCallback} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {rejectInvitation, requestChatMode} from "../../action/chat.action";
import {Invitation} from "../../domain/Invitation";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {RoomMode} from "../../domain/RoomMode";
import {Closable} from "../contract/Closable";
import {CountdownButton} from "../CountdownButton";
import {Viewport} from "../layout/Viewport";
import {SlideTransition} from "../SlideTransition";
import {CircleProfilePicture} from "./CircleProfilePicture";
import {ExitFab} from "./ExitFab";

interface InviteDialogProps extends Closable {
    open: boolean
    room: RoomDto
    invitation: Invitation
    onAccept: (roomId: RoomId, roomMode: RoomMode) => void
}

const mapStateToProps = ({chat, invitation}) => ({
    room: chat.room,
    open: invitation.active,
    invitation: invitation.invitation
});

const mapDispatchToProps = dispatch => ({
    onAccept: (roomId: RoomId, roomMode: RoomMode) => dispatch(requestChatMode(roomId, roomMode)),
    onClose: () => dispatch(rejectInvitation())
});

export const InvitationDialog = connect(mapStateToProps, mapDispatchToProps)(React.memo<InviteDialogProps>(({open, onAccept, onClose, room, invitation}) => {
    const {screenName} = room;
    const {roomId, roomMode, message} = invitation || {};
    const onAcceptCallback = useCallback(() => onAccept(roomId, roomMode), [onAccept, roomId, roomMode]);
    const disabled = open === false;

    return (
        <Dialog open={open} TransitionComponent={SlideTransition}>
            {
                invitation &&
                <Viewport>
                    <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent={"center"}>
                        <CircleProfilePicture
                            src={room?.publicData?.profilePic}
                            title={intl.getHTML("invitation.to.".concat(roomMode), {screenName})}/>

                        {
                            message &&
                            <Box textAlign="center" margin={4}>
                                <Typography>{message}</Typography>
                            </Box>
                        }
                    </Box>

                    {
                        <CountdownButton disabled={disabled} timeout={20} label={intl.get("invitation.btn.accept")} onTimeIsUp={onClose} onClick={onAcceptCallback}/>
                    }

                    <ExitFab disabled={disabled} onClick={onClose}/>
                </Viewport>
            }
        </Dialog>
    );
}));
