export enum FeatureDisabledReason {
    Disabled = "disabled",
    UserIsNotMember = "guestNotAllowed",
    ViewerIsVoyeur = "voyeurNotAllowed",
    RoomModeIsGroupOrPrivate = "roomModeIsGroupOrPrivate",
    RoomModeIsNotGroupOrPrivate = "roomModeIsNotGroupOrPrivate",
    RoomModeIsNotPrivate = "roomModeIsNotPrivate",
    RoomCategoryIsNotCouple = "categoryIsNotCouple",
    Other = "other"
}
