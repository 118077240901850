import Fab, {FabClassKey} from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback, useMemo} from "react";
import intl from "react-intl-universal";
import {useDispatch} from "react-redux";
import {requestChatMode} from "../../action/chat.action";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {RoomMode} from "../../domain/RoomMode";
import {transitions} from "../../domain/RoomModeTransitionMap";
import {clsx} from "../../helper";
import {AlternateTwoElements} from "../AlternateTwoElements";

interface Props {
    labelClass: string
    room: RoomDto
}

function createStyles(amount: number) {
    const sampleStyle = {
        right: "5px",
        position: "absolute",
    };

    const styles = {
        root: {
            width: "unset",
        },
    };

    for (let i = 0; i < amount; i++) {
        styles[`btnRoomMode${i}`] = {
            ...sampleStyle,
            bottom: `${5 + i * 45}px`,
        };
    }

    return makeStyles(styles)();
}

interface ModeButtonProps {
    classes: { [key in FabClassKey]?: string }
    className: string
    roomMode: RoomMode
    roomId: RoomId
    text: string
}

const ModeButton = React.memo<ModeButtonProps>(({classes, className, roomMode, roomId, text}) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(
        () => dispatch(requestChatMode(roomId, roomMode)),
        [dispatch, roomId, roomMode],
    );

    return <Fab classes={classes} className={className} color="secondary" onClick={handleClick}>{text}</Fab>;
});

export const RoomModeButtons = React.memo<Props>(({room, labelClass}) => {
    const roomModes = (transitions[room.roomMode] as Array<RoomMode>).filter(roomMode => room.allowedModes.includes(roomMode));
    const classes = createStyles(roomModes.length);
    const fabClasses = useMemo(() => ({label: labelClass, root: classes.root}), [labelClass, classes.root]);

    return (
        <React.Fragment>
            {
                [...roomModes].reverse().map((roomMode, index) => {
                    const className = clsx("right-on-idle", classes[`btnRoomMode${index}`]);

                    return (
                        <AlternateTwoElements
                            key={roomMode}

                            firstElement={
                                <ModeButton
                                    roomId={room.roomid}
                                    roomMode={roomMode}
                                    classes={fabClasses}
                                    className={className}
                                    text={intl.get("chat.enter.".concat(roomMode))}
                                />
                            }

                            secondElement={
                                <ModeButton
                                    roomId={room.roomid}
                                    roomMode={roomMode}
                                    classes={fabClasses}
                                    className={className}
                                    text={intl.get("chat.price", {price: room.pricing[roomMode]})}
                                />
                            }
                        />
                    );
                })
            }
        </React.Fragment>
    );
});
