import {CircularProgress, Fab} from "@material-ui/core";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {turnOff, turnOn} from "../../../action/camera.action";
import {RootState} from "../../../reducer";
import {CameraIcon} from "./CameraIcon";

interface Cam2CamButtonProps {
    className?: string
}

export const CameraButton = React.memo<Cam2CamButtonProps>(({className}) => {
    const inProgress = useSelector<RootState, boolean>(({camera}) => camera.progress);
    const isActive = useSelector<RootState, boolean>(({camera}) => camera.active);
    const dispatch = useDispatch();
    const dispatchAction = useCallback(() => dispatch(isActive ? turnOff() : turnOn()), [dispatch, isActive]);

    return (
        <Fab color="secondary"
             className={className}
             disabled={inProgress}
             onClick={dispatchAction}>

            {
                inProgress
                    ? <CircularProgress size={16}/>
                    : <CameraIcon active={isActive}/>
            }

        </Fab>
    );
});
