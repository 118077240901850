export const config = {
    playback: {
        autoplay: true, // set on purpose, play method throws error
        automute: false, // set on purpose, when true disables manual mute/unmute
        muted: true,
        metadata: false,
        forceTech: "h5live",
        keepConnection: false
    },
    style: {
        controls: false,
        interactive: false,
        view: false,
        scaling: "fill",
        keepFrame: true,
        width: "100%",
        height: "100%"
    },
    tweaks: {
        buffer: {
            min: 0.2,
            start: 0.5,
            max: 8.0,
            target: 1.2,
            limit: 1.7
        }
    }
};
