import {baseUrl} from "./application";

export const config = {
    fetchUser: baseUrl.concat("/ajax/api/user"),
    signUp: baseUrl,
    signIn: baseUrl,
    signOut: baseUrl.concat("/logout?ajax"),
    reminder: baseUrl,
    validateHash: hash => baseUrl.concat("/validate_email/").concat(hash)
};
