import {config} from "../config/consent";
import {Consent} from "../domain/Consent";
import {Http} from "./http";
import {LocalStorage} from "./LocalStorage";
import {Recaptcha} from "./Recaptcha";

export enum ConsentFailedReason {
    NotSatisfied = "notSatisfied",
    Technical = "technical",
    AccessDenied = "accessDenied",
    NotAllowed = "notAllowed",
    Unknown = "unknown"
}

export class ConsentService {
    constructor(private http: Http, private storage: LocalStorage) {
    }

    getAck(): Promise<boolean> {
        return Promise.resolve(this.storage.getItem(config.ageLimitAck.key) === config.ageLimitAck.value);
    }

    setAck(): Promise<void> {
        this.storage.setItem(config.ageLimitAck.key, config.ageLimitAck.value);

        return Promise.resolve();
    }

    getConsents(): Promise<Consent> {
        return this.http.fetch(config.getConsents)
            .then(response => {
                if (response.missing && response.active) {
                    return response;
                } else {
                    return {
                        missing: [],
                        active: []
                    };
                }
            });
    }

    setConsents(consents: Array<string>): Promise<Consent> {
        return Recaptcha.getToken()
            .catch(() => Promise.reject({
                success: false,
                reason: ConsentFailedReason.Technical
            }))
            .then(token => {
                const body = new FormData();

                consents.forEach(consent => body.append("dataStreams[]", consent));

                body.set("token", token);

                return {
                    body
                };
            })
            .then((requestInit: RequestInit) => this.http.post(config.setConsents, requestInit))
            .then((response: any) => {
                if (response.success === true) {
                    return Promise.resolve({
                        missing: [],
                        active: consents
                    });
                } else {
                    const error: any = {
                        type: response.reason
                    };

                    switch (response.reason) {
                        case ConsentFailedReason.AccessDenied:
                        case ConsentFailedReason.NotAllowed: {
                            break;
                        }

                        case ConsentFailedReason.NotSatisfied: {
                            error.consent = {
                                missing: response.missing,
                                active: []
                            };

                            break;
                        }

                        case ConsentFailedReason.Technical: {
                            error.message = response.e || null;

                            break;
                        }

                        default:
                            error.type = ConsentFailedReason.Unknown;
                    }

                    return Promise.reject(error);
                }
            });
    }
}
