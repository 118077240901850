import {Box, Typography} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {I18n} from "../../domain/I18n";
import {routeToRoomList} from "../../helper";
import {redirectToRoomList, redirectToSignIn} from "../../redirect";
import {Body} from "../layout/Body";
import {Header} from "../layout/Header";
import {Toolbar} from "../layout/Toolbar";
import {Viewport} from "../layout/Viewport";
import {LinkInline} from "../LinkInline";
import {SignUpForm} from "./SignUpForm";

export const SignUpScreen = React.memo(() => {
    return (
        <Viewport color="secondary">
            <Header onClose={redirectToRoomList} title={intl.get(I18n.SignUpTitle)}/>
            <Body>
                <SignUpForm/>
            </Body>
            <Toolbar>
                <Box flex={1} marginY={1} textAlign="center">
                    <Typography component="div" variant="body2"><span>{intl.get(I18n.SignUpAlreadySignedUp)} </span>
                        <LinkInline onClick={() => redirectToSignIn(routeToRoomList())} title={intl.get(I18n.SignUpSignInLink)}/></Typography>
                </Box>
            </Toolbar>
        </Viewport>
    );
});
