import {Scene} from "./Scene";
import {BaseScene} from "./BaseScene";

export const GiftScene: Scene = {
    ...BaseScene,
    modeBar: false,
    messages: false,
    input: false,
    gift: true
};
