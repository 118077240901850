import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Player, PlayerState} from "../domain/Player";
import {PlayerError} from "../domain/PlayerError";
import {StreamData} from "../domain/StreamData";
import {MuteStateHandler} from "./MuteStateHandler";
import {PlayerStateHandler} from "./PlayerStateHandler";

export abstract class BasePlayer implements Player {

    protected video: HTMLVideoElement = BasePlayer.createVideoElement();
    protected playerStateSubject: Subject<PlayerState> = new BehaviorSubject(PlayerState.Stopped);
    protected muteStateSubject: Subject<boolean> = new BehaviorSubject(true);
    protected stream: StreamData = null;
    private muteStateHandler = new MuteStateHandler(this.video, this.muteStateSubject);
    private playerStateHandler = new PlayerStateHandler(this.video, this.playerStateSubject);

    private static createVideoElement(): HTMLVideoElement {
        const video = document.createElement("video") as HTMLVideoElement;

        video.muted = true;
        video.setAttribute("playsinline", "");

        return video;
    }

    public getElement(): HTMLElement {
        return this.video;
    }

    public play(stream: StreamData, muted: boolean): Promise<void> {
        const video = this.video;

        this.storeStream(stream);

        video.muted = muted;

        return video.play()?.catch(error => {

            // try to play again muted
            if (video.muted === false) {
                this.mute();

                return video.play();
            }

            return Promise.reject(error);
        })?.catch(error => {
            this.stop();

            return Promise.reject(new PlayerError("BasePlayer failed to play due to ".concat(error.name)));
        });
    }

    public stop(): void {
        this.clearStream();
        this.video.pause();
        this.video.removeAttribute("src");
        this.video.srcObject = null;
        this.video.load();
    }

    public destroy(): void {
        this.muteStateHandler?.destroy();
        this.muteStateHandler = null;

        this.playerStateHandler?.destroy();
        this.playerStateHandler = null;

        this.stop();

        this.video.remove();
        this.video = null;
    }

    public playState(): Observable<PlayerState> {
        return this.playerStateSubject;
    }

    public mute(): void {
        this.video.muted = true;
    }

    public muteState(): Observable<boolean> {
        return this.muteStateSubject;
    }

    public unmute(): void {
        this.video.muted = false;
    }

    abstract isPlaying(stream: StreamData): boolean

    protected storeStream(stream: StreamData): void {
        this.stream = stream;
    }

    protected clearStream(): void {
        this.stream = null;
    }
}
