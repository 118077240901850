import {createGenerateClassName, createMuiTheme} from "@material-ui/core";
import {isDevelopment} from "./environment";

export const generateClassName = createGenerateClassName({
    productionPrefix: "s",
    disableGlobal: isDevelopment === false
});

export const baseColor = "#21ACE5";

export const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 360,
            md: 600,
            lg: 960,
            xl: 1280
        }
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                a: {
                    color: "#009CDE",
                    textDecoration: "none"
                }
            }
        },
        MuiGridListTile: {
            root: {
                marginBottom: 1
            }
        },
        MuiFormLabel: {
            root: {
                color: "inherit",
                paddingBottom: 8
            }
        },
        MuiTextField: {
            root: {
                marginBottom: 16
            }
        },
        MuiIconButton: {
            root: {
                color: "#fff",
                borderRadius: 0
            }
        },
        MuiTypography: {
            h5: {
                marginBottom: 8
            },
            body1: {
                color: "#fff"
            },
            caption: {
                fontSize: "1rem",
                fontWeight: "normal"
            }
        },
        MuiInputBase: {
            root: {
                "&$disabled": {
                    color: "#787878"
                },
                color: "inherit",
                backgroundColor: "inherit",
                border: 0
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "#000",
                color: "#fff"
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                borderRadius: 0
            },
            label: {
                color: "#fff"
            },
            contained: {
                boxShadow: "none !important",
                borderRadius: 3
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiMenuItem: {
            root: {
                minHeight: "unset",
                color: "#fff"
            }
        },
        MuiTabs: {
            root: {
                backgroundColor: "#000",
                boxShadow: "0 0 5px #000"
            },
            scrollButtons: {
                color: "#2A3337"
            }
        },
        MuiOutlinedInput: {
            root: {
                "&$disabled $notchedOutline": {
                    borderColor: "#787878"
                },
                "&:hover $notchedOutline": {
                    borderColor: "#787878"
                }
            },
            notchedOutline: {
                borderColor: "#787878",
                borderRadius: 0
            }
        },
        MuiCheckbox: {
            root: {
                "&$disabled": {
                    color: "#787878"
                },
                color: "inherit"
            },
            colorPrimary: {
                "&$disabled": {
                    color: "#787878"
                }
            }
        },
        MuiInputLabel: {
            root: {
                "&$disabled": {
                    color: "#787878"
                },
                color: "#787878"
            }
        },
        MuiFormControlLabel: {
            label: {
                "&$disabled": {
                    color: "#787878"
                },
                color: "inherit"
            }
        },
        MuiFab: {
            root: {
                borderRadius: 3
            },
            secondary: {
                "&$disabled": {
                    backgroundColor: "rgba(0,0,0,0.8)"
                },
                "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.8)"
                },
                backgroundColor: "rgba(0,0,0,0.8)"
            }
        },
        MuiButtonBase: {
            root: {
                "&$disabled": {
                    pointerEvents: "auto"
                }
            }
        },
        MuiAccordion: {
            root: {
                backgroundColor: "#fff",
                "&$expanded": {
                    margin: 0,
                    "&:before": {
                        opacity: 1
                    }
                }
            }
        },
        MuiAccordionSummary: {
            content: {
                color: "#000",
                fontSize: "1rem"
            },
            expandIcon: {
                color: "#000"
            },
            root: {
                minHeight: 48,
                height: 48,
                "&$expanded": {
                    minHeight: 48,
                    height: 48
                }
            }
        },
        MuiAccordionDetails: {
            root: {
                color: "#000",
                padding: 0
            }
        }
    },
    props: {
        MuiLink: {
            underline: "none"
        },
        MuiFab: {
            size: "small",
            disableFocusRipple: true
        },
        MuiDialog: {
            transitionDuration: 250,
            fullScreen: true,
            disableEscapeKeyDown: true,
            disableBackdropClick: true,
            hideBackdrop: true
        },
        MuiDialogTitle: {
            disableTypography: true
        },
        MuiButtonBase: {
            disableTouchRipple: true,
            disableRipple: true
        },
        MuiIconButton: {
            disableFocusRipple: true
        },
        MuiButton: {
            color: "primary",
            variant: "contained",
            disableFocusRipple: true
        },
        MuiInput: {
            margin: "dense",
            disableUnderline: true
        },
        MuiTabs: {
            indicatorColor: "primary"
        },
        MuiPaper: {
            elevation: 0,
            square: true
        },
        MuiTextField: {
            margin: "dense",
            variant: "outlined"
        }
    },
    palette: {
        action: {
            disabledBackground: baseColor
        },
        background: {
            default: "#000"
        },
        primary: {
            main: baseColor,
            contrastText: "#fff"
        },
        secondary: {
            main: "#000",
            contrastText: "#fff"
        },
        type: "light"
    }
});
