import {AuthActionType} from "../action/auth.action";
import {User} from "../domain/authentication/User";
import intl from "react-intl-universal";
import {config} from "../config/autopay";
import {config as chatConfig} from "../config/chat";
import {Http} from "../service/http";
import {ChatActionType, runningOutOfCredit} from "../action/chat.action";
import {ChatState} from "../reducer/chat.reducer";
import {RoomMode} from "../domain/RoomMode";
import {showSnackbar} from "../action/snackbar.action";

export const balanceMiddleware = (http: Http) => store => {
    const autopay = (credit: number) => store.dispatch(showSnackbar({
        id: "autopay",
        label: intl.get("payment.autopay.success.snack", {credit}),
        timeout: 5000
    }));

    let lastId = 0;
    let timer: any = 0;

    const start = () => {
        lastId = 0;
        stop();

        timer = setInterval(() => {
            const requestInit: RequestInit = {
                body: "lastid=".concat(String(lastId))
            };

            http.fetch(config.autopay, requestInit)
                .then(response => {
                    if (response.success && response.item) {
                        lastId = response.item.id;

                        if (response.item.status === "successful") {
                            autopay(response.item.credit);
                        }
                    }
                }).catch(() => null);
        }, 60000);
    };

    const stop = () => {
        clearInterval(timer);
    };

    return next => action => {
        const {type} = action;

        switch (type) {
            case AuthActionType.UserSet: {
                const user: User = action.user;

                if (user && user.isMember) {
                    start();
                } else {
                    stop();
                }

                break;
            }
            case ChatActionType.CreditChanged: {
                const {event: {credit}} = action;
                const chat = store.getState().chat as ChatState;
                const {room} = chat

                if (room && room.roomMode !== RoomMode.Free) {
                    const price = room.pricing[room.roomMode];
                    const beforeMinute = chatConfig.beforeMsRunningOutOfCredit/60000;
                    const minutesLeft = Math.trunc(credit/price);
                    if (minutesLeft <= beforeMinute && minutesLeft > 0) {
                        store.dispatch(runningOutOfCredit(beforeMinute))
                    }
                }

                break;
            }
        }
        return next(action);
    };
};
