import {push} from "connected-react-router";
import intl from "react-intl-universal";
import {turnOn} from "../../action/camera.action";
import {openGiftPanel} from "../../action/chat.action";
import {showSnackbar} from "../../action/snackbar.action";
import {showTopUp} from "../../action/topup.action";
import {Gift} from "../../domain/Gift";
import {Price} from "../../domain/Price";
import {routeToSignIn} from "../../helper";

export const guestNotAllowed = (next?: string) => showSnackbar({
    id: "guest-not-allowed",
    label: intl.get("snack.guest-not-allowed.label"),
    button: {
        label: intl.get("snack.guest-not-allowed.button"),
        action: push(routeToSignIn(next))
    }
});

export const promotedToPrivateOwner = (username: string) => showSnackbar({
    id: "promoted-to-private-owner",
    label: intl.get("snack.promoted-to-private-owner", {username})
});

export const confirmGift = (gift: Gift, price: Price) => showSnackbar({
    id: "gift-success",
    label: intl.get("snack.gift-success", {
        gift: gift.name || gift.id,
        price
    })
});

export const outOfCredit = () => showSnackbar({
    id: "out-of-credit",
    label: intl.get("snack.out-of-credit.label"),
    button: {
        label: intl.get("snack.out-of-credit.button"),
        action: showTopUp()
    }
});

export const technicalError = () => showSnackbar({
    id: "technical-error",
    label: intl.get("snack.technical-error")
});

export const voyeurNotAllowed = () => showSnackbar({
    id: "voyeur-not-allowed",
    label: intl.get("snack.voyeur-not-allowed")
});

export const onlyWithCouple = () => showSnackbar({
    id: "only-with-couple",
    label: intl.get("snack.only-with-couple")
});

export const onlyInPrivate = () => showSnackbar({
    id: "only-in-private",
    label: intl.get("snack.only-in-private")
});

export const notAllowedInFreeShow = () => showSnackbar({
    id: "not-available-in-free-show",
    label: intl.get("snack.not-available-in-free-show")
});

export const featureIsNotAvailable = () => showSnackbar({
    id: "feature-not-available",
    label: intl.get("snack.feature-not-available")
});

export const cameraError = () => showSnackbar({
    id: "camera.error.technical",
    label: intl.get("snack.camera.error.technical.label"),
    button: {
        label: intl.get("snack.camera.error.technical.button"),
        action: turnOn()
    }
});

export const cameraBlocked = () => showSnackbar({
    id: "camera.error.blocked",
    label: intl.get("snack.camera.error.blocked.label")
});

export const cameraNotSupported = () => showSnackbar({
    id: "camera.error.not-supported",
    label: intl.get("snack.camera.error.not-supported.label")
});

export const offerKnockout = (screenName: string) => showSnackbar({
    id: "offer-knockout",
    label: intl.get("snack.offer-knockout.label", {
        screenName
    }),
    button: {
        label: intl.get("snack.offer-knockout.button"),
        action: openGiftPanel("ohmibod_knockout")
    },
    timeout: 30000
});

export const runningOutOfCredit = (minutesLeft: number) => showSnackbar({
    id: "running-out-of-credit",
    label: intl.get("snack.running-out-of-credit.label", {
        minutesLeft
    }),
    button: {
        label: intl.get("snack.running-out-of-credit.button"),
        action: showTopUp()
    },
    timeout: 30000
});

export const notifyPushPromo = (credits: number, price: string) => showSnackbar({
    id: "push-promo",
    label: intl.get("snack.push-promo.label", {
        credits,
        price
    }),
    button: {
        label: intl.get("snack.push-promo.button"),
        action: showTopUp()
    },
    timeout: 30000
});

export const showRelease = () => showSnackbar({
    id: "show-release",
    label: process.env.REACT_APP_RELEASE,
    timeout: 10000
});
