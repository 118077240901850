import {AbortError} from "../../domain/AbortError";

interface RTCStat {
    type: string
    kind: string
    mediaType: string
    framesDecoded: number
    bytesReceived: number
}

const isConditionApply = (report: RTCStat) => {
    if (report.type === "inbound-rtp" && (report.kind === "video" || report.mediaType === "video")) {
        return report.framesDecoded > 0 || report.bytesReceived > 0;
    }

    return false;
};

const toData = ([, data]) => data as RTCStat;

export class FrameDetector {
    private timer;
    private abort: AbortController = new AbortController();

    public stop() {
        clearTimeout(this.timer);
        this.abort.abort();
    }

    public detect(rtcPeerConnection: RTCPeerConnection): Promise<void> {
        let retryCount = 40;
        const retryFrequency = 250;

        return new Promise<void>((resolve, reject) => {
            this.abort.signal.addEventListener("abort", () => reject(new AbortError()));

            const checkFrame = () => {
                if (this.abort.signal.aborted) {
                    return;
                }

                rtcPeerConnection.getStats(null)
                    .then(stats => Array.from(stats).map(toData))
                    .then(stats => stats.some(isConditionApply))
                    .then(detected => {
                        if (this.abort.signal.aborted) {
                            return;
                        }

                        if (detected) {
                            resolve();
                        } else {
                            if (retryCount === 0) {
                                reject();
                            } else {
                                retryCount--;
                                this.timer = setTimeout(checkFrame, retryFrequency);
                            }
                        }
                    }).catch(reject);
            };

            this.timer = setTimeout(checkFrame, 0);
        });
    }
}
