import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {showRelease} from "../action/application.action";

interface Props {
    subTitle?: string
}

export const Logo = React.memo<Props>(({subTitle}) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(() => dispatch(showRelease()), [dispatch]);

    return (
        <svg onDoubleClick={handleClick} display="block" width="137" height="31" viewBox="0 0 137 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.0651 6.15845L24.8563 4.34659C23.5108 4.15031 21.8931 4.67877 20.986 5.67529L20.6383 6.05276L22.7397 7.00399C23.284 6.56612 24.1457 6.29434 24.7958 6.40004L36.3309 8.1968C37.0868 8.31759 37.4043 8.96684 37.0415 9.63119L30.5104 21.514C30.1476 22.1783 29.2556 22.5105 28.53 22.2387L15.3923 17.3316C14.6818 17.0598 14.5004 16.3653 14.9993 15.7915L18.8997 11.3223L16.6471 10.4767L10.7208 17.1051C9.81376 18.1167 10.0859 19.4001 11.3256 19.9437L23.6771 25.5303L23.9038 30.1052L26.9879 27.0402L29.7545 28.2934C30.9791 28.852 32.4456 28.2934 33.0049 27.0704L41.2745 8.87625C41.8793 7.68344 41.3955 6.35474 40.0651 6.15845Z" fill="white"/>
            <path d="M30.1779 10.3861L11.7489 2.05155C10.5092 1.4929 9.05788 2.05155 8.49851 3.27456L0.228902 21.4687C-0.330468 22.6917 0.153312 24.0355 1.48371 24.2318L12.656 25.5605L17.4031 30.1656L17.01 26.0587C18.2799 26.1342 19.7313 25.6208 20.5628 24.6998L20.9105 24.3223L18.809 23.3711C18.2648 23.809 17.4031 24.0808 16.753 23.9751L5.21788 22.1783C4.46197 22.0575 4.14449 21.4083 4.50733 20.7439L11.0383 8.86113C11.4012 8.19678 12.2931 7.86461 13.0188 8.13639L26.1564 13.0435C26.867 13.3153 27.0484 14.0098 26.5495 14.5836L22.6188 19.0076L24.8714 19.8531L30.7977 13.2247C31.6897 12.2131 31.4175 10.9448 30.1779 10.3861Z" fill="#21ACE5"/>
            <path d="M56.7307 8.51423C55.8633 7.73706 54.8783 7.46859 53.7169 7.46859C52.2909 7.46859 51.497 7.89249 51.497 8.62727C51.497 9.3903 52.2174 9.81421 53.7757 9.91312C56.0692 10.0544 58.98 10.549 58.98 13.6576C58.98 15.7207 57.2306 17.5011 53.7463 17.5011C51.8205 17.5011 49.8946 17.1902 48.1157 15.4098L49.6006 13.3468C50.4679 14.2794 52.4379 14.9576 53.8051 14.9859C54.9372 15.0142 56.0104 14.4348 56.0104 13.587C56.0104 12.7816 55.3194 12.4424 53.5846 12.3577C51.2912 12.1881 48.5421 11.3827 48.5421 8.72618C48.5421 6.01317 51.4529 5.06644 53.6728 5.06644C55.5693 5.06644 57.01 5.41969 58.4214 6.60664L56.7307 8.51423Z" fill="#21ACE5"/>
            <path d="M63.4786 12.2853C63.6697 13.7266 64.9782 14.7722 67.1099 14.7722C68.2272 14.7722 69.6826 14.3625 70.3736 13.6842L72.2994 15.507C71.0204 16.7929 68.9181 17.4005 67.0658 17.4005C62.8612 17.4005 60.3766 14.9135 60.3766 11.1549C60.3766 7.59405 62.8906 5.03647 66.8452 5.03647C70.9175 5.03647 73.4609 7.45275 72.9904 12.2712H63.4786V12.2853ZM70.0942 9.89729C69.9031 8.37121 68.6682 7.62231 66.9334 7.62231C65.3016 7.62231 63.9638 8.38535 63.5227 9.89729H70.0942Z" fill="#21ACE5"/>
            <path d="M82.8256 5.40387H86.4274V5.50278L82.4287 11.1832L86.6774 17.0472V17.1179H83.0755L80.4293 13.2038L77.783 17.1179H74.1812V17.0472L78.4299 11.1832L74.4164 5.50278V5.40387H78.0183L80.4146 9.37448L82.8256 5.40387Z" fill="#21ACE5"/>
            <path d="M98.6443 15.6201C97.233 16.9484 95.7481 17.4712 93.9546 17.4712C90.4262 17.4712 87.486 15.4364 87.486 11.2821C87.486 7.12781 90.4262 5.09305 93.9546 5.09305C95.6893 5.09305 97.0124 5.57348 98.3503 6.83107L96.4538 8.75279C95.7334 8.13106 94.8513 7.82019 94.0134 7.82019C91.9846 7.82019 90.4997 9.24735 90.4997 11.2821C90.4997 13.5147 92.0875 14.7016 93.9546 14.7016C94.9249 14.7016 95.8804 14.4473 96.6155 13.7267L98.6443 15.6201Z" fill="white"/>
            <path d="M102.996 0.500671V7.0006C104.084 5.64409 105.422 5.24845 106.803 5.24845C110.258 5.24845 111.787 7.50929 111.787 10.9429V17.1179H108.773V10.9712C108.773 8.83754 107.612 7.9332 106.01 7.9332C104.231 7.9332 102.996 9.37449 102.996 11.1408V17.1179H99.9821V0.500671H102.996Z" fill="white"/>
            <path d="M123.989 5.40389H126.886V17.1179H124.048L123.901 15.4081C123.21 16.7788 121.314 17.4429 119.946 17.4711C116.345 17.4994 113.669 15.3516 113.669 11.2538C113.669 7.21256 116.462 5.10715 120.02 5.13541C121.652 5.13541 123.21 5.87019 123.901 7.02887L123.989 5.40389ZM116.683 11.2538C116.683 13.4864 118.285 14.8147 120.285 14.8147C125.033 14.8147 125.033 7.72125 120.285 7.72125C118.285 7.70712 116.683 9.02124 116.683 11.2538Z" fill="white"/>
            <path d="M133.148 2.11154V5.43216H136.5V7.91909H133.119V12.9777C133.119 14.094 133.766 14.6451 134.707 14.6451C135.177 14.6451 135.721 14.5038 136.162 14.2918L137 16.7646C136.133 17.1038 135.412 17.2451 134.501 17.2592C131.855 17.3581 130.135 15.9027 130.135 12.9919V7.93322H127.856V5.44629H130.135V2.42241L133.148 2.11154Z" fill="white"/>

            {
                subTitle && <text fill="#ffc022" fontSize="10" x="48" y="30">{subTitle}</text>
            }
        </svg>
    );
});
