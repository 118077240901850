import {Fab} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {User} from "../../domain/authentication/User";
import {clsx} from "../../helper";
import {redirectToSignIn} from "../../redirect";
import {RootState} from "../../reducer";
import "./SignInButton.scss";

interface Props {
    size?: "small" | "large"
    className?: string
    next?: string
}

const useStyles = makeStyles({
    root: {
        position: "fixed",
        top: "calc(100% - 48px)",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "&:hover": {
            backgroundColor: "#21ACE5"
        },
        backgroundColor: "#21ACE5",
        color: "#ffffff",
        animationName: "pulsate",
        animationDuration: "1s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        borderRadius: "50%"
    }
});

export const SignInButton = React.memo<Props>(({size, className, next}) => {
    const classes = useStyles();
    const user = useSelector<RootState, User>(({auth}) => auth.user);
    const handleClick = useCallback(() => redirectToSignIn(next), [next]);

    return user && user.isMember
        ? null
        : <Fab size={size || "large"} className={clsx(classes.root, className)} onClick={handleClick}><LockIcon/></Fab>;
});
