import {AggregateDeviceInfo} from "../domain/c2c/AggregateDeviceInfo";
import {Setup} from "../domain/c2c/Setup";
import {CameraErrorReason} from "../domain/c2c/CameraErrorReason";
import {CameraMediaStream} from "./CameraMediaStream";

const transformError = (askTime: number) => (error: DOMException) => {
    const responseTime = Date.now() - askTime;

    switch (error.name) {
        case "NotAllowedError": {
            if (responseTime < 1000) {
                return Promise.reject(CameraErrorReason.AlreadyBlocked);
            }

            return Promise.reject(CameraErrorReason.Blocked);
        }

        case "SecurityError":
            return Promise.reject(CameraErrorReason.Blocked);

        case "NotFoundError": // no media track of specified type in constraints
            return Promise.reject(CameraErrorReason.NotSupported);

        case "AbortError": // ???, don't know why, but blew up
        case "NotReadableError": // HW error
        case "OverconstrainedError": // constraints asked for cannot be met by device
        case "TypeError": // empty constraints or insecure context
        default:
            return Promise.reject(CameraErrorReason.Other);
    }
};

const transfromDeviceInfo = (devices: Array<MediaDeviceInfo>): AggregateDeviceInfo => {
    const output: AggregateDeviceInfo = {
        audio: [],
        video: []
    };

    return devices.reduce((output, device) => {
        const {audio, video} = output;
        const {kind, deviceId, label} = device;

        switch (kind) {
            case "audioinput": {
                audio.push({
                    id: deviceId,
                    name: label || `Microphone #${audio.length + 1}`
                });

                break;
            }

            case "videoinput": {
                video.push({
                    id: deviceId,
                    name: label || `Camera #${video.length + 1}`
                });
            }
        }

        return output;
    }, output);
};

const transformSetupToConstraints = (setup: Setup): MediaStreamConstraints => {
    if (setup) {
        return {
            audio: {
                deviceId: {
                    exact: setup.audio
                }
            },
            video: {
                deviceId: {
                    exact: setup.video
                }
            }
        };
    }

    return {
        audio: true,
        video: true
    };
};

export class MediaSource {
    public enumerateDevices(): Promise<AggregateDeviceInfo> {
        return window.navigator.mediaDevices.enumerateDevices()
            .then(transfromDeviceInfo);
    }

    public getMediaStream(setup: Setup): Promise<CameraMediaStream> {
        if (Boolean(window?.navigator?.mediaDevices?.getUserMedia) === false) {
            return Promise.reject(CameraErrorReason.NotSupported);
        }

        return window.navigator.mediaDevices.getUserMedia(transformSetupToConstraints(setup))
            .then(ms => new CameraMediaStream(ms))
            .catch(transformError(Date.now()));
    }
}
