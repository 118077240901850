import React from "react";
import {Route} from "react-router";
import {empty} from "../../helper";
import {GuardCondition} from "./GuardCondition";

interface Props {
    conditions: Array<GuardCondition>
    path: string

    [arbitrary: string]: any
}

export const Guard = React.memo<Props>(({conditions = empty, path, ...rest}) => {
    const match = conditions.find(condition => condition.test());

    return match
        ? <Route {...match.getRouteProps({path, ...rest})}/>
        : <Route {...rest} path={path}/>;
});
