import {DataURI} from "../domain/DataURI";

export class VideoCapturer {
    private format = "image/jpeg";

    constructor(private video: HTMLVideoElement) {
    }

    public capture(): Promise<DataURI>
    public capture(quality: number): Promise<DataURI>
    public capture(quality: number, width: number, height: number): Promise<DataURI>
    public capture(quality: number = 0.75, width: number = null, height: number = null): Promise<DataURI> {
        return new Promise<DataURI>(resolve => {
            const {video} = this;
            const canvas = window.document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            width = canvas.width = width || video.videoWidth;
            height = canvas.height = height || video.videoHeight;

            ctx.drawImage(video, 0, 0, width, height);

            resolve(canvas.toDataURL(this.format, quality));
        });
    }
}
