import {BehaviorSubject, Observable, Subject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {config} from "../config/topup";
import {ExtraCredit} from "../domain/ExtraCredit";
import {Credit} from "../domain/topup/Credit";
import {TopUpProvider} from "../domain/topup/TopUpProvider";
import {TopUpService} from "../domain/topup/TopUpService";
import {TopUpType} from "../domain/topup/TopUpType";
import {Http} from "./http";
import {PremiumRateCallProvider} from "./topup/PremiumRateCallProvider";
import {PremiumRateTextProvider} from "./topup/PremiumRateTextProvider";
import {SecurionQuickbuyProvider} from "./topup/SecurionQuickbuyProvider";
import {SecurionTopUpProvider} from "./topup/SecurionTopUpProvider";
import {PushPromo} from "../domain/PushPromo";
import {AuthenticationService} from "./AuthenticationService";
import {User} from "../domain/authentication/User";
import {PromoType} from "../domain/PromoType";

export class MonolithTopUpService implements TopUpService {
    private providers: Map<TopUpType, TopUpProvider> = new Map();

    private happyHourSubject: Subject<ExtraCredit> = new BehaviorSubject<ExtraCredit>(null);

    private pushPromoSubject: BehaviorSubject<PushPromo> = new BehaviorSubject<PushPromo>(null);

    private pushPromoPollInterval;

    constructor(private http: Http, private authenticationService: AuthenticationService) {
        authenticationService.user.subscribe((user: User) => {
            if (user?.isMember) {
                this.startPushPromoPoll();
            } else {
                this.stopPushPromoPoll();
            }
        });
        this.initPoll();
    }


    private static compare(a: ExtraCredit, b: ExtraCredit) {
        if (a === b) {
            return true;
        }

        if (a === null || b === null) {
            return false;
        }

        return a && b
            && a.start === b.start
            && a.end === b.end
            && a.bonus === b.bonus;
    }

    private static comparePushPromoChange(a: PushPromo, b: PushPromo) {
        if (a === b) {
            return true;
        }

        if (a === null || b === null) {
            return false;
        }

        return a && b
            && a.credits === b.credits
            && a.extraCredits === b.extraCredits;
    }

    public get happyHour(): Observable<ExtraCredit> {
        return this.happyHourSubject
            .pipe(distinctUntilChanged(MonolithTopUpService.compare));
    }

    public get pushPromo(): Observable<PushPromo> {
        return this.pushPromoSubject
            .pipe(distinctUntilChanged(MonolithTopUpService.comparePushPromoChange));
    }

    private initPoll() {
        setInterval(() => this.poll(), 30000);
        this.poll();
    }

    private poll() {
        this.http.fetch(config.happyHour)
            .then(response => this.happyHourSubject.next(response.extraCredit))
            .catch(() => this.happyHourSubject.next(null));
    }

    private startPushPromoPoll() {
        this.pushPromoPollInterval = setInterval(() => this.pushPromoPoll(), 60000);
        this.pushPromoPoll();
    }

    private stopPushPromoPoll() {
        clearInterval(this.pushPromoPollInterval);
        this.pushPromoSubject.next(null);
    }

    private pushPromoPoll() {
        this.http.fetch(config.pushPromo)
            .then(response => {
                const {spECP}: { spECP: PushPromo | boolean } = response;

                if (spECP === true) {
                    return;
                }

                if (spECP === false) {
                    this.pushPromoSubject.next(null);

                    return;
                }

                this.http.fetch(config.api).then(response => {
                    const {credits} = spECP;
                    const {currencies, topupTypes} = response?.topup;
                    const {entries} = topupTypes?.creditcard;
                    const entryItem = entries[credits];
                    if (entryItem) {
                        this.pushPromoSubject.next({
                            ...spECP,
                            formattedPrice: MonolithTopUpService.formatPrice(currencies, entryItem.currency, entryItem.price)
                        });
                    }
                });
            })
            .catch(() => this.pushPromoSubject.next(null));
    }

    private fetch(): Promise<void> {
        return this.http.fetch(config.api)
            .then(response => {
                this.providers.clear();

                const types = response.topup.topupTypes;
                const creditcard = types.creditcard;
                const happyHour = response.extraCredit && response.extraCredit.priceTable;
                const pushPromo = this.pushPromoSubject.getValue();

                if (response.securion.quickbuy) {
                    this.providers.set(
                        TopUpType.QUICKBUY,
                        new SecurionQuickbuyProvider(
                            this.http,
                            MonolithTopUpService.creditObjectToArray(response.securion.menu, happyHour, pushPromo)
                        )
                    );
                }

                if (creditcard) {
                    this.providers.set(
                        TopUpType.CARD,
                        new SecurionTopUpProvider(
                            this.http,
                            response.securion.publicKey,
                            MonolithTopUpService.creditObjectToArray(creditcard.entries, happyHour, pushPromo)
                        )
                    );
                }

                if (types.phone_hu) {
                    this.providers.set(
                        TopUpType.CALL,
                        new PremiumRateCallProvider(
                            this.http,
                            MonolithTopUpService.creditObjectToArray(types.phone_hu.entries),
                            types.phone_hu.entries
                        )
                    );
                }

                if (types.sms_hu) {
                    this.providers.set(
                        TopUpType.TEXT,
                        new PremiumRateTextProvider(
                            MonolithTopUpService.creditObjectToArray(types.sms_hu.entries),
                            types.sms_hu.entries,
                            types.sms_hu.code
                        )
                    );
                }
            });
    }

    private static creditObjectToArray(entries: object, happyHour?: object, pushPromo?: PushPromo): Array<Credit> {
        const credits: Array<Credit> = [];

        for (const credit in entries) {
            if (entries.hasOwnProperty(credit)) {
                const entry = entries[credit];
                const amount = parseInt(credit, 10);
                let extraCredit = 0;
                let promotionType;

                if (happyHour && happyHour[amount] && !pushPromo) {
                    promotionType = PromoType.HappyHour;
                    extraCredit = parseInt(happyHour[amount], 10);
                }

                if (pushPromo && amount === pushPromo.credits) {
                    promotionType = PromoType.PushPromo;
                    extraCredit = pushPromo.extraCredits;
                }

                credits.push({
                    amount: amount,
                    extraCredit: extraCredit,
                    totalAmount: amount + extraCredit,
                    promotionType,
                    price: entry.price,
                    previousPrice: entry.previousPrice || entry.prevPrice,
                    currency: entry.currency.toUpperCase()
                });
            }
        }

        return credits;
    }

    getProvider(name: TopUpType): Promise<TopUpProvider> {
        return this.getProviders()
            .then(providers => {
                if (providers.has(name)) {
                    return Promise.resolve(providers.get(name));
                }

                return Promise.reject();
            });
    }

    getProviders(): Promise<Map<TopUpType, TopUpProvider>> {
        return this.fetch().then(() => this.providers);
    }

    private static formatPrice(currencies: { [key: string]: { template: string } }, currency: string, amount: number) {
        const templateStrings = {
            "cre": "{{value}}",
            "huf": "{{intvalue}}",
            // eslint-disable-next-line
            "usd": "${{value}}"
        }
        if (!currencies[currency]) {
            return "";
        }
        const currencyTemplate = currencies[currency].template;

        return currencyTemplate.replace(templateStrings[currency], amount.toString())
    }
}

