import {makeStyles} from "@material-ui/styles";
import React, {useEffect, useRef, useState} from "react";
import {filter, map} from "rxjs/operators";
import {useApplicationContext} from "../../application/ApplicationContext";
import {Gift} from "../../domain/Gift";
import {GiftNotification} from "../../domain/GiftNotification";
import {TipType} from "../../domain/TipType";
import {priceUri} from "../../price.helper";
import "./GiftOverlay.scss";

const useStyles = makeStyles({
    root: {
        overflow: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        animationName: "giftoverlay-popup",
        animationFillMode: "both",
        animationDuration: "4s",
        animationIterationCount: 1,
        animationTimingFunction: "linear",
        "&:before": {
            content: "\"\"",
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "url(/images/ray.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            animationName: "giftoverlay-spin",
            animationDuration: "20s",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
            opacity: 0.6
        },
        "& img": {
            position: "absolute",
            width: "50%",
            height: "50%",
            objectFit: "contain"
        }
    }
});

interface GiftOverlayProps {
}

export const GiftOverlay = React.memo<GiftOverlayProps>(() => {
    const classes = useStyles();
    const eventName = "animationend";
    const {giftService, roomService} = useApplicationContext();
    const [gift, setGift] = useState<Gift>(null);
    const targetRef = useRef<HTMLDivElement>();
    const withPrice: Array<string> = [TipType.Tip, TipType.SmartToy];

    useEffect(() => {
        const {current} = targetRef;
        const unsetGift = () => setGift(null);
        const mapper = (notification: GiftNotification): Gift => {
            const gift = giftService.getById(notification.giftId);

            if (gift) {
                gift.price = notification.price;
            }

            return gift;
        };

        current.addEventListener(eventName, unsetGift);

        const subscription = roomService.events
            .pipe(
                filter<GiftNotification>(event => event instanceof GiftNotification),
                map<GiftNotification, Gift>(mapper),
                filter<Gift>(gift => Boolean(gift && (gift.svg || gift.png)))
            )
            .subscribe(setGift);

        return () => {
            current.removeEventListener(eventName, unsetGift);
            subscription.unsubscribe();
        };
    }, [roomService, giftService]);

    return (
        <div className={classes.root} ref={targetRef}>
            {
                gift &&
                <div className={classes.container}>
                    <img src={gift.svg || gift.png} alt={gift.name}/>

                    {
                        withPrice.includes(gift.id) &&
                        <img alt="price" src={priceUri(gift.price)}/>
                    }
                </div>
            }
        </div>
    );
}, () => true);
