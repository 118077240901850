import React, {PureComponent, ReactNode} from "react";
import {createStyles, TextField, Typography, withStyles, WithStyles} from "@material-ui/core";
import {RoomId} from "../../../domain/RoomId";
import {sendTip} from "../../../action/chat.action";
import {connect} from "react-redux";
import intl from "react-intl-universal";
import {SubLabelButton} from "../../SubLabelButton";
import {push} from "connected-react-router";
import {noop, routeToRoomList} from "../../../helper";

const styles = createStyles({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    tips: {
        margin: "10px 0",
        display: "flex",
        flexDirection: "row",
        color: "white !important",
        justifyContent: "space-evenly"
    },
    body: {
        flex: 1
    },
    tip: {
        flex: 1,
        maxWidth: "70px",
        height: "70px",
        textAlign: "center",
        lineHeight: "70px",
        backgroundSize: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(/images/tip-coinbg.svg)",
        "&.selected": {
            backgroundImage: "url(/images/tip-coinbg-selected.svg)"
        }
    },
    area: {
        padding: "0 15px"
    },
    message: {
        margin: "10px 15px",
        color: "#FFDD67",
        fontSize: "11px"
    }
});

interface Props extends WithStyles<typeof styles> {
    inProgress: boolean
    sendTip: (roomId, message, price) => void
    roomId: RoomId
    message?: string
    text: string
}

const mapStateToProps = ({gift}) => ({
    inProgress: gift.inProgress
});

const mapDispatchToProps = dispatch => ({
    sendTip: (roomId, message, price) => dispatch(sendTip(roomId, message, price, push(routeToRoomList())))
});

export const Tip = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props> {
    tips = [200, 300, 400, 500, 750];

    state = {
        selected: 300
    };

    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
        this.setInputRef = this.setInputRef.bind(this);
    }

    private handleSelect(selected: number) {
        this.setState({
            selected
        });
    }

    private inputRef: HTMLTextAreaElement;

    private keyPress = (e) => {
        if(e.keyCode === 13){
            this.submit();
        }
    }

    private setInputRef(ref) {
        this.inputRef = ref;
    }

    private submit() {
        const {roomId, sendTip} = this.props;
        const {selected} = this.state;
        const message = this.inputRef.value.trim();

        sendTip(roomId, message, selected);
    }

    render(): ReactNode {
        const {classes, message, text, inProgress}: Props = this.props;
        const {selected} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.body}>
                    <div className={classes.tips}>
                        {
                            this.tips.map(tip => (
                                <div key={tip}
                                     onClick={inProgress ? noop : () => this.handleSelect(tip)}
                                     className={[classes.tip].concat(selected === tip ? ["selected"] : []).join(" ")}>
                                    <Typography component="span" style={{lineHeight: "unset"}}>{tip}</Typography>
                                </div>
                            ))
                        }
                    </div>

                    {
                        message &&
                        <Typography className={classes.message}>{message}</Typography>
                    }

                    <div className={classes.area}>
                        <TextField inputRef={this.setInputRef}
                                   disabled={inProgress}
                                   fullWidth
                                   onKeyDown={this.keyPress}
                                   placeholder={intl.get("tip.placeholder")}
                                   rowsMax={1}/>
                    </div>
                </div>
                <SubLabelButton disabled={inProgress} onClick={this.submit} label={text.toUpperCase()}/>
            </div>
        );
    }
}));
