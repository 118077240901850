import {createStore, Store, compose} from "redux";
import middleware from "./middleware";
import {rootReducer} from "./reducer";
import * as Sentry from "@sentry/react";
import {isDevelopment} from "./environment";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const composeEnhancers = isDevelopment ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default createStore(rootReducer, composeEnhancers(middleware, sentryReduxEnhancer)) as Store;
