import React from "react";
import intl from "react-intl-universal";
import {ComingUpNextLobby} from "./ComingUpNextLobby";

interface Props {
}

export const RoomNotFoundLobby = React.memo<Props>(() => (
    <ComingUpNextLobby title={intl.get("lobby.room-not-found.title")}/>
));
