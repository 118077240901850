import * as Sentry from "@sentry/react";

export class Recaptcha {
    static getToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(() => {
                grecaptcha.execute(process.env.REACT_APP_RECAPTCHA, {action: "homepage"})
                    .then(resolve, reject);
            });
        }).catch(() => {
            Sentry.captureMessage("recaptcha error", Sentry.Severity.Critical);

            return Promise.reject("recaptcha-error");
        }) as Promise<string>;
    }
}
