import {CircularProgress, makeStyles} from "@material-ui/core";
import React, {ReactNode, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useApplicationContext} from "../../application/ApplicationContext";
import {PlayerState} from "../../domain/Player";
import {StreamData} from "../../domain/StreamData";
import {clsIf, clsx} from "../../helper";
import {RootState} from "../../reducer";

const useStyles = makeStyles({
    root: {
        position: "relative",
        width: "100%",
        height: "100%"
    },
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        transition: "opacity 250ms",
        "&.error": {
            opacity: 0
        },
        "&.loading": {
            opacity: 0
        },
        "& div": {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            "& video": {
                width: "100%",
                height: "100%"
            }
        }
    },
    center: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});

interface StreamProps {

    // due to memo and children comparism
    overlayA?: ReactNode
    overlayB?: ReactNode
}

export const Stream = React.memo<StreamProps>(({overlayA, overlayB}) => {
    const classes = useStyles();
    const {streamService} = useApplicationContext();
    const containerRef = useRef<HTMLDivElement>();
    const [state, setState] = useState<PlayerState>(PlayerState.Loading);
    const streamData: StreamData = useSelector<RootState, StreamData>(root => root?.chat?.room?.modeSpecific?.main);

    useLayoutEffect(() => {
        containerRef.current.appendChild(streamService.getElement());

        return () => streamService.stop();
    }, [streamService]);

    useEffect(() => {
        const subscription = streamService.playerState().subscribe(setState);

        return () => subscription.unsubscribe();
    }, [streamService]);

    useEffect(() => {
        if (streamData) {
            streamService.play(streamData);
        } else {
            streamService.stop();
        }
    }, [streamService, streamData]);

    return (
        <div className={classes.root}>
            <div className={clsx(
                classes.container,
                clsIf(state === PlayerState.Error, "error"),
                clsIf(state === PlayerState.Playing, "playing"),
                clsIf(state === PlayerState.Loading, "loading")
            )} ref={containerRef}/>

            {
                state === PlayerState.Playing
                    ? overlayA
                    : <div className={classes.center}><CircularProgress/></div>
            }

            {overlayB}
        </div>
    );
});
