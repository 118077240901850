import React, {PureComponent, ReactNode} from "react";
import {connect} from "react-redux";
import {Locale} from "../../domain/Locale";
import {changeLocale} from "../../action/i18n.action";

interface Props {
    i18n: Locale,
    change: (code: string) => void
}

class ToggleLocale extends PureComponent<Props> {
    transition = {
        en: "hu",
        hu: "en"
    };

    label = {
        hu: "magyar verzió",
        en: "english version"
    };

    render(): ReactNode {
        const {i18n, change} = this.props;
        const to = this.transition[i18n.code] || "en";
        const label = this.label[to];

        return <span onClick={() => change(to)} title={label}>{label}</span>;
    }
}

const mapStateToProps = state => ({
    i18n: state.i18n
});

const mapDispatchToProps = dispatch => ({
    change: (code: string) => dispatch(changeLocale(code))
});

const component = connect(mapStateToProps, mapDispatchToProps)(ToggleLocale);

export {
    component as ToggleLocale
};
