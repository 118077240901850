import {createStyles, withStyles, WithStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, {PureComponent} from "react";
import intl from "react-intl-universal";
import {Card} from "../../domain/topup/Card";
import {CardTopUpCommand} from "../../domain/topup/CardTopUpCommand";
import {Credit} from "../../domain/topup/Credit";
import {SecurionTopUpProvider} from "../../service/topup/SecurionTopUpProvider";
import {CanGoBack} from "../contract/CanGoBack";
import {Closable} from "../contract/Closable";
import {InputJumper} from "../InputJumper";
import {Header} from "../layout/Header";
import {Toolbar} from "../layout/Toolbar";
import {Viewport} from "../layout/Viewport";
import {ProgressButton} from "../ProgressButton";
import {CreditCardFormat} from "./CreditCardFormat";
import {MonthOrYearFormat} from "./MonthOrYearFormat";

const styles = createStyles({
    label: {
        color: "#000"
    }
});

interface Props extends WithStyles<typeof styles>, Closable, CanGoBack {
    provider: SecurionTopUpProvider
    credit: Credit
    onSuccess: () => void
}

class CardTopUpScreen extends PureComponent<Props> {
    state = {
        inProgress: false,
        error: null,
        enableQuickbuy: false,
        enableAutopay: false
    };

    private formRef: HTMLFormElement;
    private inputJumper: InputJumper;

    constructor(props) {
        super(props);

        this.handleAutopayChange = this.handleAutopayChange.bind(this);
        this.handleQuickbuyChange = this.handleQuickbuyChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setFormRef = this.setFormRef.bind(this);
    }

    private handleAutopayChange(event) {
        this.setState({
            enableAutopay: event.target.checked
        });
    }

    private handleQuickbuyChange(event) {
        this.setState({
            enableQuickbuy: event.target.checked,
            enableAutopay: false
        });
    }

    private attachInputJumper(root: HTMLElement) {
        this.detachInputJumper();

        this.inputJumper = new InputJumper(root, {
            number: [16, "cvc"],
            cvc: [4, "expMonth"],
            expMonth: [2, "expYear"],
            expYear: [2, "email"]
        });
    }

    private detachInputJumper() {
        if (this.inputJumper) {
            this.inputJumper.detach();
            this.inputJumper = null;
        }
    }

    private setFormRef(formRef: HTMLFormElement) {
        this.formRef = formRef;

        if (formRef) {
            this.attachInputJumper(formRef);
        }
    }

    componentWillUnmount(): void {
        this.detachInputJumper();
    }

    private handleSubmit(event) {
        event.preventDefault();

        this.setState({
            inProgress: true
        });

        const {provider, onSuccess} = this.props;

        provider.execute(this.createCardTopUp())
            .then(() => onSuccess(), error => this.setState({inProgress: false, error}));
    }

    private getCheckboxValue(name: string): boolean {
        return (this.formRef.querySelector(`input[type=checkbox][name=${name}]`) as HTMLInputElement).checked;
    }

    private getFieldValue(name: string): string {
        return (this.formRef.querySelector(`input[name=${name}]`) as HTMLInputElement).value.trim();
    }

    private createCardTopUp(): CardTopUpCommand {
        return {
            credit: this.props.credit,
            card: this.createCard(),
            email: this.getFieldValue("email"),
            enableQuickbuy: this.getCheckboxValue("quickbuy"),
            enableAutopay: this.getCheckboxValue("autopay")
        };
    }

    private createCard(): Card {
        return {
            cardholderName: this.getFieldValue("cardholderName"),
            number: this.getFieldValue("number"),
            cvc: this.getFieldValue("cvc"),
            expMonth: this.getFieldValue("expMonth"),
            expYear: this.getFieldValue("expYear")
        };
    }

    render(): React.ReactNode {
        const {onBack, onClose, credit} = this.props;
        const {error, inProgress, enableAutopay, enableQuickbuy} = this.state;

        return (
            <Viewport color={"secondary"}>
                <Header
                    onBack={onBack}
                    onClose={onClose}
                    title={intl.get("topup.card.title")}/>
                <Box flex={1} style={{overflowY: "auto", padding: "16px"}}>

                    <Typography variant="body2">{intl.getHTML("topup.card.description", {amount: credit.totalAmount})}</Typography>

                    {
                        error &&
                        <Typography color="error" variant="body2" style={{
                            textAlign: "center",
                            margin: "16px auto"
                        }}>{error.message}</Typography>
                    }

                    <form onSubmit={this.handleSubmit} ref={this.setFormRef}>

                        <TextField disabled={inProgress} fullWidth required name="cardholderName" type="text" label={intl.get("topup.card.cardholderName")}/>

                        <TextField InputProps={{
                            inputComponent: CreditCardFormat
                        }} disabled={inProgress} fullWidth required name="number" type="text" label={intl.get("topup.card.number")}/>

                        <TextField inputProps={{
                            pattern: "\\d{3,4}",
                            inputMode: "numeric"
                        }} disabled={inProgress} fullWidth required name="cvc" inputMode="numeric" type="text" label={intl.get("topup.card.cvc")}/>

                        <Box display="flex" flexDirection="row">
                            <Box flex={1} style={{marginRight: "12px"}}>
                                <TextField InputProps={{
                                    inputComponent: MonthOrYearFormat
                                }} disabled={inProgress} fullWidth required name="expMonth" type="text" label={intl.get("topup.card.expMonth")}/>
                            </Box>
                            <Box flex={1}>
                                <TextField InputProps={{
                                    inputComponent: MonthOrYearFormat
                                }} disabled={inProgress} fullWidth required name="expYear" type="text" label={intl.get("topup.card.expYear")}/>
                            </Box>
                        </Box>

                        <TextField disabled={inProgress} fullWidth required name="email" type="email" label={intl.get("topup.card.email")}/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="quickbuy"
                                    checked={enableQuickbuy}
                                    onChange={this.handleQuickbuyChange}
                                    color="primary"
                                />
                            }
                            disabled={inProgress}
                            labelPlacement="end"
                            label={intl.get("topup.card.quickbuy")}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="autopay"
                                    disabled={enableQuickbuy === false}
                                    checked={enableAutopay}
                                    onChange={this.handleAutopayChange}
                                    color="primary"
                                />
                            }
                            disabled={inProgress}
                            labelPlacement="end"
                            label={intl.get("topup.card.autopay")}
                        />

                        <Box textAlign="right">
                            <ProgressButton
                                progress={inProgress}
                                label={`${intl.get("topup.card.submit")} ${intl.get("topup.price.".concat(credit.currency.toLowerCase()), credit)}`}
                                type="submit"/>
                        </Box>
                    </form>
                </Box>
                <Toolbar>
                    <Typography style={{
                        fontSize: "11px",
                        flex: 1,
                        textAlign: "center",
                        margin: "8px 0"
                    }} component="div" variant="body2">
                        <span style={{
                            display: "inline-flex",
                            alignItems: "center"
                        }}>
                            <img style={{marginRight: "5px"}} height={22} width={22} src="/images/securion.svg" alt="securionpay.com logo"/>
                            <span>{intl.get("topup.card.securionpay")}</span>
                        </span>
                    </Typography>
                </Toolbar>
            </Viewport>
        );
    }
}

const component = withStyles(styles)(CardTopUpScreen);

export {
    component as CardTopUpScreen
};
