import React from "react";

interface CameraIconProps {
    active: boolean
}

export const CameraIcon = React.memo<CameraIconProps>(({active}) => active
    ? <img src="/images/webcam-on.svg" width={24} height={24} alt="cam2cam active"/>
    : <img src="/images/webcam.svg" width={24} height={24} alt="cam2cam inactive"/>
);
