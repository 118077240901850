import {Closable} from "../contract/Closable";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import intl from "react-intl-universal";

interface Props extends Closable {
    onAccept: () => void
}

export const NotSavedDialog = React.memo<Props>(({onAccept, onClose}) => {
    return (
        <>
            <DialogTitle id="alert-dialog-title">{intl.get("settings.not-saved-dialog.title")}</DialogTitle>
            <DialogActions>
                <Button onClick={onAccept} color="primary">
                    {intl.get("settings.not-saved-dialog.yes")}
                </Button>
                <Button onClick={onClose} color="primary" autoFocus>
                    {intl.get("settings.not-saved-dialog.no")}
                </Button>
            </DialogActions>
        </>
    )
});
