export class LazyValue<T> {

    private value: T;
    private initialized: boolean = false;

    constructor(private readonly provider: () => T) {
    }

    public getValue(): T {
        if (this.initialized) {
            return this.value;
        }

        const value = this.provider();

        this.initialized = true;

        return this.value = value;
    }
}
