import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback} from "react";
import intl from "react-intl-universal";
import {useDispatch} from "react-redux";
import {acknowledgeAgeLimit} from "../action/consent.action";
import {Viewport} from "./layout/Viewport";

const useStyles = makeStyles({
    root: {
        justifyContent: "center"
    },
    circle: {
        borderRadius: "50%",
        width: "80px",
        height: "80px",
        margin: "0 auto",
        backgroundColor: "#D40707",
        border: "2px solid #ffffff",
        textAlign: "center",
        lineHeight: "80px",
        fontSize: "30px",
        fontWeight: 700,
        color: "#ffffff"
    },
    button: {
        margin: "10px 0"
    },
    text: {
        textAlign: "center",
        fontSize: "14px",
        margin: "20px",
        marginBottom: 0
    }
});

interface Props {
    acknowledge: () => void
}

export const AgeRestrictionScreen = React.memo<Props>(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const acknowledge = useCallback(() => dispatch(acknowledgeAgeLimit()), [dispatch]);

    return (
        <Viewport className={classes.root}>
            <div className={classes.circle}>18+</div>
            <Typography className={classes.text}>{intl.get("warning.p1")}</Typography>
            <Typography className={classes.text}>{intl.getHTML("warning.p2")}</Typography>
            <Typography className={classes.text} style={{margin: "10px 20% 0 20%"}}>
                <Button className={classes.button} onClick={acknowledge}>{intl.get("warning.accept")}</Button>
                <span> {intl.getHTML("warning.leave")}</span>
            </Typography>
        </Viewport>
    );
});
