import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React, {Fragment, PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {Credit} from "../../domain/topup/Credit";
import {PremiumRateTextProvider} from "../../service/topup/PremiumRateTextProvider";
import {CanGoBack} from "../contract/CanGoBack";
import {Closable} from "../contract/Closable";
import {Header} from "../layout/Header";
import {Toolbar} from "../layout/Toolbar";
import {Viewport} from "../layout/Viewport";

const styles = (theme: Theme) => createStyles({
    details: {
        borderColor: theme.palette.primary.main,
        borderWidth: "3px",
        borderStyle: "solid",
        padding: "16px",
        margin: "16px auto",
        textAlign: "center",
        fontWeight: "bold"
    }
});

interface Props extends WithStyles<typeof styles>, Closable, CanGoBack {
    provider: PremiumRateTextProvider
    credit: Credit
}

class TextTopUpScreen extends PureComponent<Props> {
    state = {
        phone: null,
        code: null
    };

    componentDidMount(): void {
        this.props.provider.getPhoneAndCode(this.props.credit.amount).then(result => {
            this.setState({
                code: result.code,
                phone: result.phone
            });
        });
    }

    private renderDetails(): ReactNode {
        const {classes} = this.props;
        const {phone, code} = this.state;

        if (phone && code) {
            return (
                <Fragment>
                    <div className={classes.details}>
                        <div>{intl.get("topup.phone.number", {
                            phone: phone.replace(/(\d\d)(\d\d)(\d{3})(\d*)/, "$1 $2 $3 $4")
                        })}</div>
                        <div style={{margin: "16px auto"}}>
                            <img src="/images/arrow-down.svg" alt="arrow down"/>
                        </div>
                        <div>{intl.get("topup.phone.code", {code})}</div>
                    </div>
                    <Box textAlign="right">
                        <Button href={`sms:${phone}`} target="_self">{intl.get("topup.text.button")}</Button>
                    </Box>
                </Fragment>
            );
        } else {
            return <Box textAlign="center"><CircularProgress/></Box>;
        }
    }

    render(): React.ReactNode {
        const {onBack, onClose, credit} = this.props;

        return (
            <Viewport color={"secondary"}>
                <Header
                    onBack={onBack}
                    onClose={onClose}
                    title={intl.get("topup.text.title")}/>
                <Box flex={1} style={{overflowY: "auto", padding: "16px"}}>
                    <Typography variant="body2" style={{
                        marginBottom: "16px"
                    }}>{intl.get("topup.text.product", credit)}</Typography>
                    <Typography variant="body2" style={{
                        marginBottom: "16px"
                    }}>{intl.get("topup.text.description")}</Typography>
                    <Typography variant="body2" style={{
                        marginBottom: "16px",
                        fontWeight: "bold"
                    }}>{intl.getHTML("topup.text.summary", {
                        ...credit,
                        net: Math.floor(credit.price / 1.27)
                    })}</Typography>

                    {
                        this.renderDetails()
                    }
                </Box>
                <Toolbar>
                    <Typography style={{
                        fontSize: "11px",
                        flex: 1,
                        textAlign: "center",
                        margin: "8px 0"
                    }} component="div" variant="body2">{intl.get("topup.phone.footer")}</Typography>
                </Toolbar>
            </Viewport>
        );
    }
}

const component = withStyles(styles)(TextTopUpScreen);

export {
    component as TextTopUpScreen
};
