import {UpdateActionType} from "../action/update.action";

export interface UpdateState {
    showReloadRequest: boolean
}

const initialState: UpdateState = {
    showReloadRequest: false
};

export function updateReducer(state: UpdateState = initialState, action): UpdateState {
    const {type} = action;

    if (type === UpdateActionType.RequestReload) {
        return {
            showReloadRequest: true
        };
    }

    return state;
}
