import {Store} from "redux";
import {ApplicationActionType} from "../action/application.action";
import {StreamService} from "../service/StreamService";

export const streamMiddleware = (service: StreamService) => (store: Store) => {
    return next => action => {
        const {type} = action;

        switch (type) {
            case ApplicationActionType.BecameInvisible: {
                service.stop();

                break;
            }

            default:
        }

        return next(action);
    };
};
