import {push} from "connected-react-router";
import {hideDialog, showDialog} from "./action/application.action";
import {requestFeature} from "./action/feature.action";
import {showTopUp} from "./action/topup.action";
import {Dialog} from "./domain/Dialog";
import {Feature} from "./domain/feature/Feature";
import {RoomId} from "./domain/RoomId";
import {
    routeToForgottenPassword,
    routeToRoom,
    routeToRoomList,
    routeToSettings,
    routeToSignIn,
    routeToSignUp
} from "./helper";
import store from "./store";

export const redirectToSignIn = (next?: string) => store.dispatch(push(routeToSignIn(next)));
export const redirectToRoomList = () => store.dispatch(push(routeToRoomList()));
export const redirectToRoom = (roomId: RoomId) => store.dispatch(push(routeToRoom(roomId)));
export const redirectToTopUp = () => store.dispatch(requestFeature(Feature.TopUp, showTopUp()));
export const redirectToSignUp = () => store.dispatch(requestFeature(Feature.SignUp, push(routeToSignUp())));
export const redirectToForgottenPassword = () => store.dispatch(push(routeToForgottenPassword()));
export const redirectToSettings = () => store.dispatch(push(routeToSettings()));

export const showPrivacyDialog = () => store.dispatch(showDialog(Dialog.PRIVACY));
export const showTermsDialog = () => store.dispatch(showDialog(Dialog.TERMS));
export const closeDialog = () => store.dispatch(hideDialog());
