export interface RequestContext {
    retried: boolean
    readonly resource: string
    readonly requestInit: RequestInit

    replay(): Promise<Response>
}

type onRequest = (context: RequestContext) => Promise<RequestContext>;
type onResponse = (response: any, context: RequestContext) => Promise<any>;
type onError = (error: any, context: RequestContext) => Promise<any>;

const defaultOnResponse = (response, context: RequestContext) => Promise.resolve(response);
const defaultOnError = (error, context: RequestContext) => Promise.reject(error);
const interceptors = {
    request: {
        interceptor: (context: RequestContext) => context,
        use: function (onRequest: onRequest): void {
            this.interceptor = onRequest;
        }
    },
    response: {
        interceptor: {
            onResponse: defaultOnResponse,
            onError: defaultOnError
        },
        use: function (onResponse, onError): void {
            this.interceptor = {
                onResponse: onResponse || defaultOnResponse,
                onError: onError || defaultOnError
            };
        }
    }
};

const headers: Header = {};

const fetch = (context: RequestContext) => {
    const {resource, requestInit} = context;

    return window.fetch(resource, requestInit);
};

const request = (context: RequestContext): Promise<Response> => {
    return Promise.resolve(interceptors.request.interceptor(context))
        .then(fetch)
        .then(response => {
            if (response.ok) {
                return response;
            }

            return response.json().then(
                data => Promise.reject(data),
                () => Promise.reject(`error: ${response.status}`)
            );
        })
        .then(response => response.text())
        .then(response => {
            try {
                return JSON.parse(response);
            } catch (error) {
                return response;
            }
        })
        .then(response => interceptors.response.interceptor.onResponse(response, context))
        .catch(error => interceptors.response.interceptor.onError(error, context));
};

interface Header {
    [key: string]: string
}

export interface Http {
    setDefaultHeaders(defaultHeaders: Header): void

    interceptor: {
        request: {
            use: (onRequest: onRequest) => void
        }
        response: {
            use: (onResponse: onResponse, onError?: onError) => void
        }
    }

    get(resource: string, requestInit?: Omit<RequestInit, "method">): Promise<any>

    post(resource: string, requestInit?: Omit<RequestInit, "method">): Promise<any>

    fetch(resource: string, requestInit?: RequestInit): Promise<any>
}

export const http: Http = {
    interceptor: {
        request: {
            use: interceptors.request.use.bind(interceptors.request)
        },
        response: {
            use: interceptors.response.use.bind(interceptors.response)
        }
    },
    setDefaultHeaders: function (defaultHeaders: Header) {
        Object.keys(defaultHeaders).reduce<Header>((headers: Header, key: string) => {
            const value = defaultHeaders[key];

            if (value === undefined) {
                delete headers[key];
            } else {
                headers[key] = value;
            }

            return headers;
        }, headers);
    },
    get: function (resource: string, requestInit: Omit<RequestInit, "method"> = {}) {
        return this.fetch(resource, {
            ...requestInit,
            method: "GET"
        });
    },
    post: function (resource: string, requestInit: Omit<RequestInit, "method"> = {}) {
        return this.fetch(resource, {
            ...requestInit,
            method: "POST"
        });
    },
    fetch: function (resource: string, requestInit: RequestInit = {}): Promise<Response> {
        const extendedRequestInit: RequestInit = {
            ...requestInit,
            headers: {
                ...headers,
                ...requestInit.headers
            }
        } as RequestInit;

        const context: RequestContext = {
            retried: false,
            resource,
            requestInit: extendedRequestInit,
            replay: function (): Promise<Response> {
                context.retried = true;

                return request(context);
            }
        };

        return request(context);
    }
};
