import {AggregateDeviceInfo} from "../domain/c2c/AggregateDeviceInfo";
import {Publisher} from "../domain/c2c/Publisher";
import {Setup} from "../domain/c2c/Setup";
import {RoomId} from "../domain/RoomId";
import {Storage} from "../domain/Storage";
import {CameraMediaStream} from "../publisher/CameraMediaStream";
import {MediaSource} from "../publisher/MediaSource";
import {SxPublisher} from "../publisher/SxPublisher";
import {RoomService} from "./RoomService";
import {SxRoomService} from "./SxRoomService";
import {VxRoomService} from "./visitx/VxRoomService";
import {WebApiService} from "./webapi.service";

export class CameraService {
    private mediasource: MediaSource = new MediaSource();
    private publisher: Publisher;
    private video: HTMLVideoElement;
    private stream: CameraMediaStream;

    constructor(
        private readonly setupStorage: Storage<Setup>,
        private readonly webapiService: WebApiService,
        private readonly sxRoomService: SxRoomService,
        private readonly vxRoomService: VxRoomService
    ) {
    }

    public start(roomId: RoomId): Promise<void> {

        // FIXME stored setup does not work properly
        // return this.setupStorage.getItem()
        return Promise.resolve(null as Setup)
            .then(setup => this.mediasource.getMediaStream(setup))
            .then(stream => Promise.all([

                // FIXME stored setup does not work properly
                // stream.getSetup().then(setup => this.setupStorage.setItem(setup)),
                this.connectStreamToVideo(stream.getMediaStream())
            ]).then(() => stream))
            .then(stream => {
                const publisher = this.createPublisher(roomId);

                return publisher.publish(stream.getMediaStream(), this.video)
                    .then(() => [stream, publisher]);
            })
            .then(([stream, publisher]) => {
                this.stream = stream as CameraMediaStream;
                this.publisher = publisher as Publisher;
            })
            .catch(reason => {
                this.stop();

                console.log(reason);

                return Promise.reject(reason);
            });
    }

    public getDevices(): Promise<AggregateDeviceInfo> {
        return this.mediasource.enumerateDevices();
    }

    public getSetup(): Promise<Setup> {
        return this.stream?.getSetup() || null;
    }

    public reconfigure(setup: Setup): Promise<any> {
        // TODO handle error

        this.video?.pause();
        this.publisher?.unpublish();
        this.stream?.stop();
        this.stream = null;

        return this.mediasource.getMediaStream(setup)
            .then(stream => Promise.all([

                // FIXME stored setup does not work properly
                // stream.getSetup().then(setup => this.setupStorage.setItem(setup)),
                this.connectStreamToVideo(stream.getMediaStream())
            ]).then(() => stream))
            .then(stream => this.publisher.publish(stream.getMediaStream(), this.video).then(() => stream))
            .then(stream => this.stream = stream)
            .catch(reason => {
                this.stop();

                return Promise.reject(reason);
            });
    }

    public attachVideo(video: HTMLVideoElement) {
        this.video = video;
    }

    public detachVideo() {
        this.video = null;
    }

    public mute() {
        this.stream?.mute();
    }

    public unmute() {
        this.stream?.unmute();
    }

    public stop() {
        this.video?.pause();

        this.publisher?.unpublish();
        this.publisher = null;

        this.stream?.stop();
        this.stream = null;
    }

    private createPublisher(roomId: RoomId): Publisher {
        if (RoomService.isVisitX(roomId)) {
            return this.vxRoomService.getPublisher();
        }

        return new SxPublisher(
            this.webapiService,
            this.sxRoomService,
            roomId
        );
    }

    private connectStreamToVideo(stream: MediaStream): Promise<void> {
        const {video} = this;

        video.srcObject = stream;

        return video.play()?.catch(error => {
            switch (error) {
                case "NotAllowedError":
                case "NotSupportedError":
                default:
                    return Promise.reject("playFailed");
            }
        }) || Promise.resolve();
    }
}
