import {LOCATION_CHANGE} from "connected-react-router";
import {Store} from "redux";
import {ApplicationActionType} from "../../action/application.action";
import {CameraActionType} from "../../action/camera.action";
import {ChatActionType} from "../../action/chat.action";
import {FeatureActionType} from "../../action/feature.action";
import {hideAllSnackbar} from "../../action/snackbar.action";
import {TopUpActionType} from "../../action/topup.action";
import {User} from "../../domain/authentication/User";
import {CameraErrorReason} from "../../domain/c2c/CameraErrorReason";
import {FeatureDisabledReason} from "../../domain/feature/FeatureDisabledReason";
import {GiftFailedReason} from "../../domain/GiftFailedReason";
import {JoinRoomFailedReason} from "../../domain/JoinRoomFailedReason";
import {PushPromo} from "../../domain/PushPromo";
import {RoomDto} from "../../domain/RoomDto";
import {RoomMode} from "../../domain/RoomMode";
import {SendMessageFailedReason} from "../../domain/SendMessageFailedReason";
import {TipType} from "../../domain/TipType";
import {routeToRoom} from "../../helper";
import {GiftService} from "../../service/gift.service";
import {
    cameraBlocked,
    cameraError,
    cameraNotSupported,
    confirmGift,
    featureIsNotAvailable,
    guestNotAllowed,
    notAllowedInFreeShow,
    notifyPushPromo,
    offerKnockout,
    onlyInPrivate,
    onlyWithCouple,
    outOfCredit,
    promotedToPrivateOwner,
    runningOutOfCredit,
    showRelease,
    technicalError,
    voyeurNotAllowed
} from "./snackbar.definitions";

export const snackbarMiddleware = (giftService: GiftService) => (store: Store) => next => action => {
    const {type} = action;
    const result = next(action);

    switch (type) {
        case FeatureActionType.FeatureDisabled: {
            const {reason} = action;

            switch (reason) {
                case FeatureDisabledReason.UserIsNotMember: {
                    store.dispatch(guestNotAllowed());

                    break;
                }

                case FeatureDisabledReason.ViewerIsVoyeur: {
                    store.dispatch(voyeurNotAllowed());

                    break;
                }

                case FeatureDisabledReason.RoomCategoryIsNotCouple: {
                    store.dispatch(onlyWithCouple());

                    break;
                }

                case FeatureDisabledReason.RoomModeIsNotGroupOrPrivate: {
                    store.dispatch(notAllowedInFreeShow());

                    break;
                }

                case FeatureDisabledReason.RoomModeIsNotPrivate: {
                    store.dispatch(onlyInPrivate());

                    break;
                }

                case FeatureDisabledReason.Other:
                case FeatureDisabledReason.Disabled:
                default: {
                    store.dispatch(featureIsNotAvailable());
                }
            }

            break;
        }

        case ChatActionType.SendGiftSucceeded:
        case ChatActionType.SendTipSucceeded: {
            const {giftId = TipType.Tip, price} = action;
            const gift = giftService.getById(giftId);

            if (gift) {
                store.dispatch(confirmGift(gift, price));
            }

            break;
        }

        case ChatActionType.SendTipFailed:
        case ChatActionType.SendZzzFailed:
        case ChatActionType.SendGiftFailed: {
            const {reason, roomId} = action;

            switch (reason) {
                case GiftFailedReason.OutOfCredit: {
                    store.dispatch(outOfCredit());

                    break;
                }

                case GiftFailedReason.GuestNotAllowed: {
                    store.dispatch(guestNotAllowed(routeToRoom(roomId)));

                    break;
                }

                case GiftFailedReason.VoyeurNotAllowed: {
                    store.dispatch(voyeurNotAllowed());

                    break;
                }

                default: {
                    store.dispatch(technicalError());
                }
            }

            break;
        }

        case ChatActionType.SendStickerFailed:
        case ChatActionType.SendMessageFailed: {
            const {reason, roomId} = action;

            switch (reason) {
                case SendMessageFailedReason.GuestNotAllowed: {
                    store.dispatch(guestNotAllowed(routeToRoom(roomId)));

                    break;
                }

                case SendMessageFailedReason.VoyeurNotAllowed: {
                    store.dispatch(voyeurNotAllowed());

                    break;
                }

                default: {
                    store.dispatch(technicalError());
                }
            }

            break;
        }

        case ChatActionType.RequestChatModeFailed: {
            const {reason, roomId} = action;

            switch (reason) {
                case JoinRoomFailedReason.NotEnoughCredits: {
                    store.dispatch(outOfCredit());

                    break;
                }

                case JoinRoomFailedReason.GuestNotAllowed: {
                    store.dispatch(guestNotAllowed(routeToRoom(roomId)));

                    break;
                }

                default: {
                    store.dispatch(technicalError());
                }
            }

            break;
        }

        case ChatActionType.PromotedToPrivateOwner: {
            const {roomId} = action.event;
            const {auth, chat} = store.getState();
            const {room}: { room: RoomDto } = chat;
            const {user}: { user: User } = auth;

            if (user && room && room.roomid === roomId && room.roomMode === RoomMode.Private) {
                store.dispatch(promotedToPrivateOwner(user.auth.userName));
            }

            break;
        }

        case ChatActionType.RunningOutOfCredit: {
            const {minutesLeft} = action;

            store.dispatch(runningOutOfCredit(minutesLeft));

            break;
        }

        case CameraActionType.TurnOnFailed:
        case CameraActionType.ReconfigureFailed: {
            const {reason} = action;

            switch (reason) {
                case CameraErrorReason.Blocked:
                case CameraErrorReason.AlreadyBlocked: {
                    store.dispatch(cameraBlocked());

                    break;
                }

                case CameraErrorReason.NotSupported: {
                    store.dispatch(cameraNotSupported());

                    break;
                }

                default:
                    store.dispatch(cameraError());
            }

            break;
        }

        case ChatActionType.OfferKnockout: {
            const {screenName} = action;

            store.dispatch(offerKnockout(screenName));

            break;
        }

        case LOCATION_CHANGE:
        case TopUpActionType.ShowTopUp:
        case ApplicationActionType.ShowDialog: {
            store.dispatch(hideAllSnackbar());

            break;
        }

        case ApplicationActionType.ShowRelease: {
            store.dispatch(showRelease());

            break;
        }

        case TopUpActionType.PushPromoStarted: {
            const {pushPromo: {extraCredits, credits, formattedPrice}}: { pushPromo: PushPromo } = action;

            store.dispatch(notifyPushPromo(extraCredits + credits, formattedPrice));

            break;
        }

        default:
    }

    return result;
};
