import {createStyles, WithStyles, withStyles} from "@material-ui/core";
import React, {PureComponent} from "react";
import intl from "react-intl-universal";
import ApplicationContext from "../../application/ApplicationContext";
import {ChatMessageEvent} from "../../domain/ChatMessageEvent";
import {GiftNotification} from "../../domain/GiftNotification";
import {HappyHourStartedEvent} from "../../domain/HappyHourStartedEvent";
import {PromotedToPrivateOwnerEvent} from "../../domain/PromotedToPrivateOwnerEvent";
import {clsx} from "../../helper";
import {redirectToTopUp} from "../../redirect";
import {TextWithEmoji} from "../TextWithEmoji";

const styles = createStyles({
    root: {
        flex: 1,
        minHeight: "80px",
        backgroundColor: "#454545",
        overflowY: "scroll"
    },
    member: {},
    performer: {
        "&.from": {
            color: "#4EADED"
        }
    },
    message: {
        margin: "5px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "0.8em",
        color: "#fff",
        "&.gift": {
            color: "#98bb63"
        },
        "&.echh": {
            color: "#ffc022"
        },
        "&.promotion": {}
    }
});

interface Props extends WithStyles<typeof styles> {
    messages: Array<ChatMessageEvent | GiftNotification>
}

export const ChatMessages = withStyles(styles)(class extends PureComponent<Props> {
    static contextType = ApplicationContext;

    private containerRef: HTMLDivElement;
    private atBottom: boolean;

    constructor(props) {
        super(props);

        this.setContainerRef = this.setContainerRef.bind(this);
    }

    private setContainerRef(ref) {
        this.containerRef = ref;
    }

    componentDidMount(): void {
        window.addEventListener("resize", this.scrollToBottom);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.scrollToBottom);
    }

    UNSAFE_componentWillUpdate() {
        const containerRef = this.containerRef;
        const scrollPos = Math.ceil(containerRef.scrollTop);
        const scrollBottom = (containerRef.scrollHeight - containerRef.clientHeight);

        this.atBottom = scrollBottom <= 0 || scrollPos === scrollBottom;
    }

    componentDidUpdate() {
        if (this.atBottom) {
            this.scrollToBottom();
        }
    }

    private scrollToBottom = () => {
        const containerRef = this.containerRef;
        const scrollHeight = containerRef.scrollHeight;
        const height = containerRef.clientHeight;
        const maxScrollTop = scrollHeight - height;

        containerRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    render(): React.ReactNode {
        const {classes, messages} = this.props;

        return (
            <div className={classes.root} ref={this.setContainerRef}>

                {
                    messages.map((event, index) => {
                        if (event instanceof ChatMessageEvent) {
                            if (event.isSticker()) {
                                return (
                                    <div key={index} className={clsx(classes.message, classes.member)}>
                                        <span className="from"><TextWithEmoji text={event.getName()}/>: </span>
                                        <span>
                                            <img src={`//img.a.apn2.com/asset/stickers/${String(event.getStickerId()).padStart(2, "0")}.svg`} alt="sticker" width={64} height={64}/>
                                        </span>
                                    </div>
                                );
                            }

                            if (event.isMember()) {
                                return (
                                    <div key={index} className={clsx(classes.message, classes.member)}>
                                        <span className="from"><TextWithEmoji text={event.getName()}/>: </span>
                                        <span>
                                            <TextWithEmoji text={event.getMessage()}/>
                                        </span>
                                    </div>
                                );
                            }

                            if (event.isPerformer()) {
                                return (
                                    <div key={index} className={clsx(classes.message, classes.performer)}>
                                        <span className="from"><TextWithEmoji text={event.getName()}/>: </span>
                                        <span>
                                            <TextWithEmoji text={event.getMessage()}/>
                                        </span>
                                    </div>
                                );
                            }
                        }

                        if (event instanceof GiftNotification) {
                            const gift = this.context.giftService.getById(event.giftId);
                            const message = intl.get("chat.gift-received", {
                                username: event.username,

                                // eslint-disable-next-line
                                giftName: gift && gift.name || event.giftId,
                                price: event.price
                            });

                            return (
                                <div key={index} className={clsx(classes.message, "gift")}>
                                    <TextWithEmoji text={message}/>
                                </div>
                            );
                        }

                        if (event instanceof HappyHourStartedEvent) {
                            return (
                                <div key={index}
                                     className={clsx(classes.message, "echh")}
                                     onClick={redirectToTopUp}>{intl.get("topup.extra-credit.chat", event.extraCredit)}</div>
                            );
                        }

                        if (event instanceof PromotedToPrivateOwnerEvent) {
                            const message = intl.get("chat.message.promoted-to-private-owner", {
                                screenName: event.room.screenName
                            });

                            return (
                                <div key={index} className={clsx(classes.message, "promotion")}>
                                    <TextWithEmoji text={message}/>
                                </div>
                            );
                        }

                        return null;
                    })
                }
            </div>
        );
    }
});
