import {Dialog} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback, useRef, useState} from "react";
import intl from "react-intl-universal";
import {useProgress} from "../../hooks/progress.hook";
import {redirectToRoomList} from "../../redirect";
import {SettingsModule} from "../contract/SettingsModule";
import {Body} from "../layout/Body";
import {Header} from "../layout/Header";
import {Viewport} from "../layout/Viewport";
import {SlideTransition} from "../SlideTransition";
import {ChangeEmail} from "./modules/ChangeEmail";
import {ChangePassword} from "./modules/ChangePassword";
import {DeleteAccount} from "./modules/DeleteAccount";
import {NewsletterSettings} from "./modules/NewsletterSettings";
import {RoomListAppearance} from "./modules/RoomListAppearance";
import {NotSavedDialog} from "./NotSavedDialog";

const useStyles = makeStyles({
    body: {
        padding: 0
    },
    progress: {
        marginLeft: 8
    },
    title: {
        backgroundColor: "#000",
        color: "#fff",
        paddingLeft: "12px"
    }
});

const modules = (ref, onLoad) => [
    {
        component: <ChangePassword ref={ref} onLoad={onLoad}/>,
        id: "change-password"
    },
    {
        component: <ChangeEmail ref={ref} onLoad={onLoad}/>,
        id: "change-email"
    },
    {
        component: <NewsletterSettings ref={ref} onLoad={onLoad}/>,
        id: "newsletter"
    },
    {
        component: <RoomListAppearance ref={ref} onLoad={onLoad}/>,
        id: "roomlist"
    },
    {
        component: <DeleteAccount ref={ref} onLoad={onLoad}/>,
        id: "delete-account"
    }
];

export const SettingsScreen = React.memo(() => {
    const [alertDialog, setAlertDialog] = useState<() => void>(null);
    const ref = useRef<SettingsModule>();
    const classes = useStyles();
    const [inProgress, , startProgress, stopProgress] = useProgress();
    const [expandedModuleId, setExpandedModuleId] = useState<string>(null);
    const isActiveModuleDirty = (): boolean => ref.current?.isDirty || false;
    const isActiveModuleInProgress = (): boolean => ref.current?.inProgress || false;

    const handleModuleChange = (moduleId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        const targetModuleId = isExpanded ? moduleId : null;

        if (inProgress) {
            return;
        }

        if (isActiveModuleInProgress()) {
            return;
        }

        if (isActiveModuleDirty()) {
            setAlertDialog(() => () => {
                if (targetModuleId) {
                    startProgress();
                }

                setExpandedModuleId(targetModuleId);
            });

            return;
        }

        if (targetModuleId) {
            startProgress();
        }

        setExpandedModuleId(targetModuleId);
    };

    const onClose = (): void => {
        if (isActiveModuleInProgress()) {
            return;
        }

        if (isActiveModuleDirty()) {
            setAlertDialog(() => redirectToRoomList);

            return;
        }

        redirectToRoomList();
    };

    const handleDialogAccept = useCallback(() => {
        alertDialog();
        setAlertDialog(null);
    }, [alertDialog]);

    const handleDialogClose = useCallback(() => {
        setAlertDialog(null);
    }, []);

    return (
        <>
            <Viewport color={"secondary"}>
                <Header onClose={onClose} title={intl.get("menu.settings")}/>
                <Body classes={{root: classes.body}}>
                    {
                        modules(ref, stopProgress).map(module => (
                            <Accordion key={module.id}
                                       expanded={expandedModuleId === module.id}
                                       onChange={handleModuleChange(module.id)}
                                       TransitionProps={{unmountOnExit: true}}>
                                <AccordionSummary
                                    id={module.id}
                                    expandIcon={
                                        inProgress && module.id === expandedModuleId
                                            ? <CircularProgress className={classes.progress} size={16}/>
                                            : <ExpandMoreIcon/>
                                    }>
                                    {intl.get(`settings.${module.id}.title`)}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {module.component}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Body>
            </Viewport>

            <Dialog fullScreen={false}
                    onBackdropClick={handleDialogClose}
                    open={Boolean(alertDialog)}
                    TransitionComponent={SlideTransition}>
                <NotSavedDialog onAccept={handleDialogAccept} onClose={handleDialogClose}/>
            </Dialog>
        </>
    );
});

