import {Store} from "redux";
import {ApplicationActionType} from "../action/application.action";
import {AuthActionType} from "../action/auth.action";
import {
    ConsentActionType,
    consentSet,
    giveConsentFailed,
    giveConsentSucceeded,
    initAgeLimitAck
} from "../action/consent.action";
import {User} from "../domain/authentication/User";
import {noop} from "../helper";
import {ConsentService} from "../service/ConsentService";

export const consentMiddleware = (service: ConsentService) => (store: Store) => next => action => {
    const result = next(action);

    switch (action.type) {
        case ApplicationActionType.Start: {
            service.getAck()
                .then(acknowledged => store.dispatch(initAgeLimitAck(acknowledged)));

            break;
        }

        case ConsentActionType.AcknowledgeAgeLimit: {
            service.setAck().catch(noop);

            break;
        }

        case AuthActionType.UserSet: {
            const user: User = action.user;

            if (user && user.isMember) {
                service.getConsents()
                    .then(consent => store.dispatch(consentSet(consent)))
                    .catch(noop);
            } else {
                store.dispatch(consentSet({missing: [], active: []}));
            }

            break;
        }

        case ConsentActionType.GiveConsent: {
            service.setConsents(action.consents)
                .then(consent => store.dispatch(giveConsentSucceeded(consent)))
                .catch(error => store.dispatch(giveConsentFailed(error)));

            break;
        }

        default:
    }

    return result;
};
