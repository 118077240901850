import {Action} from "redux";
import {GiftFailedReason} from "../domain/GiftFailedReason";
import {GiftId} from "../domain/GiftId";
import {JoinRoomFailedReason} from "../domain/JoinRoomFailedReason";
import {Price} from "../domain/Price";
import {RoomDto} from "../domain/RoomDto";
import {RoomId} from "../domain/RoomId";
import {RoomMode} from "../domain/RoomMode";
import {SendMessageFailedReason} from "../domain/SendMessageFailedReason";
import {StickerId} from "../domain/StickerId";
import {JoinRoomFailed, JoinRoomSucceeded} from "../domain/events";

export enum ChatActionType {
    ExitShow = "chat/exit-show",
    TipBeforeExit = "chat/tip-before-exit",

    JoinRoom = "chat/join-room",
    JoinRoomSucceeded = "chat/join-room-succeeded",
    JoinRoomFailed = "chat/join-room-failed",

    LeaveRoom = "chat/leave-room",
    LeaveRoomSucceeded = "chat/leave-room-succeeded",
    LeaveRoomFailed = "chat/leave-room-failed",

    RequestChatMode = "chat/request-chat-mode",
    RequestChatModeSucceeded = "chat/request-chat-mode-succeeded",
    RequestChatModeFailed = "chat/request-chat-mode-failed",

    RoomStatusChanged = "chat/room-status-changed",
    RoomModeSetupChanged = "chat/room-mode-setup-changed",
    RemovedFromRoom = "chat/removed-from-room",

    PromotedToPrivateOwner = "chat/promoted-to-private-owner",

    SendMessage = "chat/send-message",
    SendMessageFailed = "chat/send-message-failed",

    SendSticker = "chat/send-sticker",
    SendStickerFailed = "chat/send-sticker-failed",

    SendZzz = "chat/send-zzz",
    SendZzzSucceeded = "chat/send-zzz-succeeded",
    SendZzzFailed = "chat/send-zzz-failed",

    SendGift = "chat/send-gift",
    SendGiftSucceeded = "chat/send-gift-succeeded",
    SendGiftFailed = "chat/send-gift-failed",

    SendTip = "chat/send-tip",
    SendTipSucceeded = "chat/send-tip-succeeded",
    SendTipFailed = "chat/send-tip-failed",

    GiftReceived = "chat/gift-received",
    MessageReceived = "chat/message-received",

    CreditChanged = "chat/credit-changed",
    RunningOutOfCredit = "chat/running-out-of-credit",

    RoomPaused = "chat/room-paused",
    RoomResumed = "chat/room-resumed",

    InvitationReceived = "chat/invitation-received",
    RejectInvitation = "chat/reject-invitation",

    CheckKnockout = "chat/check-knockout",
    OfferKnockout = "chat/offer-knockout",

    OpenGiftPanel = "chat/open-gift-panel",
    GiftPanelClosed = "chat/gift-panel-closed",
}

export const rejectInvitation = () => ({
    type: ChatActionType.RejectInvitation
});

export const exitShow = (roomId: RoomId) => ({
    type: ChatActionType.ExitShow,
    roomId
});

export const tipBeforeExit = (room: RoomDto) => ({
    type: ChatActionType.TipBeforeExit,
    room
});

export const joinRoom = (roomId: RoomId, roomMode: RoomMode) => ({
    type: ChatActionType.JoinRoom,
    roomId,
    roomMode
});

// TODO remove event
export const joinRoomSucceeded = (event: JoinRoomSucceeded) => ({
    type: ChatActionType.JoinRoomSucceeded,
    event
});

// TODO remove event
export const joinRoomFailed = (event: JoinRoomFailed) => ({
    type: ChatActionType.JoinRoomFailed,
    event
});

export const leaveRoom = (roomId: RoomId) => ({
    type: ChatActionType.LeaveRoom,
    roomId
});

export const leaveRoomSucceeded = (roomId: RoomId) => ({
    type: ChatActionType.LeaveRoomSucceeded,
    roomId
});

export const leaveRoomFailed = (roomId: RoomId) => ({
    type: ChatActionType.LeaveRoomFailed,
    roomId
});

export const requestChatMode = (roomId: RoomId, roomMode: RoomMode) => ({
    type: ChatActionType.RequestChatMode,
    roomId,
    roomMode
});

export const requestChatModeSucceeded = (room: RoomDto) => ({
    type: ChatActionType.RequestChatModeSucceeded,
    room
});

export const requestChatModeFailed = (reason: JoinRoomFailedReason, roomId: RoomId, roomMode: RoomMode) => ({
    type: ChatActionType.RequestChatModeFailed,
    reason,
    roomId,
    roomMode
});

export const sendMessage = (roomId: RoomId, message: string) => ({
    type: ChatActionType.SendMessage,
    roomId,
    message
});

export const sendMessageFailed = (reason: SendMessageFailedReason, roomId: RoomId, message: string) => ({
    type: ChatActionType.SendMessageFailed,
    reason,
    roomId,
    message
});

export const sendSticker = (roomId: RoomId, stickerId: StickerId) => ({
    type: ChatActionType.SendSticker,
    roomId,
    stickerId
});

export const sendStickerFailed = (reason: SendMessageFailedReason, roomId: RoomId, stickerId: StickerId) => ({
    type: ChatActionType.SendStickerFailed,
    reason,
    roomId,
    stickerId
});

export const sendZzz = (roomId: RoomId, price: Price) => ({
    type: ChatActionType.SendZzz,
    roomId,
    price
});

export const sendZzzSucceeded = (roomId: RoomId, price: Price) => ({
    type: ChatActionType.SendZzzSucceeded,
    roomId,
    price
});

export const sendZzzFailed = (reason: GiftFailedReason, roomId: RoomId, price: Price) => ({
    type: ChatActionType.SendZzzFailed,
    reason,
    roomId,
    price
});

export const sendGift = (roomId: RoomId, giftId: GiftId, message: string, price: Price, actionOnSuccess?: Action) => ({
    type: ChatActionType.SendGift,
    roomId,
    giftId,
    message,
    price,
    actionOnSuccess
});

export const sendGiftSucceeded = (roomId: RoomId, giftId: GiftId, message: string, price: Price, nextAction?: Action) => ({
    type: ChatActionType.SendGiftSucceeded,
    roomId,
    giftId,
    message,
    price,
    nextAction
});

export const sendGiftFailed = (reason: GiftFailedReason, roomId: RoomId, giftId: GiftId, message: string, price: Price) => ({
    type: ChatActionType.SendGiftFailed,
    reason,
    roomId,
    giftId,
    message,
    price
});

export const sendTip = (roomId: RoomId, message: string, price: Price, actionOnSuccess?: Action) => ({
    type: ChatActionType.SendTip,
    roomId,
    message,
    price,
    actionOnSuccess
});

export const sendTipSucceeded = (roomId: RoomId, message: string, price: Price, nextAction?: Action) => ({
    type: ChatActionType.SendTipSucceeded,
    roomId,
    message,
    price,
    nextAction
});

export const sendTipFailed = (reason: GiftFailedReason, roomId: RoomId, message: string, price: Price) => ({
    type: ChatActionType.SendTipFailed,
    reason,
    roomId,
    message,
    price
});

export const runningOutOfCredit = (minutesLeft: number) => ({
    type: ChatActionType.RunningOutOfCredit,
    minutesLeft
});

export const checkKnockout = () => ({
    type: ChatActionType.CheckKnockout
});

export const offerKnockout = (screenName) => ({
    type: ChatActionType.OfferKnockout,
    screenName
});

export const openGiftPanel = (selectedGiftId: string) => ({
    type: ChatActionType.OpenGiftPanel,
    selectedGiftId
});

export const giftPanelClosed = () => ({
    type: ChatActionType.GiftPanelClosed
});
