import {Consent} from "../../domain/Consent";
import {RouteProps} from "react-router";
import {ConsentScreen} from "../ConsentScreen";
import {GuardCondition} from "./GuardCondition";

export class ConsentGuardCondition implements GuardCondition {
    constructor(private consent: Consent) {
    }

    getRouteProps(props): RouteProps {
        return {...props, component: ConsentScreen};
    }

    test(): boolean {
        return this.consent && this.consent.missing.length > 0;
    }
}
