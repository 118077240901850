import "core-js/stable";
import "regenerator-runtime/runtime";
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/hu";

import {CssBaseline, StylesProvider} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {ApplicationActionType} from "./action/application.action";
import ApplicationContext from "./application/ApplicationContext";
import context from "./context";
import {history} from "./service";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import {generateClassName, theme} from "./theme";
import {App} from "./ui/App";
import "./wdyr";
import * as Sentry from "@sentry/react";
import {config} from "./config/sentry";
import {isDevelopment} from "./environment";
import { Snackbar } from "./ui/Snackbar";
import {SnackbarProvider} from "./provider/Snackbar";

Sentry.init({
    enabled: process.env.REACT_APP_RELEASE !== "development",
    dsn: config.dsn,
    environment: isDevelopment ? "development" : "production",
    release: process.env.REACT_APP_RELEASE
});

ReactDOM.render(
    <StylesProvider generateClassName={generateClassName}>
        <Provider store={store}>
            <ApplicationContext.Provider value={context}>
                <MuiThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <Snackbar />
                        <CssBaseline/>
                        <App history={history}/>
                    </SnackbarProvider>
                </MuiThemeProvider>
            </ApplicationContext.Provider>
        </Provider>
    </StylesProvider>,
    document.getElementById("root")
);

setImmediate(() => store.dispatch({type: ApplicationActionType.Start}));
serviceWorker.unregister();
