import {Setup} from "../domain/c2c/Setup";

const toDeviceId = track => track.getSettings().deviceId;
const mustBeString = deviceId => typeof deviceId === "string";

export class CameraMediaStream {
    constructor(private stream: MediaStream) {
    }

    public getMediaStream(): MediaStream {
        return this.stream;
    }

    public mute() {
        this.stream?.getAudioTracks().forEach(track => track.enabled = false);
    }

    public unmute() {
        this.stream?.getAudioTracks().forEach(track => track.enabled = true);
    }

    public stop() {
        this.stream?.getTracks().forEach(track => track.stop());
        this.stream = null;
    }

    public getSetup(): Promise<Setup> {
        const {stream} = this;

        if (stream) {
            const audio = stream.getAudioTracks().map(toDeviceId).filter(mustBeString)[0] || true;
            const video = stream.getVideoTracks().map(toDeviceId).filter(mustBeString)[0] || true;

            if (audio && video) {
                return Promise.resolve({
                    audio,
                    video
                });
            }
        }

        return Promise.resolve(null);
    }
}
