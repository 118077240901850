import {createBrowserHistory} from "history";
import {setLogger} from "vchat-core";
import {Setup} from "./domain/c2c/Setup";
import {isDevelopment} from "./environment";
import {ConsoleLogger} from "./logger/infrastructure/ConsoleLogger";
import {NullLogger} from "./logger/infrastructure/NullLogger";
import {AuthenticationService} from "./service/AuthenticationService";
import {CameraService} from "./service/CameraService";
import {ConsentService} from "./service/ConsentService";
import {GiftService} from "./service/gift.service";
import {http} from "./service/http";
import {I18nService} from "./service/i18n.service";
import {KnockoutService} from "./service/knockout.service";
import {LocalStorage} from "./service/LocalStorage";
import {RoomListService} from "./service/RoomListService";
import {RoomService} from "./service/RoomService";
import {SettingsService} from "./service/settings/SettingsService";
import {SimpleStorage} from "./service/SimpleStorage";
import {StreamService} from "./service/StreamService";
import {SxRoomListService} from "./service/SxRoomListService";
import {SxRoomService} from "./service/SxRoomService";
import {MonolithTopUpService} from "./service/topup.service";
import {VisitXLogger} from "./service/visitx/VisitXLogger";
import {VxRoomService} from "./service/visitx/VxRoomService";
import {VxRoomListService} from "./service/VxRoomListService";
import {WebApiService} from "./service/webapi.service";

const forceLogger = window.localStorage.getItem("logger") === "enabled";
// eslint-disable-next-line
const logger = isDevelopment || forceLogger ? new ConsoleLogger : new NullLogger;
const history = createBrowserHistory();
// eslint-disable-next-line
const localStorage = new LocalStorage;
const i18nService = new I18nService(http, localStorage);

const authService = new AuthenticationService(http);
const webapiService = new WebApiService(authService);

const vxRoomListService = new VxRoomListService(webapiService, authService);
const vxRoomService = new VxRoomService(webapiService, logger, authService, vxRoomListService);

const sxRoomService = new SxRoomService(logger, authService);
const sxRoomListService = new SxRoomListService(http, sxRoomService);

const roomService = new RoomService(logger, sxRoomService, vxRoomService);
const roomListService = new RoomListService(sxRoomListService, vxRoomListService);

const giftService = new GiftService(http);
const streamService = new StreamService(authService, localStorage);
const consentService = new ConsentService(http, localStorage);
const topupService = new MonolithTopUpService(http, authService);
const cameraService = new CameraService(
    new SimpleStorage<Setup>("camera.setup", localStorage),
    webapiService,
    sxRoomService,
    vxRoomService
);

const knockoutService = new KnockoutService(webapiService);
const settingsService = new SettingsService(http);

setLogger(new VisitXLogger(logger));

export {
    i18nService,
    giftService,
    localStorage,
    logger,
    authService,
    streamService,
    roomService,
    roomListService,
    consentService,
    history,
    topupService,
    http,
    cameraService,
    webapiService,
    knockoutService,
    settingsService
};
