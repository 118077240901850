import Button, {ButtonProps} from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";
import React from "react";

interface ProgressButtonProps extends ButtonProps {
    label: string
    progress?: boolean
}

const useStyles = makeStyles({
    progress: {
        color: "inherit",
        marginLeft: "10px"
    }
});

export const ProgressButton = React.memo<ProgressButtonProps>(({label, progress, disabled, ...props}) => {
    const classes = useStyles();

    return (
        <Button disabled={disabled || progress} {...props}>{label}
            {
                progress &&
                <CircularProgress size={16} className={classes.progress}/>
            }
        </Button>
    );
});
