import {Chat, PicUpStream} from "vchat-core";
import {Publisher} from "../../domain/c2c/Publisher";
import {noop} from "../../helper";
import {VideoCapturer} from "../VideoCapturer";
import {SnapshotProvider} from "./SnapshotProvider";

export class VxPublisher implements Publisher {
    private stream: PicUpStream = null;

    constructor(private chat: Chat) {
    }

    public publish(ignored: MediaStream, video: HTMLVideoElement): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.chat.startUpstream()
                .then(targetSet => targetSet.jpeg[0])
                .then(({stream, fps, width, height, quality}) => {
                    const provider = new SnapshotProvider(new VideoCapturer(video), quality, width, height);
                    const upStream = new PicUpStream(provider);

                    upStream.publish(stream, fps);

                    this.stream = upStream;
                })
                .then(resolve, reason => {
                    this.unpublish();

                    reject(reason);
                });
        });
    }

    public unpublish() {
        this.stream?.stop();
        this.stream = null;
        this.chat?.stopUpstream().catch(noop);
    }
}

