import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import intl from "react-intl-universal";
import {Message as MessageDomain} from "../domain/Message";
import {MessageType} from "../domain/MessageType";
import {ProgressButton} from "./ProgressButton";

interface Props {
    getErrorMethod?: () => void;
    inProgress: boolean;
    message: MessageDomain;
    saveErrorMethod?: (text?) => void;
}

const useStyles = makeStyles({
    error: {
        color: "red"
    },
    success: {
        color: "green"
    },
    message: {
        padding: "0 0 16px 0"
    },
    button: {
        marginLeft: 8
    }
});

export const Message = React.memo<Props>(({getErrorMethod, inProgress, message, saveErrorMethod}) => {
    const classes = useStyles();

    return (
        <Box className={classes.message}>
            {
                message.type === MessageType.GET_ERROR &&
                <Box className={classes.error} display="flex" alignItems="center">

                    {
                        message.customText
                            ? <div dangerouslySetInnerHTML={{__html: message.customText}}/>
                            : intl.get("message.get.error.text")
                    }

                    {
                        getErrorMethod &&
                        <ProgressButton progress={inProgress}
                                        className={classes.button}
                                        onClick={getErrorMethod}
                                        label={intl.get("message.get.error.button")}/>
                    }
                </Box>
            }

            {
                message.type === MessageType.SAVE_ERROR &&
                <Box className={classes.error} display="flex" alignItems="center">

                    {
                        !message.customText && intl.get("message.save.error.text")
                    }

                    <div dangerouslySetInnerHTML={{__html: message.customText}}/>

                    {
                        saveErrorMethod &&
                        <ProgressButton progress={inProgress} className={classes.button}
                                        onClick={saveErrorMethod}
                                        label={intl.get("message.save.error.button")}/>
                    }
                </Box>
            }

            {
                message.type === MessageType.SAVE_SUCCESS &&
                <Box className={classes.success}>
                    {
                        !message.customText && intl.get("message.save.success.text")
                    }

                    <div dangerouslySetInnerHTML={{__html: message.customText}}/>
                </Box>
            }
        </Box>
    );
});
