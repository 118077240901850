import {SnackbarActionType} from "../action/snackbar.action";

import {Snack} from "../domain/snack/Snack";

export interface SnackbarState {
    snack: Snack
}

const initialState: SnackbarState = {
    snack: null
};

export function snackbarReducer(state: SnackbarState = initialState, action): SnackbarState {
    const {snack, type} = action;

    if (type === SnackbarActionType.HideAll) {
        return {
            snack: null
        };
    }

    if (type === SnackbarActionType.Show) {
        return {
            snack
        };
    }

    return state;
}
