import {BehaviorSubject, Observable, Subject} from "rxjs";
import {config} from "../config/h5live";
import {Player, PlayerState} from "../domain/Player";
import {PlayerError} from "../domain/PlayerError";
import {StreamData} from "../domain/StreamData";

export class H5LivePlayer implements Player {

    private placeholder: HTMLDivElement = document.getElementById("nanoplayer-placeholder") as HTMLDivElement;
    private container: HTMLDivElement = document.getElementById("nanoplayer-container") as HTMLDivElement;
    private player: NanoPlayer = new NanoPlayer("nanoplayer-container");
    private stream: StreamData = null;
    private configured: boolean = false;
    private muteStateSubject: Subject<boolean> = new BehaviorSubject(true);
    private playerStateSubject: Subject<PlayerState> = new BehaviorSubject(PlayerState.Stopped);

    public static isSupported(): boolean {
        return NanoPlayer.capabilities.includes("h5live");
    }

    private static convertStreamDataToSource(data: StreamData) {
        return {
            h5live: {
                server: data.h5live,
                rtmp: {
                    url: data.rtmp.address,
                    streamname: "aac_".concat(data.rtmp.streamName)
                }
            }
        };
    }

    public getElement(): HTMLElement {
        return this.container;
    }

    public isPlaying(stream: StreamData): boolean {
        return this.stream && this.stream?.rtmp?.streamName === stream?.rtmp?.streamName;
    }

    public muteState(): Observable<boolean> {
        return this.muteStateSubject;
    }

    public playState(): Observable<PlayerState> {
        return this.playerStateSubject;
    }

    public destroy(): void {
        this.stop();
        this.player.destroy();
        this.container = null;
    }

    public play(streamData: StreamData, muted): Promise<void> {
        const source = H5LivePlayer.convertStreamDataToSource(streamData);

        this.stream = streamData;

        return (
            this.configured
                ? this.player.updateSource(source)
                : this.configure(source, muted)
        ).catch(() => {
            this.stream = null;
            this.configured && this.player.pause();
            this.playerStateSubject.next(PlayerState.Error);

            return Promise.reject(new PlayerError("NanoPlayer failed to start"));
        });
    }

    public stop(): void {
        this.stream = null;
        this.configured && this.player.pause();
        this.placeholder.appendChild(this.container);
    }

    public mute(): void {
        this.muteStateSubject.next(true);
        this.configured && this.player.mute();
    }

    public unmute(): void {
        this.muteStateSubject.next(false);
        this.configured && this.player.unmute();
    }

    private configure(source, muted: boolean): Promise<any> {
        const newConfig = Object.assign({}, config, {
            source,
            muted,
            events: {
                onError: () => this.playerStateSubject.next(PlayerState.Error),
                onLoading: () => this.playerStateSubject.next(PlayerState.Loading),
                onPlay: () => this.playerStateSubject.next(PlayerState.Playing),
                onPause: () => this.playerStateSubject.next(PlayerState.Stopped),
                onMute: () => this.muteStateSubject.next(true),
                onUnmute: () => this.muteStateSubject.next(false)
            }
        });

        return this.player.setup(newConfig).then(() => this.configured = true);
    }
}
