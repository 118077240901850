import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {AuthActionType} from "../action/auth.action";
import {baseUrl} from "../config/application";
import {User} from "../domain/authentication/User";
import {noop} from "../helper";
import {Http} from "../service/http";

export const fingerprintMiddleware = (http: Http) => () => {
    return next => action => {
        if (action.type === AuthActionType.UserSet) {
            const {user}: { user: User } = action;

            if (user?.isMember === false) {
                FingerprintJS.load()
                    .then(fp => fp.get())
                    .then(result => result.visitorId)
                    .then(visitorId => {
                        const body = new FormData();

                        body.set("fp", visitorId);

                        return http.post(baseUrl.concat("/ajax/fingerprinter"), {body});
                    }).catch(noop);
            }
        }

        next(action);
    };
};
