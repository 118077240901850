import {Store} from "redux";

export const nextMiddleware = () => (store: Store) => {
    return next => action => {
        const result = next(action);
        const {nextAction} = action;

        if (nextAction) {
            store.dispatch(nextAction);
        }

        return result;
    };
};
