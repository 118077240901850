import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React, {ImgHTMLAttributes, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {isIntersectionObserverSupported, useIntersectionObserver} from "../context/IntersectionObserverContext";
import {clsx} from "../helper";

interface LazyImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "loading"> {
    fallbackSourceOnError?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        visibility: "hidden"
    },
    loaded: {
        visibility: "visible"
    },
    failed: {}
}));

const hasNativeLoadingSupport = "loading" in HTMLImageElement.prototype;

const unLazifyImage = (image: HTMLImageElement): void => {
    image.src = image.dataset.src;
};

const onePixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

type ExtraAttributes = { "data-src": string; src: string } | { src: string; loading: string } | { src: string };

export const LazyImage = React.memo<LazyImageProps>(({className, fallbackSourceOnError, src, alt, ...props}) => {
    const classes = useStyles();
    const [failed, setFailed] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const observer = useIntersectionObserver();
    const imageRef = useRef<HTMLImageElement>(null);

    const handleIntersection = useCallback(target => {
        observer.unobserve(target);
        unLazifyImage(target as HTMLImageElement);
    }, [observer]);

    const handleLoad = useCallback((event: SyntheticEvent<HTMLImageElement>) => {
        const isLoaded = event.currentTarget.src.endsWith(src);

        setLoaded(isLoaded);
    }, [src]);

    const handleError = useCallback(() => setFailed(true), []);

    const extraAttributes: ExtraAttributes = useMemo(() => {
        if (failed) {
            return {
                src: fallbackSourceOnError || onePixel
            };
        }

        if (isIntersectionObserverSupported) {
            return {
                "data-src": src,
                src: onePixel
            };
        }

        if (hasNativeLoadingSupport) {
            return {
                loading: "lazy",
                src
            };
        }

        return {
            src
        };
    }, [failed, src, fallbackSourceOnError]);

    useEffect(() => {
        if (failed) {
            return;
        }

        const target = imageRef.current;

        if (isIntersectionObserverSupported && target) {
            observer.observe(target, handleIntersection);

            return () => observer.unobserve(target);
        }
    }, [failed, src, observer, handleIntersection]);

    return <img
        {...props}
        {...extraAttributes as ImgHTMLAttributes<HTMLImageElement>}
        className={clsx(className, classes.root, loaded ? classes.loaded : null, failed ? classes.failed : null)}
        alt={alt}
        ref={imageRef}
        onLoad={handleLoad}
        onError={handleError}
    />;
});
