import {
    Box,
    Checkbox,
    Container,
    createStyles,
    FormControlLabel,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import React, {Fragment, PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {giveConsent} from "../action/consent.action";
import {Consent} from "../domain/Consent";
import {ConsentState} from "../reducer/consent.reducer";
import {Header} from "./layout/Header";
import {Viewport} from "./layout/Viewport";
import {ProgressButton} from "./ProgressButton";

const styles = createStyles({
    root: {},
    field: {
        border: 0,
        backgroundColor: "#fff"
    },
    header: {
        padding: "10px",
        backgroundColor: "#000"
    },
    footer: {
        textAlign: "center",
        backgroundColor: "#000",
        padding: "5px",
        "& a, & a:visited": {
            textDecoration: "none",
            color: "#009CDE"
        }
    },
    label: {
        color: "#000"
    }
});

interface State {
    accepted: object
}

interface Props extends WithStyles<typeof styles> {
    consent: ConsentState
    submitConsents: (consents: Array<string>) => void
}

class ConsentScreen extends PureComponent<Props, State> {
    state = {
        accepted: {}
    };

    private types = [
        "email_system",
        "email_newsletter",
        "purchase_data",
        "age_over_18"
    ];

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    initState() {
        const consent: Consent = this.props.consent.consent;
        const accepted = this.types.reduce((map, type) => {
            map[type] = consent.active.includes(type);

            return map;
        }, {});

        this.setState({
            accepted
        });
    }

    componentDidMount(): void {
        this.initState();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.consent !== prevProps.consent) {
            this.initState();
        }
    }

    onCheck(type, checked) {
        this.setState({
            accepted: {
                ...this.state.accepted,
                [type]: checked
            }
        });
    }

    isChecked(type): boolean {
        return Boolean(this.state.accepted[type]);
    }

    isSubmitDisabled(): boolean {
        const {consent: {consent}} = this.props;

        return consent ? consent.missing.every(this.isChecked, this) === false : true;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.submitConsents(Object.keys(this.state.accepted));
    }

    render(): ReactNode {
        const {classes, consent: {error, consent, inProgress}} = this.props;

        return (
            <Viewport color={"secondary"}>
                <Header title={intl.get("consent.title")}/>
                <Box flex={1} style={{overflowY: "auto"}}>
                    <Container style={{paddingTop: "8px", paddingBottom: "8px"}}>
                        <Typography style={{
                            color: "#000"
                        }}>{intl.get("consent.description", {organization: "R.A.L. Rati Art Limited"})}</Typography>

                        {
                            error &&
                            <Typography style={{
                                textAlign: "center",
                                paddingBottom: "8px",
                                color: "red"
                            }}>{intl.get("consent.error")}</Typography>
                        }

                        <form onSubmit={this.handleSubmit}>

                            {
                                this.types.map(type => (
                                    <Fragment key={type}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={consent ? inProgress : true}
                                                    required={consent && consent.missing.includes(type)}
                                                    checked={this.isChecked(type)}
                                                    onChange={event => this.onCheck(type, event.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            labelPlacement="end"
                                            label={intl.getHTML(`consent.${type}.title`)}
                                        />
                                        <Typography style={{
                                            color: "#000",
                                            marginLeft: "32px"
                                        }} variant="body2">{intl.getHTML(`consent.${type}.description`)}</Typography>
                                    </Fragment>
                                ))
                            }

                            <Typography style={{
                                color: "#000",
                                margin: "8px 0"
                            }}>{intl.getHTML("consent.cancellation", {
                                notifymail: "privacy@apn2.com"
                            })}</Typography>

                            <Box textAlign="right">
                                <ProgressButton
                                    disabled={this.isSubmitDisabled()}
                                    progress={inProgress}
                                    label={intl.get("consent.submit")}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                />
                            </Box>
                        </form>
                    </Container>
                </Box>
            </Viewport>
        );
    }
}

const mapStateToProps = ({consent}) => ({
    consent
});

const mapDispatchToProps = dispatch => ({
    submitConsents: (consents: Array<string>) => dispatch(giveConsent(consents))
});

const component = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsentScreen));

export {
    component as ConsentScreen
};
