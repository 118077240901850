import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import {CSSProperties} from "@material-ui/styles";
import React from "react";
import {CanGoBack} from "../contract/CanGoBack";
import {Closable} from "../contract/Closable";
import {Disableable} from "../contract/Disableable";
import {Toolbar} from "./Toolbar";

const base: CSSProperties = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flex: 1
};

const withMargin: CSSProperties = {...base, marginLeft: 12};
const withoutMargin: CSSProperties = {...base, marginLeft: 0};

interface Props extends CanGoBack, Closable, Disableable {
    title?: string
}

export const Header = React.memo<Props>(({disabled = false, onBack = null, onClose = null, title = null}) => (
    <Toolbar>
        {
            onBack && <IconButton disabled={disabled} onClick={onBack}><LeftIcon/></IconButton>
        }

        <div style={onBack ? withoutMargin : withMargin}>
            {
                title && <Typography variant="caption">{title}</Typography>
            }
        </div>

        {
            onClose && <IconButton disabled={disabled} onClick={onClose}><CloseIcon/></IconButton>
        }
    </Toolbar>
));
