import {makeStyles} from "@material-ui/styles";
import React, {PropsWithChildren} from "react";
import {clsx} from "../../helper";
import {ClassNameHolder} from "../contract/ClassNameHolder";

enum Color {
    PRIMARY = "primary",
    SECONDARY = "secondary"
}

interface Props extends ClassNameHolder {
    color?: "primary" | "secondary"
}

const useStyles = makeStyles({
    root: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column"
    },
    primary: {
        backgroundColor: "#000",
        color: "#fff"
    },
    secondary: {
        backgroundColor: "#fff",
        color: "#000"
    }
});

export const Viewport = React.memo<PropsWithChildren<Props>>(({children, color = Color.PRIMARY, className}) => {
    const classes = useStyles();

    return <div children={children} className={clsx(classes.root, color === Color.SECONDARY ? classes.secondary : classes.primary, className)}/>;
});

Viewport["whyDidYouRender"] = false;
