import {Box, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useFormik} from "formik";
import React, {ChangeEvent, useCallback, useEffect, useImperativeHandle, useState} from "react";
import intl from "react-intl-universal";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {MessageType} from "../../../domain/MessageType";
import {LiveFilter} from "../../../domain/settings/roomlist/LiveFilter";
import {
    LiveFilters,
    RoomListAppearance as RoomListAppearanceInterface
} from "../../../domain/settings/roomlist/RoomListAppearance";
import {noop} from "../../../helper";
import {useDataLoaded} from "../../../hooks/data-loaded.hook";
import {useMessage} from "../../../hooks/message.hook";
import {useProgress} from "../../../hooks/progress.hook";
import {Loadable} from "../../contract/Loadable";
import {SettingsModule} from "../../contract/SettingsModule";
import {Message} from "../../Message";

interface FormValues {
    liveFilter: LiveFilter;
}

const initialValues: FormValues = {
    liveFilter: LiveFilter.DEFAULT
};

const useStyles = makeStyles({
    root: {},
    rootContainer: {
        padding: "8px 16px 16px"
    },
    description: {
        marginBottom: 8,
        textAlign: "justify"
    },
    liveFilter: {
        marginBottom: 8
    }
});

interface Props extends Loadable {
}

export const RoomListAppearance = React.forwardRef<SettingsModule, Props>(({onLoad}, ref) => {
    const classes = useStyles();
    const [liveFilters, setLiveFilters] = useState<LiveFilters>({});
    const {settingsService} = useApplicationContext();
    const {dataLoaded, setDataLoaded} = useDataLoaded();
    const [inProgress, trackProgress] = useProgress();
    const {message, resetMessage, setErrorOnGet, setErrorOnSave, setSuccessOnSave} = useMessage();
    const formik = useFormik<FormValues>({
        initialValues,
        onSubmit: values => saveDataToServer(values)
    });

    const setValues = ({liveFilters, liveFilter}: RoomListAppearanceInterface) => {
        setLiveFilters(liveFilters);
        formik.resetForm({
            values: {
                liveFilter
            }
        });
    };

    const getDataFromServer = trackProgress(() => {
        return settingsService.getRoomListAppearance().then(values => {
            setValues(values);
            resetMessage();
            setDataLoaded();
        }).catch(() => {
            setErrorOnGet();
        });
    });

    const saveDataToServer = trackProgress(values => {
        return settingsService.setRoomListAppearance(values).then(values => {
            const {result} = values;

            setValues(values);

            if (result.success) {
                setSuccessOnSave();
            } else {
                setErrorOnSave();
            }
        }).catch(() => {
            setErrorOnSave();
        });
    });

    const handleFormChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (message) {
            resetMessage();
        }

        formik.handleChange(event);
        formik.submitForm().catch(noop);
    }, [formik, message, resetMessage]);

    useImperativeHandle(ref, (): SettingsModule => ({
        isDirty: formik.dirty,
        inProgress: inProgress
    }));

    useEffect(() => {
        getDataFromServer().finally(onLoad);
        // eslint-disable-next-line
    }, []);

    return (
        <Box className={classes.root}>

            {
                message?.type === MessageType.GET_ERROR &&
                <Box className={classes.rootContainer}>
                    <Message getErrorMethod={getDataFromServer} inProgress={inProgress} message={message}/>
                </Box>
            }

            {
                dataLoaded &&
                <Box className={classes.rootContainer}>
                    <section className={classes.description}>
                        {intl.get("settings.roomlist.description")}
                    </section>

                    {
                        message &&
                        <Message inProgress={inProgress} message={message}/>
                    }

                    <form onSubmit={formik.handleSubmit}>
                        <RadioGroup
                            name="liveFilter"
                            value={formik.values.liveFilter}
                            onChange={handleFormChange}
                        >
                            {
                                Object.entries(liveFilters).map(([value, label]) => (
                                    <FormControlLabel
                                        key={value}
                                        className={classes.liveFilter}
                                        disabled={inProgress}
                                        value={value}
                                        control={<Radio/>}
                                        label={label}
                                    />
                                ))
                            }
                        </RadioGroup>
                    </form>
                </Box>
            }
        </Box>
    );
});
