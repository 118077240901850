export enum JoinRoomFailedReason {
    DifferentMode = "differentMode",
    GuestNotAllowed = "guestNotAllowed",
    RoomNotFound = "roomNotFound",
    RoomIsExclusive = "roomIsExclusive",
    InvalidRoomMode = "invalidRoomMode",
    ModeChangeInProgress = "modeChangeInProgress",
    AlreadyJoined = "alreadyJoined",
    Banned = "banned",
    NotEnoughCredits = "notEnoughCredits",
    Other = "other"
}
