export enum Feature {
    SignUp = "signUp",
    Chat = "chat",
    Emoji = "emoji",

    Tip = "tip",

    GiftShow = "gift:show",
    Gift = "gift",

    ZzzShow = "zzz:show",
    Zzz = "zzz",

    StickerShow = "sticker:show",
    GirlSticker = "girlSticker",
    CoupleSticker = "coupleSticker",

    TopUp = "topUp",
    SmartToy = "hasSmartToy",

    Camera = "camera",

    MicrophoneShow = "microphone:show",
    Microphone = "microphone",

    Knockout = "knockout",

    Update = "update"
}
