import {Box, Typography} from "@material-ui/core";
import React, {Fragment, FunctionComponent, useCallback, useMemo} from "react";
import intl from "react-intl-universal";
import {useSelector} from "react-redux";
import {RoomDto} from "../../../domain/RoomDto";
import {redirectToRoom, redirectToRoomList} from "../../../redirect";
import {RootState} from "../../../reducer";
import {CountdownButton} from "../../CountdownButton";
import {Viewport} from "../../layout/Viewport";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import {useStyles} from "./styles";

interface Props {
    title: string
}

export const ComingUpNextLobby: FunctionComponent<Props> = React.memo<Props>(({children, title}) => {
    const classes = useStyles();
    const room: RoomDto = useSelector<RootState, RoomDto>(state => state.rooms.upcoming);
    const handleClick = useCallback(() => room ? redirectToRoom(room.roomid) : redirectToRoomList(), [room]);
    const label = useMemo(() => room ? intl.get("common.button.to-room", room) : intl.get("common.button.back-to-rooms"), [room]);

    return (
        <Viewport>
            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                {
                    room ? (
                        <Fragment>
                            <Typography className={classes.message}>{title}</Typography>
                            <CircleProfilePicture src={room?.publicData?.profilePic}/>
                            <Typography className={classes.message}>{intl.get("lobby.coming-up", room)}</Typography>
                        </Fragment>
                    ) : (children || <Typography className={classes.message}>{title}</Typography>)
                }

            </Box>
            <CountdownButton onClick={handleClick} label={label}/>
            <ExitToRoomListButton/>
        </Viewport>
    );
});
