import {Observable} from "rxjs";
import {filter} from "rxjs/operators";
import {SignalingChannel} from "../domain/c2c/SignalingChannel";
import {Cam2CamSignalingEvent} from "../domain/Cam2CamSignalingEvent";
import {RoomId} from "../domain/RoomId";
import {SxRoomService} from "../service/SxRoomService";

export class RoomServiceSignalingChannel implements SignalingChannel {
    constructor(
        private readonly roomService: SxRoomService,
        private readonly roomId: RoomId
    ) {
    }

    get events(): Observable<Cam2CamSignalingEvent> {
        return this.roomService.events.pipe(
            filter(event => event instanceof Cam2CamSignalingEvent),
            filter(event => event.roomId === this.roomId)
        );
    };

    public signalCandidate(candidate: RTCIceCandidate): Promise<void> {
        return this.roomService.webRtcCandidate(this.roomId, candidate);
    }

    public signalOffer(offer: RTCSessionDescriptionInit) {
        return this.roomService.webRtcOffer(this.roomId, offer);
    }
}
