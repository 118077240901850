import {BehaviorSubject, noop, Observable, Subject} from "rxjs";
import {filter, first} from "rxjs/operators";
import io from "socket.io-client";
import {config} from "../config/chat";
import {User} from "../domain/authentication/User";
import {Cam2CamSignalingEvent} from "../domain/Cam2CamSignalingEvent";
import {ChatMessageEvent} from "../domain/ChatMessageEvent";
import {CreditChanged} from "../domain/CreditChanged";
import {DataURI} from "../domain/DataURI";
import {SendGiftFailedDto} from "../domain/dto/SendGiftFailedDto";
import {
    AuthenticationFailed,
    FavoriteListUpdated,
    FavoriteUpdated,
    JoinRoomFailed,
    JoinRoomSucceeded,
    LeaveRoomFailed,
    LeaveRoomSucceeded,
    PromotedToPrivateOwner,
    ReleaseReceived,
    RequestChatModeFailed,
    RoomModeSetupChanged,
    RoomStatusChanged,
    SendGiftFailed,
    SendGiftSucceeded,
    TechnicalError
} from "../domain/events";
import {GiftId} from "../domain/GiftId";
import {GiftNotification} from "../domain/GiftNotification";
import {Invitation} from "../domain/Invitation";
import {ModeSetupUpdateData} from "../domain/ModeSetupUpdateData";
import {Price} from "../domain/Price";
import {RemovedFromRoom} from "../domain/RemovedFromRoom";
import {RoomDto} from "../domain/RoomDto";
import {RoomId} from "../domain/RoomId";
import {RoomMode} from "../domain/RoomMode";
import {RoomPaused} from "../domain/RoomPaused";
import {RoomResumed} from "../domain/RoomResumed";
import {StickerId} from "../domain/StickerId";
import {TipType} from "../domain/TipType";
import {ChatMessageDto} from "../dto/ChatMessageDto";
import {GiftNotificationDto} from "../dto/GiftNotificationDto";
import {Logger} from "../logger/Logger";
import {AuthenticationService} from "./AuthenticationService";

export class SxRoomService {

    private socket: SocketIOClient.Socket;

    private readonly subject: Subject<any>;

    private readonly _ready: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get events(): Observable<any> {
        return this.subject;
    }

    get onReady(): Observable<boolean> {
        return this._ready;
    }

    private user: User;

    constructor(
        private logger: Logger,
        private authService: AuthenticationService
    ) {
        // eslint-disable-next-line
        this.subject = new Subject;
        this.authService.user.subscribe(user => user && this.connectUser(user));
    }

    private pingInterval: number = 5000;
    private pingTimer;
    private pingCheckTimer;

    private startPing() {
        this.stopPing();
        this.pingTimer = setInterval(this.ping.bind(this), this.pingInterval);
    }

    private stopPing() {
        clearTimeout(this.pingCheckTimer);
        clearInterval(this.pingTimer);
    }

    private ping() {
        if (this.socket.disconnected) {
            return;
        }

        clearTimeout(this.pingCheckTimer);
        this.pingCheckTimer = setTimeout(this.disconnect.bind(this), this.pingInterval * 2);

        this.socket.emit("ping", {
            ping: true
        }, noop);
    }

    public sendTip(roomId: RoomId, message: string, price: Price): Promise<SendGiftSucceeded> {
        return this.sendGift(roomId, TipType.Tip, message, price);
    }

    public sendZzz(roomId: RoomId, price: Price): Promise<SendGiftSucceeded> {
        return this.sendGift(roomId, TipType.SmartToy, "", price);
    }

    public sendGift(roomId: RoomId, giftId: GiftId, message: string, price: Price): Promise<SendGiftSucceeded> {
        return new Promise((resolve, reject) => {
            const subscription = this.events.pipe(
                filter(event => event instanceof SendGiftSucceeded || event instanceof SendGiftFailed),
                first()
            ).subscribe(event => {
                subscription.unsubscribe();

                if (event instanceof SendGiftSucceeded) {
                    resolve(event);
                } else {
                    reject(event);
                }
            });

            this.socket.emit("call", {
                method: "sendGift",
                roomid: roomId,
                message,
                giftid: giftId,
                price,
                extra: {
                    price
                }
            });
        });
    }

    public webRtcOffer(roomId: RoomId, description: RTCSessionDescriptionInit): Promise<void> {
        return this.camToCamSignaling(roomId, {
            type: "offer",
            description
        });
    }

    public webRtcCandidate(roomId: RoomId, candidate: RTCIceCandidate): Promise<void> {
        return this.camToCamSignaling(roomId, {
            type: "newIceCandidate",
            candidate
        });
    }

    private camToCamSignaling(roomId: RoomId, message: object): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.socket.emit("call", {
                method: "cam2camSignaling",
                roomid: roomId,
                message
            }, response => {
                if (response.success) {
                    resolve();
                } else {

                    // TODO better error handling
                    reject(response.reason);
                }
            });
        });
    }

    public startCamToCam(): Promise<void> {
        return new Promise(resolve => {
            this.socket.emit("call", {
                method: "startCamStream",
                mode: "webrtc",
                autoCam2Cam: true
            });

            resolve();
        });

    }

    public stopCamToCam(): Promise<void> {
        return new Promise(resolve => {
            this.socket.emit("call", {
                method: "stopCamStream"
            });

            resolve();
        });
    }

    public sendSticker(roomId: RoomId, stickerId: StickerId) {
        this.sendMessage(roomId, "#sticker:".concat(stickerId));
    }

    public sendMessage(roomId: RoomId, message: string) {
        this.socket.emit("call", {
            method: "sendChatMessage",
            roomid: roomId,
            message: {
                type: "chat",
                text: message
            }
        });
    }

    public sendSnapshot(uri: DataURI): Promise<void> {
        return new Promise((resolve, reject) => {
            this.socket.emit("call", {
                method: "uploadSnapshot",
                data: uri ? uri.split(",", 2)[1] : null,
                format: "image/jpeg;base64",
                asProfilePicture: false,
                asCam2CamSnapshot: true
            }, success => success ? resolve() : reject());
        });

    }

    public join(roomId: RoomId, mode: RoomMode): Promise<JoinRoomSucceeded> {
        return new Promise((resolve, reject) => {
            const subscription = this.events.pipe(
                filter(event => event instanceof JoinRoomSucceeded || event instanceof JoinRoomFailed),
                first()
            ).subscribe(event => {
                subscription.unsubscribe();

                if (event instanceof JoinRoomSucceeded) {
                    resolve(event);
                } else {
                    reject(event);
                }
            });

            this.socket.emit("call", {
                method: "joinRoom",
                roomid: roomId,
                mode,
                dropOldClient: true
            });
        });
    }

    public requestChatMode(roomId: RoomId, mode: RoomMode): Promise<JoinRoomSucceeded> {
        let subscription;

        const secondPromise = new Promise((resolve, reject) => {
            subscription = this.events.pipe(
                filter(event => event instanceof JoinRoomSucceeded || event instanceof JoinRoomFailed),
                first()
            ).subscribe(event => {
                subscription.unsubscribe();

                if (event instanceof JoinRoomSucceeded) {
                    resolve(event);
                } else {
                    reject(event);
                }
            });
        });

        const firstPromise = new Promise((resolve, reject) => {
            this.socket.emit("call", {
                method: "requestChatMode",
                roomid: roomId,
                mode
            }, response => {
                if (response.success) {
                    resolve();
                } else {
                    reject(new RequestChatModeFailed(response.reason));
                }
            });
        }).catch(error => {
            subscription && subscription.unsubscribe();

            return Promise.reject(error);
        });

        return Promise.all([firstPromise, secondPromise]).then(([unused, response]) => response as JoinRoomSucceeded);
    }

    public leave(roomId: RoomId): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.socket.disconnected) {
                reject();
                return;
            }

            const subscription = this.events.pipe(
                filter(event => event instanceof LeaveRoomSucceeded || event instanceof LeaveRoomFailed),
                first()
            ).subscribe(event => {
                subscription.unsubscribe();

                if (event instanceof LeaveRoomSucceeded) {
                    resolve();
                } else {
                    reject();
                }
            });

            this.socket.emit("call", {
                method: "leaveRoom",
                roomid: roomId
            });
        });
    }

    private onAuthenticated() {
        this.logger.info("socket authenticated");
        this.startPing();

        this.socket.emit("call", {
                method: "registerRoomStatusCallback",
                filter: {}
            }
        );

        this._ready.next(true);
    }

    private authenticate() {
        const user: User = this.user;

        if (!user) {
            return;
        }

        const auth = user.auth;

        let request;

        if (user.isMember) {
            request = {
                authenticate: "member",
                userbase: "sexchat",
                username: auth.userName,
                memberid: auth.memberID,
                preauth: auth.preAuth,
                webid: auth.webID,
                tokenid: auth.tokenID
            };
        } else {
            request = {
                authenticate: "guest",
                userbase: "sexchat",
                webid: auth.webID
            };
        }

        this.socket.emit("call", request);
    }

    private onConnected() {
        this.logger.info("socket connected");
        this.authenticate();
    }

    private connectUser(user: User) {
        this.user = user;
        this.connect();
    }

    private connect() {
        this.disconnect();

        this.socket = io(config.chatServer, {
            upgrade: false,
            transports: ["websocket"],
            autoConnect: false,
            reconnection: false,
            forceNew: true
        });

        this.initListeners();

        this.socket.connect();
    }

    private initListeners() {
        const {socket, subject} = this;

        socket.once("connect", () => this.onConnected());
        socket.once("authenticated", () => this.onAuthenticated());
        socket.once("authenticated", data => {
            const mobileVersion = data?.componentVersions?.mobileVersion;

            if (mobileVersion) {
                this.subject.next(new ReleaseReceived(mobileVersion));
            }
        });

        socket.on("favList", roomIds => {
            subject.next(new FavoriteListUpdated(roomIds));
        });

        socket.on("favUpdate", data => {
            subject.next(new FavoriteUpdated(data.roomid, data.status));
        });

        socket.on("creditChange", data => {
            subject.next(new CreditChanged(data.credit));
        });

        socket.on("leaveRoomSuccessful", () => {
            // eslint-disable-next-line
            subject.next(new LeaveRoomSucceeded);
        });

        socket.on("leaveRoomFailed", () => {
            // eslint-disable-next-line
            subject.next(new LeaveRoomFailed);
        });

        socket.on("joinRoomSuccessful", (room: RoomDto) => {
            subject.next(new JoinRoomSucceeded(room));
        });

        socket.on("joinRoomFailed", error => {
            subject.next(new JoinRoomFailed(error.reason, error));
        });

        socket.on("chatMessage", (message: ChatMessageDto) => {
            subject.next(new ChatMessageEvent(message));
        });

        socket.on("giftNotification", (data: GiftNotificationDto) => {
            subject.next(new GiftNotification(data));
        });

        socket.on("sendGiftSuccess", data => {
            subject.next(new SendGiftSucceeded(data.giftid, data.extra.price));
        });

        socket.on("sendGiftFailed", (data: SendGiftFailedDto) => {
            subject.next(new SendGiftFailed(data.failureReason));
        });

        socket.on("roomStatusChange", (room: RoomDto) => {
            subject.next(new RoomStatusChanged(room));
        });

        socket.on("promotedToPrivateOwner", data => {
            subject.next(new PromotedToPrivateOwner(data.roomid));
        });

        socket.on("roomModeSetupChange", (data: ModeSetupUpdateData) => {
            subject.next(new RoomModeSetupChanged(data));
        });

        socket.on("roomPaused", data => {
            subject.next(new RoomPaused(data.roomid));
        });

        socket.on("roomResumed", data => {
            subject.next(new RoomResumed(data.roomid));
        });

        // TODO do we need this?
        // socket.on("forceTerminate", data => {
        // console.log("forceTerminate", data);
        // });

        socket.on("removedFromRoom", data => {
            subject.next(new RemovedFromRoom(data.reason, data.roomid));
        });

        socket.on("technicalError", () => {
            // eslint-disable-next-line
            subject.next(new TechnicalError);
        });

        socket.on("authentiationFailed", () => {
            // eslint-disable-next-line
            subject.next(new AuthenticationFailed);
        });

        socket.on("postAuthenticationFailed", () => {
            // eslint-disable-next-line
            subject.next(new AuthenticationFailed);
        });

        socket.on("componentVersionChanged", data => {
            const mobileVersion = data?.mobileVersion;

            if (mobileVersion) {
                this.subject.next(new ReleaseReceived(mobileVersion));
            }
        });

        socket.on("metaData", data => {
            const {room, message} = data;
            const {type, text} = message || {};
            let roomMode: RoomMode = null;

            switch (type) {
                case "invite_vip": {
                    roomMode = RoomMode.Group;
                    break;
                }

                case "invite_private": {
                    roomMode = RoomMode.Private;
                    break;
                }

                default:
            }

            if (room && roomMode) {
                const msg = typeof text === "string" ? (text as string).trim() || null : null;

                subject.next(new Invitation(room, roomMode, msg));
            }
        });

        socket.on("cam2camSignaling", ({roomid, type, candidate, description}) => {
            subject.next(new Cam2CamSignalingEvent(roomid, type, candidate, description));
        });
    }

    private disconnect() {
        const {socket} = this;

        this._ready.next(false);
        this.logger.debug("disconnected");
        this.stopPing();

        if (socket && socket.connected) {
            socket.disconnect();
        }
    }

    public suspend() {
        this.disconnect();
    }

    public addFavorite(roomId: RoomId): void {
        this.socket.emit("call", {
            method: "setFav",
            roomid: roomId,
            status: true
        });
    }

    public removeFavorite(roomId: RoomId): void {
        this.socket.emit("call", {
            method: "setFav",
            roomid: roomId,
            status: false
        });
    }
}
