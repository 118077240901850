import {ChatActionType} from "../action/chat.action";
import {ActivityInitiator} from "../domain/ActivityInitiator";
import {ActivityState} from "../domain/ActivityState";

const initialState: ActivityState = {
    idle: true,
    lastActivityBy: ActivityInitiator.SYSTEM
};

export function idleReducer(state: ActivityState = initialState, action): ActivityState {
    const {lastActivityBy} = state;
    const {type, initiator} = action;

    switch (type) {

        // FIXME due to a UI bug, where controls stuck on screen, we initiate a idle
        case ChatActionType.InvitationReceived: {
            return {
                idle: true,
                lastActivityBy: ActivityInitiator.USER
            };
        }

        case "idle/idle": {
            return {
                idle: true,
                lastActivityBy
            };
        }

        case "idle/activity": {
            return {
                idle: false,
                lastActivityBy: initiator
            };
        }

        default:
    }

    return state;
}
