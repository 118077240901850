import {Store} from "redux";
import {ChatActionType} from "../action/chat.action";
import {ActivityInitiator} from "../domain/ActivityInitiator";

export const idleMiddleware = () => (store: Store) => {
    const {dispatch} = store;

    let timer;

    const start = () => {
        stop();
        timer = setTimeout(idle, 3000);
    };

    const stop = () => clearTimeout(timer);

    const idle = () => dispatch({
        type: "idle/idle"
    });

    return next => action => {
        const {type} = action;

        if (type === "idle/activity") {
            start();
        }

        if (type === ChatActionType.JoinRoom) {
            dispatch({
                type: "idle/activity",
                initiator: ActivityInitiator.SYSTEM
            });
        }

        return next(action);
    };
};
