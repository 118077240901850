import {ChatActionType} from "../action/chat.action";
import {Price} from "../domain/Price";
import {zzzSteps} from "../domain/zzzSteps";
import {localStorage} from "../service";

export interface ZzzState {
    step: number
    inProgress: boolean
}

// TODO move this into a middleware
const retrieveValue = (): number => Number.parseInt(localStorage.getItem("zzz", "1"), 10);
const storeValue = (value: number) => localStorage.setItem("zzz", String(value));
const findCurrentStep = (price: Price) => zzzSteps.includes(price) ? zzzSteps.indexOf(price) : 0;
const nextStep = (step: number) => step < 3 ? step + 1 : step;

const initialState: ZzzState = {
    step: retrieveValue(),
    inProgress: false
};

export function giftReducer(state: ZzzState = initialState, action): ZzzState {
    const {type} = action;

    switch (type) {
        case ChatActionType.SendZzz: {
            const {price} = action;
            const step = findCurrentStep(price);

            storeValue(step);

            state = {
                ...state,
                step,
                inProgress: true
            };

            break;
        }

        case ChatActionType.SendTip:
        case ChatActionType.SendGift: {

            state = {
                ...state,
                inProgress: true
            };

            break;
        }

        case ChatActionType.SendZzzSucceeded: {
            const {price} = action;
            const currentStep = findCurrentStep(price);
            const step = nextStep(currentStep);

            storeValue(step);

            state = {
                ...state,
                step,
                inProgress: false
            };

            break;
        }

        case ChatActionType.SendTipSucceeded:
        case ChatActionType.SendTipFailed:
        case ChatActionType.SendGiftSucceeded:
        case ChatActionType.SendGiftFailed:
        case ChatActionType.SendZzzFailed: {

            state = {
                ...state,
                inProgress: false
            };

            break;
        }

        default:
    }

    return state;
}
