import {createStyles, makeStyles} from "@material-ui/core";

const definition = {
    root: {
        backgroundColor: "#000",
        display: "flex",
        flexDirection: "column"
    },
    body: {
        margin: "0 auto",
        flex: 1
    },
    center: {
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)"
    },
    message: {
        margin: "0 20px",
        textAlign: "center"
    },
    close: {
        top: "5px",
        right: "5px",
        position: "absolute"
    }
};

export const styles = createStyles(definition as any);
export const useStyles = makeStyles(definition as any);
