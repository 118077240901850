import {Button, Paper} from "@material-ui/core";
import React, {ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {exitShow, requestChatMode} from "../../action/chat.action";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {RoomMode} from "../../domain/RoomMode";
import {RoomHolder} from "../contract/RoomHolder";
import {ModeButton} from "./ModeButton";
import {transitions} from "../../domain/RoomModeTransitionMap";

interface Props extends RoomHolder {
    children?: ReactNode
    exitRoom: (roomId: RoomId) => void
    requestChatMode: (roomId: RoomId, roomMode: RoomMode) => void
    className: string
}

const mapDispatchToProps = dispatch => ({
    exitRoom: (roomId: RoomId) => dispatch(exitShow(roomId)),
    requestChatMode: (roomId, roomMode) => dispatch(requestChatMode(roomId, roomMode))
});

export const ModeBar = connect(null, mapDispatchToProps)(
    React.memo<Props>(({children, room, exitRoom, requestChatMode, className}) => {
        const {roomid: roomId, pricing, allowedModes, roomMode}: RoomDto = room;
        const roomModes = (transitions[roomMode] as Array<RoomMode>).filter(roomMode => allowedModes.includes(roomMode));

        return (
            <Paper className={className}>
                {
                    roomModes.map(roomMode => (
                        <ModeButton key={roomMode}
                                    mode={roomMode}
                                    price={pricing[roomMode]}
                                    onClick={() => requestChatMode(roomId, roomMode)}/>
                    ))
                }

                {
                    roomMode && roomModes.length === 0 &&
                    <Button onClick={() => exitRoom(roomId)}>{intl.getHTML("chat.leave.".concat(roomMode))}</Button>
                }

                {children}
            </Paper>
        );
    }));
