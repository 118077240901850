import {Setup} from "../domain/c2c/Setup";
import {CameraErrorReason} from "../domain/c2c/CameraErrorReason";

export enum CameraActionType {
    TurnOn = "camera:on",
    TurnOnSucceeded = "camera:on.succeeded",
    TurnOnFailed = "camera:on.failed",

    TurnOff = "camera:off",

    Mute = "camera:mute",
    Unmute = "camera:unmute",

    Reconfigure = "camera:reconfigure",
    ReconfigureSucceeded = "camera:reconfigure.succeeded",
    ReconfigureFailed = "camera:reconfigure.failed"
}

export const reconfigureCamera = (setup: Setup) => ({
    type: CameraActionType.Reconfigure,
    setup
});

export const reconfigureCameraSucceeded = () => ({
    type: CameraActionType.ReconfigureSucceeded
});

export const reconfigureCameraFailed = (reason: CameraErrorReason) => ({
    type: CameraActionType.ReconfigureFailed,
    reason
});

export const mute = () => ({
    type: CameraActionType.Mute
});

export const unmute = () => ({
    type: CameraActionType.Unmute
});

export const turnOn = () => ({
    type: CameraActionType.TurnOn
});

export const turnOnSucceeded = () => ({
    type: CameraActionType.TurnOnSucceeded
});

export const turnOnFailed = reason => ({
    type: CameraActionType.TurnOnFailed,
    reason
});

export const turnOff = () => ({
    type: CameraActionType.TurnOff
});
