import Hls from "hls.js/dist/hls.light";
import {AbortError} from "../domain/AbortError";
import {Player, PLAYER_TIMEOUT, PlayerState} from "../domain/Player";
import {PlayerError} from "../domain/PlayerError";
import {StreamData} from "../domain/StreamData";
import {TimeoutError} from "../domain/TimeoutError";
import {BasePlayer} from "./BasePlayer";

export class HlsJsPlayer extends BasePlayer implements Player {
    private hls: Hls = null;
    private abort: AbortController = null;

    public static isSupported(): boolean {
        return Hls.isSupported();
    }

    public stop(): void {
        super.stop();

        this.abort?.abort();
        this.abort = null;

        this.hls?.stopLoad();
        this.hls?.detachMedia();
        this.hls?.destroy();
        this.hls = null;
    }

    public play(streamData: StreamData, muted: boolean): Promise<void> {
        this.stop();
        this.storeStream(streamData);

        return new Promise((resolve, reject) => {
            const abort = this.abort = new AbortController();
            const hls = new Hls();
            const timer = setTimeout(() => {
                fullTeardown();
                reject(new TimeoutError());
            }, PLAYER_TIMEOUT);

            const partialTeardown = () => {
                this.abort = null;
                clearTimeout(timer);
                removeEventListeners();
            };

            const fullTeardown = () => {
                partialTeardown();
                hls.stopLoad();
                hls.destroy();
            };

            const removeEventListeners = () => {
                abort.signal.removeEventListener("abort", aborted);
                hls.off(Hls.Events.MANIFEST_PARSED, success);
                hls.off(Hls.Events.ERROR, failure);
            };

            const success = () => {
                partialTeardown();
                this.hls = hls;
                resolve();
            };

            const failure = (event, data) => {
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                        case Hls.ErrorTypes.MEDIA_ERROR: {
                            break;
                        }

                        default: {
                            fullTeardown();
                            reject(new PlayerError());
                        }
                    }
                }
            };

            const aborted = () => {
                fullTeardown();
                reject(new AbortError());
            };

            abort.signal.addEventListener("abort", aborted);
            hls.on(Hls.Events.MANIFEST_PARSED, success);
            hls.on(Hls.Events.ERROR, failure);

            // recovery
            hls.on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR: {
                            return hls.startLoad();
                        }
                    }
                }
            });

            hls.attachMedia(this.video);
            hls.loadSource(streamData.hls.address);
        })
            .then(() => super.play(streamData, muted))
            .catch(() => {
                this.stop();
                this.playerStateSubject.next(PlayerState.Error);

                return Promise.reject(new PlayerError("Hls.js failed to start"));
            });
    }

    public isPlaying(stream: StreamData): boolean {
        return this.stream && this.stream?.hls?.address === stream?.hls?.address;
    }
}
