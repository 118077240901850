import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {redirectToTopUp} from "../../../redirect";
import {CountdownButton} from "../../CountdownButton";
import {Viewport} from "../../layout/Viewport";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import {styles} from "./styles";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const OutOfCreditLobby = withStyles(styles)(React.memo<Props>(({classes, lobby}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic} icon="/images/lowbattery.svg"/>
            <Typography className={classes.message}>{intl.get("lobby.out-of-credit", {screenName: lobby.screenName})}</Typography>
        </Box>
        <CountdownButton onClick={redirectToTopUp} label={intl.get("lobby.button.topup")}/>
        <ExitToRoomListButton/>
    </Viewport>
)));
