import {CameraActionType} from "../action/camera.action";
import {ChatActionType} from "../action/chat.action";

export interface CameraState {
    progress: boolean
    active: boolean,
    muted: boolean
}

const initialState: CameraState = {
    progress: false,
    active: false,
    muted: true
};

export function cameraReducer(state: CameraState = initialState, action): CameraState {
    const {type} = action;

    switch (type) {
        case CameraActionType.Reconfigure: {
            return {
                ...state,
                progress: true
            };
        }

        case CameraActionType.TurnOn: {
            return {
                ...state,
                progress: true,
                active: false
            };
        }

        case CameraActionType.ReconfigureSucceeded:
        case CameraActionType.TurnOnSucceeded: {
            return {
                ...state,
                progress: false,
                active: true
            };
        }

        case ChatActionType.RemovedFromRoom:
        case ChatActionType.JoinRoom:
        case ChatActionType.LeaveRoom:
        case CameraActionType.ReconfigureFailed:
        case CameraActionType.TurnOnFailed:
        case CameraActionType.TurnOff: {
            return initialState;
        }

        case ChatActionType.JoinRoomSucceeded:
        case ChatActionType.RequestChatModeSucceeded:
        case CameraActionType.Mute: {
            return {
                ...state,
                muted: true
            };
        }

        case CameraActionType.Unmute: {
            return {
                ...state,
                muted: false
            };
        }
    }

    return state;
}
