import {Logger} from "../Logger";

export class NullLogger implements Logger {
    public debug() {
    }

    public error() {
    }

    public info() {
    }

    public warn() {
    }
}
