import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import {clsx} from "../../helper";
import {ClassNameHolder} from "../contract/ClassNameHolder";
import {TextWithEmoji} from "../TextWithEmoji";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: "0 auto",
        position: "relative",
        width: "230px",
        height: "200px",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
    },
    moodNatural: {
        backgroundImage: "unset"
    },
    moodNormal: {
        backgroundImage: "url(/images/tip-profimgcircle.svg)"
    },
    moodAngry: {
        backgroundImage: "url(/images/tip-angrycircle.svg)"
    },
    moodExcited: {
        backgroundImage: "url(/images/excited.svg)",
        backgroundPositionY: 0,
        height: 208
    },
    title: {
        textAlign: "center",
        position: "absolute",
        bottom: "15%",
        left: "50%",
        textTransform: "uppercase",
        padding: "10px 20px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        transform: "rotate(-5deg) translateX(-50%)"
    },
    light: {
        // TODO theme
        backgroundColor: "#fff",
        color: "#000"
    },
    dark: {
        // TODO theme
        backgroundColor: "#000",
        color: "#fff"
    },
    base: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    imgWrap: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "138px",
        height: "138px",
        margin: "auto",
        borderRadius: "50%",
        overflow: "hidden",
        border: "2px solid #fff"
    },
    imgWrapBlankPicture: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    imgBlankPicture: {
        width: "80%",
        height: "100%"
    },
    icon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "42px",
        height: "42px"
    }
}));

interface Props extends ClassNameHolder {
    src?: string
    title?: string
    mood?: "natural" | "normal" | "angry" | "excited"
    icon?: string
    color?: "light" | "dark" | "base"
}

const moodMap = {
    natural: "moodNatural",
    normal: "moodNormal",
    angry: "moodAngry",
    excited: "moodExcited"
};

const blankProfilePicture = "/images/sexchat-mini.svg";

export const CircleProfilePicture = React.memo<Props>(({className, src, title, mood = "natural", icon, color = "light"}) => {
    const classes = useStyles();
    let isValidPicture = Boolean(src);

    src = isValidPicture ? src : blankProfilePicture;

    return (
        <div className={clsx(classes.root, classes[moodMap[mood]], className)}>
            <div className={clsx(classes.imgWrap, !isValidPicture && classes.imgWrapBlankPicture)}>
                <img alt="performer" src={src} className={isValidPicture ? classes.img : classes.imgBlankPicture}/>

                {
                    icon && <img alt="icon" src={icon} className={classes.icon}/>
                }

            </div>

            {
                title && <div className={clsx(classes.title, classes[color])}><TextWithEmoji text={title}/></div>
            }

        </div>
    );
});
