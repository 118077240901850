import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";

interface ToolbarProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 48,
        minHeight: 48,
        backgroundColor: theme.palette.background.default,
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}));

export const Toolbar = React.memo<ToolbarProps>(({children}) => {
    const classes = useStyles();

    return <div className={classes.root}>{children}</div>;
});
