import {RoomId} from "../domain/RoomId";

export enum FavoriteActionType {
    ListUpdated = "favorite/list-updated",
    RoomUpdated = "favorite/room-updated"
}

export const updateFavorite = (roomId: RoomId, status: boolean) => ({
    type: FavoriteActionType.RoomUpdated,
    roomId,
    status
});

export const updateFavorites = (roomIds: Array<RoomId>) => ({
    type: FavoriteActionType.ListUpdated,
    roomIds
});
