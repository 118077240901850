import {Box, createStyles, withStyles, WithStyles} from "@material-ui/core";
import React, {PureComponent, ReactNode} from "react";
import {http} from "../service";
import {Closable} from "./contract/Closable";
import {Header} from "./layout/Header";
import {Viewport} from "./layout/Viewport";
import {ProgressScreen} from "./ProgressScreen";

const styles = createStyles({
    body: {
        flex: 1,
        overflowY: "scroll",
        padding: "0 24px"
    }
});

interface Props extends WithStyles<typeof styles>, Closable {
    title: string
    url: string
}

interface State {
    updating: boolean
    content: string
}

export const DynamicContentScreen = withStyles(styles)(class extends PureComponent<Props, State> {

    state = {
        updating: true,
        content: null
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {url} = this.props;

        if (prevProps.url !== url) {
            this.fetchContent();
        }
    }

    componentDidMount(): void {
        this.fetchContent();
    }

    private namedAnchorRe = /"#(\w+)"/g;

    private replaceNamedAnchors(content: string): string {
        return content.replace(this.namedAnchorRe, (match, g1) => `"${window.location.pathname}#${g1}"`);
    }

    private fetchContent() {
        const {url} = this.props;
        const requestInit: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "text/html"
            }
        };

        this.setState({
            updating: true
        });

        http.fetch(url, requestInit)
            .then((content: string) => {
                this.setState({
                    updating: false,
                    content: this.replaceNamedAnchors(content)
                });
            });
    }

    render(): ReactNode {
        const {updating} = this.state;

        return updating
            ? <ProgressScreen/>
            : this.renderContent();
    }

    private renderContent(): ReactNode {
        const {classes, title, onClose} = this.props;

        return (
            <Viewport color={"secondary"}>
                <Header title={title} onClose={onClose}/>
                <Box className={classes.body} dangerouslySetInnerHTML={{__html: this.state.content}}/>
            </Viewport>
        );
    }
});
