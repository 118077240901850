import {createStyles, Link, withStyles, WithStyles} from "@material-ui/core";
import React from "react";
import {Clickable} from "./contract/Clickable";

export const styles = createStyles({
    root: {
        color: "#fff",
        textDecoration: "underline"
    }
});

interface Props extends WithStyles<typeof styles>, Clickable {
    title: string
}

export const LinkButton = withStyles(styles)(React.memo<Props>(({classes, onClick, title}) => (
    <Link component="button" className={classes.root} variant="body1" onClick={onClick}>{title}</Link>
)));
