import React from "react";
import intl from "react-intl-universal";
import {Viewport} from "../layout/Viewport";
import {Header} from "../layout/Header";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {Credit} from "../../domain/topup/Credit";
import Button from "@material-ui/core/Button";
import {Closable} from "../contract/Closable";

interface Props extends Closable {
    credit: Credit
}

export const SuccessfulTopUpScreen = React.memo<Props>(({credit, onClose}) => {
    return <Viewport>
        <Header title={intl.get("topup.card.title")} onClose={onClose}/>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly">
            <img src="/images/topup/success.svg" alt="success"/>
            <Box textAlign="center">
                <Typography variant="body2">
                    {intl.get("topup.card.confirmation", {amount: credit.totalAmount})}<br/>
                    {intl.get("topup.card.have-fun")}
                </Typography>
            </Box>
        </Box>
        <Box textAlign="right" style={{padding: "16px"}}>
            <Button onClick={onClose}>{intl.get("common.button.close")}</Button>
        </Box>
    </Viewport>;
});
