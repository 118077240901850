import {Store} from "redux";
import {changeOrientation, ScreenActionType} from "../action/screen.action";
import {ApplicationActionType} from "../action/application.action";
import {debounce} from "../helper";
import {Orientation} from "../domain/Orientation";

interface Measure {
    width: number
    height: number
    screenWidth: number
    screenHeight: number
    orientation: Orientation
    shrink: number
}

let previousMeasure: Measure = null;

const measure = (): Measure => {
    const screen = window.screen;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const orientation = Math.abs(window.orientation as number) === 90 ? Orientation.Landscape : Orientation.Portrait;
    const long = Math.max(screen.availWidth, screen.availHeight);
    const short = Math.min(screen.availWidth, screen.availHeight);
    const screenWidth = orientation === Orientation.Portrait ? short : long;
    const screenHeight = orientation === Orientation.Portrait ? long : short;
    let shrink = 0;

    if (previousMeasure) {
        const previousHeight = previousMeasure.height;

        if (orientation === previousMeasure.orientation
            && height < previousHeight) {
            if (previousHeight - height > (previousHeight / 2 | 0)) {
                shrink = previousHeight - height;
            }
        }
    }

    return previousMeasure = {
        width,
        height,
        screenWidth,
        screenHeight,
        orientation,
        shrink
    };
};

const register = fn => {
    window.addEventListener("orientationchange", fn, false);
    window.addEventListener("resize", fn, false);
};

export const screenMiddleware = () => (store: Store) => {
    const update = () => store.dispatch(changeOrientation(measure()));

    return next => action => {
        const result = next(action);

        switch (action.type) {
            case ApplicationActionType.Start: {
                register(debounce(update, 50));
                update();

                break;
            }

            case ScreenActionType.OrientationChanged: {
                const el = document.getElementById("root");
                const {orientation}: Measure = action.screen;
                const classList = el.classList;

                window.requestAnimationFrame(() => {
                    classList.add(orientation);
                    classList.remove(orientation === Orientation.Landscape ? "portrait" : "landscape");

                    /* if (shrink > 0) {
                        const up = shrink / -2 | 0;

                        el.style.top = `${up}px`;
                        el.style.height = `${height - up}px`;
                    } else {
                        el.style.removeProperty("top");
                        el.style.removeProperty("height");
                    } */
                });

                break;
            }

            default:
        }

        return result;
    };
};
