import {push} from "connected-react-router";
import {Store} from "redux";
import {ApplicationActionType} from "../action/application.action";
import {AuthActionType, signInFailed, userSet} from "../action/auth.action";
import {User} from "../domain/authentication/User";
import {noop, routeToRoomList} from "../helper";
import {AuthenticationService} from "../service/AuthenticationService";

export const authMiddleware = (api: AuthenticationService) => (store: Store) => {
    api.user.subscribe((user: User) => user && store.dispatch(userSet(user)));

    return next => action => {
        const result = next(action);

        switch (action.type) {
            case ApplicationActionType.Start: {
                api.fetchUser().catch(noop);

                break;
            }

            case AuthActionType.SignIn: {
                const {username, password, next} = action;

                api.signIn(username, password)

                    // TODO redirect to room can be a race condition due to socket connection and authentication delay
                    .then(() => store.dispatch(push(next || routeToRoomList())))
                    .catch(error => store.dispatch(signInFailed(error)));

                break;
            }

            case AuthActionType.AccountDeleted:
            case AuthActionType.SignOut: {
                api.signOut().catch(noop);

                break;
            }

            default:
        }

        return result;
    };
};
