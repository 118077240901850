import Fab from "@material-ui/core/Fab";
import {Favorite, FavoriteBorder} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useApplicationContext} from "../../application/ApplicationContext";
import {RoomId} from "../../domain/RoomId";
import {clsx} from "../../helper";
import {RootState} from "../../reducer";
import {TextWithEmoji} from "../TextWithEmoji";

interface FavoriteButtonProps {
    className: string
}

const useStyles = makeStyles({
    label: {
        margin: "auto 8px",
        textTransform: "none",
        "& > svg": {}
    },
    icon: {
        marginRight: "8px"
    },
    favored: {
        color: "#ff5a79"
    }
});

export const FavoriteButton = React.memo<FavoriteButtonProps>(({className}) => {
    const {roomService} = useApplicationContext();
    const classes = useStyles();
    const roomId = useSelector<RootState, RoomId>(root => root?.chat?.room?.roomid);
    const screenName = useSelector<RootState, string>(root => root?.chat?.room?.screenName);
    const authenticated = useSelector<RootState, boolean>(root => root?.auth?.user?.isMember === true);
    const loaded = useSelector<RootState, boolean>(root => root?.favorite?.loaded === true);
    const favored = useSelector<RootState, boolean>(root => root?.favorite?.roomIds?.includes(roomId) === true);
    const handleClick = useCallback(
        () => loaded && authenticated && roomId && roomService[favored ? "removeFavorite" : "addFavorite"](roomId),
        [favored, roomService, roomId, authenticated, loaded]
    );

    return screenName && roomId ? <Fab
        color="secondary"
        classes={{label: classes.label}}
        className={clsx(className, favored ? "favorite" : "neutral")}
        onClick={handleClick}
    >
        {
            loaded && authenticated &&
            <React.Fragment>
                {
                    favored
                        ? <Favorite className={clsx(classes.icon, classes.favored)}/>
                        : <FavoriteBorder className={classes.icon}/>
                }
            </React.Fragment>
        }

        {
            screenName &&
            <TextWithEmoji text={screenName}/>
        }
    </Fab> : null;
});
