import {baseUrl} from "./application";

export const config = {
    getConsents: baseUrl.concat("/ajax/api/getConsent"),
    setConsents: baseUrl.concat("/ajax/submit-gdpr-consent"),
    ageLimitAck: {
        key: "43zr",
        value: "11e9"
    }
};
