import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import {styles} from "./styles";
import {Tip} from "./Tip";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const KickedLobbyOfferTip = withStyles(styles)(React.memo<Props>(({classes, lobby}) => {
    return (
        <Viewport>
            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <CircleProfilePicture src={lobby?.publicData?.profilePic} mood="angry" title={intl.get("lobby.banned.title")}/>
                <Typography className={classes.message}>{intl.get("chat.banned", lobby)}</Typography>
            </Box>
            <Tip roomId={lobby.roomid}
                 message={intl.get("lobby.banned.tip.description")}
                 text={intl.get("lobby.banned.tip.send")}/>
            <ExitToRoomListButton/>
        </Viewport>
    );
}));
