import {isDevelopment} from "../environment";

const env = window.localStorage.getItem("override");
const developmentUrl = "https://web-he.beta.apn2.com";
const productionUrl = "https://sexchat.hu";
const defaultUrl = window.location.host === "mobile-dev.apn2.com"
    ? developmentUrl
    : productionUrl;

const override = (development, production, defaultValue): string => {
    switch (env) {
        case "development": {
            return development;
        }

        case "production": {
            return production;
        }

        default: {
            return defaultValue;
        }
    }
};

export const baseUrl = isDevelopment
    ? override("/d", "/p", "/d")
    : override(developmentUrl, productionUrl, defaultUrl);

