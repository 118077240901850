import {Logger as VxLogger} from "vchat-core/dist/logging/logger";
import {Logger} from "../../logger/Logger";

export class VisitXLogger implements VxLogger {
    constructor(private logger: Logger) {
    }

    public error(message: any, ...optionalParams: any[]): void {
        this.logger.error(message, ...optionalParams);
    }

    public log(message: any, ...optionalParams: any[]): void {
        this.logger.info(message, ...optionalParams);
    }

    public warn(message: any, ...optionalParams: any[]): void {
        this.logger.warn(message, ...optionalParams);
    }
}
