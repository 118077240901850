import {TopUpProvider} from "../../domain/topup/TopUpProvider";
import {Credit} from "../../domain/topup/Credit";
import {config} from "../../config/msol";
import {Http} from "../http";

export class PremiumRateCallProvider implements TopUpProvider {

    constructor(private http: Http, private credits: Array<Credit>, private numbers) {
    }

    getCreditOptions(): Array<Credit> {
        return this.credits;
    }

    private getUserPhoneCode(credit: number): Promise<string> {
        const requestInit: RequestInit = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            method: "POST",
            body: `command=getUserPhoneCode&credit=${credit}`
        };

        return this.http.fetch(config.api, requestInit)
            .then(response => response.code as string);
    }

    getNumberByCredit(amount: number): Promise<{ code: string, phone: string }> {
        return this.getUserPhoneCode(amount).then(code => ({
            code,
            phone: this.numbers[amount].phone
        }));
    }
}
