import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import {styles} from "./styles";
import {Tip} from "./Tip";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const TerminatedLobbyOfferTip = withStyles(styles)(React.memo<Props>(({classes, lobby}) => {
        return (
            <Viewport>
                <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <CircleProfilePicture
                        src={lobby?.publicData?.profilePic}
                        mood="normal"
                        title={intl.get("lobby.terminated.title")}/>
                    <Typography className={classes.message}>{intl.get("lobby.terminated.suprise", {
                        screenName: lobby.screenName
                    })}</Typography>
                </Box>
                <Tip roomId={lobby.roomid}
                     message={intl.get("lobby.terminated.tip.description")}
                     text={intl.get("lobby.terminated.tip.send")}/>
                <ExitToRoomListButton/>
            </Viewport>
        );
    }
));
