import {I18nActionType, localeSet} from "../action/i18n.action";
import {ApplicationActionType} from "../action/application.action";
import {Store} from "redux";
import {I18nService} from "../service/i18n.service";

export const i18nMiddleware = (service: I18nService) => {
    return (store: Store) => next => action => {
        const result = next(action);

        switch (action.type) {
            case ApplicationActionType.Start: {
                service.initialize().then(locale => store.dispatch(localeSet(locale)));

                break;
            }

            case I18nActionType.ChangeLocale: {
                service.setLocale({code: action.code}).then(locale => store.dispatch(localeSet(locale)));

                break;
            }

            default:

        }

        return result;
    };
};
