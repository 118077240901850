import React from "react";
import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {styles} from "./styles";
import {SignInButton} from "../../auth/SignInButton";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import {routeToRoom} from "../../../helper";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
}

export const GuestNotAllowedLobby = withStyles(styles)(React.memo<Props>(({classes, lobby}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic}/>
            <Typography className={classes.message}>{intl.get("chat.must-signed-in", lobby)}</Typography>
        </Box>
        <ExitToRoomListButton/>
        <SignInButton next={routeToRoom(lobby.roomid)}/>
    </Viewport>
)));
