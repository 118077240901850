import {BehaviorSubject, Observable, Subject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {Player, PlayerState} from "../domain/Player";
import {StreamData} from "../domain/StreamData";
import {noop} from "../helper";
import {PlayerFactory, PlayerType} from "../player/PlayerFactory";
import {InteractionTracker} from "../utility/InteractionTracker";
import {AuthenticationService} from "./AuthenticationService";
import {LocalStorage} from "./LocalStorage";

export class StreamService {

    private readonly interaction: InteractionTracker = new InteractionTracker();
    private readonly player: PlayerFactory = new PlayerFactory();
    private readonly playerStateSubject: Subject<PlayerState> = new BehaviorSubject(PlayerState.Stopped);
    private readonly muteStateSubject: Subject<boolean> = new BehaviorSubject(this.muted);

    constructor(
        private authentication: AuthenticationService,
        private storage: LocalStorage
    ) {
        this[this.muted ? "mute" : "unmute"]();
        this.player.playState().subscribe(this.playerStateSubject);

        // player can change mute state
        this.player.muteState()
            .pipe(distinctUntilChanged())
            .subscribe(muted => this.muted = muted);
    }

    private get muted(): boolean {
        return JSON.parse(this.storage.getItem("muted")) === true;
    }

    private set muted(value: boolean) {
        if (this.muted === value) {
            return;
        }

        this.muteStateSubject.next(value);
        this.storage.setItem("muted", JSON.stringify(value));
    }

    public playerState(): Observable<PlayerState> {
        return this.playerStateSubject;
    }

    public muteState(): Observable<boolean> {
        return this.muteStateSubject;
    }

    public getElement(): HTMLElement {
        return this.player.getElement();
    }

    public mute(): void {
        this.muted = true;
        this.player.mute();
    }

    public unmute(): void {
        this.muted = false;
        this.player.unmute();
    }

    public stop(): void {
        this.player.stop();
    }

    public play(stream: StreamData): void {
        if (this.player.isPlaying(stream)) {
            return;
        }

        const isMember = this.authentication.getUser()?.isMember === true;
        const preferences = isMember ? [PlayerType.Auto, PlayerType.HLS] : [PlayerType.HLS, PlayerType.Auto];
        const anyInteraction = this.interaction.wasAny();
        const muted = this.muted || anyInteraction === false;

        this[muted ? "mute" : "unmute"]();
        this.player.play(stream, this.muted, preferences).catch(noop);
    }
}
