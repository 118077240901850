import {Box, IconButton} from "@material-ui/core";
import {Mic, MicOff, Settings} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import React, {CSSProperties, Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import Draggable, {DraggableData} from "react-draggable";
import {useDispatch, useSelector} from "react-redux";
import {mute, reconfigureCamera, unmute} from "../../../action/camera.action";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {Setup} from "../../../domain/c2c/Setup";
import {Feature} from "../../../domain/feature/Feature";
import {Orientation} from "../../../domain/Orientation";
import {clsIfNot, clsx, emptyObject} from "../../../helper";
import {RootState} from "../../../reducer";
import {Screen} from "../../../reducer/screen.reducer";
import {ClassNameHolder} from "../../contract/ClassNameHolder";
import {SettingsDialog} from "./SettingsDialog";

const useStyles = makeStyles({
    root: {
        position: "fixed",
        padding: 8
    },
    hidden: {
        visibility: "hidden"
    },
    button: {
        color: "#fff"
    },
    wrapper: {
        position: "relative",
        width: 96,
        height: 96,
        border: "2px solid white",
        borderRadius: "50%",
        backgroundColor: "black",
        overflow: "hidden",
        zIndex: 1
    },
    video: {
        zIndex: 0, // iOS devices won't crop video regardless overflow hidden
        objectFit: "cover",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
});

interface CameraStreamProps {
}

const fillWidth = {
    width: "100%"
};

const fillHeight = {
    height: "100%"
};

interface Position {
    x: number
    y: number
}

const getDefaultPosition = (screen: Screen): Position => {
    const {width, height, orientation} = screen;

    return orientation === Orientation.Landscape
        ? {
            x: width - 96 - 16,
            y: 0 - 126 - 16
        }
        : {
            x: width - 96 - 16,
            y: height * .5
        };
};

const memory = {
    storage: {},
    getItem(key: string): string {
        return this.storage[key] || null;
    },
    setItem(key: string, value: string) {
        this.storage[key] = value;
    }
};

const getSavedPosition = (screen: Screen): Position => {
    const savedPosition = memory.getItem("c2c.position.".concat(screen.orientation));

    return savedPosition ? JSON.parse(savedPosition) : getDefaultPosition(screen);
};

const MicrophoneSwitch = React.memo<ClassNameHolder>(({className}) => {
    const dispatch = useDispatch();
    const isMuted = useSelector<RootState, boolean>(({camera}) => camera.muted);
    const handleClick = useCallback(() => dispatch(isMuted ? unmute() : mute()), [dispatch, isMuted]);

    return (
        <IconButton color="primary" size="small" onClick={handleClick} className={className}>
            {
                isMuted ? <MicOff/> : <Mic/>
            }
        </IconButton>
    );
});

export const CameraStream = React.memo<CameraStreamProps>(() => {
    const dispatch = useDispatch();
    const isActive = useSelector<RootState, boolean>(({camera}) => camera.active);
    const screen = useSelector<RootState, Screen>(({screen}) => screen);
    const initialPosition: Position = useMemo<Position>(() => getSavedPosition(screen), [screen]);
    const classes = useStyles();
    const videoRef = useRef<HTMLVideoElement>();
    const {cameraService} = useApplicationContext();
    const [videoStyle, setVideoStyle] = useState<CSSProperties>(emptyObject);
    const [position, setPosition] = useState<Position>(initialPosition);
    const [open, setOpen] = useState<boolean>(false);
    const openSettings = useCallback(() => setOpen(true), []);
    const closeSettings = useCallback(() => setOpen(false), []);
    const isAudioAvailable = useSelector<RootState, boolean>(({feature}) => feature[Feature.MicrophoneShow]);

    const resize = useCallback((video: HTMLVideoElement) => {
        const {videoWidth, videoHeight} = video;

        if (videoWidth > videoHeight) {
            setVideoStyle(fillHeight);
        } else {
            setVideoStyle(fillWidth);
        }
    }, []);

    const reconfigure = useCallback((setup: Setup) => {
        dispatch(reconfigureCamera(setup));
        setOpen(false);
    }, [dispatch]);

    const savePosition = (ignored, data: DraggableData) => {
        const position = {
            x: data.x,
            y: data.y
        };

        memory.setItem("c2c.position.".concat(screen.orientation), JSON.stringify(position));
        setPosition(position);
    };

    useEffect(() => {
        resize(videoRef.current as HTMLVideoElement);
        setPosition(getSavedPosition(screen));
    }, [screen, resize]);

    useEffect(() => {
        const video = videoRef.current as HTMLVideoElement;
        const handler = event => resize(event.target);
        const event = "loadedmetadata";

        video.addEventListener(event, handler);

        return () => video.removeEventListener(event, handler);
    }, [videoRef, resize]);

    useEffect(() => {
        const video = videoRef.current as HTMLVideoElement;

        cameraService.attachVideo(video);

        return () => cameraService.detachVideo();
    }, [cameraService, videoRef]);

    return (
        <Fragment>
            <Draggable bounds="#root" position={position} onStop={savePosition} handle={".".concat(classes.wrapper)}>
                <div className={clsx(classes.root, clsIfNot(isActive, classes.hidden))}>
                    <Box className={classes.wrapper} boxShadow={8}>
                        <video className={classes.video} style={videoStyle} ref={videoRef} muted={true} controls={false} playsInline={true}/>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <IconButton color="primary" size="small" onClick={openSettings} className={classes.button}>
                            <Settings/>
                        </IconButton>
                        <MicrophoneSwitch className={clsx(classes.button, clsIfNot(isAudioAvailable, classes.hidden))}/>
                    </Box>
                </div>
            </Draggable>
            <SettingsDialog onSelect={reconfigure} open={open} onClose={closeSettings}/>
        </Fragment>
    );
});
