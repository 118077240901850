import {Subject} from "rxjs";
import {PlayerState} from "../domain/Player";

export class PlayerStateHandler {

    private loading = () => this.state.next(PlayerState.Loading);
    private playing = () => this.state.next(PlayerState.Playing);
    private stopped = () => this.state.next(PlayerState.Stopped);
    private onError = () => this.state.next(PlayerState.Error);

    private handlers = {
        loadstart: this.loading,
        pause: this.stopped,
        playing: this.playing,
        error: this.onError
    };

    constructor(
        private video: HTMLVideoElement,
        private state: Subject<PlayerState>
    ) {
        Object.entries(this.handlers)
            .forEach(([event, handler]) => video.addEventListener(event, handler));
    }

    public destroy() {
        Object.entries(this.handlers || {})
            .forEach(([event, handler]) => this.video.removeEventListener(event, handler));

        this.handlers = null;
        this.state = null;
        this.video = null;
    }
}
