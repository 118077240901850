export enum LobbyType {
    Vip = "group",
    Private = "private",
    Exclusive = "exclusive",
    PausedOnJoin = "pause",
    PausedInRoom = "paused-in-room",

    InvalidRoomMode = "invalidRoomMode",
    RoomIsExclusive = "roomIsExclusive",
    GuestNotAllowed = "guestNotAllowed",
    RoomNotFound = "roomNotFound",
    NotEnoughCredits = "notEnoughCredits",

    RoomRejoined = "roomRejoined",
    OutOfCredit = "outOfCredit",

    KickedOfferTip = "kickedOfferTip",
    KickedOfferNext = "kickedOfferNext",

    StreamFailed = "streamFailed",

    ModeChangeInProgress = "modeChangeInProgress",

    TerminatedOfferTip = "terminatedOfferTip",
    TerminatedOfferNext = "terminatedOfferNext",

    Other = "other"
}
