import {LOCATION_CHANGE} from "connected-react-router";
import {Store} from "redux";
import {ChatActionType} from "../action/chat.action";
import {IncentiveActionType, showIncentiveDialog} from "../action/incentive.action";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";
import {RootState} from "../reducer";

export const incentiveMiddleware = (storage: Storage) => (store: Store) => {
    const {dispatch} = store;

    let timer;
    const BYPASS_KEY = "incentive.bypass";
    const PHASE_KEY = "incentive.phase";
    const BYPASS_MS = 24 * 3600 * 1000;
    const PHASES = [120 * 1000, 90 * 1000, 60 * 1000, 30 * 1000, 30 * 1000];

    const now = (): number => +(new Date());
    const getTimeout = (): [number, boolean, Function] => {
        const bypass: number = parseInt(storage.getItem(BYPASS_KEY), 10) || 0;
        const phase: number = parseInt(storage.getItem(PHASE_KEY), 10) || 0;
        const hasMore = PHASES[phase + 1] > 0;

        if (bypass) {
            if (bypass > now()) {
                return [0, false, () => null];
            }
        }

        return [PHASES[phase] || 0, hasMore, () => {
            if (hasMore) {
                storage.setItem(PHASE_KEY, String(phase + 1));
                storage.removeItem(BYPASS_KEY);
            } else {
                storage.setItem(PHASE_KEY, "0");
                storage.setItem(BYPASS_KEY, (now() + BYPASS_MS).toString());
            }
        }];
    };

    const schedule = (room: RoomDto) => {
        const [timeout, hasMore, use] = getTimeout();

        if (timeout > 0) {
            timer = setTimeout(() => {
                use();
                dispatch(showIncentiveDialog(room, hasMore));
            }, timeout);
        } else {
            use();
            dispatch(showIncentiveDialog(room, false));
        }
    };

    return next => action => {
        const {type} = action;
        const {auth, chat}: RootState = store.getState();
        const {user} = auth;
        const {room} = chat;

        switch (type) {
            case IncentiveActionType.CloseDialog: {
                if (room && room.roomMode === RoomMode.Free) {
                    schedule(room);
                }

                break;
            }

            case ChatActionType.JoinRoomSucceeded: {
                const room = action.event.room as RoomDto;

                if (user && user.isMember === false && room && room.roomMode === RoomMode.Free) {
                    schedule(room);
                }

                break;
            }

            case ChatActionType.LeaveRoom:
            case ChatActionType.RemovedFromRoom:
            case LOCATION_CHANGE: {
                clearTimeout(timer);
            }
        }

        next(action);
    };
};
