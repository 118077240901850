import {Dialog} from "../domain/Dialog";

export enum ApplicationActionType {
    Start = "app/start",
    Ready = "app/ready",
    BecameVisible = "app/became-visible",
    BecameInvisible = "app/became-invisible",
    ToggleFullscreen = "app/toggle-fullscreen",
    ExitedFullscreen = "app/exited-fullscreen",
    EnteredFullscreen = "app/entered-fullscreen",
    ShowDialog = "app/show-dialog",
    HideDialog = "app/hide-dialog",
    ShowRelease = "app/show-release"
}

export const toggleFullScreen = () => ({
    type: ApplicationActionType.ToggleFullscreen
});

export const exitedFullscreen = () => ({
    type: ApplicationActionType.ExitedFullscreen
});

export const enteredFullscreen = () => ({
    type: ApplicationActionType.EnteredFullscreen
});

export const showDialog = (dialog: Dialog) => ({
    type: ApplicationActionType.ShowDialog,
    dialog
});

export const hideDialog = () => ({
    type: ApplicationActionType.HideDialog
});

export const showRelease = () => ({
    type: ApplicationActionType.ShowRelease
});


