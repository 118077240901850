import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo} from "react";

interface ObserverCallback {
    (target: Element): void;
}

interface Observer {
    observe(target: Element, callback: ObserverCallback)

    unobserve(target: Element)
}

class LazyObserver implements Observer {
    private setOfElements: Set<Element> = new Set<Element>();
    private map: WeakMap<Element, ObserverCallback> = new WeakMap<Element, ObserverCallback>();
    private observer: IntersectionObserver;

    public attach(observer: IntersectionObserver) {
        if (this.observer) {
            return;
        }

        this.observer = observer;
        this.setOfElements.forEach(observer.observe, observer);
        this.setOfElements.clear();
    }

    public detach() {
        this.observer = null;
    }

    public notify(target: Element) {
        const callback = this.map.get(target);

        if (callback) {
            callback(target);
        }
    }

    public observe(target: Element, callback: ObserverCallback): void {
        this.map.set(target, callback);

        if (this.observer) {
            this.observer.observe(target);
        } else {
            this.setOfElements.add(target);
        }
    };

    public unobserve(target: Element): void {
        this.map.delete(target);

        if (this.observer) {
            this.observer.unobserve(target);
        } else {
            this.setOfElements.delete(target);
        }
    };
}

const fallbackObserver: Observer = {
    observe: (target: Element, callback: ObserverCallback): void => {
        setImmediate(callback, target);
    },
    unobserve: (): void => {
    }
};

export const isIntersectionObserverSupported = "IntersectionObserver" in window;

const Context = createContext<Observer>(null);

export const useIntersectionObserver = (): Observer => {
    return useContext<Observer>(Context);
};

interface ProviderProps extends Pick<IntersectionObserverInit, "root" | "rootMargin"> {
}

export const IntersectionObserverContext = React.memo<PropsWithChildren<ProviderProps>>(({
                                                                                             root,
                                                                                             rootMargin = "0px",
                                                                                             children
                                                                                         }) => {
    const observer = useMemo<Observer>(() => isIntersectionObserverSupported ? new LazyObserver() : fallbackObserver, []);

    useEffect(() => {
        if (isIntersectionObserverSupported && (root || root === null)) {
            const lazyObserver = observer as unknown as LazyObserver;
            const notifyEntry = entry => {
                if (entry.isIntersecting) {
                    lazyObserver.notify(entry.target);
                }
            };

            const handlerEntries = (entries: Array<IntersectionObserverEntry>) => entries.forEach(notifyEntry);

            const io = new IntersectionObserver(handlerEntries, {
                root,
                rootMargin
            });

            lazyObserver.attach(io);

            return () => {
                lazyObserver.detach();
                io.disconnect();
            };
        }
    }, [observer, root, rootMargin]);

    return <Context.Provider value={observer} children={children}/>;
});
