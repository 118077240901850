import {Context} from "./application/Context";
import {
    authService,
    cameraService,
    giftService,
    localStorage,
    logger,
    roomListService,
    roomService,
    settingsService,
    streamService,
    topupService
} from "./service";

const context: Context = {
    topupService,
    giftService,
    streamService,
    localStorage,
    authService,
    roomService,
    roomListService,
    logger,
    cameraService,
    settingsService
};

export default context;
