import {BehaviorSubject, Observable, Subject} from "rxjs";
import {
    H5LivePlayerFactory,
    HlsJsPlayerFactory,
    HlsPlayerFactory,
    JpegPlayerFactory,
    Player as VxPlayer,
    WebRTCPlayerFactory
} from "vchat-player";
import {PlayerConfig} from "vchat-player/dist/player-config";
import {Player, PlayerState} from "../../domain/Player";
import {PlayerError} from "../../domain/PlayerError";
import {StreamData} from "../../domain/StreamData";
import {VisitXPlayerHandler} from "./VisitXPlayerHandler";

export class VisitXPlayer implements Player {

    private placeholder: HTMLDivElement = document.getElementById("visitx-placeholder") as HTMLDivElement;
    private container: HTMLDivElement = document.getElementById("visitx-container") as HTMLDivElement;
    private playerStateSubject: Subject<PlayerState> = new BehaviorSubject(PlayerState.Stopped);
    private muteStateSubject: Subject<boolean> = new BehaviorSubject(true);
    private player: VxPlayer;

    constructor() {
        const config: PlayerConfig = {
            container: this.container,
            handler: new VisitXPlayerHandler(
                this.playerStateSubject,
                this.muteStateSubject
            ),
            factories: [
                new H5LivePlayerFactory(),
                new HlsJsPlayerFactory(),
                new HlsPlayerFactory(),
                new WebRTCPlayerFactory(),
                new JpegPlayerFactory()
            ]
        };

        this.player = new VxPlayer(config);
        this.mute();
    }

    public destroy(): void {
        this.player.destroy();
        this.player = null;
    }

    public getElement(): HTMLElement {
        return this.container;
    }

    public isPlaying(stream: StreamData): boolean {
        return false;
    }

    public mute(): void {
        this.player.volume = 0;
    }

    public muteState(): Observable<boolean> {
        return this.muteStateSubject;
    }

    public playState(): Observable<PlayerState> {
        return this.playerStateSubject;
    }

    public stop(): void {
        this.player.stop();
        this.placeholder.appendChild(this.container);
        this.playerStateSubject.next(PlayerState.Stopped);
    }

    public unmute(): void {
        this.player.volume = 1;
    }

    public play(stream: StreamData, muted: boolean): Promise<void> {
        this.player.stop();
        this.playerStateSubject.next(PlayerState.Loading);

        this[muted ? "mute" : "unmute"]();

        return this.player.play(stream.visitx)
            .catch(() => {
                this.playerStateSubject.next(PlayerState.Error);

                return Promise.reject(new PlayerError("VisitXPlayer failed to start"));
            });
    }
}
