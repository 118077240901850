import {User} from "../domain/authentication/User";
import {Feature} from "../domain/feature/Feature";
import {FeatureDisabledReason} from "../domain/feature/FeatureDisabledReason";
import {Requirement} from "../domain/feature/Requirement";
import {PrivateMode} from "../domain/PrivateMode";
import {RoomCategory} from "../domain/RoomCategory";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";

// TODO move this to domain object
export const isSxRoom = (room: RoomDto) => room?.roomid?.startsWith("v") === false;
export const isVxRoom = (room: RoomDto) => Boolean(room?.roomid?.startsWith("v"));
const isMember = (user: User) => Boolean(user && user.isMember === true);
const isVoyeurViewer = (room: RoomDto) => Boolean(room && room.roomMode === RoomMode.Private && room.modeSpecific && room.modeSpecific.privateMode === PrivateMode.Voyeur);
const isNormalViewer = (room: RoomDto) => Boolean(room && (([RoomMode.Free, RoomMode.Group, RoomMode.Exclusive] as Array<RoomMode>).includes(room.roomMode) || (room.roomMode === RoomMode.Private && room.modeSpecific.privateMode === PrivateMode.Private)));
const isGroupOrPrivate = (room: RoomDto) => Boolean(room && ([RoomMode.Group, RoomMode.Private, RoomMode.Exclusive] as Array<RoomMode>).includes(room.roomMode));
const isNotGroupOrPrivate = (room: RoomDto) => isGroupOrPrivate(room) === false;
const isPrivate = (room: RoomDto) => Boolean(room && ([RoomMode.Private, RoomMode.Exclusive] as Array<RoomMode>).includes(room.roomMode));
const hasSmartToy = (room: RoomDto) => Boolean(room && room.publicData && room.publicData.toys && room.publicData.toys.registered && room.publicData.toys.registered.length > 0);
const isCouple = (room: RoomDto) => Boolean(room && room.publicData && ([
    RoomCategory.Couple,
    RoomCategory.BoyCouple,
    RoomCategory.GirlCouple,
    RoomCategory.Group
] as Array<RoomCategory>).includes(room.publicData.primaryCat));

export const featureAttribute = (feature: Feature) => (el: HTMLElement) => el && el.setAttribute && el.setAttribute("data-feature", feature);
export const featureProp = (feature: Feature) => ({"data-feature": feature});

export const checker = (user: User, room: RoomDto) => (flag: number): boolean => {
    const requirements = [];

    if (flag & Requirement.Disabled) {
        return false;
    }

    if (flag & Requirement.UserIsMember) {
        requirements.push(isMember(user));
    }

    if (flag & Requirement.InRoomAsNormal) {
        requirements.push(isNormalViewer(room));
    }

    if (flag & Requirement.RoomModeIsGroupOrPrivate) {
        requirements.push(isGroupOrPrivate(room));
    }

    if (flag & Requirement.RoomCategoryIsCouple) {
        requirements.push(isCouple(room));
    }

    if (flag & Requirement.HasSmartToy) {
        requirements.push(hasSmartToy(room));
    }

    if (flag & Requirement.RoomModeIsPrivate) {
        requirements.push(isPrivate(room));
    }

    if (flag & Requirement.RoomModeIsNotGroupOrPrivate) {
        requirements.push(isNotGroupOrPrivate(room));
    }

    if (flag & Requirement.InSxRoom) {
        requirements.push(isSxRoom(room));
    }

    return requirements.every(item => item);
};

export const disabled = () => Promise.reject(FeatureDisabledReason.Disabled);

export const mustBeSxRoom = (room: RoomDto) => () => isSxRoom(room)
    ? Promise.resolve()
    : Promise.reject(FeatureDisabledReason.Disabled);

export const mustBeMember = (user: User) => () => new Promise<void>((resolve, reject) => {
    isMember(user) ? resolve() : reject(FeatureDisabledReason.UserIsNotMember);
});

export const mustNotBeVoyeur = (room: RoomDto) => () => new Promise<void>((resolve, reject) => {
    isVoyeurViewer(room) ? reject(FeatureDisabledReason.ViewerIsVoyeur) : resolve();
});

export const mustBeGroupOrPrivate = (room: RoomDto) => () => new Promise<void>((resolve, reject) => {
    isGroupOrPrivate(room) ? resolve() : reject(FeatureDisabledReason.RoomModeIsNotGroupOrPrivate);
});

export const mustBeNotGroupOrPrivate = (room: RoomDto) => () => new Promise<void>((resolve, reject) => {
    isNotGroupOrPrivate(room) ? resolve() : reject(FeatureDisabledReason.RoomModeIsGroupOrPrivate);
});

export const mustBePrivate = (room: RoomDto) => () => new Promise<void>((resolve, reject) => {
    isPrivate(room) ? resolve() : reject(FeatureDisabledReason.RoomModeIsNotPrivate);
});

export const mustBeCouple = (room: RoomDto) => () => new Promise<void>((resolve, reject) => {
    isCouple(room) ? resolve() : reject(FeatureDisabledReason.RoomCategoryIsNotCouple);
});

export const mustHaveSmartToy = (room: RoomDto) => () => new Promise<void>((resolve, reject) => {
    hasSmartToy(room) ? resolve() : reject(FeatureDisabledReason.Other);
});
