import React from "react";
import {isDevelopment} from "./environment";

if (isDevelopment && false) {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");

    whyDidYouRender(React, {
        trackAllPureComponents: true
    });
}
