import {ZzzScene} from "./ZzzScene";
import {EmojiScene} from "./EmojiScene";
import {ChatScene} from "./ChatScene";
import {GiftScene} from "./GiftScene";

export const scenes = {
    gift: GiftScene,
    chat: ChatScene,
    emoji: EmojiScene,
    zzz: ZzzScene
};
