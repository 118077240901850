import {ChatActionType} from "../action/chat.action";
import {TopUpActionType} from "../action/topup.action";
import {config} from "../config/chat";
import {JoinRoomFailed, RoomModeSetupChanged} from "../domain/events";
import {JoinRoomFailedReason} from "../domain/JoinRoomFailedReason";
import {LobbyType} from "../domain/LobbyType";
import {PrivateMode} from "../domain/PrivateMode";
import {RemovedFromRoomReason} from "../domain/RemovedFromRoomReason";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";
import {isSxRoom} from "../middleware/helper";
import {LobbyHolder} from "../ui/contract/LobbyHolder";
import {RoomHolder} from "../ui/contract/RoomHolder";
import {RootState} from "./index";

export interface ChatState extends LobbyHolder, RoomHolder {
    roomModeChangeInProgress: boolean
    messages: Array<any>
    showStartedAt: number,
    showGiftPanel: boolean,
    giftPanelSelectedGiftId: string,
}

const initialState = {
    roomModeChangeInProgress: false,
    showStartedAt: null,
    lobby: null,
    room: null,
    messages: [],
    showGiftPanel: false,
    giftPanelSelectedGiftId: null
};

let lastExtraCredit = null;

const canOfferTip = (root: RootState) => root?.auth?.user?.isMember && root?.credit >= config.minimumCreditBeforeTip;

export function chatReducer(state: ChatState = initialState, action, root: RootState): ChatState {
    const {type} = action;

    switch (type) {
        case ChatActionType.TipBeforeExit: {
            const {room} = action;

            state = {
                ...initialState,
                lobby: {
                    ...room as RoomDto,
                    type: LobbyType.TerminatedOfferTip
                }
            };

            break;
        }

        case ChatActionType.RoomStatusChanged: {
            const {room} = state;
            const changedRoom: RoomDto = action.event.room;

            if (room && changedRoom
                && room.roomid === changedRoom.roomid
                && room.roomMode === RoomMode.Private
                && changedRoom.roomMode === RoomMode.Private
                && room.modeSpecific.privateMode === PrivateMode.Voyeur
                && changedRoom.modeSpecific.privateMode === PrivateMode.Private) {
                state = {
                    ...state,
                    room: changedRoom
                };
            }

            break;
        }

        case ChatActionType.RoomModeSetupChanged: {
            const {data}: RoomModeSetupChanged = action.event;
            const {room} = state;

            if (room && data && room.roomid === data.roomid) {
                state = {
                    ...state,
                    room: {
                        ...room,
                        ...data
                    }
                };
            }

            break;
        }

        case ChatActionType.JoinRoom: {
            state = initialState;

            break;
        }

        case ChatActionType.JoinRoomSucceeded: {
            const {event: {room}} = action;

            state = {
                ...initialState,
                messages: lastExtraCredit ? [lastExtraCredit] : [],
                showStartedAt: room.roomMode === RoomMode.Free ? null : Date.now(),
                room: {
                    ...room as RoomDto
                }
            };

            break;
        }

        case ChatActionType.RoomPaused: {
            const {event: {roomId}} = action;
            const {room} = state;

            if (room && room.roomid === roomId) {
                state = {
                    ...state,
                    room: null,
                    lobby: {
                        ...room,
                        type: LobbyType.PausedInRoom
                    }
                };
            }

            break;
        }

        case ChatActionType.RoomResumed: {
            const {event: {roomId}} = action;
            const {lobby} = state;

            if (lobby && lobby.type === LobbyType.PausedInRoom && lobby.roomid === roomId) {
                const {type, ...room} = lobby;

                state = {
                    ...state,
                    lobby: null,
                    room
                };
            }

            break;
        }

        case ChatActionType.JoinRoomFailed: {
            const event: JoinRoomFailed = action.event;
            const {room, reason} = event;

            switch (reason) {
                case JoinRoomFailedReason.ModeChangeInProgress: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.ModeChangeInProgress
                        }
                    };

                    break;
                }

                case JoinRoomFailedReason.Banned: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: isSxRoom(room) && canOfferTip(root)
                                ? LobbyType.KickedOfferTip
                                : LobbyType.KickedOfferNext
                        }
                    };

                    break;
                }

                case JoinRoomFailedReason.AlreadyJoined: {
                    state = {
                        ...initialState,
                        room
                    };

                    break;
                }

                case JoinRoomFailedReason.RoomNotFound: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.RoomNotFound
                        }
                    };

                    break;
                }

                case JoinRoomFailedReason.DifferentMode: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: room.roomMode
                        }
                    };

                    break;
                }

                case JoinRoomFailedReason.GuestNotAllowed: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.GuestNotAllowed
                        }
                    };

                    break;
                }

                case JoinRoomFailedReason.NotEnoughCredits: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.NotEnoughCredits
                        }
                    };

                    break;
                }

                default: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.Other
                        }
                    };
                }
            }

            break;
        }

        case ChatActionType.RequestChatMode: {
            state = {
                ...state,
                roomModeChangeInProgress: true
            };

            break;
        }

        case ChatActionType.RequestChatModeSucceeded: {
            const {room} = action;
            const {showStartedAt} = state;

            state = {
                ...state,
                roomModeChangeInProgress: false,
                showStartedAt: showStartedAt || Date.now(),
                room: {
                    ...room as RoomDto
                }
            };

            break;
        }

        case ChatActionType.RequestChatModeFailed: {
            state = {
                ...state,
                roomModeChangeInProgress: false
            };

            break;
        }

        case ChatActionType.LeaveRoomSucceeded:
        case ChatActionType.LeaveRoomFailed: {
            const {roomId} = action;
            const room = state.room || state.lobby;

            if (room && room.roomid === roomId) {
                state = initialState;
            }

            break;
        }

        case ChatActionType.RemovedFromRoom: {
            const {reason} = action.event;
            const room = state.room || state.lobby;

            switch (reason) {
                case RemovedFromRoomReason.RoomTerminated: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: isSxRoom(room) && canOfferTip(root)
                                ? LobbyType.TerminatedOfferTip
                                : LobbyType.TerminatedOfferNext
                        }
                    };

                    break;
                }

                case RemovedFromRoomReason.Kicked: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: isSxRoom(room) && canOfferTip(root)
                                ? LobbyType.KickedOfferTip
                                : LobbyType.KickedOfferNext
                        }
                    };

                    break;
                }

                case RemovedFromRoomReason.RoomRejoined: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.RoomRejoined
                        }
                    };

                    break;
                }

                case RemovedFromRoomReason.OutOfCredit: {
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            type: LobbyType.OutOfCredit
                        }
                    };

                    break;
                }

                case RemovedFromRoomReason.StreamFailed:
                default:
                    state = {
                        ...initialState,
                        lobby: {
                            ...room,
                            roomMode: RoomMode.Unknown,
                            type: LobbyType.Other,
                            reason
                        }
                    };

                    break;
            }

            break;
        }

        case TopUpActionType.HappyHourEnded: {
            lastExtraCredit = null;

            break;
        }

        case TopUpActionType.HappyHourStarted:
            lastExtraCredit = action.event;

        // eslint-disable-next-line
        case ChatActionType.GiftReceived:
        case ChatActionType.MessageReceived: {
            const {room} = state;

            if (room) {
                state = {
                    ...state,
                    messages: [...state.messages]
                        .concat(action.event)
                        .filter(message => Boolean(message))
                };
            }

            break;
        }

        case ChatActionType.OpenGiftPanel: {
            const {selectedGiftId} = action;
            state = {
                ...state,
                giftPanelSelectedGiftId: selectedGiftId,
                showGiftPanel: true
            };

            break;
        }

        case ChatActionType.GiftPanelClosed: {
            state = {
                ...state,
                giftPanelSelectedGiftId: null,
                showGiftPanel: false
            };

            break;
        }

        default:
    }

    return state;
}
