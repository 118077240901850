import * as Sentry from "@sentry/react";
import {Severity} from "@sentry/react";
import {Subject} from "rxjs";
import {AbortError} from "../../domain/AbortError";
import {PLAYER_TIMEOUT} from "../../domain/Player";
import {PlayerError} from "../../domain/PlayerError";
import {TimeoutError} from "../../domain/TimeoutError";
import {noop} from "../../helper";
import {FrameDetector} from "./FrameDetector";

class Timeout {
    private timer;

    constructor(
        fn: Function,
        timeout: number,
        context: object = null,
        ...args: Array<any>
    ) {
        this.timer = setTimeout(fn.bind(context), timeout, ...args);
    }

    public cancel() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}

interface WowzaStream {
    applicationName: string
    sessionId?: string
    streamName: string
}

interface WowzaMessageDto {
    status: number
    command: "getOffer" | "sendResponse"
    statusDescription: string
    streamInfo: WowzaStream
    sdp: RTCSessionDescriptionInit
    iceCandidates: Array<RTCIceCandidateInit>
}

export class WowzaViewer {
    private isChrome = Boolean(window["chrome"]) && (Boolean(window["chrome"]["webstore"]) || Boolean(window["chrome"]["runtime"]));
    private sdpURL: string;
    private streamInfo: WowzaStream;

    private wsConnection: WebSocket = null;
    private peerConnection: RTCPeerConnection = null;
    private events: Subject<any> = new Subject<any>();
    private iceCandidateTcpOnly: boolean = false;

    private connectTimeout: number = PLAYER_TIMEOUT;
    private connectTimeoutTimer: Timeout;
    private connectCount: number = 0;
    private readonly connectMaxRetry = 2;

    private isConnecting: boolean = false;
    private isPlaying: boolean = false;

    private detector: FrameDetector;
    private mediaStream: MediaStream;

    public start(
        sdpURL: string,
        applicationName: string,
        streamName: string
    ): Promise<MediaStream> {
        this.stop();

        this.connectCount = 0;
        this.sdpURL = sdpURL;
        this.streamInfo = {
            applicationName,
            streamName
        };

        return new Promise((resolve, reject) => {
            const subscription = this.events.subscribe(event => {
                subscription.unsubscribe();

                event === true ? resolve(this.mediaStream) : reject(event);
            });

            this.connect(false);
        });
    }

    private cancelTimers() {
        this.detector?.stop();
        this.detector = null;

        this.connectTimeoutTimer?.cancel();
        this.connectTimeoutTimer = null;
    }

    public stop() {
        this.isPlaying = false;
        this.isConnecting = false;

        this.cancelTimers();
        this.closeWebSocket();
        this.closePeerConnection();

        this.mediaStream?.getTracks().forEach(track => track.stop());
        this.mediaStream = null;
    }

    private retry(fallback: boolean = false, reason?: any) {
        if (this.isConnecting) {
            this.connect(fallback, reason);
        }
    }

    private connect(fallback: boolean = false, reason?: any) {
        if (this.connectCount <= this.connectMaxRetry) {
            this.stop();

            this.connectCount++;
            this.iceCandidateTcpOnly = fallback && this.isChrome;
            this.isConnecting = true;
            this.connectTimeoutTimer = new Timeout(() => this.retry(true, new TimeoutError()), this.connectTimeout);

            this.connectWs();
        } else {
            this.onConnectionError(reason);
        }
    }

    private connectWs() {
        const connection = this.wsConnection = new WebSocket(this.sdpURL);

        connection.binaryType = "arraybuffer";
        connection.onopen = () => this.isConnecting && this.handleWsOpen();
        connection.onerror = () => this.isConnecting && this.handleWsError();
        connection.onmessage = event => this.isConnecting && this.handleWsMessage(event);
    }

    private handleWsOpen() {
        const connection = this.peerConnection = new RTCPeerConnection();
        this.mediaStream = new MediaStream();

        connection.ontrack = (event: RTCTrackEvent) => this.handleTrackEvent(event);
        connection.oniceconnectionstatechange = event => {
            if (this.peerConnection && event.currentTarget === this.peerConnection) {
                this.handleIceConnectionStateChange(this.peerConnection.iceConnectionState);
            }
        };

        this.sendGetOffer();
    }

    private handleIceConnectionStateChange(state: RTCIceConnectionState) {
        switch (state) {
            case "closed":
            case "failed":
            case "disconnected": {
                if (this.isPlaying) {
                    return this.onConnectionError(new PlayerError("handleIceConnectionStateChange, playing, ".concat(state)));
                }

                if (this.isConnecting) {
                    return this.retry(true, new PlayerError("handleIceConnectionStateChange, connecting, ".concat(state)));
                }

                break;
            }

            case "connected": {
                this.cancelTimers();

                if (this.isConnecting) {
                    this.detector = new FrameDetector();
                    this.detector.detect(this.peerConnection)
                        .then(() => this.onPlaying())
                        .catch(error => {
                            if (error instanceof AbortError) {
                                return;
                            }

                            this.retry(true, new PlayerError("frame detection failed"));
                        });
                }
            }
        }
    }

    private handleSdp(spd: RTCSessionDescriptionInit) {
        this.peerConnection.setRemoteDescription(new RTCSessionDescription(spd))
            .then(() => this.peerConnection?.createAnswer())
            .then(description => {
                if (description && this.isConnecting) {
                    Sentry.addBreadcrumb({
                        level: Severity.Debug,
                        category: "webrtc",
                        message: "createAnswer",
                        data: {
                            description
                        }
                    });

                    return this.gotDescription(description);
                }
            })
            .catch(error => this.retry(true, new PlayerError("handleSdp: ".concat(error?.message))));
    }

    private static transformCandidate(iceCandidate: RTCIceCandidateInit): RTCIceCandidateInit {
        const typePreference = 50;
        const localPreference = 50;
        const parts = iceCandidate.candidate.split(" ");
        const priorityBase = 256 - parseInt(parts[1], 10);

        parts[3] = String((typePreference << 24) + (localPreference << 8) + priorityBase);

        iceCandidate.candidate = parts.join(" ");

        return iceCandidate;
    }

    private filterCandidate(iceCandidate: RTCIceCandidateInit) {
        const protocol = iceCandidate.candidate.split(" ")[2];

        return Boolean(this.iceCandidateTcpOnly && protocol?.toLowerCase() === "udp") === false;
    }

    private duplicateIceCandidates(promises: Array<Promise<void>>, iceCandidate: RTCIceCandidateInit) {
        return promises.concat([
            this.peerConnection.addIceCandidate(new RTCIceCandidate({
                ...iceCandidate,
                sdpMLineIndex: 0
            })),
            this.peerConnection.addIceCandidate(new RTCIceCandidate({
                ...iceCandidate,
                sdpMLineIndex: 1
            })).catch(noop)
        ]);
    }

    private handleIceCandidates(iceCandidates: Array<RTCIceCandidateInit>) {
        Promise.all(
            iceCandidates
                .filter(this.filterCandidate, this)
                .map(WowzaViewer.transformCandidate)
                .reduce(this.duplicateIceCandidates.bind(this), [])
            )
            .catch(() => this.retry(true, new PlayerError("handleIceCandidates")));
    }

    private onConnectionError(reason) {
        this.stop();
        this.events.next(reason);
    }

    private onPlaying() {
        this.cancelTimers();
        this.closeWebSocket();
        this.isPlaying = true;
        this.isConnecting = false;
        this.events.next(true);
    }

    private handleWsMessage(event: MessageEvent) {
        const data: WowzaMessageDto = JSON.parse(event.data);

        Sentry.addBreadcrumb({
            level: Severity.Debug,
            category: "webrtc",
            type: "handleWsMessage",
            data
        });

        switch (data.status) {
            case 200: {
                const streamInfoResponse = data.streamInfo;

                if (streamInfoResponse) {
                    this.streamInfo.sessionId = streamInfoResponse.sessionId;
                }

                if (data.sdp) {
                    this.handleSdp(data.sdp);
                }

                if (data.iceCandidates) {
                    this.handleIceCandidates(data.iceCandidates);
                }

                break;
            }

            default: {
                this.retry(false, new PlayerError(data.statusDescription));
            }
        }
    }

    private handleWsError() {
        this.retry(false, new PlayerError("websocket error"));
    }

    private sendGetOffer() {
        if (this.isConnecting && this.wsConnection?.readyState === WebSocket.OPEN) {
            this.wsConnection.send(JSON.stringify({
                direction: "play",
                command: "getOffer",
                streamInfo: this.streamInfo
            }));
        }
    }

    private sendSendResponse(sdp: RTCSessionDescriptionInit) {
        if (this.isConnecting && this.wsConnection?.readyState === WebSocket.OPEN) {
            this.wsConnection.send(JSON.stringify({
                direction: "play",
                command: "sendResponse",
                streamInfo: this.streamInfo,
                sdp
            }));
        }
    }

    private closeWebSocket() {
        this.wsConnection?.close();
        this.wsConnection = null;
    }

    private closePeerConnection() {
        this.peerConnection?.getReceivers().forEach(receiver => receiver.track.stop());
        this.peerConnection?.close();
        this.peerConnection = null;
    }

    private gotDescription(description: RTCSessionDescriptionInit): Promise<void> {
        return this.peerConnection.setLocalDescription(description)
            .then(() => this.sendSendResponse(description));
    }

    private handleTrackEvent(event: RTCTrackEvent) {
        if (this.isConnecting && this.peerConnection && event.currentTarget === this.peerConnection) {
            this.mediaStream.addTrack(event.track);
        }
    }
}
