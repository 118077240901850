import {Consent} from "../domain/Consent";

export enum ConsentActionType {
    ConsentSet = "consent/set",
    GiveConsent = "consent/give",
    GiveConsentFailed = "consent/give-failed",
    GiveConsentSucceeded = "consent/give-succeeded",
    InitAgeLimitAck = "consent/init-age-limit-ack",
    AcknowledgeAgeLimit = "consent/age-limit-ack"
}

export const initAgeLimitAck = (acknowledged: boolean) => ({
    type: ConsentActionType.InitAgeLimitAck,
    acknowledged
});

export const acknowledgeAgeLimit = () => ({
    type: ConsentActionType.AcknowledgeAgeLimit
});

export const giveConsent = (consents: Array<string>) => ({
    type: ConsentActionType.GiveConsent,
    consents
});

export const consentSet = (consent: Consent) => ({
    type: ConsentActionType.ConsentSet,
    consent
});

export const giveConsentSucceeded = (consent: Consent) => ({
    type: ConsentActionType.GiveConsentSucceeded,
    consent
});

export const giveConsentFailed = (error) => ({
    type: ConsentActionType.GiveConsentFailed,
    error
});
