import {routerMiddleware} from "connected-react-router";
import {applyMiddleware} from "redux";
import {ApplicationActionType} from "../action/application.action";
import {AuthActionType} from "../action/auth.action";
import {ConsentActionType} from "../action/consent.action";
import {I18nActionType} from "../action/i18n.action";
import {ScreenActionType} from "../action/screen.action";
import {
    authService,
    cameraService,
    consentService,
    giftService,
    history,
    http,
    i18nService,
    knockoutService,
    localStorage,
    logger,
    roomListService,
    roomService,
    streamService,
    topupService
} from "../service";
import {analyticsMiddleware} from "./analytics.middleware";
import {applicationMiddleware} from "./application.middleware";
import {authMiddleware} from "./auth.middleware";
import {balanceMiddleware} from "./balance.middleware";
import {cameraMiddleware} from "./camera.middleware";
import {chatMiddleware} from "./chat.middleware";
import {consentMiddleware} from "./consent.middleware";
import {favoriteMiddleware} from "./favorite.middleware";
import {featureMiddleware} from "./feature.middleware";
import {fingerprintMiddleware} from "./fingerprint.middleware";
import {i18nMiddleware} from "./i18n.middleware";
import {idleMiddleware} from "./idle.middleware";
import {incentiveMiddleware} from "./incentive.middleware";
import {knockoutMiddleware} from "./knockout.middleware";
import {nextMiddleware} from "./next.middleware";
import {roomListMiddleware} from "./roomlist.middleware";
import {screenMiddleware} from "./screen.middleware";
import {sentryMiddleware} from "./sentry.middleware";
import {snackbarMiddleware} from "./snackbar/snackbar.middleware";
import {streamMiddleware} from "./stream.middleware";
import {topupMiddleware} from "./topup.middleware";
import {updateMiddleware} from "./update.middleware";

const stages = [
    ApplicationActionType.BecameVisible,
    ScreenActionType.OrientationChanged,
    ConsentActionType.InitAgeLimitAck,
    AuthActionType.UserSet,
    I18nActionType.LocaleSet
];

export default applyMiddleware(
    sentryMiddleware(),
    updateMiddleware(roomService),
    featureMiddleware(),
    incentiveMiddleware(localStorage),
    cameraMiddleware(cameraService),
    topupMiddleware(topupService),
    screenMiddleware(),
    streamMiddleware(streamService),
    applicationMiddleware(stages),
    balanceMiddleware(http),
    roomListMiddleware(roomListService),
    i18nMiddleware(i18nService),
    authMiddleware(authService),
    fingerprintMiddleware(http),
    consentMiddleware(consentService),
    chatMiddleware(roomService, giftService, authService),
    routerMiddleware(history),
    analyticsMiddleware(),
    idleMiddleware(),
    snackbarMiddleware(giftService),
    knockoutMiddleware(knockoutService, logger),
    favoriteMiddleware(roomService),

    // nextMiddleware must be the last middleware
    nextMiddleware()
);
