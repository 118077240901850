import {Typography} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ComingUpNextLobby} from "./ComingUpNextLobby";
import {useStyles} from "./styles";

interface Props extends LobbyHolder {
}

export const KickedLobbyOfferNext = React.memo<Props>(({lobby}) => {
    const classes = useStyles();
    const title = intl.get("chat.banned", lobby);

    return (
        <ComingUpNextLobby title={title}>
            <CircleProfilePicture
                src={lobby?.publicData?.profilePic}
                mood="angry"
                title={intl.get("lobby.banned.title")}
            />
            <Typography className={classes.message}>{title}</Typography>
        </ComingUpNextLobby>
    );
});
