import {TopUpActionType} from "../action/topup.action";
import {ExtraCredit} from "../domain/ExtraCredit";
import {LOCATION_CHANGE} from "connected-react-router";
import {PushPromo} from "../domain/PushPromo";

export interface TopUpState {
    showTopUp: boolean
    isHappyHour: boolean
    extraCredit: ExtraCredit
    pushPromo: PushPromo | null
}

const initialState: TopUpState = {
    showTopUp: false,
    isHappyHour: false,
    extraCredit: null,
    pushPromo: null
};

export function topupReducer(state: TopUpState = initialState, action): TopUpState {
    if (action.type === TopUpActionType.HappyHourStarted) {
        return {
            ...state,
            isHappyHour: true,
            extraCredit: action.event.extraCredit
        };
    }

    if (action.type === TopUpActionType.HappyHourEnded) {
        return {
            ...state,
            isHappyHour: false,
            extraCredit: null
        };
    }

    if (action.type === TopUpActionType.PushPromoStarted) {
        return {
            ...state,
            pushPromo: action.pushPromo
        };
    }

    if (action.type === TopUpActionType.PushPromoEnded) {
        return {
            ...state,
            pushPromo: null
        };
    }

    if (action.type === TopUpActionType.ShowTopUp) {
        return {
            ...state,
            showTopUp: true
        };
    }

    if (action.type === TopUpActionType.HideTopUp || action.type === LOCATION_CHANGE) {
        return {
            ...state,
            showTopUp: false
        };
    }

    return state;
}
