import {Feature} from "../domain/feature/Feature";
import {Action} from "redux";
import {FeatureDisabledReason} from "../domain/feature/FeatureDisabledReason";

export enum FeatureActionType {
    FeatureDisabled = "feature/disabled",
    RequestFeature = "feature/request"
}

export const requestFeature = (feature: Feature, actionOnEnabled: Action) => ({
    type: FeatureActionType.RequestFeature,
    feature,
    actionOnEnabled
});

export const featureDisabled = (feature: Feature, reason: FeatureDisabledReason) => ({
    type: FeatureActionType.FeatureDisabled,
    feature,
    reason
});
