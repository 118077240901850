import {DynamicContentScreen} from "./DynamicContentScreen";
import React from "react";
import {baseUrl} from "../config/application";
import {Closable} from "./contract/Closable";
import intl from "react-intl-universal";

const url = baseUrl.concat("/terms?ajax=1&toc=off&titlestyle=none");

interface Props extends Closable {
}

export const TermsScreen = ({onClose}: Props) =>
    <DynamicContentScreen onClose={onClose} title={intl.get("terms.title")} url={url}/>;
