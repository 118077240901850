import {AuthActionType} from "../action/auth.action";
import {User} from "../domain/authentication/User";
import {LOCATION_CHANGE} from "connected-react-router";

const initialState = {
    user: null,
    inProgress: false,
    error: null
};

export interface AuthState {
    user: User
    inProgress: boolean
    error: any
}

export function authReducer(state: AuthState = initialState, action): AuthState {
    switch (action.type) {
        case LOCATION_CHANGE: {
                state = {
                    ...state,
                    error: null,
                    inProgress: false
                };

            break;
        }

        case AuthActionType.SignIn:
        case AuthActionType.SignOut: {
            state = {
                ...state,
                inProgress: true
            };

            break;
        }

        case AuthActionType.SignInFailed: {
            state = {
                ...state,
                error: action.error,
                inProgress: false
            };

            break;
        }

        case AuthActionType.UserSet: {
            state = {
                ...initialState,
                user: action.user
            };

            break;
        }

        default:
    }

    return state;
}
