import {Box, Link} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {FormikErrors, useFormik} from "formik";
import React, {ChangeEvent, useCallback, useEffect, useImperativeHandle} from "react";
import intl from "react-intl-universal";
import * as Yup from "yup";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {useMessage} from "../../../hooks/message.hook";
import {useProgress} from "../../../hooks/progress.hook";
import {Loadable} from "../../contract/Loadable";
import {SettingsModule} from "../../contract/SettingsModule";
import {Message} from "../../Message";
import {PasswordField} from "../../PasswordField";
import {ProgressButton} from "../../ProgressButton";

interface FormValues {
    password: string;
    newPassword: string;
    newPasswordAgain: string;
}

const initialValues: FormValues = {
    password: "",
    newPassword: "",
    newPasswordAgain: ""
};

const useStyles = makeStyles({
    root: {
        textAlign: "justify",
        padding: "8px 16px 16px"
    },
    description: {
        marginBottom: 16,
        textAlign: "justify"
    },
    button: {
        marginBottom: 16
    },
    infoLink: {
        marginBottom: 8
    }
});

interface Props extends Loadable {
}

export const ChangePassword = React.forwardRef<SettingsModule, Props>(({onLoad}, ref) => {
    const classes = useStyles();
    const [inProgress, trackProgress] = useProgress();
    const {message, resetMessage, setErrorOnSave, setSuccessOnSave} = useMessage();
    const {settingsService} = useApplicationContext();
    const formik = useFormik<FormValues>({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            password: Yup.string().required(intl.get("settings.change-password.error.required")),
            newPassword: Yup.string()
                .required(intl.get("settings.change-password.error.required")),
            newPasswordAgain: Yup.string()
                .required(intl.get("settings.change-password.error.required"))
                .equals([Yup.ref("newPassword")], intl.get("settings.change-password.error.notmatch"))
        }),
        onSubmit: values => saveDataToServer(values)
    });

    const saveDataToServer = trackProgress(values => {
        return settingsService.changePassword(values).then(values => {
            const {result} = values;

            if (result.success) {
                formik.resetForm();
                setSuccessOnSave(result.message);
            } else {
                Object.keys(result.fields).length > 0 && formik.setErrors(result.fields as FormikErrors<FormValues>);
                result.message && setErrorOnSave(result.message);
            }
        }).catch(() => {
            setErrorOnSave();
        });
    });

    const handleFormChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (message) {
            resetMessage();
        }

        formik.handleChange(event);
    }, [message, resetMessage, formik]);

    useImperativeHandle(ref, (): SettingsModule => ({
        isDirty: formik.dirty,
        inProgress: inProgress
    }));

    useEffect(onLoad, []);

    return (
        <Box className={classes.root}>
            <section className={classes.description}>
                {intl.get("settings.change-password.description")}
            </section>

            <section className={classes.infoLink}>
                <Link href={"https://sexchat.hu/help/securepassword"}>{intl.get("settings.change-password.link")}</Link>
            </section>

            {
                message &&
                <Message inProgress={inProgress} message={message}/>
            }

            <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
                <PasswordField
                    autoComplete="new-password"
                    disabled={inProgress}
                    error={Boolean(formik.errors.password)}
                    helperText={formik.errors.password}
                    fullWidth
                    name="password"
                    label={intl.get("settings.change-password.password.placeholder")}
                    value={formik.values.password}
                    onChange={handleFormChange}
                />
                <PasswordField
                    autoComplete="new-password"
                    disabled={inProgress}
                    error={Boolean(formik.errors.newPassword)}
                    helperText={formik.errors.newPassword}
                    fullWidth
                    name="newPassword"
                    label={intl.get("settings.change-password.new-password.placeholder")}
                    value={formik.values.newPassword}
                    onChange={handleFormChange}
                />
                <PasswordField
                    autoComplete="new-password"
                    disabled={inProgress}
                    error={Boolean(formik.errors.newPasswordAgain)}
                    helperText={formik.errors.newPasswordAgain}
                    fullWidth
                    name="newPasswordAgain"
                    label={intl.get("settings.change-password.new-password-again.placeholder")}
                    value={formik.values.newPasswordAgain}
                    onChange={handleFormChange}
                />
                <Box textAlign="right">
                    <ProgressButton
                        progress={inProgress}
                        label={intl.get("settings.change-password.button")}
                        type="submit"/>
                </Box>
            </form>
        </Box>
    );
});
