import {ApplicationActionType} from "../action/application.action";
import fscreen from "fscreen";
import {Dialog} from "../domain/Dialog";
import {LOCATION_CHANGE} from "connected-react-router";

const initialState = {
    dialog: null,
    ready: false,
    visible: false,
    isFullscreen: false,
    isFullscreenEnabled: fscreen.fullscreenEnabled
};

export interface ApplicationState {
    dialog: Dialog
    ready: boolean
    visible: boolean
    isFullscreenEnabled: boolean
    isFullscreen: boolean
}

export function applicationReducer(state: ApplicationState = initialState, action): ApplicationState {
    const {type} = action;

    if (type === ApplicationActionType.ShowDialog) {
        state = {
            ...state,
            dialog: action.dialog
        };
    }

    if (type === LOCATION_CHANGE || type === ApplicationActionType.HideDialog) {
        state = {
            ...state,
            dialog: null
        };
    }

    if (type === ApplicationActionType.Ready) {
        state = {
            ...state,
            ready: true
        };
    }

    if (type === ApplicationActionType.BecameVisible) {
        state = {
            ...state,
            visible: true
        };
    }

    if (type === ApplicationActionType.BecameInvisible) {
        state = {
            ...state,
            visible: false
        };
    }

    if (type === ApplicationActionType.EnteredFullscreen) {
        state = {
            ...state,
            isFullscreen: true
        };
    }

    if (type === ApplicationActionType.ExitedFullscreen) {
        state = {
            ...state,
            isFullscreen: false
        };
    }

    return state;
}
