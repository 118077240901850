import {Box, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useFormik} from "formik";
import React, {ChangeEvent, useCallback, useEffect, useImperativeHandle, useState} from "react";
import intl from "react-intl-universal";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {MessageType} from "../../../domain/MessageType";
import {
    AvailableLanguages,
    NewsletterSettings as NewsletterSettingsInterface
} from "../../../domain/settings/newsletter/NewsletterSettings";
import {NotificationLanguage} from "../../../domain/settings/newsletter/NotificationLanguage";
import {NotificationSettings} from "../../../domain/settings/newsletter/NotificationSettings";
import {noop} from "../../../helper";
import {useDataLoaded} from "../../../hooks/data-loaded.hook";
import {useMessage} from "../../../hooks/message.hook";
import {useProgress} from "../../../hooks/progress.hook";
import {Loadable} from "../../contract/Loadable";
import {SettingsModule} from "../../contract/SettingsModule";
import {DisplayField} from "../../DisplayField";
import {Message} from "../../Message";

interface FormValues {
    emailLanguage: NotificationLanguage
    emailPrivacy: NotificationSettings
}

const initialValues: FormValues = {
    emailLanguage: NotificationLanguage.ENGLISH,
    emailPrivacy: NotificationSettings.ALL
};

const useStyles = makeStyles({
    root: {
        textAlign: "justify"
    },
    rootContainer: {
        padding: "8px 16px 16px"
    },
    message: {
        marginBottom: 16
    },
    description: {
        marginBottom: 16,
        textAlign: "justify"
    },
    email: {
        marginBottom: 8
    },
    emailText: {
        fontWeight: "bold",
        marginLeft: 8
    },
    notificationSettings: {
        marginBottom: 16
    }
});

interface Props extends Loadable {
}

export const NewsletterSettings = React.forwardRef<SettingsModule, Props>(({onLoad}, ref) => {
    const classes = useStyles();
    const [currentEmail, setCurrentEmail] = useState<string>("");
    const [languages, setLanguages] = useState<AvailableLanguages>({});
    const {settingsService} = useApplicationContext();
    const {dataLoaded, setDataLoaded} = useDataLoaded();
    const [inProgress, trackProgress] = useProgress();
    const {message, resetMessage, setErrorOnGet, setErrorOnSave, setSuccessOnSave} = useMessage();
    const formik = useFormik<FormValues>({
        initialValues,
        onSubmit: values => saveDataToServer(values)
    });

    const setValues = ({availableLanguages, email, emailPrivacy, emailLanguage}: NewsletterSettingsInterface) => {
        setLanguages(availableLanguages);
        setCurrentEmail(email);
        formik.resetForm({
            values: {
                emailLanguage,
                emailPrivacy
            }
        });
    };

    const getDataFromServer = trackProgress(() => {
        return settingsService.getNewsletterSettings().then(values => {
            setValues(values);
            resetMessage();
            setDataLoaded();
        }).catch(() => {
            setErrorOnGet();
        });
    });

    const saveDataToServer = trackProgress(values => {
        return settingsService.setNewsletterSettings(values).then(values => {
            const {result} = values;

            setValues(values);

            if (result.success) {
                setSuccessOnSave();
            } else {
                setErrorOnSave();
            }
        }).catch(() => {
            setErrorOnSave();
        });
    });

    const handleFormChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        if (message) {
            resetMessage();
        }

        formik.handleChange(event);
        formik.submitForm().catch(noop);
    }, [formik, message, resetMessage]);

    useImperativeHandle(ref, (): SettingsModule => ({
        isDirty: formik.dirty,
        inProgress: inProgress
    }));

    useEffect(() => {
        getDataFromServer().finally(onLoad);
        // eslint-disable-next-line
    }, []);

    return (
        <Box className={classes.root}>

            {
                message?.type === MessageType.GET_ERROR &&
                <Box className={classes.rootContainer}>
                    <Message getErrorMethod={getDataFromServer} inProgress={inProgress} message={message}/>
                </Box>
            }

            {
                dataLoaded &&
                <Box className={classes.rootContainer}>
                    <section className={classes.description}>
                        {intl.get("settings.newsletter.description")}
                    </section>

                    {
                        message &&
                        <Message inProgress={inProgress} message={message}/>
                    }

                    <form onSubmit={formik.handleSubmit}>

                        {
                            currentEmail &&
                            <DisplayField
                                label={intl.get("settings.newsletter.email")}
                                value={currentEmail}
                            />
                        }

                        <Box mb={1}>
                            <FormControl fullWidth>
                                <InputLabel id={"notification-label"}>{intl.get("settings.newsletter.notification.title")}</InputLabel>
                                <Select
                                    labelId={"notification-label"}
                                    disabled={inProgress}
                                    name="emailPrivacy"
                                    value={formik.values.emailPrivacy}
                                    onChange={handleFormChange}>
                                    <MenuItem
                                        value={NotificationSettings.ALL}>{intl.get("settings.newsletter.notification.option.all")}</MenuItem>
                                    <MenuItem
                                        value={NotificationSettings.SEVERE}>{intl.get("settings.newsletter.notification.option.severe")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mb={1}>
                            <FormControl fullWidth>
                                <InputLabel id={"language-label"}>{intl.get("settings.newsletter.language.title")}</InputLabel>
                                <Select
                                    labelId={"language-label"}
                                    disabled={inProgress}
                                    name="emailLanguage"
                                    value={formik.values.emailLanguage}
                                    onChange={handleFormChange}>

                                    {
                                        Object.entries(languages).map(([key, label]) => (
                                            <MenuItem key={key} value={key}>{label}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Box>
                    </form>
                </Box>
            }
        </Box>
    );
});
