import {Subject} from "rxjs";
import {PlayerHandler} from "vchat-player";
import {PlayerState} from "../../domain/Player";

export class VisitXPlayerHandler implements PlayerHandler {
    constructor(
        private playerStateSubject: Subject<PlayerState>,
        private muteStateSubject: Subject<boolean>
    ) {
    }

    public onVolumeChange(value: number) {
        this.muteStateSubject.next(value === 0);
    }

    public onError(error: string | object) {
        this.playerStateSubject.next(PlayerState.Error);
    }

    public onPlayError(level: number, error?: any) {
        this.playerStateSubject.next(PlayerState.Error);
    }

    public onPlayStart(stream: string) {
        this.playerStateSubject.next(PlayerState.Playing);
    }

    public onPlayStop() {
        this.playerStateSubject.next(PlayerState.Stopped);
    }
}
