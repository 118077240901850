import {useCallback, useState} from "react";
import {Message} from "../domain/Message";
import {MessageType} from "../domain/MessageType";

export const useMessage = (): {
    message: Message,
    resetMessage: () => void,
    setErrorOnGet: (customText?) => void,
    setErrorOnSave: (customText?) => void,
    setSuccessOnSave: (customText?) => void,
} => {
    const [message, setMessage] = useState<Message>(null);

    return {
        message,
        resetMessage: useCallback(() => setMessage(null), []),
        setErrorOnGet: useCallback(customText => setMessage({
            customText: customText,
            type: MessageType.GET_ERROR
        }), []),
        setErrorOnSave: useCallback(customText => setMessage({
            customText: customText,
            type: MessageType.SAVE_ERROR
        }), []),
        setSuccessOnSave: useCallback(customText => setMessage({
            customText: customText,
            type: MessageType.SAVE_SUCCESS
        }), [])
    };
};
