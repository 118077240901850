export class InputJumper {
    private eventName: string = "keyup";
    private pasteEventName: string = "paste";
    private reSpace: RegExp = / /g;
    private keysToIgnore = ["ArrowUp", "ArrowRight", "ArrowDown", "ArrowLeft", "Up", "Right", "Down", "Left"];

    constructor(private root: HTMLElement, private transition: object) {
        this.onEvent = window.setTimeout.bind(window, this.onEvent.bind(this), 10);
        this.attach();
    }

    private attach() {
        const {root} = this;

        root.addEventListener(this.eventName, this.onEvent, false);
        root.addEventListener(this.pasteEventName, this.onEvent, false);
    }

    public detach() {
        const {root} = this;

        root.removeEventListener(this.eventName, this.onEvent, false);
        root.removeEventListener(this.pasteEventName, this.onEvent, false);

        this.root = null;
    }

    private onEvent(event: KeyboardEvent) {
        const {keysToIgnore, transition, reSpace} = this;
        const {key, target} = event;

        if (key && keysToIgnore.includes(key)) {
            return;
        }

        const {value, name}: HTMLInputElement = target as HTMLInputElement;

        if (transition.hasOwnProperty(name) === false) {
            return;
        }

        const [length, next] = transition[name];
        const cleanValue = value.replace(reSpace, "");

        if (length === cleanValue.length) {
            const nextEl: HTMLInputElement = this.root.querySelector(`input[name="${next}"]`);

            if (nextEl) {
                nextEl.focus();

                try {
                    nextEl.setSelectionRange(0, nextEl.value.length);
                } catch (error) {
                }
            }
        }
    }
}
