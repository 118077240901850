import {config} from "../../config/settings-screen";
import {ChangeEmail} from "../../domain/settings/change-email/ChangeEmail";
import {NewsletterSettings} from "../../domain/settings/newsletter/NewsletterSettings";
import {RoomListAppearance} from "../../domain/settings/roomlist/RoomListAppearance";
import {SettingsResult} from "../../domain/settings/SettingsResult";
import {Http} from "../http";
import {
    extractDataFromChangeEmailResponse,
    extractDataFromChangePasswordResponse,
    extractDataFromDeleteAccountResponse,
    extractDataFromNewsletterSettingsResponse,
    extractDataFromRoomListAppearanceResponse
} from "./settings.transformers";

export class SettingsService {
    constructor(private http: Http) {
    }

    public cancelEmailChangeRequest(): Promise<SettingsResult & ChangeEmail> {
        const formData = new FormData();

        formData.append("action", "cancel");

        return this.http.post(config.changeEmail, {body: formData})
            .then(extractDataFromChangeEmailResponse);
    }

    public getChangeEmail(): Promise<ChangeEmail> {
        return this.http.get(config.changeEmail)
            .then(extractDataFromChangeEmailResponse);
    }

    public resendActivationEmail(): Promise<SettingsResult & ChangeEmail> {
        const formData = new FormData();

        formData.append("action", "resend");

        return this.http.post(config.changeEmail, {body: formData})
            .then(extractDataFromChangeEmailResponse);
    }

    public changeEmail(settings): Promise<SettingsResult & ChangeEmail> {
        const {email, password} = settings;
        const formData = new FormData();

        formData.append("newemail", email);
        formData.append("passwd", password);
        formData.append("action", "change");

        return this.http.post(config.changeEmail, {body: formData})
            .then(extractDataFromChangeEmailResponse);
    }

    public changePassword(settings): Promise<SettingsResult> {
        const {newPassword, newPasswordAgain, password} = settings;
        const formData = new FormData();

        formData.append("passwd", password);
        formData.append("newpasswd", newPassword);
        formData.append("newpasswd2", newPasswordAgain);

        return this.http.post(config.changePassword, {body: formData})
            .then(extractDataFromChangePasswordResponse);
    }

    public deleteAccount(settings): Promise<SettingsResult> {
        const {password, captcha} = settings;
        const formData = new FormData();

        formData.append("passwd", password);
        formData.append("captcha", captcha);

        return this.http.post(config.deleteAccount, {body: formData})
            .then(extractDataFromDeleteAccountResponse);
    }

    public getNewsletterSettings(): Promise<NewsletterSettings> {
        return this.http.get(config.newsletterSettings)
            .then(extractDataFromNewsletterSettingsResponse);
    }

    public setNewsletterSettings(settings): Promise<SettingsResult & NewsletterSettings> {
        const {emailLanguage, emailPrivacy} = settings;
        const formData = new FormData();

        formData.append("email_privacy", emailPrivacy);
        formData.append("email_language", emailLanguage);

        return this.http.post(config.newsletterSettings, {body: formData})
            .then(extractDataFromNewsletterSettingsResponse);
    }

    public getRoomListAppearance(): Promise<RoomListAppearance> {
        return this.http.get(config.roomListAppearance)
            .then(extractDataFromRoomListAppearanceResponse);
    }

    public setRoomListAppearance(settings): Promise<SettingsResult & RoomListAppearance> {
        const {liveFilter} = settings;
        const formData = new FormData();

        formData.append("livefilter", liveFilter);

        return this.http.post(config.roomListAppearance, {body: formData})
            .then(extractDataFromRoomListAppearanceResponse);
    }
}
