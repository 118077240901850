import {Subject} from "rxjs";

export class MuteStateHandler {

    private handleChange = () => this.state.next(this.video.muted);

    constructor(
        private video: HTMLVideoElement,
        private state: Subject<boolean>
    ) {
        video.addEventListener("volumechange", this.handleChange);

        this.handleChange();
    }

    destroy() {
        this.video.removeEventListener("volumechange", this.handleChange);
    }
}
