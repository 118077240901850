import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {RoomDto} from "../domain/RoomDto";
import {noop} from "../helper";
import {SxRoomListService} from "./SxRoomListService";
import {VxRoomListService} from "./VxRoomListService";

export class RoomListService {
    public constructor(
        private readonly sxService: SxRoomListService,
        private readonly vxService: VxRoomListService
    ) {
    }

    public get rooms(): Observable<Array<RoomDto>> {
        return combineLatest([
            this.sxService.rooms,
            this.vxService.rooms
        ]).pipe(map(([sx, vx]) => sx.concat(vx)));
    }

    public getRoomList(): Promise<Array<RoomDto>> {
        return this.rooms.toPromise();
    }

    public update() {

        // TODO revisit this mechanism
        this.sxService.update().catch(noop);
        // this.vxService.update();
    }
}
