import {AppBar, Box, GridList, GridListTile, Theme, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React, {Fragment, useCallback} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {IntersectionObserverContext} from "../../context/IntersectionObserverContext";
import {ExtraCredit} from "../../domain/ExtraCredit";
import {MouseClickEvent} from "../../domain/MouseClickEvent";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {useGlobalScrollPosition} from "../../hooks/scroll.hook";
import {redirectToRoom, redirectToTopUp} from "../../redirect";
import {SignInButton} from "../auth/SignInButton";
import {UserHolder} from "../contract/UserHolder";
import {IconToggleLocale} from "../i18n/IconToggleLocale";
import {LazyImage} from "../LazyImage";
import {Logo} from "../Logo";
import {Search} from "../search/Search";
import {TextWithEmoji} from "../TextWithEmoji";
import {Menu} from "./Menu";
import "./Menu.scss";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        backgroundColor: "rgba(0,0,0,0.6)"
    },
    toolbar: {
        paddingRight: "4px"
    },
    gridList: {
        width: "100%",
        paddingTop: "64px",
        transform: "translateZ(0)"
    },
    tileBar: {
        position: "absolute",
        color: "white",
        display: "flex",
        flexDirection: "row",
        top: 0,
        left: 0,
        right: 0,
        background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.8em",
        padding: "10px"
    },
    titleWrap: {
        flex: 1
    },
    smartToy: {
        height: "24px"
    },
    nrOne: {
        height: "24px"
    },
    imgExtraCredit: {
        width: "100%",
        height: "100%"
    },
    unset: {
        height: "unset !important"
    },
    img: {
        position: "relative",
        top: "50%",
        width: "100%",
        transform: "translateY(-50%)"
    }
}));

interface Props extends UserHolder {
    rooms: Array<RoomDto>
    extraCredit: ExtraCredit
}

const mapStateToProps = ({rooms, topup, auth}) => ({
    rooms: rooms.rooms,
    extraCredit: topup.extraCredit,
    user: auth.user
});

const factory = id => ({
    "data-room-id": id
});

export const RoomList = connect(mapStateToProps)(React.memo<Props>(({rooms, extraCredit, user}) => {
    const classes = useStyles();
    const handleClick = useCallback<(event: MouseClickEvent) => void>((event: MouseClickEvent) => {
        const roomId: RoomId = (event.currentTarget as HTMLElement).dataset.roomId;

        if (roomId) {
            redirectToRoom(roomId);
        }
    }, []);

    useGlobalScrollPosition<HTMLDivElement>();

    const tiles = rooms.map((room: RoomDto) => (
        // @ts-ignore
        <GridListTile key={room.roomid} {...factory(room.roomid)} onClick={handleClick}>
            <LazyImage
                alt={room.screenName}
                className={classes.img}
                fallbackSourceOnError={"/images/sexchat-full.svg"}
                src={room.publicData?.profilePic}
            />
            <div className={classes.tileBar}>
                <div className={classes.titleWrap}>
                    <div><TextWithEmoji text={room.screenName}/></div>
                    <div>{intl.get("chat.enter.".concat(room.roomMode))}</div>
                </div>
                <div>
                    {
                        room.publicData?.toys?.registered?.length > 0 &&
                        <img className={classes.smartToy} alt="smarttoy" src="/images/smarttoy.svg"/>
                    }
                    {
                        room.pom &&
                        <img className={classes.nrOne} alt="number one" src="/images/nr1.svg"/>
                    }
                </div>
            </div>
        </GridListTile>
    ));

    if (extraCredit && extraCredit.bonus >= 25) {
        tiles.splice(1, 0, (
            <GridListTile key="happyhour" className={classes.unset} onClick={redirectToTopUp}>
                <img src={"/images/".concat(intl.get("topup.extra-credit.bannerOver25"))}
                     alt="happy hour extra credit" className={classes.imgExtraCredit}/>
            </GridListTile>
        ));
    }

    return (
        // TODO should be used with any ref on any level
        <IntersectionObserverContext root={document.getElementById("root")} rootMargin={"100% 0%"}>
            <GridList spacing={0} cols={1} className={classes.gridList} cellHeight={220}>
                {tiles}
            </GridList>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    {
                        <Fragment>
                            <Box flex={1}>
                                <Logo subTitle={extraCredit ? intl.get("topup.extra-credit.logo.subTitle", extraCredit) : intl.get("app.logo.subTitle")}/>
                            </Box>

                            {
                                user && user.isMember &&
                                <Fragment>
                                    <Search/>
                                    <Menu/>
                                </Fragment>
                            }

                            {
                                (user === null || user.isMember === false) &&
                                <IconToggleLocale/>
                            }
                        </Fragment>
                    }
                </Toolbar>
            </AppBar>
            <SignInButton/>
        </IntersectionObserverContext>
    );
}));
