import {Locale} from "../domain/Locale";
import {I18nActionType} from "../action/i18n.action";

const initialState: Locale = null;

export function i18nReducer(state: Locale = initialState, action): Locale {
    if (action.type === I18nActionType.LocaleSet) {
        state = action.locale;
    }

    return state;
}
