import {Box, Dialog, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React, {useCallback, useMemo} from "react";
import intl from "react-intl-universal";
import {useDispatch, useSelector} from "react-redux";
import {closeIncentiveDialog} from "../action/incentive.action";
import {I18n} from "../domain/I18n";
import {routeToRoom} from "../helper";
import {RootState} from "../reducer";
import {IncentiveState} from "../reducer/incentive.reducer";
import {SignInButton} from "./auth/SignInButton";
import {SignUpForm} from "./auth/SignUpForm";
import {Body} from "./layout/Body";
import {Viewport} from "./layout/Viewport";
import {CircleProfilePicture} from "./room/CircleProfilePicture";
import {ExitFab} from "./room/ExitFab";
import {SlideTransition} from "./SlideTransition";

const useStyles = makeStyles({
    signin: {
        top: 5,
        right: 5,
        left: "unset",
        position: "absolute",
        transform: "unset"
    },
    exit: {
        left: 5
    },
    profile: {
        marginTop: -15
    },
    bbar: {
        position: "absolute",
        margin: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: 44,
        display: "flex",
        zIndex: 1350
    },
    button: {
        textTransform: "uppercase",
        borderRadius: 0,
        flex: 1
    }
});

export const IncentiveDialog = React.memo(() => {
    const classes = useStyles();
    const incentive = useSelector<RootState, IncentiveState>(({incentive}) => incentive);
    const {room, closable} = incentive;
    const dispatch = useDispatch();
    const close = useCallback(() => dispatch(closeIncentiveDialog()), [dispatch]);
    const overrides = useMemo(() => ({
        bbar: classes.bbar,
        submitButton: classes.button
    }), [classes.bbar, classes.button]);

    return (
        <Dialog open={incentive.open} TransitionComponent={SlideTransition}>
            <Viewport>
                <Body>
                    {
                        room &&
                        <CircleProfilePicture
                            className={classes.profile}
                            src={room?.publicData?.profilePic}
                            title={intl.get(I18n.IncentiveTitle)}
                            mood={"excited"}
                            color={"base"}/>
                    }

                    <Box marginY={2}>
                        <Typography>{intl.get(I18n.IncentiveTimeToSignUp)}</Typography>
                    </Box>

                    <SignUpForm classes={overrides}/>
                </Body>

                {
                    closable && <ExitFab onClick={close} className={classes.exit}/>
                }

                <SignInButton size={"small"} className={classes.signin} next={room && routeToRoom(room.roomid)}/>
            </Viewport>
        </Dialog>
    );
});
