import {Box, Link, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {FormikErrors, useFormik} from "formik";
import React, {ChangeEvent, useCallback, useEffect, useImperativeHandle, useState} from "react";
import intl from "react-intl-universal";
import * as Yup from "yup";
import {useApplicationContext} from "../../../application/ApplicationContext";
import {MessageType} from "../../../domain/MessageType";
import {ChangeEmailStep} from "../../../domain/settings/change-email/ChangeEmailStep";
import {useDataLoaded} from "../../../hooks/data-loaded.hook";
import {useMessage} from "../../../hooks/message.hook";
import {useProgress} from "../../../hooks/progress.hook";
import {Loadable} from "../../contract/Loadable";
import {SettingsModule} from "../../contract/SettingsModule";
import {DisplayField} from "../../DisplayField";
import {Message} from "../../Message";
import {PasswordField} from "../../PasswordField";
import {ProgressButton} from "../../ProgressButton";

interface FormValues {
    password: string
    email: string
}

const initialValues: FormValues = {
    email: "",
    password: ""
};

const useStyles = makeStyles({
    root: {},
    rootContainer: {
        padding: "8px 16px 16px"
    },
    description: {
        marginBottom: 16,
        textAlign: "justify"
    },
    email: {
        marginBottom: 16
    },
    emailText: {
        paddingLeft: 8
    },
    undoButton: {
        marginRight: 16
    }
});

interface Props extends Loadable {
}

export const ChangeEmail = React.forwardRef<SettingsModule, Props>(({onLoad}, ref) => {
    const classes = useStyles();
    const [step, setStep] = useState<ChangeEmailStep>(ChangeEmailStep.MODIFY);
    const [currentEmail, setCurrentEmail] = useState<string>("");
    const {settingsService} = useApplicationContext();
    const {dataLoaded, setDataLoaded} = useDataLoaded();
    const [inProgress, trackProgress] = useProgress();
    const {message, resetMessage, setErrorOnGet, setErrorOnSave, setSuccessOnSave} = useMessage();

    const saveDataToServer = trackProgress(values => {
        return settingsService.changeEmail(values).then(values => {
            const {result} = values;

            if (result.success) {
                resetForm(values);
                setSuccessOnSave(result.message);
            } else {
                Object.keys(result.fields).length > 0 && formik.setErrors(result.fields as FormikErrors<FormValues>);
                result.message && setErrorOnSave(result.message);
            }
        }).catch(() => {
            setErrorOnSave();
        });
    });

    const formik = useFormik<FormValues>({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            password: Yup.string().required(intl.get("settings.change-email.modify.error.required")),
            email: Yup.string()
                .required(intl.get("settings.change-email.modify.error.required"))
        }),
        onSubmit: values => saveDataToServer(values)
    });

    const resetForm = useCallback(values => {
        const {currentEmail, currentChangeEmail, step} = values;

        setCurrentEmail(currentEmail);
        setStep(step);

        formik.resetForm({
            values: {
                email: currentChangeEmail || "",
                password: ""
            }
        });
    }, [formik]);

    const getDataFromServer = useCallback(() => {
        return settingsService.getChangeEmail().then(values => {
            resetForm(values);
            setDataLoaded();
        }).catch(() => {
            setErrorOnGet();
        });
    }, [settingsService, resetForm, setDataLoaded, setErrorOnGet]);

    const cancelChange = trackProgress(() => {
        return settingsService.cancelEmailChangeRequest().then(values => {
            const {result} = values;

            if (result.success) {
                resetForm({
                    ...values,
                    currentChangeEmail: ""
                });
                setSuccessOnSave(result.message);
            } else {
                setErrorOnSave(result.message);
            }
        }).catch(() => {
            setErrorOnSave();
        });
    });

    const resendActivationEmail = trackProgress(() => {
        return settingsService.resendActivationEmail().then(values => {
            const {result} = values;

            if (result.success) {
                resetForm(values);
                setSuccessOnSave(result.message);
            } else {
                setErrorOnSave(result.message);
            }
        }).catch(() => {
            setErrorOnSave();
        });
    });

    const handleFormChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (message) {
            resetMessage();
        }

        formik.handleChange(event);
    }, [message, resetMessage, formik]);

    useImperativeHandle(ref, (): SettingsModule => ({
        isDirty: formik.dirty,
        inProgress: inProgress
    }));

    useEffect(() => {
        getDataFromServer().finally(onLoad);
        // eslint-disable-next-line
    }, []);

    return (
        <Box className={classes.root}>
            {
                message?.type === MessageType.GET_ERROR &&
                <Box className={classes.rootContainer}>
                    <Message getErrorMethod={getDataFromServer} inProgress={inProgress} message={message}/>
                </Box>
            }

            {
                dataLoaded &&
                <Box className={classes.rootContainer}>
                    {
                        step === ChangeEmailStep.MODIFY &&
                        <>
                            <header
                                className={classes.description}>
                                {intl.get("settings.change-email.modify.description")}
                            </header>

                            {
                                currentEmail &&
                                <DisplayField
                                    label={intl.get("settings.change-email.modify.email")}
                                    value={currentEmail}
                                />
                            }

                            {
                                message &&
                                <Message inProgress={inProgress} message={message}/>
                            }

                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <TextField
                                    autoComplete="new-password"
                                    disabled={inProgress}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                    fullWidth
                                    name="email"
                                    label={intl.get("settings.change-email.modify.new.placeholder")}
                                    value={formik.values.email}
                                    onChange={handleFormChange}
                                    type="email"
                                />
                                <PasswordField
                                    autoComplete="new-password"
                                    disabled={inProgress}
                                    error={Boolean(formik.errors.password)}
                                    helperText={formik.errors.password}
                                    fullWidth
                                    name="password"
                                    label={intl.get("settings.change-email.modify.password.placeholder")}
                                    value={formik.values.password}
                                    onChange={handleFormChange}
                                />
                                <Box textAlign="right">
                                    <ProgressButton
                                        progress={inProgress}
                                        label={intl.get("settings.change-email.modify.button")}
                                        type="submit"/>
                                </Box>
                            </form>
                        </>
                    }

                    {
                        step === ChangeEmailStep.ACTIVATION &&
                        <>
                            <section className={classes.description}>
                                {intl.get("settings.change-email.activation.description")}
                            </section>

                            <DisplayField
                                label={intl.get("settings.change-email.activation.email.current")}
                                value={currentEmail}
                            />

                            <DisplayField
                                label={intl.get("settings.change-email.activation.email.new")}
                                helperText={intl.get("settings.change-email.activation.confirmation")}
                                value={formik.values.email}
                            />

                            {
                                message &&
                                <Message inProgress={inProgress} message={message}/>
                            }

                            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                                <Link onClick={cancelChange} className={classes.undoButton}>
                                    {intl.get("settings.change-email.activation.undo")}
                                </Link>
                                <ProgressButton
                                    onClick={resendActivationEmail}
                                    progress={inProgress}
                                    label={intl.get("settings.change-email.activation.resend")}/>
                            </Box>
                        </>
                    }
                </Box>
            }
        </Box>
    );
});
