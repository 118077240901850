import {RoomDto} from "../domain/RoomDto";

export enum IncentiveActionType {
    ShowDialog = "incentive:show",
    CloseDialog = "incentive:close"
}

export const showIncentiveDialog = (room: RoomDto, closable: boolean = true) => ({
    type: IncentiveActionType.ShowDialog,
    room,
    closable
});

export const closeIncentiveDialog = () => ({
    type: IncentiveActionType.CloseDialog
});
