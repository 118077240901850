import {merge, Observable, Subject} from "rxjs";
import {JoinRoomSucceeded, SendGiftFailed, SendGiftSucceeded} from "../domain/events";
import {GiftFailedReason} from "../domain/GiftFailedReason";
import {GiftId} from "../domain/GiftId";
import {NotAvailableError} from "../domain/NotAvailableError";
import {Price} from "../domain/Price";
import {RoomId} from "../domain/RoomId";
import {RoomMode} from "../domain/RoomMode";
import {StickerId} from "../domain/StickerId";
import {Logger} from "../logger/Logger";
import {SxRoomService} from "./SxRoomService";
import {VxRoomService} from "./visitx/VxRoomService";

export class RoomService {

    public static isVisitX(roomId: RoomId) {
        return roomId?.startsWith("v") === true;
    }

    private readonly subject: Subject<any> = new Subject<any>();

    get events(): Observable<any> {
        return this.subject.asObservable();
    }

    get onReady(): Observable<boolean> {

        // TODO refactor this mechanism
        return this.sx.onReady;
    }

    constructor(
        private logger: Logger,
        private sx: SxRoomService,
        private vx: VxRoomService
    ) {
        merge(sx.events, vx.events).subscribe(this.subject);
    }

    public sendTip(roomId: RoomId, message: string, price: Price): Promise<SendGiftSucceeded> {
        if (RoomService.isVisitX(roomId)) {
            return Promise.reject(new SendGiftFailed(GiftFailedReason.NotAvailable));
        }

        return this.sx.sendTip(roomId, message, price);
    }

    public sendZzz(roomId: RoomId, price: Price): Promise<SendGiftSucceeded> {
        if (RoomService.isVisitX(roomId)) {
            return Promise.reject(new SendGiftFailed(GiftFailedReason.NotAvailable));
        }

        return this.sx.sendZzz(roomId, price);
    }

    public sendGift(roomId: RoomId, giftId: GiftId, message: string, price: Price): Promise<SendGiftSucceeded> {
        if (RoomService.isVisitX(roomId)) {
            return Promise.reject(new SendGiftFailed(GiftFailedReason.NotAvailable));
        }

        return this.sx.sendGift(roomId, giftId, message, price);
    }

    public sendSticker(roomId: RoomId, stickerId: StickerId) {
        if (RoomService.isVisitX(roomId)) {
            throw new NotAvailableError();
        }

        this.sendMessage(roomId, "#sticker:".concat(stickerId));
    }

    public sendMessage(roomId: RoomId, message: string) {
        if (RoomService.isVisitX(roomId)) {
            this.vx.sendMessage(roomId, message);
        }

        this.sx.sendMessage(roomId, message);
    }

    public join(roomId: RoomId, mode: RoomMode): Promise<JoinRoomSucceeded> {
        if (RoomService.isVisitX(roomId)) {
            return this.vx.join(roomId, mode);
        }

        return this.sx.join(roomId, mode);
    }

    public requestChatMode(roomId: RoomId, mode: RoomMode): Promise<JoinRoomSucceeded> {
        if (RoomService.isVisitX(roomId)) {
            return this.vx.requestChatMode(roomId, mode);
        }

        return this.sx.requestChatMode(roomId, mode);
    }

    public leave(roomId: RoomId): Promise<void> {
        if (RoomService.isVisitX(roomId)) {
            return this.vx.leave(roomId);
        }

        return this.sx.leave(roomId);
    }

    public suspend() {

        // TODO make the service handle this by itself
        this.sx.suspend();
    }

    public addFavorite(roomId: RoomId) {
        this.sx.addFavorite(roomId);
    }

    public removeFavorite(roomId: RoomId) {
        this.sx.removeFavorite(roomId);
    }
}
