import Fab from "@material-ui/core/Fab";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeOnIcon from "@material-ui/icons/VolumeUp";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useApplicationContext} from "../../application/ApplicationContext";
import {clsx} from "../../helper";

interface Props {
    className?: string
}

export const MuteButton = React.memo<Props>(({className}) => {
    const {streamService} = useApplicationContext();
    const [muted, setMuted] = useState<boolean>(true);
    const mute = useCallback(() => streamService.mute(), [streamService]);
    const unmute = useCallback(() => streamService.unmute(), [streamService]);
    const handleClick = useMemo(() => muted ? unmute : mute, [muted, mute, unmute]);

    useEffect(() => {
        const subscription = streamService.muteState().subscribe(setMuted);

        return () => subscription.unsubscribe();
    }, [streamService]);

    return <Fab
        color="secondary"
        className={clsx(className, muted ? "muted" : "unmuted")}
        onClick={handleClick}
    >{muted ? <VolumeOffIcon/> : <VolumeOnIcon/>}</Fab>;
});
