import {AuthActionType} from "../action/auth.action";
import {ChatActionType} from "../action/chat.action";

export function creditReducer(state: number = 0, action): number {
    const {type} = action;

    switch (type) {
        case AuthActionType.UserSet: {
            return 0;
        }

        case ChatActionType.CreditChanged: {
            const {event: {credit}} = action;

            return credit | 0;
        }

        default:
            return state;
    }
}
