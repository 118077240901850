import {ChatMessageDto} from "../dto/ChatMessageDto";

export class ChatMessageEvent {
    private stickerRe: RegExp = /^#sticker:([0-9]+)$/;

    // TODO ideally this should be a separate class
    private readonly stickerId: number = null;

    constructor(private data: ChatMessageDto) {
        const match = this.stickerRe.exec(this.data.message.text);

        if (match) {
            this.stickerId = parseInt(match[1], 10);
        }
    }

    isMember(): boolean {
        return this.data.type === "member";
    }

    isPerformer(): boolean {
        return this.data.type === "performer";
    }

    isSticker(): boolean {
        return this.stickerId !== null;
    }

    getStickerId(): number {
        return this.stickerId;
    }

    getName(): string {
        return this.data.from;
    }

    getMessage(): string {
        return this.data.message.text;
    }
}
