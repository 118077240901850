import React from "react";
import {redirectToRoomList} from "../../redirect";
import {ExitFab} from "./ExitFab";

interface Props {
    className?: string
}

export const ExitToRoomListButton = React.memo<Props>(({className}) => (
    <ExitFab onClick={redirectToRoomList} className={className}/>
));
