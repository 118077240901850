export class I18n {
    static EmailValidationRoomComingUp = "user.validation.coming-up";
    static EmailValidationTitle = "user.validation.title";
    static RestrictedArea = "auth.403";
    static SignInButton = "auth.signin";
    static SignInErrorCredentials = "auth.signin.error.credentials";
    static SignInErrorTechnical = "auth.signin.error.technical";
    static SignInNotSignedUp = "user.signin.not-signed-up";
    static SignInPasswordLabel = "login.password";
    static SignInSignUpLink = "auth.signin.redirect-to-signup";
    static SignInForgottenPasswordLink = "user.signin.redirect-to-forgotten-password";
    static SignInTitle = "auth.signin";
    static SignInUsernameLabel = "login.username";
    static SignUpAlreadySignedUp = "user.signup.already-signed-up";
    static SignUpEmailLabel = "user.signup.email";
    static SignUpErrorDisabled = "user.signup.error.disabled";
    static SignUpErrorEmailInvalid = "user.signup.error.emailinvalid";
    static SignUpErrorEmailUsed = "user.signup.error.emailused";
    static SignUpErrorMissingData = "user.signup.error.missingdata";
    static SignUpErrorPasswordInvalid = "user.signup.error.passwdinvalid";
    static SignUpErrorPasswordMismatch = "user.signup.error.passwdmismatch";
    static SignUpErrorTechnical = "user.signup.error.technical";
    static SignUpErrorTemporaryBan = "user.signup.error.iptempban";
    static SignUpErrorUsernameInvalid = "user.signup.error.usernameinvalid";
    static SignUpErrorUsernameTaken = "user.signup.error.usernametaken";
    static SignUpPasswordAgainLabel = "user.signup.password-again";
    static SignUpPasswordLabel = "user.signup.password";
    static SignUpSignInLink = "user.signup.redirect-to-signin";
    static SignUpSubmitButton = "user.signup.submit";
    static SignUpSuccessText = "user.signup.success.finish";
    static SignUpSuccessTitle = "user.signup.success.title";
    static SignUpTerms = "user.signup.accept-terms";
    static SignUpTitle = "user.signup.title";
    static SignUpUsernameLabel = "user.signup.userid";
    static ToRoomButton = "common.button.to-room";
    static ToRoomListButton = "common.button.back-to-rooms";
    static ForgottenPasswordTitle = "forgotten-password.title";
    static ForgottenPasswordText = "forgotten-password.text";
    static ForgottenPasswordFieldLabel = "forgotten-password.email.label";
    static ForgottenPasswordButtonLabel = "forgotten-password.send";
    static ForgottenPasswordBackToSignIn = "forgotten-password.redirect-to-signin";
    static ForgottenPasswordSucceededText = "forgotten-password.succeeded.text";
    static ForgottenPasswordSucceededButton = "forgotten-password.succeeded.button";
    static ForgottenPasswordFailedText = "forgotten-password.failed.text";
    static ForgottenPasswordFailedButton = "forgotten-password.failed.button";
    static IncentiveTitle = "incentive.title";
    static IncentiveTimeToSignUp = "incentive.time-to-sign-up";
}
