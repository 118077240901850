import React, {PureComponent, ReactNode} from "react";
import {Clickable} from "./contract/Clickable";
import {Disableable} from "./contract/Disableable";
import {SubLabelButton} from "./SubLabelButton";

interface Props extends Clickable, Disableable {
    onTimeIsUp?: () => void
    label: string
    timeout?: number
}

const noop = () => null;

export class CountdownButton extends PureComponent<Props> {
    state = {
        used: false,
        toGo: null
    };

    private defaultTimeout: number = 10;
    private timer: number;

    constructor(props) {
        super(props);

        this.state = {
            used: false,
            toGo: props.timeout || this.defaultTimeout
        };

        this.onClick = this.onClick.bind(this);
    }

    private static format(secondsToGo: number): string {
        const minutes = Math.floor(secondsToGo / 60);
        const seconds = Math.floor(secondsToGo % 60);

        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }

    componentDidMount(): void {
        this.start();
    }

    componentWillUnmount(): void {
        this.stop();
    }

    componentDidUpdate(): void {
        const {used, toGo} = this.state;

        if (used) {
            return;
        }

        if (toGo === 0) {
            if (this.props.onTimeIsUp) {
                this.onTimeIsUp();
            } else {
                this.onClick();
            }
        }
    }

    private decrease(): void {
        const toGo = this.state.toGo - 1;

        this.setState({toGo});
    }

    private start() {
        this.timer = window.setInterval(this.decrease.bind(this), 1000);
    }

    private stop() {
        window.clearInterval(this.timer);
        this.setState({
            used: true,
            toGo: 0
        });
    }

    private onClick() {
        this.stop();
        this.props.onClick();
    }

    private onTimeIsUp() {
        this.stop();
        this.props.onTimeIsUp();
    }

    render(): ReactNode {
        const {label, disabled} = this.props;
        const {toGo, used} = this.state;

        return <SubLabelButton disabled={disabled}
                               label={label}
                               subLabel={toGo ? CountdownButton.format(toGo) : null}
                               onClick={used ? noop : this.onClick}/>;
    }
}
