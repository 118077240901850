import {Store} from "redux";
import {TopUpService} from "../domain/topup/TopUpService";
import {endHappyHour, endPushPromo, startHappyHour, startPushPromo} from "../action/topup.action";
import {ExtraCredit} from "../domain/ExtraCredit";
import {ApplicationActionType} from "../action/application.action";
import {PushPromo} from "../domain/PushPromo";

export const topupMiddleware = (service: TopUpService) => (store: Store) => {
    const register = () => service.happyHour.subscribe((extraCredit: ExtraCredit) => {
        if (extraCredit) {
            store.dispatch(startHappyHour(extraCredit));
        } else {
            store.dispatch(endHappyHour());
        }
    });

    const registerPushPromo = () => service.pushPromo.subscribe((pushPromo: PushPromo) => {
        if (pushPromo) {
            store.dispatch(startPushPromo(pushPromo));
        } else {
            store.dispatch(endPushPromo());
        }
    });

    return next => action => {
        const result = next(action);

        if (action.type === ApplicationActionType.Ready) {
            register();
            registerPushPromo();
        }

        return result;
    };
};
