import {GiftFailedReason} from "./GiftFailedReason";
import {GiftId} from "./GiftId";
import {JoinRoomFailedReason} from "./JoinRoomFailedReason";
import {ModeSetupUpdateData} from "./ModeSetupUpdateData";
import {Price} from "./Price";
import {RoomDto} from "./RoomDto";
import {RoomId} from "./RoomId";

// TODO separate this into single files

export class JoinRoomSucceeded {
    constructor(public room: RoomDto) {
    }
}

export class JoinRoomFailed {
    constructor(public reason: JoinRoomFailedReason, public room: RoomDto = {} as RoomDto) {
    }
}

export class RequestChatModeFailed {
    constructor(public reason: JoinRoomFailedReason) {
    }
}

export class LeaveRoomSucceeded {
}

export class LeaveRoomFailed {
}

export class SendGiftSucceeded {
    constructor(public giftId: GiftId, public price: Price) {
    }
}

export class SendGiftFailed {
    constructor(public reason: GiftFailedReason) {
    }
}

export class RoomStatusChanged {
    constructor(public room: RoomDto) {
    }
}

export class PromotedToPrivateOwner {
    constructor(public roomId: RoomId) {
    }
}

export class TechnicalError {
}

export class AuthenticationFailed {
}

export class RoomModeSetupChanged {
    constructor(public data: ModeSetupUpdateData) {
    }
}

export class ReleaseReceived {
    constructor(public release: string) {
    }
}

export class FavoriteListUpdated {
    constructor(public roomIds: Array<RoomId>) {
    }
}

export class FavoriteUpdated {
    constructor(public readonly roomId: RoomId, public readonly status: boolean) {
    }
}
