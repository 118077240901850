import {Store} from "redux";
import {FeatureActionType, featureDisabled} from "../action/feature.action";
import {Feature} from "../domain/feature/Feature";
import {FeatureDisabledReason} from "../domain/feature/FeatureDisabledReason";
import {ActionToFeature, Matrix} from "../domain/feature/Matrix";
import {Requirement} from "../domain/feature/Requirement";
import {MouseClickEvent} from "../domain/MouseClickEvent";
import {noop} from "../helper";
import {
    disabled,
    mustBeCouple,
    mustBeGroupOrPrivate,
    mustBeMember,
    mustBeNotGroupOrPrivate,
    mustBePrivate,
    mustBeSxRoom,
    mustHaveSmartToy,
    mustNotBeVoyeur
} from "./helper";

export const featureMiddleware = () => (store: Store) => {
    const handleReject = (feature: Feature) => (reason: FeatureDisabledReason) => {
        switch (reason) {
            case FeatureDisabledReason.Disabled:
            case FeatureDisabledReason.RoomCategoryIsNotCouple:
            case FeatureDisabledReason.RoomModeIsNotGroupOrPrivate:
            case FeatureDisabledReason.RoomModeIsGroupOrPrivate:
            case FeatureDisabledReason.RoomModeIsNotPrivate:
            case FeatureDisabledReason.ViewerIsVoyeur:
            case FeatureDisabledReason.UserIsNotMember:
            case FeatureDisabledReason.Other: {
                store.dispatch(featureDisabled(feature, reason));

                break;
            }

            default: {
                store.dispatch(featureDisabled(feature, FeatureDisabledReason.Other));
            }
        }
    };

    const checkFeature = (feature: Feature): Promise<void> => {
        if (Matrix.hasOwnProperty(feature) === false) {
            return Promise.resolve();
        }

        const bits = Matrix[feature];
        const {auth, chat} = store.getState();
        const {lobby, room} = chat;
        const currentRoom = lobby || room;

        return Promise.resolve()
            .then(bits & Requirement.Disabled ? disabled : noop)
            .then(bits & Requirement.InSxRoom ? mustBeSxRoom(currentRoom) : noop)
            .then(bits & Requirement.RoomModeIsPrivate ? mustBePrivate(currentRoom) : noop)
            .then(bits & Requirement.RoomModeIsGroupOrPrivate ? mustBeGroupOrPrivate(currentRoom) : noop)
            .then(bits & Requirement.RoomModeIsNotGroupOrPrivate ? mustBeNotGroupOrPrivate(currentRoom) : noop)
            .then(bits & Requirement.RoomCategoryIsCouple ? mustBeCouple(currentRoom) : noop)
            .then(bits & Requirement.InRoomAsNormal ? mustNotBeVoyeur(currentRoom) : noop)
            .then(bits & Requirement.UserIsMember ? mustBeMember(auth.user) : noop)
            .then(bits & Requirement.HasSmartToy ? mustHaveSmartToy(currentRoom) : noop);
    };

    const event = "click";
    const selector = "[disabled][data-feature]";
    const attribute = "data-feature";

    const captureDisabledFeature = (event: MouseClickEvent) => {
        const {target} = event;
        const el = target && target.closest && target.closest(selector);

        if (el) {
            const feature = el.getAttribute(attribute) as Feature;

            checkFeature(feature)
                .catch(handleReject(feature));
        }
    };

    document.addEventListener(event, captureDisabledFeature, false);

    return next => action => {
        const {type} = action;

        // TODO old way, clean up
        if (type === FeatureActionType.RequestFeature) {
            const {feature, actionOnEnabled} = action;

            checkFeature(feature)
                .then(() => actionOnEnabled && store.dispatch(actionOnEnabled))
                .catch(handleReject(feature));

            next(action);
        }

        // TODO new way, transfrom into it
        if (ActionToFeature.hasOwnProperty(type)) {
            const [feature, silent = false] = ActionToFeature[type];

            checkFeature(feature)
                .then(() => next(action))
                .catch(silent ? noop : handleReject(feature));

            return;
        }

        next(action);
    };
};
