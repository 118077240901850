import {Snack} from "../domain/snack/Snack";

export enum SnackbarActionType {
    HideAll = "snackbar/hide-all",
    Show = "snackbar/show"
}

export const hideAllSnackbar = () => ({
    type: SnackbarActionType.HideAll
});

export const showSnackbar = ({button, id, label, timeout = 3000}: Snack) => ({
    type: SnackbarActionType.Show,
    snack: {button, id, label, timeout}
});
