import {Storage} from "../domain/Storage";
import {LocalStorage} from "./LocalStorage";

export class SimpleStorage<T> implements Storage<T> {
    constructor(
        private readonly key: string,
        private readonly localStorage: LocalStorage
    ) {
    }

    getItem(): Promise<T> {
        const data = this.localStorage.getItem(this.key);
        const parsed = data ? JSON.parse(data) : null;

        return Promise.resolve(parsed);
    }

    setItem(item: T): Promise<T> {
        this.localStorage.setItem(this.key, JSON.stringify(item));

        return Promise.resolve(item);
    }
}
