import React from "react";
import {Box, Typography, WithStyles, withStyles} from "@material-ui/core";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {styles} from "./styles";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";
import {CountdownButton} from "../../CountdownButton";
import {redirectToRoom, redirectToRoomList} from "../../../redirect";
import {RoomHolder} from "../../contract/RoomHolder";
import {LinkButton} from "../../LinkButton";
import {connect} from "react-redux";

interface Props extends WithStyles<typeof styles>, LobbyHolder, RoomHolder {
}

const mapStateToProps = ({rooms}) => ({
    room: rooms.upcoming
});

export const ExclusiveLobby = connect(mapStateToProps)(withStyles(styles)(React.memo<Props>(({classes, lobby, room}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic}/>

            <Typography className={classes.message}>
                <span>{intl.get("lobby.exclusive", lobby)}</span>

                {
                    room && <span> {intl.get("lobby.exclusive.coming-up", room)}</span>
                }
            </Typography>

            {
                room &&
                <LinkButton onClick={redirectToRoomList} title={intl.get("common.button.back-to-rooms")}/>
            }

        </Box>

        <CountdownButton onClick={room ? () => redirectToRoom(room.roomid) : redirectToRoomList}
                         label={room
                             ? intl.get("common.button.to-room", room)
                             : intl.get("common.button.back-to-rooms")}/>
        <ExitToRoomListButton/>
    </Viewport>
))));
