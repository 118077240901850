import {makeStyles} from "@material-ui/styles";
import {theme} from "../theme";
import React, {FC} from "react";
import {SnackbarProvider as NotiStackProvider} from "notistack";

const useStyles = makeStyles(() => ({
    containerAnchorOriginBottomCenter: {
        bottom: 0,
        "& > div": {
            paddingLeft: 0,
            paddingRight: 0,
        },
        "& > div > div": {
            marginTop: 0,
            marginBottom: 0,
        }
    },
    contentRoot: {
        borderRadius: 0
    },
    variantInfo: {
        backgroundColor: `${theme.palette.background.default} !important`,
        flexWrap: "nowrap",
    },
}));

export const SnackbarProvider: FC = ({children}) => {
    const classes = useStyles();

    return <NotiStackProvider dense classes={classes} children={children} hideIconVariant/>;
};
