import {BehaviorSubject, Observable} from "rxjs";
import {config} from "../config/authentication";
import {GuestUser, MemberUser, User} from "../domain/authentication/User";
import {noop} from "../helper";
import {Http} from "./http";
import {Recaptcha} from "./Recaptcha";

export class AuthenticationService {

    private readonly subject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    public get user(): Observable<User> {
        return this.subject.asObservable();
    }

    public getUser(): User {
        return this.subject.getValue();
    }

    constructor(private http: Http) {
        http.interceptor.request.use(context => {
            Object.assign(context.requestInit, {
                mode: "cors",
                cache: "no-store",
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    ...context.requestInit.headers,
                    "X-Requested-With": "fetch"
                }
            });

            return Promise.resolve(context);
        });
    }

    public fetchUser(): Promise<User | null> {
        return this.http.get(config.fetchUser)
            .then(auth => {
                if (auth?.tokenID === "guest") {
                    return new GuestUser(auth);
                } else if (auth && auth.tokenID && auth.tokenID.indexOf("member") === 0) {
                    return new MemberUser(auth);
                }

                return null;
            }, () => null)
            .then((user: User) => {
                this.subject.next(user);

                return user;
            });
    }

    public signUp(userid: string, email: string, password: string, passwordAgain: string): Promise<boolean> {
        return Recaptcha.getToken()
            .then(token => {
                const body = new FormData();

                body.set("ajax", "1");
                body.set("action", "join");
                body.set("token", token);
                body.set("userid", userid);
                body.set("email", email);
                body.set("passwd", password);
                body.set("passwd2", passwordAgain);

                return {body};
            })
            .then(requestInit => this.http.post(config.signUp, requestInit))
            .then((response: any) => {
                if (response?.error === false) {
                    return Promise.resolve(true);
                } else {
                    return Promise.reject(response.error); // TODO
                }
            });
    }

    public signOut(): Promise<User> {
        return this.http.get(config.signOut)
            .catch(noop)
            .then(() => this.fetchUser());
    }

    public signIn(username: string, password): Promise<User> {
        return Recaptcha.getToken()
            .then(token => {
                const body = new FormData();

                body.set("ajax", "1");
                body.set("action", "login");
                body.set("token", token);
                body.set("userid", username);
                body.set("passwd", password);

                return {body};
            })
            .then(requestInit => this.http.post(config.signIn, requestInit))
            .then((response: any) => {
                if (response.error) {
                    return Promise.reject(response.error);
                } else {
                    return this.fetchUser();
                }
            });
    }

    public confirmEmail(hash: string): Promise<string> {
        return this.http.post(config.validateHash(hash))
            .then(({success, message}) => {
                if (success) {
                    return this.fetchUser().catch(noop).then(() => message);
                } else {
                    return Promise.reject(message);
                }
            });
    }

    public sendPasswordReminder(email: string): Promise<void> {
        const body = new FormData();

        body.set("ajax", "1");
        body.set("action", "reminder");
        body.set("email", email);

        return this.http.post(config.reminder, {body})
            .then(response => {
                if (response.error === false) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response.error);
                }
            });
    }
}
