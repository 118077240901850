import {TopUpProvider} from "../../domain/topup/TopUpProvider";
import {Credit} from "../../domain/topup/Credit";

export class PremiumRateTextProvider implements TopUpProvider {

    constructor(private credits: Array<Credit>, private numbers, private code: string) {
    }

    getCreditOptions(): Array<Credit> {
        return this.credits;
    }

    getPhoneAndCode(credit: number): Promise<{ code: string, phone: string }> {
        return Promise.resolve({
            code: this.code,
            phone: this.numbers[credit].phone
        });
    }
}
