import {BehaviorSubject, Observable, Subject} from "rxjs";
import {filter, throttleTime} from "rxjs/operators";
import {config} from "../config/chat";
import {RoomStatusChanged} from "../domain/events";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";
import {noop} from "../helper";
import {Http} from "./http";
import {SxRoomService} from "./SxRoomService";

interface RoomListItem {
    onlineparams: RoomDto
    special_flag: string
}

export class SxRoomListService {

    private subject: Subject<Array<RoomDto>> = new BehaviorSubject([]);

    public get rooms(): Observable<Array<RoomDto>> {
        return this.subject.asObservable();
    }

    private readonly allowedRoomModes: Array<RoomMode> = [
        RoomMode.Free, RoomMode.Group, RoomMode.Private, RoomMode.Exclusive
    ];

    public constructor(
        private readonly http: Http,
        private readonly sxRoomService: SxRoomService
    ) {
        sxRoomService.events.pipe(
            filter(event => event instanceof RoomStatusChanged),
            throttleTime(1000)
        ).subscribe(() => this.update());

        this.update().catch(noop);
    }

    public getRoomList(): Promise<Array<RoomDto>> {
        return this.http.fetch(config.roomList)
            .then((rooms: Array<RoomListItem>) => rooms.filter(room => room.onlineparams))
            .then((rooms: Array<RoomListItem>) => rooms.map(room => ({
                ...room.onlineparams,
                pom: room.special_flag === "pom"
            })))
            .then((rooms: Array<RoomDto>) => rooms.filter(room => this.allowedRoomModes.includes(room.roomMode)))
            .then((rooms: Array<RoomDto>) => {
                this.subject.next(rooms);

                return rooms;
            });
    }

    public update(): Promise<void> {
        return this.getRoomList().then(noop).catch(noop);
    }
}
