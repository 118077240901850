import {config} from "../../config/securionpay";
import {PromoType} from "../../domain/PromoType";
import {Card} from "../../domain/topup/Card";
import {CardTopUpCommand} from "../../domain/topup/CardTopUpCommand";
import {Credit} from "../../domain/topup/Credit";
import {SecurionpayToken} from "../../domain/topup/SecurionpayToken";
import {SecurionpayTokenError} from "../../domain/topup/SecurionpayTokenError";
import {TopUpProvider} from "../../domain/topup/TopUpProvider";
import {Http} from "../http";

function noop() {
}

function unexpectedError() {
    return {
        success: false,
        message: "Technical Error"
    };
}

declare class Securionpay {
    static setPublicKey(publicKey: string);

    static createCardToken(form: HTMLFormElement, callback: (response) => void);

    static verifyThreeDSecure(data: any, callback: (response) => void);
}

export class SecurionTopUpProvider implements TopUpProvider {
    private keys = ["cardholderName", "number", "cvc", "expMonth", "expYear"];

    constructor(private http: Http, private publicKey: string, private credits: Array<Credit>) {
        Securionpay.setPublicKey(publicKey);
    }

    private cardToForm(card: Card): HTMLFormElement {
        const form: HTMLFormElement = document.createElement("form");

        return this.keys.reduce((form: HTMLFormElement, key: string) => {
            const input = document.createElement("input");

            input.setAttribute("data-securionpay", key);
            input.value = card[key];

            form.appendChild(input);

            return form;
        }, form);
    }

    public execute(command: CardTopUpCommand): Promise<any> {
        return this.createToken(command.card)
            .then((token: SecurionpayToken) => this.verify(command, token))
            .then(
                (token: SecurionpayToken) => this.charge(command, token),
                (error: SecurionpayTokenError) => this.reportTokenError(command, error)
                    .then(noop, noop)
                    .then(() => Promise.reject(error))
            );
    }

    private verify(command: CardTopUpCommand, token: SecurionpayToken) {
        return new Promise((resolve, reject) => {
            Securionpay.verifyThreeDSecure({
                amount: command.credit.price * 100, // https://securionpay.com/docs/api#checkout-request
                currency: command.credit.currency,
                card: token.id
            }, token => token.error ? reject(token.error) : resolve(token));
        });
    }

    private createToken(card: Card): Promise<SecurionpayToken> {
        return new Promise<SecurionpayToken>((resolve, reject) => {
            const form = this.cardToForm(card);

            Securionpay.createCardToken(
                form,
                response => response.error ? reject(response.error) : resolve(response)
            );
        });
    }

    private charge(command: CardTopUpCommand, token: SecurionpayToken): Promise<void> {
        const body = [
            `token=${encodeURIComponent(token.id)}`,
            `first6=${token.first6}`,
            `last4=${token.last4}`,
            `name=${encodeURIComponent(token.cardholderName)}`,
            `expMonth=${token.expMonth}`,
            `expYear=${token.expYear}`,
            `amount=${command.credit.amount}`,
            `price=${command.credit.price}`,
            `currency=${command.credit.currency}`,
            `email=${encodeURIComponent(command.email)}`,
            `store=${Number(command.enableQuickbuy)}`,
            `autopay=${Number(command.enableAutopay)}`
        ];

        if (command.credit.promotionType === PromoType.HappyHour) {
            body.push(
                "promoType=extraCredit",
                "promoName=echh",
                `promoAmount=${command.credit.extraCredit}`
            );
        }
        if (command.credit.promotionType === PromoType.PushPromo) {
            body.push(
                `ecp=${command.credit.extraCredit}`
            );
        }

        const requestInit: RequestInit = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            method: "POST",
            body: body.join("&")
        };

        return this.http.fetch(config.pay, requestInit)
            .catch(unexpectedError)
            .then(response => {
                if (response.success) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response);
                }
            });
    }

    private reportTokenError(command: CardTopUpCommand, error: SecurionpayTokenError): Promise<void> {
        const requestInit: RequestInit = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            method: "POST",
            body: `response=${encodeURIComponent(JSON.stringify(error))}`
                + `&amount=${command.credit.amount}`
                + `&price=${command.credit.price}`
                + `&currency=${command.credit.currency}`
        };

        return this.http.fetch(config.payError, requestInit)
            .catch(unexpectedError)
            .then(response => {
                if (response.success) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response);
                }
            });
    }

    getCreditOptions(): Array<Credit> {
        return this.credits;
    }
}
