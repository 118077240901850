import {ScreenActionType} from "../action/screen.action";
import {Orientation} from "../domain/Orientation";

export interface Screen {
    orientation: Orientation
    width: number
    height: number
}

export function screenReducer(state: Screen = null, action): Screen {
    if (action.type === ScreenActionType.OrientationChanged) {
        state = action.screen;
    }

    return state;
}
