import {Http} from "./http";
import {Locale, Message} from "../domain/Locale";
import {LocalStorage} from "./LocalStorage";
import intl from "react-intl-universal";

require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/hu.js");

export class I18nService {
    private locale: Locale;
    private static storageKey: string = "i18n.code";
    private static defaultCode: string = "hu";
    private static headerKey: string = "X-Accept-Language";

    constructor(private http: Http, private storage: LocalStorage) {
    }

    initialize(): Promise<Locale> {
        const locale: Locale = {code: this.storage.getItem(I18nService.storageKey, I18nService.defaultCode)};

        return this.setLocale(locale);
    }

    getLocale(): Promise<Locale> {
        return Promise.reject(this.locale);
    }

    setLocale(locale: Locale): Promise<Locale> {
        return this.fetchLocale(locale)
            .then(messages => intl.init({
                currentLocale: locale.code,
                locales: {
                    [locale.code]: messages
                }
            }))
            .then(() => this.locale = locale)
            .then(() => this.storage.setItem(I18nService.storageKey, locale.code))
            .then(() => this.http.setDefaultHeaders({[I18nService.headerKey]: locale.code}))
            .then(() => locale);
    }

    private fetchLocale(locale: Locale): Promise<Message> {
        const file = "/i18n/locale/".concat(locale.code).concat(".json");

        return this.http.fetch(file);
    }
}
