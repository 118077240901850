import React, {useContext} from "react";
import {Context} from "./Context";

export const ApplicationContext = React.createContext<Context>({} as Context);

export default ApplicationContext;

export const useApplicationContext = (): Context => {
    const context = useContext(ApplicationContext);

    if (context) {
        return context;
    }

    throw new Error("useApplicationContext must be used within a ApplicationContextProvider");
};
