import {IconButton, InputBase, Paper, WithStyles, withStyles} from "@material-ui/core";
import React, {PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {Observable, Subscription} from "rxjs";
import {sendMessage} from "../../action/chat.action";
import {Feature} from "../../domain/feature/Feature";
import {RoomId} from "../../domain/RoomId";
import {featureAttribute, featureProp} from "../../middleware/helper";
import {Disableable} from "../contract/Disableable";

const styles = {
    root: {
        backgroundColor: "white",
        height: "48px",
        minHeight: "48px"
    },
    form: {
        display: "flex"
    },
    input: {
        backgroundColor: "white",
        color: "#000",
        border: 0,
        marginLeft: 8,
        flex: 1
    },
    iconButton: {
        padding: 10,
        paddingLeft: 0
    },
    label: {
        borderRadius: "50%",
        backgroundColor: "#21ACE5",
        padding: 6
    }
};

interface Props extends WithStyles<typeof styles>, Disableable {
    children?: ReactNode
    roomId: RoomId
    onMessage: (roomId: RoomId, message: string) => void
    onFocus: () => void
    observable: Observable<string>
}

const mapStateToProps = ({feature}) => ({
    disabled: feature.chat === false
});

const mapDispatchToProps = dispatch => ({
    onMessage: (roomId: RoomId, message: string) => dispatch(sendMessage(roomId, message))
});

export const ChatInput = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(class extends PureComponent<Props> {
    private inputRef: HTMLInputElement;

    private emojiSubscription: Subscription;

    constructor(props) {
        super(props);

        this.setInputRef = this.setInputRef.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(): void {
        this.emojiSubscription = this.props.observable.subscribe(emoji => this.insertAtCursor(emoji));
    }

    componentWillUnmount(): void {
        this.emojiSubscription?.unsubscribe();
    }

    private insertAtCursor(text: string) {
        const inputRef = this.inputRef;
        const cursorPosition = inputRef.selectionStart;
        const value = inputRef.value;

        const textBefore = value.substring(0, cursorPosition);
        const textAfter = value.substring(cursorPosition, value.length);

        inputRef.value = textBefore.concat(text).concat(textAfter);
    }

    private setInputRef(ref) {
        this.inputRef = ref;
    }

    private handleSubmit(event) {
        event && event.preventDefault();
        this.sendMessage();
    }

    private sendMessage() {
        const {inputRef, props} = this;
        const {onMessage, roomId}: Props = props;
        const message = inputRef.value.trim();

        inputRef.value = "";
        setTimeout(inputRef.focus.bind(inputRef), 0);

        if (message.length > 0) {
            onMessage(roomId, message);
        }
    }

    render(): ReactNode {
        const {classes, onFocus, disabled = false, children} = this.props;

        return (
            <Paper className={classes.root}>
                <form onSubmit={this.handleSubmit} className={classes.form}>
                    <InputBase disabled={disabled}
                               inputProps={featureProp(Feature.Chat)}
                               onFocus={onFocus}
                               className={classes.input}
                               placeholder={intl.get("chat.input.placeholder")}
                               inputRef={this.setInputRef}/>

                    {children}

                    <IconButton color="primary"
                                disabled={disabled}
                                buttonRef={featureAttribute(Feature.Chat)}
                                onClick={this.handleSubmit}
                                classes={{root: classes.iconButton, label: classes.label}}>
                        <img src="/images/paperplane.svg" alt="submit" width={12} height={12}/>
                    </IconButton>
                </form>
            </Paper>
        );
    }
}));
