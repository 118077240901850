import React from "react";
import Fab from "@material-ui/core/Fab";
import {connect} from "react-redux";
import {redirectToTopUp} from "../../redirect";
import {Disableable} from "../contract/Disableable";
import {featureAttribute} from "../../middleware/helper";
import {Feature} from "../../domain/feature/Feature";

interface Props extends Disableable {
    className?: string
    classes?: {
        root?: string
    }
    isHappyHour?: boolean
}

const mapStateToProps = ({topup, feature}) => ({
    disabled: feature.topUp === false,
    isHappyHour: topup.isHappyHour
});

export const TopUpButton = connect(mapStateToProps)(
    React.memo<Props>(({isHappyHour, classes, className, disabled = false}) => (
        <Fab color="secondary"
             className={className}
             classes={classes}
             disabled={disabled}
             buttonRef={featureAttribute(Feature.TopUp)}
             onClick={redirectToTopUp}>
            {
                isHappyHour
                    ? <img src="/images/topup-happy-hour.svg" alt="topup happy hour"/>
                    : <img src="/images/topup.svg" alt="topup"/>
            }
        </Fab>
    )));
