import React from "react";
import {TopUpType} from "../../domain/topup/TopUpType";
import IconButton from "@material-ui/core/IconButton";
import {createStyles, withStyles, WithStyles} from "@material-ui/core";
import {Closable} from "../contract/Closable";
import {Selector} from "../contract/Selector";

interface Props extends WithStyles<typeof styles>, Closable, Selector<TopUpType> {
    availableTypes: Array<TopUpType>
}

const allowedTypes = [TopUpType.QUICKBUY, TopUpType.CARD, TopUpType.CALL, TopUpType.TEXT];
const styles = createStyles({
    root: {
        margin: "32px auto"
    },
    button: {
        padding: "4px",
        margin: "0 8px",
        color: "#fff",
        width: "33px",
        height: "33px",
        border: "1px solid #a2a2a2",
        backgroundColor: "#000",
        borderRadius: "50%",
        "& img": {
            width: "15px",
            height: "15px"
        },
        "&.active": {
            width: "45px",
            height: "45px",
            backgroundColor: "#21ACE5",
            border: "3px solid #fff",
            "& img": {
                width: "26px",
                height: "26px"
            }
        }
    }
});

export const TopUpTypeSelector = withStyles(styles)(React.memo<Props>(({onClose, availableTypes, selection, onSelect, classes}) => (
    <div className={classes.root}>
        {
            allowedTypes
                .filter(type => availableTypes.includes(type))
                .map((type: TopUpType) => (
                    <IconButton
                        key={type}
                        onClick={() => onSelect(type)}
                        classes={{root: classes.button}}
                        className={selection === type ? "active" : ""}>
                        <img src={`/images/topup/${type}.svg`} alt={"topup ".concat(type)}/>
                    </IconButton>
                ))
        }

        <IconButton onClick={onClose} className={classes.button}>
            <img src="/images/close.svg" alt="close"/>
        </IconButton>
    </div>
)));
