import * as Sentry from "@sentry/react";
import {AuthActionType} from "../action/auth.action";
import {CameraActionType} from "../action/camera.action";
import {User} from "../domain/authentication/User";

export const sentryMiddleware = () => {
    const map = new Map();

    map.set(CameraActionType.TurnOnFailed, action => action.reason);
    map.set(CameraActionType.ReconfigureFailed, action => action.reason);

    return () => next => action => {
        const {type} = action;

        if (map.has(type)) {
            const transform = map.get(type);
            const output = transform(action);

            if (typeof output === "string") {
                Sentry.captureMessage(output);
            }

            if (output instanceof Error) {
                Sentry.captureException(output);
            }
        }

        switch (type) {
            case AuthActionType.UserSet: {
                const {user} = action;
                let sentryUser = null;

                if (user) {
                    const {isMember, auth: {memberID, userName}} = user as User;

                    sentryUser = {
                        id: isMember ? memberID : "guest",
                        username: isMember ? userName : "guest",
                    };
                }

                Sentry.setUser(sentryUser);

                break;
            }

            default:
        }

        return next(action);
    };
};
