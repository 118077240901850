import React from "react";
import NumberFormat from "react-number-format";

interface Props {
    inputRef: React.Ref<HTMLInputElement>
    onChange: any
}

export const CreditCardFormat = React.memo<Props>(({inputRef, onChange, ...other}) => {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            pattern="^(?:[\d]{4}(?: )){3}[\d]{4}$"
            inputMode="numeric"
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            format="#### #### #### ####"
        />
    );
});
