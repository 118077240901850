import {Scene} from "./Scene";

export const BaseScene: Scene = {
    modeBar: true,
    input: true,
    messages: true,
    emoji: false,
    gift: false,
    btnSignIn: false,
    btnZzz: false,
    zzz: false
};
