import {Box, Typography, withStyles, WithStyles} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {RoomMode} from "../../../domain/RoomMode";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {Viewport} from "../../layout/Viewport";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ExitToRoomListButton} from "../ExitToRoomListButton";
import {ModeButton} from "../ModeButton";
import {styles} from "./styles";

interface Props extends WithStyles<typeof styles>, LobbyHolder {
    action: () => void
}

export const VipLobby = withStyles(styles)(React.memo<Props>(({classes, lobby, action}) => (
    <Viewport>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircleProfilePicture src={lobby?.publicData?.profilePic}/>
            <Typography className={classes.message}>{intl.getHTML("lobby.group.title", {
                screenName: lobby.screenName
            })}</Typography>
        </Box>
        <ModeButton mode={RoomMode.Group} price={lobby.pricing.group} onClick={action}/>
        <ExitToRoomListButton/>
    </Viewport>
)));
