import {connectRouter, RouterState} from "connected-react-router";
import {ActivityState} from "../domain/ActivityState";
import {Locale} from "../domain/Locale";
import {history} from "../service";
import {applicationReducer, ApplicationState} from "./application.reducer";
import {authReducer, AuthState} from "./auth.reducer";
import {cameraReducer, CameraState} from "./camera.reducer";
import {chatReducer, ChatState} from "./chat.reducer";
import {consentReducer, ConsentState} from "./consent.reducer";
import {creditReducer} from "./credit.reducer";
import {favoriteReducer, FavoriteState} from "./favorite.reducer";
import {featureReducer, FeatureState} from "./feature.reducer";
import {giftReducer, ZzzState} from "./gift.reducer";
import {i18nReducer} from "./i18n.reducer";
import {idleReducer} from "./idle.reducer";
import {incentiveReducer, IncentiveState} from "./incentive.reducer";
import {invitationReducer, InvitationState} from "./invitation.reducer";
import {roomListReducer, RoomListState} from "./roomlist.reducer";
import {Screen, screenReducer} from "./screen.reducer";
import {snackbarReducer, SnackbarState} from "./snackbar.reducer";
import {topupReducer, TopUpState} from "./topup.reducer";
import {updateReducer, UpdateState} from "./update.reducer";

export const rootReducer = (state: RootState = {} as RootState, action) => ({
    app: applicationReducer(state.app, action),
    screen: screenReducer(state.screen, action),
    auth: authReducer(state.auth, action),
    credit: creditReducer(state.credit, action),
    chat: chatReducer(state.chat, action, state),
    invitation: invitationReducer(state.invitation, action),
    router: connectRouter(history)(state.router, action),
    i18n: i18nReducer(state.i18n, action),
    consent: consentReducer(state.consent, action),
    idle: idleReducer(state.idle, action),
    topup: topupReducer(state.topup, action),
    gift: giftReducer(state.gift, action),
    feature: featureReducer(state.feature, action),
    rooms: roomListReducer(state.rooms, action),
    camera: cameraReducer(state.camera, action),
    incentive: incentiveReducer(state.incentive, action),
    snackbar: snackbarReducer(state.snackbar, action),
    update: updateReducer(state.update, action),
    favorite: favoriteReducer(state.favorite, action)
});

export interface RootState {
    app: ApplicationState
    screen: Screen
    auth: AuthState
    credit: number
    chat: ChatState
    invitation: InvitationState
    router: RouterState
    i18n: Locale
    consent: ConsentState
    idle: ActivityState
    topup: TopUpState
    gift: ZzzState
    feature: FeatureState
    rooms: RoomListState
    camera: CameraState
    incentive: IncentiveState,
    snackbar: SnackbarState,
    update: UpdateState,
    favorite: FavoriteState
}
