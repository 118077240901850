import {Store} from "redux";
import {ChatActionType, checkKnockout, offerKnockout} from "../action/chat.action";
import {Logger} from "../logger/Logger";
import {KnockoutService} from "../service/knockout.service";

export const knockoutMiddleware = (knockoutService: KnockoutService, logger: Logger) => (store: Store) => {
    let timeout;

    const getKnockout = function () {
        knockoutService.getKnockoutOffer().then(promo => {
            if (promo) {
                const screenName = store.getState()?.chat?.room?.screenName;

                if (screenName) {
                    store.dispatch(offerKnockout(screenName));
                }
            }
        }).catch(error => logger.error(error));
    };

    return next => action => {
        const {type} = action;

        // NOTE the feature middleware takes care of checking the conditions
        switch (type) {
            case ChatActionType.CheckKnockout: {
                getKnockout();

                break;
            }

            case ChatActionType.OfferKnockout: {
                knockoutService.setKnockoutOffered().catch(error => logger.error(error));

                break;
            }

            case ChatActionType.JoinRoomSucceeded: {
                clearTimeout(timeout);
                timeout = setTimeout(
                    () => store.dispatch(checkKnockout()),
                    (Math.floor(Math.random() * 61) + 30) * 1000
                );

                break;
            }

            case ChatActionType.LeaveRoom:
            case ChatActionType.RemovedFromRoom: {
                clearTimeout(timeout);

                break;
            }
        }

        next(action);
    };
};
