import {Player, PlayerState} from "../../domain/Player";
import {StreamData} from "../../domain/StreamData";
import {LazyValue} from "../../service/LazyValue";
import {BasePlayer} from "../BasePlayer";
import {WowzaViewer} from "./WowzaViewer";

export class WebRTCPlayer extends BasePlayer implements Player {
    private static cache: LazyValue<boolean> = new LazyValue(() => {
        return "RTCSessionDescription" in window
            && "RTCPeerConnection" in window
            && "RTCIceCandidate" in window
            && "RTCRtpReceiver" in window
            && "WebSocket" in window
            && typeof RTCRtpReceiver.getCapabilities === "function"
            && RTCRtpReceiver.getCapabilities("video").codecs
                .some(capability => capability.mimeType.toLowerCase() === "video/h264");
    });

    private viewer: WowzaViewer = new WowzaViewer();

    public static isSupported(): boolean {
        return this.cache.getValue();
    }

    public stop(): void {
        super.stop();
        this.viewer.stop();
    }

    public destroy(): void {
        super.destroy();
        this.viewer = null;
    }

    public play(data: StreamData, muted: boolean): Promise<void> {
        this.stop();
        this.storeStream(data);

        return this.viewer.start(
            data.webrtc.sdpURL,
            data.webrtc.streamInfo.applicationName,
            data.webrtc.streamInfo.streamName
            )
            .then(stream => this.video.srcObject = stream)
            .then(() => super.play(data, muted))
            .catch(error => {
                this.stop();
                this.playerStateSubject.next(PlayerState.Error);

                return Promise.reject(error);
            });
    }

    public isPlaying(stream: StreamData): boolean {
        return this.stream && stream
            && this.stream?.webrtc?.sdpURL === stream?.webrtc?.sdpURL
            && this.stream?.webrtc?.streamInfo?.streamName === stream?.webrtc?.streamInfo?.streamName;
    }
}
