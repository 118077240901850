import React, {PureComponent, ReactNode} from "react";
import "rmc-picker/assets/index.css";
import {Credit} from "../../domain/topup/Credit";
import intl from "react-intl-universal";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {createStyles, withStyles, WithStyles} from "@material-ui/core";

const styles = createStyles({
    root: {
        width: "150px",
        height: "150px",
        margin: "0 auto",
        border: "3px solid white",
        borderRadius: "50%",
        backgroundColor: "#21ACE5",
        display: "flex",
        flexDirection: "column"
    },
    label: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center"
    },
    title: {
        color: "#fff",
        fontSize: "22px",
        textTransform: "uppercase"
    },
    subTitle: {
        color: "#fff",
        fontSize: "18px"
    }
});

interface Props extends WithStyles<typeof styles> {
    inProgress?: boolean
    disabled?: boolean
    credit: Credit
    onClick: () => void
}

class BuyButton extends PureComponent<Props> {
    render(): ReactNode {
        const {inProgress = false, disabled = false, classes, credit, onClick} = this.props;

        return (
            <Button classes={{
                root: classes.root,
                label: classes.label
            }} disabled={disabled || inProgress} className={classes.root} onClick={onClick}>
                <Typography className={classes.title}>{intl.get("topup.buy.button")}</Typography>
                <Typography className={classes.subTitle}>{intl.getHTML("topup.price." + credit.currency.toLowerCase(), credit)}</Typography>
            </Button>
        );
    }
}

const component = withStyles(styles)(BuyButton);

export {
    component as BuyButton
};
