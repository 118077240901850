import {Store} from "redux";
import {
    CameraActionType,
    reconfigureCameraFailed,
    reconfigureCameraSucceeded,
    turnOnFailed,
    turnOnSucceeded
} from "../action/camera.action";
import {ChatActionType} from "../action/chat.action";
import {CameraService} from "../service/CameraService";

export const cameraMiddleware = (cameraService: CameraService) => (store: Store) => {
    const {dispatch} = store;

    return next => action => {
        const {type, setup} = action;
        const {chat} = store.getState();
        const {room} = chat;

        switch (type) {
            case CameraActionType.Reconfigure: {
                cameraService.reconfigure(setup)
                    .then(reconfigureCameraSucceeded, reconfigureCameraFailed)
                    .then(dispatch);

                break;
            }

            case CameraActionType.TurnOn:
                if (room) {
                    cameraService.start(room.roomid)
                        .then(turnOnSucceeded, turnOnFailed)
                        .then(dispatch);
                }

                break;

            case ChatActionType.RemovedFromRoom:
            case ChatActionType.LeaveRoom:
            case CameraActionType.TurnOff: {
                cameraService.stop();

                break;
            }

            case ChatActionType.JoinRoomSucceeded:
            case ChatActionType.RequestChatModeSucceeded:
            case CameraActionType.Mute: {
                cameraService.mute();

                break;
            }

            case CameraActionType.Unmute: {
                cameraService.unmute();

                break;
            }
        }

        next(action);
    };
};
