import {config} from "../config/gift";
import {Gift} from "../domain/Gift";
import {GiftCategory} from "../domain/GiftCategory";
import {Http} from "./http";

const filterFn = (category: GiftCategory) => {
    if (category.visible === false) {
        return false;
    }

    if (category.hasOwnProperty("params") === false) {
        return true;
    }

    if (category.params.hasOwnProperty("special") === false) {
        return true;
    }

    return category.params.special === "ohMiBod";
};

export class GiftService {
    private categories: Array<GiftCategory> = [];
    private giftMap = {};
    private root: string;

    constructor(private http: Http) {
    }

    public update() {
        this.fetchGifts().then(categories => this.categories = categories);
    }

    public getById(giftId): Gift | null {
        return this.giftMap[giftId] || null;
    }

    public getGifts(withSmartToys: boolean): Promise<Array<GiftCategory>> {
        return Promise.resolve(this.categories.filter(category => category.id === "ohmibod" ? withSmartToys : true));
    }

    private fetchGifts(): Promise<Array<GiftCategory>> {
        return this.http.fetch(config.api)
            .then(data => this.processData(data));
    }

    private prepareGift(gift: Gift): Gift {
        const root = this.root;

        return {
            id: gift.id,
            name: gift.name,
            svg: gift.svg ? root.concat(gift.svg) : null,
            png: gift.png ? root.concat(gift.png) : null,
            price: gift.price | 0
        };
    }

    private prepareCategoryItem(item): Gift {
        const gift: Gift = this.prepareGift(item);

        this.giftMap[gift.id] = gift;

        return gift;
    }

    private processData(data): Array<GiftCategory> {
        const root = this.root = data.root;

        return data.categories.map(category => ({
                ...category,
                svg: root.concat("gift_category_").concat(category.id).concat(".svg"),
                items: category.items.map(this.prepareCategoryItem, this)
            })
        ).filter(filterFn);
    }
}
