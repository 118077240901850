import {GiftNotificationDto} from "../dto/GiftNotificationDto";

export class GiftNotification {
    constructor(private data: GiftNotificationDto) {
    }

    get giftId() {
        return this.data.giftid;
    }

    get username() {
        return this.data.username;
    }

    get roomId() {
        return this.data.roomid;
    }

    get price() {
        return this.data.price;
    }
}
