import {push} from "connected-react-router";
import {Store} from "redux";
import {filter, map} from "rxjs/operators";
import {ApplicationActionType} from "../action/application.action";
import {
    ChatActionType,
    joinRoom,
    joinRoomFailed,
    joinRoomSucceeded,
    leaveRoomFailed,
    leaveRoomSucceeded,
    requestChatModeFailed,
    requestChatModeSucceeded,
    sendGiftFailed,
    sendGiftSucceeded,
    sendMessageFailed,
    sendStickerFailed,
    sendTipFailed,
    sendTipSucceeded,
    sendZzzFailed,
    sendZzzSucceeded,
    tipBeforeExit
} from "../action/chat.action";
import {I18nActionType} from "../action/i18n.action";
import {config} from "../config/chat";
import {User} from "../domain/authentication/User";
import {ChatMessageEvent} from "../domain/ChatMessageEvent";
import {CreditChanged} from "../domain/CreditChanged";
import {
    JoinRoomFailed,
    PromotedToPrivateOwner,
    RequestChatModeFailed,
    RoomModeSetupChanged,
    RoomStatusChanged,
    SendGiftFailed
} from "../domain/events";
import {FeatureDisabledReason} from "../domain/feature/FeatureDisabledReason";
import {GiftFailedReason} from "../domain/GiftFailedReason";
import {GiftNotification} from "../domain/GiftNotification";
import {Invitation} from "../domain/Invitation";
import {JoinRoomFailedReason} from "../domain/JoinRoomFailedReason";
import {LobbyType} from "../domain/LobbyType";
import {PromotedToPrivateOwnerEvent} from "../domain/PromotedToPrivateOwnerEvent";
import {RemovedFromRoom} from "../domain/RemovedFromRoom";
import {RemovedFromRoomReason} from "../domain/RemovedFromRoomReason";
import {RoomDto} from "../domain/RoomDto";
import {RoomMode} from "../domain/RoomMode";
import {RoomPaused} from "../domain/RoomPaused";
import {RoomResumed} from "../domain/RoomResumed";
import {SendMessageFailedReason} from "../domain/SendMessageFailedReason";
import {noop, routeToRoom, routeToRoomList} from "../helper";
import {AuthenticationService} from "../service/AuthenticationService";
import {GiftService} from "../service/gift.service";
import {RoomService} from "../service/RoomService";
import {isSxRoom, mustBeMember, mustNotBeVoyeur} from "./helper";

const lobbyTypesToIgnoreOnChange = [
    LobbyType.PausedInRoom,
    LobbyType.NotEnoughCredits,
    LobbyType.OutOfCredit,
    LobbyType.KickedOfferNext,
    LobbyType.KickedOfferTip,
    LobbyType.TerminatedOfferNext,
    LobbyType.TerminatedOfferTip
];

export const chatMiddleware = (roomService: RoomService, giftService: GiftService, api: AuthenticationService) => (store: Store) => {
    const events = [
        {
            event: GiftNotification,
            action: ChatActionType.GiftReceived
        },
        {
            event: ChatMessageEvent,
            action: ChatActionType.MessageReceived
        },
        {
            event: CreditChanged,
            action: ChatActionType.CreditChanged
        },
        {
            event: RoomModeSetupChanged,
            action: ChatActionType.RoomModeSetupChanged
        },
        {
            event: RoomStatusChanged,
            action: ChatActionType.RoomStatusChanged
        },
        {
            event: PromotedToPrivateOwner,
            action: ChatActionType.PromotedToPrivateOwner
        },
        {
            event: RemovedFromRoom,
            action: ChatActionType.RemovedFromRoom
        },
        {
            event: RoomPaused,
            action: ChatActionType.RoomPaused
        },
        {
            event: RoomResumed,
            action: ChatActionType.RoomResumed
        },
        {
            event: Invitation,
            action: ChatActionType.InvitationReceived
        }
    ];

    roomService.events.pipe(
        map(event => {
            const match = events.find(item => event instanceof item.event);

            if (match) {
                return {
                    type: match.action,
                    event
                };
            }

            return null;
        }),
        filter(action => Boolean(action))
    ).subscribe(action => store.dispatch(action));

    let onBecameVisible = null;

    return next => action => {
        const result = next(action);
        const {type} = action;
        const {chat, auth} = store.getState();

        switch (type) {
            case ChatActionType.ExitShow: {
                const {roomId} = action;
                const {credit, chat} = store.getState();
                const {room, lobby, showStartedAt} = chat;
                let roomToExitFrom: RoomDto;

                if (room) {
                    roomToExitFrom = room;
                } else if (lobby && lobby.type === LobbyType.PausedInRoom) {
                    roomToExitFrom = lobby;
                }

                const isTipRequired: boolean = roomToExitFrom
                    && isSxRoom(roomToExitFrom)
                    && roomToExitFrom.roomid === roomId
                    && config.tipAfterRoomModes.includes(roomToExitFrom.roomMode)
                    && credit >= config.minimumCreditBeforeTip
                    && showStartedAt
                    && Date.now() - showStartedAt >= config.minimumMsInRoomBeforeTip;

                if (isTipRequired) {

                    // TODO ideally use the LeaveRoom event
                    roomService.leave(roomToExitFrom.roomid)
                        .catch(noop)
                        .then(() => store.dispatch(tipBeforeExit(roomToExitFrom)));
                } else {
                    store.dispatch(push(routeToRoomList()));
                }

                break;
            }

            case I18nActionType.LocaleSet: {
                giftService.update();

                break;
            }

            case ApplicationActionType.BecameInvisible: {
                roomService.suspend();

                const {room, lobby} = store.getState().chat;
                const joined = room || lobby;

                if (joined) {
                    if (joined.roomMode === RoomMode.Free) {
                        onBecameVisible = () => {

                            // TODO instead of reload the page, resume the services and rejoin into the room
                            window.location.href = routeToRoom(joined.roomid);
                        };
                    } else {
                        onBecameVisible = () => {
                            window.location.href = routeToRoomList();
                        };
                    }
                }

                break;
            }

            case ApplicationActionType.BecameVisible: {
                if (action.isFirstTime === false) {
                    if (onBecameVisible) {
                        const fn = onBecameVisible;
                        onBecameVisible = null;
                        fn();
                    } else {
                        api.fetchUser().catch(noop);
                    }
                }

                break;
            }

            case ChatActionType.JoinRoom: {
                const {roomId, roomMode} = action;

                roomService.join(roomId, roomMode)
                    .then(event => store.dispatch(joinRoomSucceeded(event)))
                    .catch(event => store.dispatch(joinRoomFailed(event)));

                break;
            }

            case ChatActionType.LeaveRoom: {
                const {roomId} = action;

                roomService.leave(roomId)
                    .then(() => store.dispatch(leaveRoomSucceeded(roomId)))
                    .catch(() => store.dispatch(leaveRoomFailed(roomId)));

                break;
            }

            case ChatActionType.SendMessage: {
                const {roomId, message} = action;

                Promise.resolve()
                    .then(mustBeMember(auth.user))
                    .then(mustNotBeVoyeur(chat.room))
                    .then(() => roomService.sendMessage(roomId, message))
                    .catch(error => {
                        if (error === FeatureDisabledReason.UserIsNotMember) {
                            store.dispatch(sendMessageFailed(SendMessageFailedReason.GuestNotAllowed, roomId, message));
                        } else if (error === FeatureDisabledReason.ViewerIsVoyeur) {
                            store.dispatch(sendMessageFailed(SendMessageFailedReason.VoyeurNotAllowed, roomId, message));
                        } else {
                            store.dispatch(sendMessageFailed(SendMessageFailedReason.Other, roomId, message));
                        }
                    });

                break;
            }

            case ChatActionType.SendSticker: {
                const {roomId, stickerId} = action;

                Promise.resolve()
                    .then(mustBeMember(auth.user))
                    .then(mustNotBeVoyeur(chat.room))
                    .then(() => roomService.sendSticker(roomId, stickerId))
                    .catch(error => {
                        if (error === FeatureDisabledReason.UserIsNotMember) {
                            store.dispatch(sendStickerFailed(SendMessageFailedReason.GuestNotAllowed, roomId, stickerId));
                        } else if (error === FeatureDisabledReason.ViewerIsVoyeur) {
                            store.dispatch(sendStickerFailed(SendMessageFailedReason.VoyeurNotAllowed, roomId, stickerId));
                        } else {
                            store.dispatch(sendStickerFailed(SendMessageFailedReason.Other, roomId, stickerId));
                        }
                    });

                break;
            }

            case ChatActionType.SendZzz: {
                const {roomId, price} = action;

                Promise.resolve()
                    .then(mustBeMember(auth.user))
                    .then(mustNotBeVoyeur(chat.room))
                    .then(() => roomService.sendZzz(roomId, price))
                    .then(() => store.dispatch(sendZzzSucceeded(roomId, price)))
                    .catch(error => {
                        if (error === FeatureDisabledReason.UserIsNotMember) {
                            store.dispatch(sendZzzFailed(GiftFailedReason.GuestNotAllowed, roomId, price));
                        } else if (error === FeatureDisabledReason.ViewerIsVoyeur) {
                            store.dispatch(sendZzzFailed(GiftFailedReason.VoyeurNotAllowed, roomId, price));
                        } else if (error instanceof SendGiftFailed) {
                            store.dispatch(sendZzzFailed(error.reason, roomId, price));
                        } else {
                            store.dispatch(sendZzzFailed(GiftFailedReason.Other, roomId, price));
                        }
                    });

                break;
            }

            case ChatActionType.SendGift: {
                const {roomId, giftId, message, price, actionOnSuccess} = action;

                Promise.resolve()
                    .then(mustBeMember(auth.user))
                    .then(mustNotBeVoyeur(chat.room))
                    .then(() => roomService.sendGift(roomId, giftId, message, price))
                    .then(() => store.dispatch(sendGiftSucceeded(roomId, giftId, message, price, actionOnSuccess)))
                    .catch(error => {
                        if (error === FeatureDisabledReason.UserIsNotMember) {
                            store.dispatch(sendGiftFailed(GiftFailedReason.GuestNotAllowed, roomId, giftId, message, price));
                        } else if (error === FeatureDisabledReason.ViewerIsVoyeur) {
                            store.dispatch(sendGiftFailed(GiftFailedReason.VoyeurNotAllowed, roomId, giftId, message, price));
                        } else if (error instanceof SendGiftFailed) {
                            store.dispatch(sendGiftFailed(error.reason, roomId, giftId, message, price));
                        } else {
                            store.dispatch(sendGiftFailed(GiftFailedReason.Other, roomId, giftId, message, price));
                        }
                    });

                break;
            }

            case ChatActionType.SendTip: {
                const {roomId, message, price, actionOnSuccess} = action;

                Promise.resolve()
                    .then(mustBeMember(auth.user))
                    .then(() => roomService.sendTip(roomId, message, price))
                    .then(() => store.dispatch(sendTipSucceeded(roomId, message, price, actionOnSuccess)))
                    .catch(error => {
                        if (error === FeatureDisabledReason.UserIsNotMember) {
                            store.dispatch(sendTipFailed(GiftFailedReason.GuestNotAllowed, roomId, message, price));
                        } else if (error instanceof SendGiftFailed) {
                            store.dispatch(sendTipFailed(error.reason, roomId, message, price));
                        } else {
                            store.dispatch(sendTipFailed(GiftFailedReason.Other, roomId, message, price));
                        }
                    });

                break;
            }

            case ChatActionType.RequestChatMode: {
                const {roomId, roomMode} = action;

                Promise.resolve()
                    .then(mustBeMember(auth.user))
                    .then(() => roomService.requestChatMode(roomId, roomMode))
                    .then(event => store.dispatch(requestChatModeSucceeded(event.room)))
                    .catch(error => {
                        if (error === FeatureDisabledReason.UserIsNotMember) {
                            store.dispatch(requestChatModeFailed(JoinRoomFailedReason.GuestNotAllowed, roomId, roomMode));
                        } else if (error instanceof JoinRoomFailed || error instanceof RequestChatModeFailed) {
                            store.dispatch(requestChatModeFailed(error.reason, roomId, roomMode));
                        } else {
                            store.dispatch(requestChatModeFailed(JoinRoomFailedReason.Other, roomId, roomMode));
                        }
                    });

                break;
            }

            case ChatActionType.PromotedToPrivateOwner: {
                const {roomId} = action.event;
                const {auth, chat} = store.getState();
                const {user}: { user: User } = auth;
                const {room}: { room: RoomDto } = chat;

                if (user && room && room.roomid === roomId && room.roomMode === RoomMode.Private) {
                    store.dispatch({
                        type: ChatActionType.MessageReceived,
                        event: new PromotedToPrivateOwnerEvent(user, room)
                    });
                }

                break;
            }

            case ChatActionType.RoomStatusChanged: {
                const {lobby} = store.getState().chat;

                if (lobby && lobbyTypesToIgnoreOnChange.includes(lobby.type)) {
                    break;
                }

                if (lobby) {
                    const currentRoomId = lobby.roomid;
                    const changedRoom: RoomDto = action.event.room;
                    const changedRoomId = changedRoom.roomid;

                    if (currentRoomId === changedRoomId) {
                        store.dispatch(joinRoom(currentRoomId, RoomMode.Free));
                    }
                }

                break;
            }

            case ChatActionType.RemovedFromRoom: {
                const {reason, roomId} = action.event;

                if (reason === RemovedFromRoomReason.ModeChanged) {
                    store.dispatch(joinRoom(roomId, RoomMode.Free));
                }

                break;
            }

            default:
        }

        return result;
    };
};
