import {AbortError} from "../domain/AbortError";
import {Player, PLAYER_TIMEOUT, PlayerState} from "../domain/Player";
import {PlayerError} from "../domain/PlayerError";
import {StreamData} from "../domain/StreamData";
import {TimeoutError} from "../domain/TimeoutError";
import {seed} from "../helper";
import {LazyValue} from "../service/LazyValue";
import {BasePlayer} from "./BasePlayer";

export class HlsNativePlayer extends BasePlayer implements Player {

    private abort: AbortController = null;

    private static cache: LazyValue<boolean> = new LazyValue(() => {
        const video = document.createElement("video");
        const result = video.canPlayType("application/vnd.apple.mpegurl");

        return result === "maybe" || result === "probably";
    });

    public static isSupported(): boolean {
        return this.cache.getValue();
    }

    public stop(): void {
        super.stop();

        this.abort?.abort();
        this.abort = null;
    }

    public play(streamData: StreamData, muted: boolean): Promise<void> {
        this.stop();
        this.storeStream(streamData);

        const load = () => new Promise<void>((resolve, reject) => {
            const video = this.video;
            const abort = this.abort = new AbortController();
            const timer = setTimeout(() => {
                teardown();
                reject(new TimeoutError());
            }, PLAYER_TIMEOUT);

            const removeEventListeners = () => {
                abort.signal.removeEventListener("abort", aborted);
                video.removeEventListener("loadedmetadata", success);
                video.removeEventListener("error", failure);
            };

            const teardown = () => {
                clearTimeout(timer);
                removeEventListeners();
            };

            const success = () => {
                this.abort = null;
                teardown();
                resolve();
            };

            const failure = () => {
                this.abort = null;
                teardown();
                reject(new PlayerError());
            };

            const aborted = () => {
                teardown();
                reject(new AbortError());
            };

            abort.signal.addEventListener("abort", aborted);
            video.addEventListener("loadedmetadata", success);
            video.addEventListener("error", failure);
            video.src = seed(streamData.hls.address);
            video.load();
        });

        return load()
            .then(() => super.play(streamData, muted))
            .catch(() => {
                this.stop();
                this.playerStateSubject.next(PlayerState.Error);

                return Promise.reject(new PlayerError("native hls player failed to start"));
            });
    }

    public isPlaying(stream: StreamData): boolean {
        return this.stream && this.stream?.hls?.address === stream?.hls?.address;
    }
}
