import {IconButton, InputAdornment, makeStyles, TextField, TextFieldProps} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import React, {useCallback, useState} from "react";

const useStyles = makeStyles(() => ({
    icon: {
        color: "#787878",
        borderRadius: "50%"
    }
}));

export const PasswordField = React.memo<TextFieldProps>(props => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const handleClickShowPassword = useCallback(() => setVisible(value => !value), []);

    return <TextField
        {...props}
        type={visible ? "text" : "password"}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    className={classes.icon}
                    edge="end">
                    {visible ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
            </InputAdornment>
        }}/>;
});
