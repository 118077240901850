import {AuthActionType} from "../action/auth.action";
import {ChatActionType} from "../action/chat.action";
import {User} from "../domain/authentication/User";
import {Feature} from "../domain/feature/Feature";
import {Matrix} from "../domain/feature/Matrix";
import {RoomDto} from "../domain/RoomDto";
import {checker} from "../middleware/helper";

export type FeatureState = {
    [K in keyof typeof Matrix]: boolean
}

let currentUser: User;
let currentRoom: RoomDto;

const initialState: FeatureState = Object.keys(Matrix).reduce((state: FeatureState, feature: Feature) => {
    state[feature] = false;

    return state;
}, {} as FeatureState);

const cache = () => {
    const map: Map<number, boolean> = new Map<number, boolean>();

    return (key: number, provider: () => boolean): boolean => {
        if (map.has(key) === false) {
            map.set(key, provider());
        }

        return map.get(key);
    };
};

const calculateState = (user: User, room: RoomDto): FeatureState => {
    const retrieve = cache();
    const check = checker(user, room);

    return Object.entries<number>(Matrix).reduce((state: FeatureState, [feature, bits]) => {
        state[feature] = retrieve(bits, () => check(bits));

        return state;
    }, {} as FeatureState);
};

export function featureReducer(state: FeatureState = initialState, action): FeatureState {
    const {type} = action;
    let updateRequired = false;

    switch (type) {
        case AuthActionType.UserSet: {
            const {user} = action;

            currentUser = user;
            updateRequired = true;

            break;
        }

        case ChatActionType.JoinRoomSucceeded: {
            const {event: {room}} = action;

            currentRoom = room;
            updateRequired = true;

            break;
        }

        case ChatActionType.RequestChatModeSucceeded: {
            const {room} = action;

            currentRoom = room;
            updateRequired = true;

            break;
        }

        case ChatActionType.LeaveRoomSucceeded:
        case ChatActionType.LeaveRoomFailed: {
            const {roomId} = action;

            if (currentRoom && currentRoom.roomid === roomId) {
                currentRoom = null;
                updateRequired = true;
            }

            break;
        }

        case ChatActionType.RoomStatusChanged: {
            const {event: {room}} = action;

            if (currentRoom && room && currentRoom.roomid === room.roomid) {
                currentRoom = room;
                updateRequired = true;
            }

            break;
        }

        default:
    }

    if (updateRequired) {
        return calculateState(currentUser, currentRoom);
    }

    return state;
}
