import React from "react";
import {LobbyType} from "../../../domain/LobbyType";
import {RoomId} from "../../../domain/RoomId";
import {RoomMode} from "../../../domain/RoomMode";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {ProgressScreen} from "../../ProgressScreen";
import {ErrorLobby} from "./ErrorLobby";
import {ExclusiveLobby} from "./ExclusiveLobby";
import {GuestNotAllowedLobby} from "./GuestNotAllowedLobby";
import {KickedLobbyOfferNext} from "./KickedLobbyOfferNext";
import {KickedLobbyOfferTip} from "./KickedLobbyOfferTip";
import {NotEnoughCreditLobby} from "./NotEnoughCreditLobby";
import {OutOfCreditLobby} from "./OutOfCreditLobby";
import {PauseLobby} from "./PauseLobby";
import {PrivateLobby} from "./PrivateLobby";
import {RoomNotFoundLobby} from "./RoomNotFoundLobby";
import {RoomRejoinedLobby} from "./RoomRejoinedLobby";
import {TerminatedLobbyOfferNext} from "./TerminatedLobbyOfferNext";
import {TerminatedLobbyOfferTip} from "./TerminatedLobbyOfferTip";
import {VipLobby} from "./VipLobby";

interface Props extends LobbyHolder {
    join: (roomId: RoomId, roomMode: RoomMode) => void
}

export const Lobby = React.memo<Props>(({lobby, join}) => {
    const {type} = lobby;

    if (type === LobbyType.ModeChangeInProgress) {
        return <ProgressScreen/>;
    }

    if (type === LobbyType.KickedOfferNext) {
        return <KickedLobbyOfferNext lobby={lobby}/>;
    }

    if (type === LobbyType.KickedOfferTip) {
        return <KickedLobbyOfferTip lobby={lobby}/>;
    }

    if (type === LobbyType.PausedInRoom || type === LobbyType.PausedOnJoin) {
        return <PauseLobby lobby={lobby}/>;
    }

    if (type === LobbyType.Vip) {
        return <VipLobby action={() => join(lobby.roomid, lobby.roomMode)} lobby={lobby}/>;
    }

    if (type === LobbyType.Private) {
        return <PrivateLobby lobby={lobby} action={() => join(lobby.roomid, lobby.roomMode)}/>;
    }

    if (type === LobbyType.Exclusive || type === LobbyType.RoomIsExclusive) {
        return <ExclusiveLobby lobby={lobby}/>;
    }

    if (type === LobbyType.TerminatedOfferNext) {
        return <TerminatedLobbyOfferNext lobby={lobby}/>;
    }

    if (type === LobbyType.TerminatedOfferTip) {
        return <TerminatedLobbyOfferTip lobby={lobby}/>;
    }

    if (type === LobbyType.GuestNotAllowed) {
        return <GuestNotAllowedLobby lobby={lobby}/>;
    }

    if (type === LobbyType.RoomNotFound) {
        return <RoomNotFoundLobby/>;
    }

    if (type === LobbyType.OutOfCredit) {
        return <OutOfCreditLobby lobby={lobby}/>;
    }

    if (type === LobbyType.NotEnoughCredits) {
        return <NotEnoughCreditLobby lobby={lobby}/>;
    }

    if (type === LobbyType.RoomRejoined) {
        return <RoomRejoinedLobby lobby={lobby}/>;
    }

    return <ErrorLobby/>;
});
