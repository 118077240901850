import {ClickAwayListener, Collapse, IconButton, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import React, {Fragment, PureComponent, ReactNode} from "react";
import intl from "react-intl-universal";
import {connect} from "react-redux";
import {signOut} from "../../action/auth.action";
import {MemberUser} from "../../domain/authentication/User";
import {Locale} from "../../domain/Locale";
import {redirectToSettings, redirectToTopUp} from "../../redirect";
import {ToggleLocale} from "../i18n/ToggleLocale";

interface Props {
    i18n: Locale
    user: MemberUser
    credit: number
    signOut
}

interface State {
    open: boolean
}

class Menu extends PureComponent<Props, State> {
    state = {
        open: false
    };

    anchorRef: HTMLElement;

    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setAnchorRef = this.setAnchorRef.bind(this);
    }

    private setAnchorRef(ref) {
        this.anchorRef = ref;
    }

    private toggle() {
        this.setState(state => ({open: !state.open}));
    };

    private close(event) {
        if (this.anchorRef.contains(event.target)) {
            return;
        }

        this.setState({open: false});
    };

    render(): ReactNode {
        const {user, credit, signOut} = this.props;
        const {open} = this.state;

        return (
            <Fragment>
                <IconButton
                    style={{paddingLeft: 8}}
                    ref={this.setAnchorRef}
                    aria-owns={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={this.toggle}>
                    <img src="/images/icon-menu.svg" alt="menu" width={32} height={32}/>
                </IconButton>
                <Popper open={open} anchorEl={this.anchorRef} transition disablePortal placement="bottom-end">
                    {({TransitionProps, placement}) => (
                        <Collapse
                            {...TransitionProps}

                            // id="menu-list-grow"
                            style={{transformOrigin: "center bottom"}}>
                            <Paper style={{color: "white", backgroundColor: "rgba(0,0,0,0.95)"}}>
                                <ClickAwayListener touchEvent="onTouchStart" onClickAway={this.close}>
                                    <MenuList>
                                        <MenuItem>{user.auth.userName}</MenuItem>
                                        <MenuItem onClick={redirectToSettings}>{intl.get("menu.settings")}</MenuItem>
                                        <MenuItem onClick={redirectToTopUp}><span className={["credit", credit < 1000 ? "credit-low" : "credit-ok"].join(" ")}>{intl.get("menu.credits", {credit})}</span></MenuItem>
                                        <MenuItem><ToggleLocale/></MenuItem>
                                        <MenuItem onClick={signOut}>{intl.get("menu.signout")}</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Collapse>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    i18n: state.i18n,
    credit: state.credit,
    user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(signOut())
});

const component = connect(mapStateToProps, mapDispatchToProps)(Menu);

export {
    component as Menu
};
