import {LOCATION_CHANGE} from "connected-react-router";
import {Store} from "redux";
import {filter, map, pluck} from "rxjs/operators";
import {releaseReceived, requestReload, UpdateActionType} from "../action/update.action";
import {ReleaseReceived} from "../domain/events";
import {RoomService} from "../service/RoomService";

const releaseRe = /^(\d{6})-(\d{4})$/;
const parse = (value: string): number => {
    const parsed = releaseRe.exec(value);

    return parsed ? parseInt(parsed[1].concat(parsed[2]), 10) || null : null;
};

const isReloadRequired = (next: string) => {
    let current = process.env.REACT_APP_RELEASE;

    if (current === "development") {
        return false; // set to true during testing in development
    }

    // current should never be null
    const currentParsed = parse(current);

    // if next is null then is not compatible with this version's parser
    const nextParsed = parse(next);

    if (nextParsed === null || currentParsed === null) {
        return false;
    }

    return currentParsed < nextParsed;
};

export const updateMiddleware = (roomService: RoomService) => (store: Store) => {
    let isUpdateAvailable = false;

    roomService.events.pipe(
        filter(event => event instanceof ReleaseReceived),
        pluck("release"),
        map(releaseReceived)
    ).subscribe(store.dispatch);

    return next => action => {
        switch (action.type) {
            case UpdateActionType.ReleaseReceived: {
                if (isReloadRequired(action.release)) {
                    isUpdateAvailable = true;
                    store.dispatch(requestReload());
                }

                break;
            }

            case LOCATION_CHANGE: {
                if (isUpdateAvailable) {
                    store.dispatch(requestReload());
                }

                break;
            }

            default:
        }

        return next(action);
    };
};
