import {event, initialize, pageview, set} from "react-ga";
import {config} from "../config/analytics";
import {AuthActionType} from "../action/auth.action";
import {User} from "../domain/authentication/User";
import {LOCATION_CHANGE} from "connected-react-router";
import {FeatureActionType} from "../action/feature.action";
import {Store} from "redux";

export const analyticsMiddleware = () => (store: Store) => {

    initialize(config.trackingId, {
        titleCase: false
    });

    return next => action => {
        const {type} = action;

        switch (type) {
            case FeatureActionType.RequestFeature: {
                const {feature} = action;

                event({
                    category: "feature",
                    action: "request",
                    label: `request "${feature}"`
                });

                break;
            }

            case FeatureActionType.FeatureDisabled: {
                const {feature, reason} = action;

                event({
                    category: "feature",
                    action: "disabled",
                    label: `"${feature}" is disabled due to "${reason}"`
                });

                break;
            }

            case LOCATION_CHANGE: {
                pageview(action.payload.location.pathname);

                break;
            }

            case AuthActionType.UserSet: {
                const user: User = action.user;

                if (user && user.auth.memberID) {
                    set({
                        userId: user.auth.memberID
                    });
                }

                break;
            }
        }

        return next(action);
    };
};
