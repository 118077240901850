import React from "react";
import {Button, createStyles, WithStyles, withStyles} from "@material-ui/core";
import {Clickable} from "./contract/Clickable";
import {Disableable} from "./contract/Disableable";

const styles = createStyles({
    root: {
        borderRadius: 0,
        height: "44px",
        minHeight: "44px"
    },
    label: {
        flexDirection: "column",
        lineHeight: "15px"
    },
    subLabel: {
        fontSize: "10px",
        color: "#3A6272"
    }
});

interface Props extends WithStyles<typeof styles>, Clickable, Disableable {
    label: string
    subLabel?: string
}

export const SubLabelButton = withStyles(styles)(React.memo<Props>(({disabled = false, classes, label, subLabel, onClick}) => (
    <Button disabled={disabled} onClick={onClick} classes={{root: classes.root, label: classes.label}}>
        <span>{label}</span>

        {
            subLabel && <span className={classes.subLabel}>{subLabel}</span>
        }
    </Button>
)));
