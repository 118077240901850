import fscreen from "fscreen";
import {Store} from "redux";
import {
    ApplicationActionType,
    enteredFullscreen,
    exitedFullscreen,
    hideDialog,
    showDialog
} from "../action/application.action";
import {ChatActionType} from "../action/chat.action";
import {ScreenActionType} from "../action/screen.action";
import {Dialog} from "../domain/Dialog";
import {MouseClickEvent} from "../domain/MouseClickEvent";
import {Orientation} from "../domain/Orientation";

export const applicationMiddleware = (stages: Array<string>) => (store: Store) => {
    let check = action => {
        const index = stages.indexOf(action.type);

        if (index > -1) {
            stages.splice(index, 1);
        }

        if (check && stages.length === 0) {
            check = null;

            store.dispatch({
                type: ApplicationActionType.Ready
            });
        }
    };

    const hrefRe = /^\/(terms|privacy)$/;
    const registerListeners = () => {
        document.addEventListener("click", (event: MouseClickEvent) => {
            const {target} = event;
            const href: string = target.getAttribute("href");
            const matches = href ? hrefRe.exec(href) : null;

            if (matches) {
                switch (matches[1]) {
                    case Dialog.TERMS:
                    case Dialog.PRIVACY:
                        store.dispatch(showDialog(matches[1] as Dialog));
                        break;
                    default:
                        store.dispatch(hideDialog());
                }

                event.preventDefault();

                return false;
            }
        }, false);

        document.addEventListener("visibilitychange", () => updateVisibility(false), false);
        fscreen.addEventListener("fullscreenchange", updateFullscreen, false);
    };

    const updateFullscreen = () => {
        if (fscreen.fullscreenElement) {
            store.dispatch(enteredFullscreen());
        } else {
            store.dispatch(exitedFullscreen());
        }
    };

    const updateVisibility = (isFirstTime: boolean) => {
        if (document.hidden) {
            store.dispatch({
                type: ApplicationActionType.BecameInvisible
            });
        } else {
            store.dispatch({
                type: ApplicationActionType.BecameVisible,
                isFirstTime
            });

        }
    };

    return next => action => {
        const {type} = action;
        const result = next(action);

        switch (type) {
            case ScreenActionType.OrientationChanged: {
                if (action.screen.orientation === Orientation.Portrait) {
                    if (fscreen.fullscreenElement) {
                        fscreen.exitFullscreen();
                    }
                }

                break;
            }

            case ApplicationActionType.ToggleFullscreen: {
                if (fscreen.fullscreenElement) {
                    fscreen.exitFullscreen();
                } else {
                    fscreen.requestFullscreen(document.documentElement);

                    const orientation = window?.screen?.orientation;

                    if (orientation && orientation.type === "portrait-primary") {
                        orientation.lock("landscape").catch(error => {
                            console.error(error);
                        });
                    }
                }

                break;
            }

            case ChatActionType.ExitShow: {
                if (fscreen.fullscreenElement) {
                    window?.screen?.orientation?.unlock();
                }

                break;
            }

            case ApplicationActionType.Start: {
                registerListeners();
                updateVisibility(true);

                break;
            }

            default:
        }

        check && check(action);

        return result;
    };
};
