import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import {Clickable} from "../contract/Clickable";
import {Disableable} from "../contract/Disableable";

const style: React.CSSProperties = {
    top: "5px",
    right: "5px",
    position: "absolute"
};

interface Props extends Disableable, Clickable {
    className?: string
}

export const ExitFab = React.memo<Props>(({disabled, className, onClick}) => (
    <Fab disabled={disabled} color="secondary" className={className} style={style} onClick={onClick}>
        <CloseIcon/>
    </Fab>
));
