import {ChangeEmail} from "../../domain/settings/change-email/ChangeEmail";
import {ChangeEmailStep} from "../../domain/settings/change-email/ChangeEmailStep";
import {NewsletterSettings} from "../../domain/settings/newsletter/NewsletterSettings";
import {NotificationLanguage} from "../../domain/settings/newsletter/NotificationLanguage";
import {NotificationSettings} from "../../domain/settings/newsletter/NotificationSettings";
import {RoomListAppearance} from "../../domain/settings/roomlist/RoomListAppearance";
import {SettingsResult} from "../../domain/settings/SettingsResult";

interface SettingsResultDto {
    result: {
        success: boolean
        message: { [K: string]: string }
    }
}

const extractFieldErrors = (map: { [K: string]: string }, {result}: SettingsResultDto): SettingsResult => {
    return {
        result: {
            success: result.success,
            ...Object.entries(result.message).reduce((output, [key, message]) => {
                if (map[key]) {
                    output.fields[map[key]] = message;
                } else {
                    output.message = message;
                }

                return output;
            }, {
                message: null as string,
                fields: {}
            })
        }
    } as SettingsResult;
};

export const extractDataFromChangeEmailResponse = (response: SettingsResultDto & ChangeEmail & { currentChange: boolean }): SettingsResult & ChangeEmail => {
    return {
        currentEmail: response.currentEmail,
        currentChangeEmail: response.currentChangeEmail,
        step: response.currentChange ? ChangeEmailStep.ACTIVATION : ChangeEmailStep.MODIFY,
        ...(response.result ? extractFieldErrors({
            "myaccount_email_passwdmismatch": "password",
            "myaccount_email_emailinvalid": "email",
            "myaccount_email_emailused": "email"
        }, response) : {} as SettingsResult)
    };
};

export const extractDataFromChangePasswordResponse = (response: SettingsResultDto): SettingsResult => {
    return extractFieldErrors({
        "myaccount_password_passwdmismatch": "password",
        "join_err_passwdinvalid": "newPassword",
        "join_err_passwdmismatch": "newPasswordAgain"
    }, response);
};

export const extractDataFromDeleteAccountResponse = (response: SettingsResultDto): SettingsResult => {
    return extractFieldErrors({
        "myaccount_delete_err_passwdmismatch": "password",
        "myaccount_delete_err_invalidcaptcha": "captcha"
    }, response);
};

export const extractDataFromNewsletterSettingsResponse = (response: SettingsResultDto & NewsletterSettings): SettingsResult & NewsletterSettings => {
    return {
        ...response,
        emailLanguage: response.emailLanguage || NotificationLanguage.ENGLISH,
        emailPrivacy: response.emailPrivacy || NotificationSettings.ALL,
        ...(response.result ? extractFieldErrors({}, response) : {} as SettingsResult)
    };
};

export const extractDataFromRoomListAppearanceResponse = (response: SettingsResultDto & RoomListAppearance): SettingsResult & RoomListAppearance => {
    return {
        ...response,
        ...(response.result ? extractFieldErrors({}, response) : {} as SettingsResult)
    };
};
