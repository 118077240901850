import {Locale} from "../domain/Locale";

export enum I18nActionType {
    ChangeLocale = "i18n/change",
    LocaleSet = "i18n/set"
}

export const changeLocale = (code: string) => ({
    type: I18nActionType.ChangeLocale,
    code
});

export const localeSet = (locale: Locale) => ({
    type: I18nActionType.LocaleSet,
    locale
});
