import {Typography} from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";
import {LobbyHolder} from "../../contract/LobbyHolder";
import {CircleProfilePicture} from "../CircleProfilePicture";
import {ComingUpNextLobby} from "./ComingUpNextLobby";
import {useStyles} from "./styles";

interface Props extends LobbyHolder {
}

export const TerminatedLobbyOfferNext = React.memo<Props>(({lobby}) => {
    const classes = useStyles();
    const title = intl.get("lobby.terminated-offer-next.title", lobby);

    return (
        <ComingUpNextLobby title={title}>
            <CircleProfilePicture src={lobby?.publicData?.profilePic} mood="normal"/>
            <Typography className={classes.message}>{title}</Typography>
        </ComingUpNextLobby>
    );
});
