import {Screen} from "../reducer/screen.reducer";

export enum ScreenActionType {
    OrientationChanged = "screen/orientation-changed"
}

export const changeOrientation = (screen: Screen) => ({
    type: ScreenActionType.OrientationChanged,
    screen
});
