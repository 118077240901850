import React, {PureComponent, ReactNode} from "react";
import {connect} from "react-redux";
import {Locale} from "../../domain/Locale";
import {changeLocale} from "../../action/i18n.action";
import IconButton from "@material-ui/core/IconButton";

interface Props {
    i18n: Locale,
    change: (code: string) => void
}

class IconToggleLocale extends PureComponent<Props> {
    transition = {
        en: "hu",
        hu: "en"
    };

    label = {
        hu: "magyar verzió",
        en: "english version"
    };

    render(): ReactNode {
        const {i18n, change} = this.props;
        const to = this.transition[i18n.code] || "en";
        const label = this.label[to];

        return (
            <IconButton onClick={() => change(to)} title={label}>
                <img alt={label} src={`/images/flag-${to}.svg`} width={18} height={18}/>
            </IconButton>
        );
    }
}

const mapStateToProps = state => ({
    i18n: state.i18n
});

const mapDispatchToProps = dispatch => ({
    change: (code: string) => dispatch(changeLocale(code))
});

const component = connect(mapStateToProps, mapDispatchToProps)(IconToggleLocale);

export {
    component as IconToggleLocale
};
