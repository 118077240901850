export enum RoomMode {
    Free = "free",
    Group = "group",
    Private = "private",
    Exclusive = "exclusive",
    Pause = "pause",

    // TODO double check these
    Unknown = "unknown",
    Voyeur = "voyeur"
}
