import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducer";
import {baseColor} from "../theme";

const useStyles = makeStyles(() => ({
    label: {
        color: baseColor
    }
}));

const showCloseButtonTimeoutThresholdMs = 3000;

export const Snackbar = React.memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const snack = useSelector((store: RootState) => store.snackbar.snack);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        if (snack) {
            const {button, id, label, timeout} = snack;
            const hasButton = Boolean(button);
            const showClose = hasButton === false || timeout > showCloseButtonTimeoutThresholdMs;

            enqueueSnackbar(label, {
                action: () => (
                    <>
                        {
                            hasButton && <Button
                                classes={{label: classes.label}}
                                onClick={() => {
                                    closeSnackbar(id);

                                    if (button.action) {
                                        dispatch(button.action);
                                    }
                                }} variant="text">
                                {button.label}
                            </Button>
                        }
                        {
                            showClose && <IconButton onClick={() => {
                                closeSnackbar(id);
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        }
                    </>
                ),
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                preventDuplicate: true,
                autoHideDuration: timeout,
                key: id,
                variant: "info"
            });
        } else {
            closeSnackbar();
        }
    }, [classes.label, closeSnackbar, dispatch, enqueueSnackbar, snack]);

    return null;
});
