import {Box, Dialog, Grid, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import {FavoriteTwoTone, Search as SearchIcon} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import React, {ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import intl from "react-intl-universal";
import {useSelector} from "react-redux";
import {RoomDto} from "../../domain/RoomDto";
import {RoomId} from "../../domain/RoomId";
import {redirectToRoom} from "../../redirect";
import {RootState} from "../../reducer";
import {ExitFab} from "../room/ExitFab";
import {TextWithEmoji} from "../TextWithEmoji";

const useStyles = makeStyles({
    icon: {
        padding: 8
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0.9)"
    },
    exit: {
        // @ts-ignore
        position: "fixed !important"
    },
    roomRoot: {
        width: 120,
        margin: 0
    },
    wrapper: {
        width: 120,
        height: 120,
        position: "relative"
    },
    imageWrapper: {
        border: "2px solid #fff",
        borderRadius: "50%",
        overflow: "hidden",
        width: "100%",
        height: "100%"
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    favoriteBadge: {
        width: 40,
        height: 40,
        position: "absolute",
        borderRadius: "50%",
        border: "2px solid white",
        right: 0,
        bottom: 0,
        backgroundColor: "#21ACE4",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > svg > path:first-child": {
            fill: "#ff5a79",
            opacity: 1
        },
        "& > svg > path:last-child": {
            color: "#fff"
        }
    },
    screenName: {
        textAlign: "center",
        marginTop: "1em",
        marginBottom: "2em"
    },
    searchBox: {
        padding: "4em 3em 2em 3em"
    },
    inputRoot: {
        backgroundColor: "#0B0B0B"
    },
    notchedOutline: {
        borderRadius: 4
    },
    message: {
        textAlign: "center"
    },
    matches: {
        justifyItems: "center",
        padding: "0 2em",
        flex: 1,
        "& > div": {
            display: "flex",
            justifyContent: "center"
        }
    }
});

interface RoomProps {
    roomId: RoomId
    picture: string
    favored: boolean
    screenName: string
}

const Room = React.memo<RoomProps>(({favored, screenName, roomId, picture}) => {
    const classes = useStyles();
    const handleClick = useCallback(() => redirectToRoom(roomId), [roomId]);

    return (
        <Fragment>
            <figure onClick={handleClick} className={classes.roomRoot}>
                <div className={classes.wrapper}>
                    <div className={classes.imageWrapper}>
                        <img className={classes.image} src={picture} alt={screenName}/>
                    </div>

                    {
                        favored &&
                        <div className={classes.favoriteBadge}><FavoriteTwoTone fontSize={"small"}/></div>
                    }

                </div>
                <Typography
                    component={"figcaption"}
                    className={classes.screenName}
                    variant={"body2"}
                ><TextWithEmoji text={screenName}/></Typography>
            </figure>
        </Fragment>
    );
});

const SearchScreen = () => {
        const classes = useStyles();
        const rooms = useSelector<RootState, Array<RoomDto>>(root => root.rooms.rooms);
        const favoredRoomIds = useSelector<RootState, Array<RoomId>>(root => root.favorite.roomIds);
        const [matches, setMatches] = useState<Array<RoomDto>>([]);
        const [query, setQuery] = useState<string>("");
        const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value), []);
        const suggestions = useMemo(() => {
            const favoredRooms: Array<RoomDto> = favoredRoomIds.length > 0
                ? rooms.filter(room => favoredRoomIds.includes(room.roomid))
                : [];
            return favoredRooms.length > 0 ? favoredRooms : rooms.slice(0, 4);
        }, [favoredRoomIds, rooms]);

        useEffect(() => {
            if (query.length > 0) {
                setMatches(rooms.filter(room => room.screenName.includes(query)));
            } else {
                setMatches(suggestions);
            }
        }, [rooms, query, suggestions]);

        return (
            <Fragment>
                <Box className={classes.searchBox}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={query}
                        onChange={handleChange}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                notchedOutline: classes.notchedOutline
                            },
                            endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>
                        }}
                    />
                </Box>

                {
                    matches.length === 0 &&
                    <Box className={classes.message}>
                        {
                            query.length === 0 &&
                            <Typography>{intl.get("search.no-rooms")}</Typography>
                        }

                        {
                            query.length > 0 &&
                            <Typography>{intl.get("search.no-match")}</Typography>
                        }
                    </Box>
                }

                {
                    matches.length > 0 &&
                    <Grid container className={classes.matches}>
                        {
                            matches.map(room =>
                                <Grid item key={room.roomid} xs={12} sm={6} md={4} lg={3} xl={1}>
                                    <Room
                                        roomId={room.roomid}
                                        screenName={room.screenName}
                                        favored={favoredRoomIds.includes(room.roomid)}
                                        picture={room?.publicData?.profilePic}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                }
            </Fragment>
        );
    }
;

export const Search = () => {
        const [opened, setOpened] = useState<boolean>(false);
        const classes = useStyles();

        const open = useCallback(() => {
            setOpened(true);
        }, []);

        const close = useCallback(() => {
            setOpened(false);
        }, []);

        return (
            <Fragment>
                <IconButton onClick={open} className={classes.icon}>
                    <img src="/images/icon-search.svg" alt="search" width={32} height={32}/>
                </IconButton>
                <Dialog open={opened} classes={{paper: classes.dialogPaper}}>
                    <ExitFab onClick={close} className={classes.exit}/>
                    <SearchScreen/>
                </Dialog>
            </Fragment>
        );
    }
;
