import {useLayoutEffect} from "react";

const storage: Map<string, [number, number]> = new Map();

// TODO should maintain any container's scroll position
export const useGlobalScrollPosition = <T extends HTMLElement>(expireAfter: number = 30000): void => {
    const root = document.getElementById("root");
    const key = "global";

    useLayoutEffect(() => {
        const [position, savedAt] = storage.get(key) || [0, 0];
        const isActive = Date.now() < savedAt + expireAfter;

        if (position > 0 && isActive && root.scrollTo) {
            root.scrollTo(0, position);
        }

        return () => {
            storage.set(key, [root.scrollTop, Date.now()]);
        };
    }, [root, key, expireAfter]);
};
